import React, { useRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

export default function CustomizedGraph(props) {
  const { style, children, title, start, onUpdate, disabled, id } = props;
  const [open, setOpen] = useState(start ? start : true);
  const divRef = useRef();

  useEffect(() => {
    //define o tamanho da div com conteudo
    let pxTotal = 40;
    const cRef = divRef.current.childNodes;
    for (let i = 0; i < cRef.length; i++) {
      pxTotal += cRef[i].clientHeight;
    }
    divRef.current.style.height = pxTotal + 'px';
  }, [children]);

  const act = () => {
    if (onUpdate) onUpdate(!open);
    setOpen(!open);
    const dF = divRef.current;
    if (!dF.className.includes('anime-close')) {
      dF.classList.toggle("anime-close");
    } else {
      dF.classList.remove("anime-close");
    }
    
  };

  const arrow = <div style={{ position: 'absolute', right: '20px' }}>
    <div style={{ position: 'absolute', width: '6px', height: '2px', borderRadius: '2px', backgroundColor: 'gray', transition: 'transform .5s', transform: 'rotate(' + (open ? -40 : 40) + 'deg)', right: '-3px' }}></div>
    <div style={{ position: 'absolute', width: '6px', height: '2px', borderRadius: '2px', backgroundColor: 'gray', transition: 'transform .5s', transform: 'rotate(' + (open ? 40 : -40) + 'deg)' }}></div>
  </div>;

  return (
    <div id={id} style={style}>
      <Button disabled={disabled} style={{ width: '100%', height: '2.3rem', borderTop: 'solid 1px rgba(0, 0, 0, 0.12)', borderBottom: !open?'solid 1px rgba(0, 0, 0, 0.12)':'' }} onClick={() => act()}><h3 style={{ marginTop: '.6rem', color: (disabled ? '#e0e0e0' : '#000') }}>{title}</h3>{!disabled ? arrow : null}</Button>
      <div ref={divRef} className={disabled ? 'anime anime-close' : 'anime'}>
        {children}
      </div>
    </div>
  );
}
