import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";

import { notification } from 'antd';

//import { withTranslation } from "react-i18next";

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}



class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, res } = this.props;

    const columnData = [      
      { id: 'NAME', disablePadding: true, label: 'Name' },
      { id: 'SERIAL', disablePadding: true, label: 'Serial' },
      { id: 'TYPO', disablePadding: true, label: 'Tipo' },
      { id: 'STATUS', disablePadding: true, label: 'Status' },
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          <TableCell padding="checkbox">
            {res.ACAO}
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight: {
    color: '#6c76ff',
    backgroundColor: '#d7d9e0',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, deleteButton, handleChange, filtro, clear, link, res } = props;

  const limpaCampo = () => {
    document.getElementById('campoFiltro').value = '';
    clear();
  }

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit">
            {numSelected} {res.SELECIONADO}
          </Typography>
        ) : (
          <Typography variant="h4" id="tableTitle">
            {res.MODULO}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={() => deleteButton()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div style={{ width: '14rem' }}>
            <SearchIcon style={{ marginRight: '.5rem', marginTop: '1.5rem' }} />
            <TextField
              placeholder={res.FILTRO}
              margin="normal"
              onChange={handleChange('filtro')}
              id="campoFiltro"
            />
            {(filtro === '') ? <div></div> :
              <IconButton style={{ position: 'absolute', marginTop: '.5rem', right: '3rem' }} onClick={() => limpaCampo()}>
                <MaterialIcon icon="clear" />
              </IconButton>
            }
          </div>
        )}
      </div>
      <div className={classes.actions}>
        <Tooltip title="Add">
          <IconButton aria-label="Add" onClick={event => { link('/app/cadastro/modulo/add') }} >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 600,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'usuarios',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
      error: null,
      filtro: '',
    };
  }

  componentDidMount() {
    this.updateList();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  clear = () => {
    this.setState({
      filtro: ''
    });
  };

  updateList = () => {
    const apiUrl = Config.server + '/module';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  deleteUser = (id) => {
    const { selected } = this.state;
    const apiUrl = Config.server + '/module';
    const formData = new URLSearchParams();
    let descr = '';

    if (isNaN(id)) {
      formData.append('ids', selected);
      descr = this.props.res.ITENS_EXCLUIDOS;
    }
    else {
      formData.append('ids', id);
      descr = this.props.res.ITEM_EXCLUIDO;
    }

    const options = {
      method: 'DELETE',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ selected: [] });

          notification.open({
            message: this.props.res.SUCESSO,
            description: descr,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });

          this.updateList();
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, link, res } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    /*const deleteIcon = id => (
      <IconButton onClick={() => this.deleteUser(id)}>
        <DeleteIcon color="secondary" />
      </IconButton>
    );*/

    const editIcon = id => (
      <IconButton onClick={event => (link('/app/cadastro/modulo/edit/' + id))} >
        <EditIcon color="primary" />
      </IconButton>
    );

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length} deleteButton={this.deleteUser} filtro={this.state.filtro} handleChange={this.handleChange} clear={this.clear} link={link} res={res} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" style={{ tableLayout: 'auto' }}>

            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              res={res}
            />
            <TableBody>
              {data
                .filter(item => {
                  return (
                    item.name.includes(this.state.filtro) || 
                    item.serial.includes(this.state.filtro) || 
                    item.type.includes(this.state.filtro) || 
                    item.status.includes(this.state.filtro)
                  );
                })
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell className="check" padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      <TableCell
                        className="coluna"
                        padding="checkbox"
                      >
                        {editIcon(n.id)}

                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                      <TableCell component="th" scope="row" padding="none">{n.serial}</TableCell>
                      <TableCell component="th" scope="row" padding="none">{n.type}</TableCell>
                      <TableCell component="th" scope="row" padding="none">{n.status}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': res.PAGINA_ANTERIOR,
          }}
          nextIconButtonProps={{
            'aria-label': res.PROXIMA_PAGINA,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const EnhancedTable1 = withStyles(styles)(EnhancedTable);

const Section = (props) => {
  const { link, res } = props;
  return (
    <div className="rowForm">
      <EnhancedTable1 link={link} res={res} />
    </div>)
}

export default Section;
