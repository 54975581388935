import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { useParams } from "react-router";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Plane {
  constructor() {
    this.qtd = 0;
    this.totalHora = 0;
    this.combust = 0;
    this.manut = 0;
    this.pilot = 0;
    this.seg = 0;
    this.finan = 0;
    this.outro = 0;
    this.oleo = 0;
    this.total = 0;
  }
}

class Edit extends React.Component {
  state = {
    aero_pistao: false,
    veic_pistao: false,
    aero_turbina: false,
    veic_turbina: false,
    pistao: new Plane(),
    pistaoApoio: new Plane(),
    turbina: new Plane(),
    turbinaApoio: new Plane(),

    aluguel: 0,
    luz: 0,
    agua: 0,
    internet: 0,
    iptu: 0,
    manutencao_predial: 0,
    material_escritorio: 0,
    patio_descontaminacao: 0,
    seguros: 0,
    alimentação: 0,
    hospedagem: 0,
    agronomo: 0,
    salário_funcionarios: 0,
    contabilidade: 0,
    investimento_estrutura: 0,
    outros: 0,
    total: 0,

    totalCPlane: 0,
    totalTPlane: 0,
    iavag: 11.04,
    totalCHPlane: 0,
    totalTHPlane: 0,

    list_data: []
  }

  componentDidMount() {
    this.loadData();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    }, () => { setTimeout(() => { this.somaValores() }, 50) });
  };
  handleChangeItem = (type, name) => event => {
    let item = this.state[type];
    item[name] = event.target.value;
    this.setState({
      type: item,
    }, () => { setTimeout(() => { this.somaValores() }, 50) });
  };

  somaValores = () => {
    let state = this.state;
    let pistao = state.pistao;
    let pistaoApoio = state.pistaoApoio;
    let turbina = state.turbina;
    let turbinaApoio = state.turbinaApoio;

    let struct = Number(state.aluguel) + Number(state.luz) + Number(state.agua) + Number(state.internet) + Number(state.iptu) +
      Number(state.manutencao_predial) + Number(state.material_escritorio) + Number(state.patio_descontaminacao) +
      Number(state.seguros) + Number(state.alimentação) + Number(state.hospedagem) + Number(state.agronomo) +
      Number(state.salário_funcionarios) + Number(state.contabilidade) + Number(state.investimento_estrutura) + Number(state.outros);

    let totalCPlane = 0;
    if (Number(pistao.totalHora) > 0) {
      totalCPlane = (Number(pistao.combust) + Number(pistao.manut) + Number(pistao.pilot) + Number(pistao.oleo) + 
        Number(pistao.seg) + Number(pistao.finan) + Number(pistao.finan) + Number(pistao.outro) + Number(pistaoApoio.combust) +
        Number(pistaoApoio.manut) + Number(pistaoApoio.pilot) + Number(pistaoApoio.seg) + Number(pistaoApoio.finan) +
        Number(pistaoApoio.finan) + Number(pistaoApoio.outro)) / Number(pistao.totalHora);
    }
    let totalTPlane = 0;
    if (Number(turbina.totalHora) > 0) {
      totalTPlane = (Number(turbina.combust) + Number(turbina.manut) + Number(turbina.pilot) +
        Number(turbina.seg) + Number(turbina.finan) + Number(turbina.finan) + Number(turbina.outro) + Number(turbinaApoio.combust) +
        Number(turbinaApoio.manut) + Number(turbinaApoio.pilot) + Number(turbinaApoio.seg) + Number(turbinaApoio.finan) +
        Number(turbinaApoio.finan) + Number(turbinaApoio.outro)) / Number(turbina.totalHora);
    }

    let per_pistao = 100 * Number(pistao.totalHora) / (Number(turbina.totalHora) + Number(pistao.totalHora));
    let per_turbina = 100 - per_pistao;

    totalCPlane += Number(pistao.totalHora) > 0 && (struct * (per_pistao / 100) / Number(pistao.totalHora));
    totalTPlane += Number(turbina.totalHora) > 0 && (struct * (per_turbina / 100) / Number(turbina.totalHora));

    let totalCHPlane = !isNaN(per_pistao) ? (totalCPlane * Number(state.iavag) / 100) + totalCPlane : 0;
    let totalTHPlane = !isNaN(per_turbina) ? (totalTPlane * Number(state.iavag) / 100) + totalTPlane : 0;

    this.setState({ totalCPlane: totalCPlane.toFixed(2), totalTPlane: totalTPlane.toFixed(2), totalCHPlane: totalCHPlane.toFixed(2), totalTHPlane: totalTHPlane.toFixed(2) }, () => {
      this.saveData();
    });
  }

  saveData = () => {
    localStorage.setItem('costData', JSON.stringify(this.state));
  }

  loadData = () => {
    let state = this.state;
    let data = localStorage.getItem('costData');
    if (data && data.length > 0) {
      Object.entries(JSON.parse(data)).forEach(([key, value]) => {
        state[key] = value;
      });
      this.setState(state);
    }
  }

  montaItensPlane = (type, item) => {
    let title = '';
    const res = this.props.res;
    if (type === 'a') {
      title = item === 'pistaoApoio' ? res.TITULO_VEICULO_AERONAVE_PISTAO : res.TITULO_VEICULO_AERONAVE_TURBINA;
    } else {
      title = item === 'pistao' ? res.TITULO_AERONAVE_PISTAO : res.TITULO_AERONAVE_TURBINA;
    }

    let values = this.state[item];

    let total = Number(values.combust) + Number(values.manut) + Number(values.pilot) + Number(values.seg) + Number(values.finan) + Number(values.outro);
    values.total = total;

    return <div className="form-group divContent" style={{ width: 'max-content', marginRight: '1rem' }}>
      <div style={{ padding: '1rem', width: '21rem' }}>
        <div>
          <h4>{title}</h4>
        </div>
        {type === 'p' &&
          <TextField
            id={item + 'pTotalHora'}
            label={res.TOTAL_DE_HORAS_VOADAS}
            value={this.state[item].totalHora}
            onChange={this.handleChangeItem(item, 'totalHora')}
            type="number"
            className="mb-4 notTooClose"
            size="small"
            margin="dense"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
            }}
          />
        }
        {item === 'pistao' &&
          <TextField
          id={item + 'pOleo'}
          label={res.OLEO}
          value={this.state[item].oleo}
          onChange={this.handleChangeItem(item, 'oleo')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        }
        <TextField
          id={item + 'pCombust'}
          label={res.COMBUSTIVEL}
          value={this.state[item].combust}
          onChange={this.handleChangeItem(item, 'combust')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id={item + 'pManut'}
          label={res.MANUTENCAO}
          value={this.state[item].manut}
          onChange={this.handleChangeItem(item, 'manut')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id={item + 'pPilot'}
          label={(type === 'p' ? res.PILOTO_ : res.EQUIPE_DE_SOLO)}
          value={this.state[item].pilot}
          onChange={this.handleChangeItem(item, 'pilot')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id={item + "pSeg"}
          label={res.SEGURO}
          value={this.state[item].seg}
          onChange={this.handleChangeItem(item, 'seg')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id={item + "pFinan"}
          label={res.FINANCIAMENTO}
          value={this.state[item].finan}
          onChange={this.handleChangeItem(item, 'finan')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id={item + "pOutro"}
          label={res.OUTROS}
          value={this.state[item].outro}
          onChange={this.handleChangeItem(item, 'outro')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          style={{ marginBottom: '1rem' }}
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        {this.structTotal(res.TOTAL, this.state[item].total)}
      </div>
    </div>
  }

  montaItensStrut = () => {
    const res = this.props.res;
    let total = Number(this.state.aluguel) + Number(this.state.luz) + Number(this.state.agua) + Number(this.state.internet) + Number(this.state.iptu) +
      Number(this.state.manutencao_predial) + Number(this.state.material_escritorio) + Number(this.state.patio_descontaminacao) + Number(this.state.seguros) +
      Number(this.state.alimentação) + Number(this.state.hospedagem) + Number(this.state.agronomo) + Number(this.state.salário_funcionarios) +
      Number(this.state.contabilidade) + Number(this.state.investimento_estrutura) + Number(this.state.outros);

    return <div className="form-group divContent" style={{ width: 'max-content', marginRight: '1rem' }}>
      <div style={{ padding: '1rem', width: '21rem' }}>
        <div>
          <h4>{this.props.res.ESTRUTURA}</h4>
        </div>
        <TextField
          id="aluguel"
          label={res.ALUGUEL}
          value={this.state.aluguel}
          onChange={this.handleChange('aluguel')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="luz"
          label={res.LUZ}
          value={this.state.luz}
          onChange={this.handleChange('luz')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="agua"
          label={res.AGUA}
          value={this.state.agua}
          onChange={this.handleChange('agua')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="internet"
          label={res.INTERNET_E_TELEFONIA}
          value={this.state.internet}
          onChange={this.handleChange('internet')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="iptu"
          label={res.IPTU_ITR}
          value={this.state.iptu}
          onChange={this.handleChange('iptu')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="manutencao_predial"
          label={res.MANUTENCAO_PREDIAL}
          value={this.state.manutencao_predial}
          onChange={this.handleChange('manutencao_predial')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="material_escritorio"
          label={res.MATERIAL_ESCRITORIO}
          value={this.state.material_escritorio}
          onChange={this.handleChange('material_escritorio')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="patio_descontaminacao"
          label={res.PATIO_DE_DESCONTAMINACAO}
          value={this.state.patio_descontaminacao}
          onChange={this.handleChange('patio_descontaminacao')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="seguros"
          label={res.SEGUROS}
          value={this.state.seguros}
          onChange={this.handleChange('seguros')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="alimentação"
          label={res.ALIMENTACAO}
          value={this.state.alimentação}
          onChange={this.handleChange('alimentação')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="hospedagem"
          label={res.HOSPEDAGEM}
          value={this.state.hospedagem}
          onChange={this.handleChange('hospedagem')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="agronomo"
          label={res.AGRONOMO}
          value={this.state.agronomo}
          onChange={this.handleChange('agronomo')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="salário_funcionarios"
          label={res.SALARIO_FUNCIONARIOS}
          value={this.state.salário_funcionarios}
          onChange={this.handleChange('salário_funcionarios')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="contabilidade"
          label={res.CONTABILIDADE}
          value={this.state.contabilidade}
          onChange={this.handleChange('contabilidade')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="investimento_estrutura"
          label={res.INVESTIMENTO_ESTRUTURA}
          value={this.state.investimento_estrutura}
          onChange={this.handleChange('investimento_estrutura')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        <TextField
          id="outros"
          label={res.OUTROS}
          value={this.state.outros}
          onChange={this.handleChange('outros')}
          type="number"
          className="mb-4 notTooClose"
          size="small"
          margin="dense"
          style={{ marginBottom: '1rem' }}
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
          }}
        />
        {this.structTotal(res.TOTAL, total)}
      </div>
    </div>
  }

  structTotal = (title, children) => {
    return <div style={{ height: '3rem', marginBottom: '1rem' }}>
      <div className="titleTextRel">
        {title}
      </div>
      <div className="contentTextRel" style={{ fontSize: '18px' }}>
        {children}
      </div>
      <div className="bLine"></div>
    </div>;
  }
  montaTotal = () => {
    const res = this.props.res;
    return (<div>
      <div>
        <h4>{this.props.res.TITULO_CUSTO_HORA}</h4>
      </div>
      {this.structTotal(res.CUSTO_HORA_AERONAVES_PISTAO, this.state.totalCPlane)}
      {this.structTotal(res.CUSTO_HORA_AERONAVES_TURBINA, this.state.totalTPlane)}
      <div>
        <h4>{this.props.res.TITULO_CUSTO_HORA_INFLACAO}</h4>
      </div>
      <TextField
        id="iavag"
        label={res.INDICE_IAVAG}
        value={this.state.iavag}
        onChange={this.handleChange('iavag')}
        type="number"
        className="mb-4 notTooClose"
        size="small"
        margin="dense"
        style={{ marginBottom: '1rem' }}
        fullWidth
        onInput={(e) => {
          e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
        }}
      />
      {this.structTotal(res.CUSTO_HORA_AERONAVES_PISTAO, this.state.totalCHPlane)}
      {this.structTotal(res.CUSTO_HORA_AERONAVES_TURBINA, this.state.totalTHPlane)}

      <a style={{ display: "table-cell", marginTop: '1rem' }} href="https://sindag.org.br/iavag-indice-de-inflacao-do-setor/" target="_blank" rel="noopener noreferrer">IAVAG – ÍNDICE DE INFLAÇÃO DO SETOR AEROAGRÍCOLA</a>
    </div>);
  }

  render() {
    //const { classes, link, res } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <div className="fixedTotal">
          <div className="form-group divContent" style={{ width: 'max-content', marginRight: '1rem' }}>
            <div style={{ padding: '1rem', width: '21rem' }}>
              {this.montaTotal()}
            </div>
          </div>
        </div>
        <div className="scrollItens">
          <div style={{ display: 'flex', flexFlow: 'row wrap', maxWidth: '50rem' }}>
            {this.montaItensPlane('p', 'pistao')}
            {this.montaItensPlane('a', 'pistaoApoio')}
            {this.montaItensPlane('p', 'turbina')}
            {this.montaItensPlane('a', 'turbinaApoio')}
          </div>
          {this.montaItensStrut()}
        </div>
      </div>

    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div>
      <TextFields1 link={link} id={id} res={res} />
    </div>)
}

export default Box;

