import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { useParams } from "react-router";
import tools from "./tools";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    velocidade: 150,
    faixa: 25,
    vazao: 3,
    nPulverizador: 20,
    coberta: 0,
    total: 0,
    vazaoPulverizador: 0,
    dsc: 99,
    tbico: 99,
    fabricante: 99,
    selected_in: [],
    selected_ex: [],
    list_local: [],
    list_data1: []
  }

  dsc_label = ['MUITO FINA', 'FINA', 'MÉDIA', 'GROSSA', 'MUITO GROSSA', 'EXTREMAMENTE GROSSA', 'ULT. GROSSA'];
  //['VERY FINE', 'FINE', 'MEDIUM', 'COARSE', 'VERY COARSE', 'EXT. COARSE', 'ULT. COARSE'];
  bico_label = ['JATO PLANO', 'JATO CÔNICO', 'ATOMIZADOR', 'ELETROSTÁTICO'];
  fabricante_label = ['TRAVICAR', 'TEEJET', 'CP', 'OUTROS'];//, 'MICRON', 'STOL', 'ZANONI', 'MICROSPIN', 'MICROBELL'

  componentDidMount() {
    let data = localStorage.getItem('callibracaoData');
    this.setState(JSON.parse(data), this.calcTotal);
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    }, () => {
      setTimeout(() => {
        this.saveStorage();
      }, 50)
    });
  };

  saveStorage = () => {
    localStorage.setItem('callibracaoData', JSON.stringify(
      {
        velocidade: this.state.velocidade,
        faixa: this.state.faixa,
        vazao: this.state.vazao,
        nPulverizador: this.state.nPulverizador,
        dsc: this.state.dsc,
        tbico: this.state.tbico,
        fabricante: this.state.fabricante,
        selected_in: this.state.selected_in,
        selected_ex: this.state.selected_ex,

      }
    ));
    this.calcTotal();
  }

  calcTotal = () => {
    this.setState(tools.calcTotal(this.state.velocidade, this.state.nPulverizador, this.state.vazao, this.state.faixa, this.state.dsc,
      this.state.fabricante, this.state.tbico, this.bico_label, this.fabricante_label));
  }

  structTotal = (title, children) => {
    return <div style={{ height: '3rem', marginBottom: '1rem' }}>
      <div className="titleTextRel">
        {title}
      </div>
      <div className="contentTextRel" style={{ fontSize: '18px' }}>
        {children}
      </div>
      <div className="bLine"></div>
    </div>;
  }

  groupBy = (list) => {
    if (list.length > 0) {
      const temp = [];
      for (let i = 0; i < list.length; i++) {
        if (!temp.includes(list[i][0])) {
          temp.push(list[i][0]);
        }
      }
      return temp;
    } else
      return [];
  };

  groupByLocal = (list) => {
    if (list.length > 0) {
      const temp = [];
      for (let i = 0; i < list.length; i++) {
        temp.push(list[i].rows[0][0]);
      }
      return temp;
    } else
      return [];
  };

  montaTotal = () => {
    //const res = this.props.res;

    return (<div style={{ marginTop: '-1rem' }}>
      <div>
        <h4>{"DADOS CALIBRAÇÃO"}</h4>
      </div>
      <TextField
        id="velocidade"
        label={'Velocidade (km/h)'}
        value={this.state.velocidade}
        onChange={this.handleChange('velocidade')}
        type="number"
        className="mb-4 notTooClose"
        size="small"
        margin="dense"
        style={{ marginBottom: '1rem' }}
        fullWidth
        onInput={(e) => {
          e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
        }}
      />
      <TextField
        id="faixa"
        label={'Faixa (m)'}
        value={this.state.faixa}
        onChange={this.handleChange('faixa')}
        type="number"
        className="mb-4 notTooClose"
        size="small"
        margin="dense"
        style={{ marginBottom: '1rem' }}
        fullWidth
        onInput={(e) => {
          e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
        }}
      />
      <TextField
        id="vazao"
        label={'Vazao Requerida (l/hec)'}
        value={this.state.vazao}
        onChange={this.handleChange('vazao')}
        type="number"
        className="mb-4 notTooClose"
        size="small"
        margin="dense"
        style={{ marginBottom: '1rem' }}
        fullWidth
        onInput={(e) => {
          e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
        }}
      />
      <TextField
        id="nPulverizador"
        label={'nº de Pulverizadores'}
        value={this.state.nPulverizador}
        onChange={this.handleChange('nPulverizador')}
        type="number"
        className="mb-4 notTooClose"
        size="small"
        margin="dense"
        style={{ marginBottom: '1rem' }}
        fullWidth
        onInput={(e) => {
          e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
        }}
      />

      {this.structTotal('Area Coberta (hec/min)', this.state.coberta.toFixed(2))}
      {this.structTotal('Vazao Total (l/min)', this.state.total.toFixed(2))}
      {this.structTotal('Vazao por Pulverizador (l/hec)', this.state.vazaoPulverizador.toFixed(3))}

      <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="age-simple">{'DSC'}</InputLabel>
        <Select
          value={this.state.dsc}
          onChange={this.handleChange('dsc')}
          inputProps={{
            id: 'dsc',
          }}
        >
          <MenuItem key={99} value={99}>{'TODOS'}</MenuItem>
          {this.dsc_label.map((item, key) => (
            <MenuItem key={key} value={key}>{item}</MenuItem>
          ))};
        </Select>
      </FormControl>

      <FormControl style={{ width: '100%', marginTop: '1rem' }}>
        <InputLabel htmlFor="age-simple">{'Tipo de Bico'}</InputLabel>
        <Select
          value={this.state.tbico}
          onChange={this.handleChange('tbico')}
          inputProps={{
            id: 'tbico',
          }}
        >
          <MenuItem key={99} value={99}>{'TODOS'}</MenuItem>
          {this.bico_label.map((item, key) => (
            <MenuItem key={key} value={key}>{item}</MenuItem>
          ))};
        </Select>
      </FormControl>
      <FormControl style={{ width: '100%', marginTop: '1rem' }}>
        <InputLabel htmlFor="age-simple">{'Fabricante'}</InputLabel>
        <Select
          value={this.state.fabricante}
          onChange={this.handleChange('fabricante')}
          inputProps={{
            id: 'fabricante',
          }}
        >
          <MenuItem key={99} value={99}>{'TODOS'}</MenuItem>
          {this.fabricante_label.map((item, key) => (
            <MenuItem key={key} value={key}>{item}</MenuItem>
          ))};
        </Select>
      </FormControl>
    </div>);
  }

  handleChangeSelectAdd = (newValue, selected) => {
    let temp = this.state[selected];
    if (!temp.includes(newValue)) {
      temp.push(newValue);
      this.setState({ [selected]: temp }, () => {
        setTimeout(() => {
          this.saveStorage();
        }, 50)
      });
    }
  }
  handleChangeSelectRemove = (newValue, selected) => {
    let temp = this.state[selected];
    let i = temp.indexOf(newValue);
    temp.splice(i, 1);
    this.setState({ [selected]: temp }, () => {
      setTimeout(() => {
        this.saveStorage();
      }, 50)
    });
  }


  render() {
    //const { classes, link, res } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <div className="fixedTotal">
          <div className="form-group divContent" style={{ width: 'max-content', marginRight: '1rem', marginTop: '2rem' }}>
            <div style={{ padding: '1rem', width: '13rem' }}>
              {this.montaTotal()}
            </div>
          </div>
        </div>
        <div className="scrollItens" style={{ marginLeft: '16rem' }}>
          <div style={{ display: 'flex', flexFlow: 'row wrap', padding: '1rem' }}>
            {this.state.list_local.map((item) => tools.montaItens(item))}
            {this.state.list_data1.map((item) => tools.montaItensEx(item))}
            {/*this.montaItensEx(this.state.list_data1)*/}
          </div>
        </div>
      </div>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div style={{ minHeight: 'calc(100vh - 5.4rem)', width: '100%' }}>
      <TextFields1 link={link} id={id} res={res} />
    </div>)
}

export default Box;

