import React from 'react';
import MaterialIcon from '../../components/MaterialIcon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import { notification } from 'antd';

import Config from '../../constants/appConfig';

import { useParams } from 'react-router';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
        };
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
        document.getElementById('loading').classList.add('load');
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value,
        });
    }

    validateEmail = (email) => {
        const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return validEmail.test(email);
    }

    handleSubmit = () => {
        const { email } = this.state;

        if (!this.validateEmail(email)) {
            notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_EMAIL,
                icon: <MaterialIcon icon="error" className="text-danger" />,
            });
            return;
        }

        const apiUrl = Config.server + '/resetPassword';

        const formData = new URLSearchParams();
        formData.append('email', email);

        const options = {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: localStorage.getItem('access-token-jwt'),
            },
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result && result.error) {
                        notification.open({
                            message: this.props.res[result.message],
                            icon: <MaterialIcon icon="error" className="text-danger" />,
                        });
                    } else {
                        notification.open({
                            message: this.props.res.SUCESSO,
                            description: this.props.res.EMAIL_ENVIADO,
                            icon: <MaterialIcon icon="check_circle" className="text-success" />,
                        });
                    }
                    this.props.history.push('/reset');
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />,
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG_INFORMACOES,
                            icon: <MaterialIcon icon="error" className="text-danger" />,
                        });
                    }
                }
            );
    }

    render() {
        const { res } = this.props;
        return (
            <div className="login-page">
                <div className="login-img "></div>
                <div className="login-div">
                    <div className='login-container'>
                        <div className="login-logo"></div>
                        <h2 style={{ marginTop: '2rem'}}>{res.SOLICITAR_SENHA}</h2>
                        <p className="lead" style={{ fontSize: '1rem', letterSpacing: '2px' }}>
                            {res.EMAIL_REDEFINIR_SENHA}
                        </p>
                        <form className="form-v1">
                            <div className="form-group">
                                <div className="input-group-v1">
                                    <div className="input-group-icon">
                                        <EmailIcon />
                                    </div>
                                    <TextField
                                        id="email"
                                        label="E-mail cadastrado"
                                        name="email"
                                        value={this.state.email}
                                        fullWidth
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group bitMoreSpace">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="login-button"
                                    onClick={this.handleSubmit}
                                >
                                    {res.ENVIAR_EMAIL}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
const Box = (props) => {
    const { link, res, history } = props;
    let { id } = useParams();
    return (
        <div>
            <LoginPage link={link} id={id} res={res} history={history} />
        </div>
    );
};

export default Box;