import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';

import { notification } from 'antd';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import SPW from "string-pixel-width";

//import { withTranslation } from "react-i18next";

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    const columnData = [
      { id: 'name', disablePadding: true, label: 'Nome' },
      { id: 'created', disablePadding: true, label: 'Criado' },
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          <TableCell padding="checkbox">

          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight: {
    color: '#6c76ff',
    backgroundColor: '#d7d9e0',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, open, res } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit">
            {numSelected} {res.SELECIONADO}
          </Typography>
        ) : (
          <Typography variant="h4" id="tableTitle">
            {"Vincular Parceiro"}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 &&
          <Button variant="contained" className="popUpButton" onClick={open}>Vincular</Button>
        }
      </div>
      <div className={classes.actions}>

      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 600,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      data: [],
      dataPartner: [],
      page: 0,
      rowsPerPage: 10,
      pageP: 0,
      error: null,
      filtro: '',
      open: false,
      selectIni: []
    };
  }

  componentDidMount() {
    this.updateList();
    this.updateListPartber();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  limitChar = (string, limit) => {
    let ret = string;
    let size = SPW(string, { font: "Arial", size: 16 });
    if (size > limit) {
      ret = string.slice(0, (string.length * (limit - 16) / size)) + '...';
    }
    return ret;
  };

  clear = () => {
    this.setState({
      filtro: ''
    });
  };

  updateList = () => {
    const apiUrl = Config.server + '/get_pending_access';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  updateListPartber = () => {
    const apiUrl = Config.server + '/partner';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            dataPartner: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  changePage = (to) => {
    if (to === 'P') {
      this.setState({ pageP: this.state.pageP - 1 });
    } else if (to === 'N') {
      this.setState({ pageP: this.state.pageP + 1 });
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  clickLeft = (item) => {
    let list = this.state.selectIni;

    let index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(item);
    }

    this.setState({ selectIni: list });
  }

  gerarToken = () => {
    const { selectIni, selected } = this.state;

    const apiUrl = Config.server + '/access_partner';

    const formData = new URLSearchParams();


    formData.append('partners', JSON.stringify(selectIni));
    formData.append('itens', JSON.stringify(selected));

    const options = {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ selected: [], selectIni: [], open: false }, () => {
            this.updateList();
          });
          notification.open({
            message: this.props.res.SUCESSO,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );

  }

  limpaCampo = () => {
    document.getElementById('campoFiltro').value = '';
    this.setState({ filtro: '' });
  }

  render() {
    const { classes, link, res } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    /*const deleteIcon = id => (
      <IconButton onClick={() => this.deleteUser(id)}>
        <DeleteIcon color="secondary" />
      </IconButton>
    );*/

    return (
      <div>
        <Dialog open={this.state.open} onClose={event => this.setState({ open: false })} >
          <DialogActions>
            <div className="">
              <div style={{}}>
                <div style={{ textAlign: 'right' }}>
                  {this.state.selectIni.length <= 0 ?
                    <Button
                      variant="contained"
                      onClick={event => this.setState({ open: !this.state.open })}
                    >
                      {res.CANCELAR}
                    </Button>
                    :
                    <Button
                      variant="contained"
                      onClick={event => this.gerarToken()}
                      color="primary"
                    >
                      {res.CONFIRMAR}
                    </Button>
                  }
                </div>
                <div style={{ width: '14rem' }}>
                  <SearchIcon style={{ marginRight: '.5rem', marginTop: '1.5rem' }} />
                  <TextField
                    placeholder={res.FILTRO}
                    margin="normal"
                    onChange={this.handleChange('filtro')}
                    id="campoFiltro"
                    type="search"
                  />
                  {(this.state.filtro === '') ? <div></div> :
                    <MaterialIcon icon="clear" style={{ position: 'absolute', top: '4.3rem', right: '.5rem', cursor: 'pointer' }} onClick={() => this.limpaCampo()} />
                  }
                </div>
              </div>
              <div className="div-list_a">
                <List
                  component="nav"
                  aria-labelledby="areas"
                  style={{ width: '100%' }}
                >
                  {this.state.dataPartner
                    .filter(item => {
                      return (
                        item.name.includes(this.state.filtro)
                      );
                    })
                    .map((item, index) => {
                      return (
                        <ListItem key={index + 1000} button
                          className={(this.state.selectIni.indexOf(item.id) >= 0) ? 'SelItem' : ''}
                          onClick={event => this.clickLeft(item.id)}
                          title={item.name}
                        >
                          <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                        </ListItem>
                      )
                    })}
                </List>
              </div>
            </div>
          </DialogActions>
        </Dialog>
        <Paper className={classes.root}>
          <EnhancedTableToolbar numSelected={selected.length} open={() => this.setState({ open: !this.state.open })} filtro={this.state.filtro} handleChange={this.handleChange} clear={this.clear} link={link} res={res} />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle" style={{ tableLayout: 'auto' }}>

              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                res={res}
              />
              <TableBody>
                {data
                  /*.filter(item => {
                    return (
                      item.name.includes(this.state.filtro)
                    );
                  })*/
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, n.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell className="check" padding="checkbox">
                          <Checkbox checked={isSelected} />
                        </TableCell>
                        <TableCell
                          className="coluna"
                          padding="checkbox"
                        >


                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                        <TableCell component="th" scope="row" padding="none">{new Date(n.created).toDateString()}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': res.PAGINA_ANTERIOR,
            }}
            nextIconButtonProps={{
              'aria-label': res.PROXIMA_PAGINA,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const EnhancedTable1 = withStyles(styles)(EnhancedTable);

const Section = (props) => {
  const { link, res } = props;
  return (
    <div className="rowForm">
      <EnhancedTable1 link={link} res={res} />
    </div>)
}

export default Section;
