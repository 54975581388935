import React, { useEffect, useState } from 'react';//, { useState, useEffect }
import Chart from 'chart.js/auto';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CustomizedGraph(props) {
  const { values, limit, type, ckey } = props; //{type}

  const [pieChart, setPieChart] = useState(null);

  const color = [
    "#e74c3c",
    "#f1c40f",
    "#5db445",
    "#6dbfbf",
    "#a536f5",
    "#a536f5",
    "#6dbfbf",
    "#5db445",
    "#f1c40f",
    "#e74c3c",
  ];
  //const colorText = "#4F4F4F";

  //const limitAtual = atual ? atual < -99 || atual > 99 ? Math.round(atual) : atual.toFixed(1) : 0;

  useEffect(() => {
    createChart('planeChart' + ckey);
  }, []);

  useEffect(() => {
    updatePieChart(values);
  }, [values]);

  const createChart = (canvas, tipo) => {
    let ctx = document.getElementById(canvas).getContext('2d');
    let d_values = [36, 36, 36, 36, 36, 36, 36, 36, 36, 36];
    setPieChart(new Chart(ctx, {
      type: 'pie',
      data: {
        labels: false,
        datasets: [{
          backgroundColor: color,
          data: d_values
        }]
      },
      options: {
        plugins: {
          tooltip: {
            enabled: false
          }
        }
      }
    }))
  };

  const updatePieChart = (values, tipo) => {
    if (pieChart) {
      let d_values = [
        Number(limit) - (values[0] + values[1]),   //0
        values[0],                      //1
        (values[1] + values[3]),        //2        
        values[4],                      //3
        Number(limit) - (values[4] + values[3])    //4
      ];
      d_values = d_values.concat(JSON.parse(JSON.stringify(d_values)).reverse());
      pieChart.data.datasets[0].data = d_values;
      pieChart.update();
    }
  };

  /**
   * let pieChart = new Chart(ctx, {
      type: 'polarArea',
      data: {
        labels: false,
        datasets: [{
          backgroundColor: color,
          data: teste
        }]
      }
    });
   */

  return (
    <div style={{ marginTop: '.5rem', display: 'flex', justifyContent: 'center', height: '9.5rem', position: 'relative' }}>
      <div>
        {type !== 'G' && <div style={{ width: `1rem`, height: '.1rem', backgroundColor: 'white', position: 'absolute', top: '4.75rem', left: `7.4rem` }}></div>}
        {type !== 'G' && <div style={{ width: `1.5rem`, height: '.1rem', backgroundColor: 'white', position: 'absolute', top: '4.75rem', left: `15.1rem` }}></div>}
        {type !== 'G' && <div style={{ position: 'absolute', top: '4.1rem', left: `16rem` }}>- Referência</div>}
      </div>
      <div style={{ width: '9rem' }}><canvas id={"planeChart" + ckey}></canvas></div>
      <div style={{ position: 'absolute', top: '3.4rem' }}>
        <SvgIcon viewBox="-3 0 200 200" className="iconArrow"
          style={{ width: '5rem', height: '5rem' }}>
          <path fill="black" className="fil0" d="M 98 6 l 10 9 l 0 21 L 180 36 L 185 40 L 188 49 L 188 65 L 108 66 L 104 99 L 126 99 L 128 102 L 128 112 L 124 115 L 106 116 C 102 116 102 118 98 121 C 95 119 94 116 92 116 L 71 115 L 68 113 L 68 101 L 70 99 L 93 99 L 89 66 L 8 66 L 8 50 L 11 39 L 16 36 L 88 36 L 88 15 L 98 6 z" />
          <path fill="white" className="fil0" d="M 98.4 8.45 L 105.71 15.56 l 0.29 23.44 L 181 39 C 185.4 46.62 186.6 53.91 185 63 L 105.82 63.64 L 101.22 100.63 L 125.67 101.21 L 125.09 112.04 C 109.83 116.77 106.11 107.76 98.4 117.79 C 91.02 111.21 91.93 113.53 70.73 112.6 L 70.23 101.2 L 95.37 100.64 L 90.56 63.83 L 10.79 63.57 C 9.74 54.33 11.6 45.3 15 39 L 90 39 L 90.26 15.56 L 98.4 8.45 z" />
        </SvgIcon>
      </div>
    </div>
  );
}
