import React, { useState, useEffect } from 'react';
import Config from '../../constants/appConfig';
import AppHeader from './header';
import AppFooter from './footer';
import AppContent from './content';
import AppSidenav from './sidenav';

export default ({ history, res, lan, link }) => {

    const menu = "sidenav";

    const [divBack, setDivBack] = useState(false);
    const [showMenu, setShowMenu] = useState(menu);
    const [profile, setProfile] = useState([]);


    useEffect(() => {
        if (link) handleClick(link)
        checkUser();
    }, []);

    const handleClick = (link) => {
        if (link === 'back') {
            history.goBack();
        } else {
            history.push(link);
        }
    };

    const checkUser = () => {
        const apiUrl = Config.server + '/currentProfile';
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    let array = [];
                    result.forEach(element => {
                        array.push(element.id_profile);
                    });
                    setProfile(array);

                    let location = window.location.pathname.split('/');//fix: fazer de uma maneira melhor
                    if (location.length <= 2) {
                        /*if (this.state.type.includes(7)) {
                            this.link('/app/dashboard');
                        } else if (this.state.type.includes(3)) {*/
                        if (array.includes(10) || array.includes(11)) {
                            handleClick('/app/ciclo');
                        } else {
                            handleClick('/app/draw' );
                        }
                        //}
                    }
                },
                (error) => {
                    handleClick('/login');
                }
            );
    }

    const showSidMenu = () => {
        setDivBack(true);
        setShowMenu("sidenav sidenavShow");
    }

    const hideMenu = () => {
        setDivBack(false);
        setShowMenu(menu);
    }

    return (
        <div className="ini maxH">
            <div className={divBack ? "back" : "sr-only"} onClick={event => hideMenu()}></div>
            <AppSidenav profile={profile} show={showMenu} hide={hideMenu} link={handleClick} res={res} />
            <AppHeader menu={showSidMenu} res={res} lan={lan} link={handleClick} />
            <div className="wrapper">
                <div className="push"></div>
                <AppContent profile={profile} history={history} link={handleClick} res={res} />
                <div className="push-bottom"></div>
            </div>
            <AppFooter />
        </div>
    );
};