import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';


import { useParams } from "react-router";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    primary: {
        backgroundColor: '#3f51b5',
        color: 'white',
    },
    secondary: {
        backgroundColor: '#e0e0e0',
    },
});

class Maintenance extends React.Component {
    state = {
        id: this.props.id,
        previsionDate: new Date().toISOString().split('T')[0],
        realizationDate: new Date().toISOString().split('T')[0],
        minInstallationDate: new Date().toISOString().split('T')[0],
    };

    componentDidMount() { };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    };

    handleDateChange = (e) => {
        this.setState({ previsionDate: e.target.value, realizationDate: e.target.value });
    };

    handleDateRealizationChange = (e) => {
        this.setState({ realizationDate: e.target.value });
    };

    render() {
        const { link, res, classes } = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="containerTop">
                    <div><h1>{res.MANUTENCAO}</h1></div>
                    <div className="centerTop"></div>
                    <div>
                        <Button variant="contained" onClick={() => { link('') }} className="popUpButton">
                            {res.CANCELAR}
                        </Button> &nbsp;
                        <Button type="submit" variant="contained" color="primary" className="popUpButton">
                            <SaveIcon className="iconMapS" />
                            {res.SALVAR}
                        </Button>
                    </div>
                </div>
                <div className="form-group notTooBig">
                    <div style={{ marginBottom: '2rem', textAlign: 'start' }}>
                        <TextField
                            label={res.VIDA_EQUIPAMENTO}
                            id="vidaEquipamento"
                            name="vidaEquipamento"
                            style={{whiteSpace: 'nowrap', width: '12rem'}}
                        />
                    </div>
                    <Table className="calibration-table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: '1.5rem' }}>Revisão</TableCell>
                                <TableCell>Previsão</TableCell>
                                <TableCell></TableCell>
                                <TableCell>Realização</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Data Verificação:</TableCell>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        id="dataPrevisao"
                                        name="dataPrevisao"
                                        value={this.state.previsionDate}
                                        onChange={this.handleDateChange}

                                        InputProps={{
                                            inputProps: {
                                                min: this.state.minInstallationDate
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="horasPrevisão"
                                        name="horasPrevisão"
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        id="dataRealização"
                                        name="dataRealização"
                                        value={this.state.realizationDate}
                                        onChange={this.handleDateRealizationChange}

                                        InputProps={{
                                            inputProps: {
                                                min: this.state.previsionDate
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="horasRealização"
                                        name="horasRealização"
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="info"
                                        name="info"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </form>
        );
    }
}

Maintenance.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Maintenance);

const Box = (props) => {
    const { link, res } = props;
    let { id } = useParams();
    return (
        <div className="rowForm">
            <div className="divContent">
                <TextFields1 link={link} id={id} res={res} />
            </div>
        </div>)
}

export default Box;

