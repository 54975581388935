import React, { useState, useEffect } from 'react';
import Month from './month';
import Week from './week';
import Day from './day';
import MaterialIcon from './../../components/MaterialIcon';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Config from '../../constants/appConfig';
import { notification } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from '@material-ui/core/TextField';
import tools from '../../components/util/tools';

export default function CustomizedGraph(props) {
  const { res, planes, new_link } = props;

  const [type, setType] = useState('month');
  const [loadType, setLoadType] = useState('month');
  const [date, setDate] = useState(new Date());
  const [from_to, setFromTo] = useState(null);
  const [selType, setSelType] = useState('all');
  const [selTypeJob, setSelTypeJob] = useState([]);
  const [itens, setItens] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalTime, setTotalTime] = useState(0);

  const tMonths = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  const typeJob = [
    'pre_orcamento',
    'planejamento',
    'execucao',
  ];

  useEffect(() => {
    changeDate();
  }, []);

  useEffect(() => {
    changeDate();
  }, [type]);

  useEffect(() => {
    setLoadType(type);
    if (from_to) {
      buscaItens();
    }
  }, [from_to]);

  useEffect(() => {
    setTotalTime(formatTime());

  }, [selectedItem]);

  const handleChange = setName => event => {
    setName(event.target.value);
  };

  const dateTimetoString = (date) => {
    if (!date) date = new Date();
    let ret = date.getFullYear() + '-';
    ret += (date.getMonth() + 1).toString().padStart(2, '0') + '-';
    ret += date.getDate().toString().padStart(2, '0') + 'T';
    ret += date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
    return ret;
  }
  const StringtoDateTime = (date) => {
    const ret = new Date(date);
    return ret;
  }

  const addDay = (date, days) => {
    let temp_date = new Date(date);
    temp_date.setDate(date.getDate() + days);
    return temp_date;
  };
  const diffDay = (from1, to1) => {
    const from = new Date(from1.getFullYear(), from1.getMonth(), from1.getDate());
    const to = new Date(to1.getFullYear(), to1.getMonth(), to1.getDate());

    return Math.ceil((to - from) / 86400000);
  };

  const handleChangeDates = (id, param) => event => {
    const indexTimeTable = selectedItem.timetable.findIndex(item => item.id === id);
    const tempDate = selectedItem.timetable[indexTimeTable][param];
    selectedItem.timetable[indexTimeTable][param] = StringtoDateTime(event.target.value);

    const diff = diffDay(tempDate, selectedItem.timetable[indexTimeTable][param]);
    if (diff !== 0) {
      if (param === 'start') {
        if (selectedItem.timetable[indexTimeTable].end) {
          selectedItem.timetable[indexTimeTable].end = addDay(selectedItem.timetable[indexTimeTable].end, diff);
        }
      } else {
        selectedItem.timetable[indexTimeTable].start = addDay(selectedItem.timetable[indexTimeTable].start, diff);
      }
    }

    setSelectedItem({ ...selectedItem });
  }

  const changeDate = (newDate, add = 0) => {
    let tempDate = newDate ? newDate : date;
    let from = null;
    let to = null;
    if (type === 'week') {
      let selectedDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + (add * 7));
      setDate(selectedDate);
      let week = rangeWeek(selectedDate);
      from = week.from;
      to = week.to;
    } else if (type === 'month') {
      let selectedDate = new Date(tempDate.getFullYear(), tempDate.getMonth() + add, tempDate.getDate());
      setDate(selectedDate);
      let month = rangeMonth(selectedDate);
      from = month.from;
      to = month.to;
    } else {
      let selectedDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + add);
      let selectedDateAdd = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1);
      setDate(selectedDate);
      from = selectedDate;
      to = selectedDateAdd;
    }
    setFromTo({ from, to });
  };
  const rangeWeek = (date) => {
    let tempFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    let tempTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7);
    return { from: tempFrom, to: tempTo };
  };
  const rangeMonth = (date) => {
    let tempDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let tempFrom = new Date(date.getFullYear(), date.getMonth(), tempDate.getDate() - tempDate.getDay());
    tempDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let tempTo = new Date(date.getFullYear(), date.getMonth(), tempDate.getDate() - tempDate.getDay() + 7);
    return { from: tempFrom, to: tempTo };
  };

  const changeItens = (item) => {
    if (item === new_link) {
      return;
    }

    let list = [];
    if (!item.type !== "pre_orcamento") {
      for (let i = 0; i < item.timetable.length; i++) {
        const element = item.timetable[i];
        if(element.end){
          list.push(element);
        }
      }
    }

    let apiUrl = Config.server + '/job_timetable/link/' + item.id;
    let method = 'PUT';

    const formData = new URLSearchParams();
    formData.append('timetable', JSON.stringify(list));

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      }).then(
        (result) => {

        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: props.res.PERMISSAO,
              description: props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: props.res.ERRO,
              description: props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  const buscaItens = () => {
    let apiUrl = '';
    let method = 'POST';
    apiUrl = Config.server + '/job_new_list';

    const formData = new URLSearchParams();
    formData.append('withPoints', false);
    formData.append('plane', JSON.stringify([]));
    formData.append('type', JSON.stringify(selTypeJob));
    formData.append('dateini', from_to.from.getTime());
    formData.append('datefim', from_to.to.getTime());

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      }).then(
        (result) => {
          let arr = [];
          for (let i = 0; i < result.length; i++) {
            const element = result[i]
            let item;
            if (element[typeJob[2]] && element[typeJob[2]].timetable.length > 0) {//execucao
              item = element[typeJob[2]];
            } else if (element[typeJob[1]] && element[typeJob[1]].timetable.length > 0) {//planejamento
              item = element[typeJob[1]];
            } else if (element[typeJob[0]] && element[typeJob[0]].timetable.length > 0) {//preorcamento
              item = element[typeJob[0]];
            }
            if (item) {
              for (let j = 0; j < item.timetable.length; j++) {
                const element = item.timetable[j];
                element.start = element.start ? new Date(element.start) : null;
                element.end = element.end ? new Date(element.end) : null;
              }
              arr.push(item);
            }
          }
          if (new_link) {
            arr.push(new_link);
          }
          setItens(arr);
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: props.res.PERMISSAO,
              description: props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: props.res.ERRO,
              description: props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  const openItem = (item) => {
    let temp = { ...item };
    let arr = [];
    for (let i = 0; i < temp.timetable.length; i++) {
      const element = temp.timetable[i];
      let start = new Date(element.start.getTime());
      let end = element.end ? new Date(element.end.getTime()) : null;
      arr.push({ ...element, start, end });
    }
    temp.timetable = arr;
    setSelectedItem(temp);
    setShowForm(true);
  };

  const onCloseDialog = () => {
    setShowForm(false);
  };

  const salvarSelected = () => {
    const temp = itens.filter(item => item.id === selectedItem.id);
    if (temp.length > 0) {
      temp[0].timetable = selectedItem.timetable;
    }
    setItens([...itens]);
    changeItens(selectedItem);
    onCloseDialog();
  };

  const formatTime = () => {
    if (!selectedItem || !selectedItem.timetable) {
      return null;
    }
    let qtdDays = 0;
    let totalTime = 0;
    let lastDay;
    for (let i = 0; i < selectedItem.timetable.length; i++) {
      const element = selectedItem.timetable[i];
      if (!element.empty && element.end) {
        if (!lastDay || diffDay(element.start, lastDay) !== 0) {
          qtdDays++;
          lastDay = element.start;
        }
        totalTime += element.end - element.start;
      }
    }
    if (totalTime === 0) {
      return null;
    } else {
      let qtd = '';
      if (qtdDays > 1) {
        qtd = `(${qtdDays} ${res.DIAS})`;
      }
      return `${tools.fancyTimeFormat(totalTime / 1000)} ${qtd}`;
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '1.5rem', display: 'flex', paddingLeft: '1rem' }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <MenuItem style={{ marginRight: '1rem', borderRadius: '3px', border: 'solid 1px #0000004a' }} variant="outlined" onClick={() => changeDate(new Date())}>Hoje</MenuItem>
          <Select
            style={{ width: '7rem' }}
            value={type}
            onChange={handleChange(setType)}
            inputProps={{
              id: 'type',
            }}
          >
            <MenuItem value="month">
              Mês
            </MenuItem>
            <MenuItem value="week">
              Semana
            </MenuItem>
            {/*             <MenuItem value="day">
              Dia
            </MenuItem> */}
          </Select>
          <Button style={{ minWidth: '2rem' }} onClick={() => changeDate(null, -1)}><MaterialIcon icon="arrow_back_ios_new" /></Button>
          <Button style={{ minWidth: '2rem' }} onClick={() => changeDate(null, 1)}><MaterialIcon icon="arrow_forward_ios" /></Button>
          <span style={{ fontSize: '20px' }}>{tMonths[date.getMonth()]} {date.getFullYear()}</span>
        </div>
        {(type === "month" || type === "week") &&
          <div>
            <Select
              style={{ width: '10rem', marginTop: '-.4rem' }}
              value={selType}
              onChange={handleChange(setSelType)}
              inputProps={{
                id: 'selType',
              }}
            >
              <MenuItem value="all">
                por Trabalho
              </MenuItem>
              <MenuItem value="item">
                por Segmento
              </MenuItem>
            </Select>
          </div>
        }
      </div>
      <div style={{ width: '100%', height: 'calc(100% - 3rem)' }}>
        <Dialog
          open={showForm}
          onClose={onCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {selectedItem &&
            <div className="divContent">
              <DialogActions>
                <div style={{ width: '100%' }}><h1>{
                  selectedItem.type === 'pre_orcamento' ?
                    res.PREORCAMENTO :
                    selectedItem.type === 'planejamento' ?
                      res.PLANEJAMENTO :
                      'Execução'
                }</h1></div>
                {selectedItem.type !== 'execucao' &&
                  <Button onClick={salvarSelected} variant="contained" color="primary" className="popUpButton">
                    {res.SALVAR}
                  </Button>
                }
              </DialogActions>
              <DialogTitle id="alert-dialog-title" style={{ marginBottom: '1rem', maxWidth: '25rem' }}>
                <TextField
                  id="title"
                  label={res.TITULO}
                  value={selectedItem.title}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ marginBottom: '1rem' }}
                  fullWidth
                />
                <TextField
                  id="client"
                  label={res.CLIENTE}
                  value={selectedItem.client_name ? selectedItem.client_name :
                    selectedItem.client ? selectedItem.client : ''
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ marginBottom: '1rem' }}
                  fullWidth
                />
                {totalTime &&
                  <TextField
                    id="tTotal"
                    label={res.TEMPO_TOTAL}
                    value={totalTime}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    style={{ marginBottom: '1rem' }}
                  />
                }
              </DialogTitle>
              {selectedItem.timetable && selectedItem.timetable
                .filter(item => !item.empty)
                .map((item, key) =>
                  <div>
                    <TextField
                      id={"datetime-local1" + key}
                      label={'Inicio do Trabalho'}
                      type="datetime-local"
                      value={dateTimetoString(item.start)}
                      onChange={handleChangeDates(item.id, 'start')}
                      style={{ width: '11.7rem', marginBottom: '1rem' }}
                      error={item.startError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: selectedItem.type !== 'pre_orcamento' && selectedItem.type !== 'planejamento'
                      }}
                    />
                    {item.end &&
                      <TextField
                        id={"datetime-local2" + key}
                        label={'Fim do Trabalho'}
                        type="datetime-local"
                        value={dateTimetoString(item.end)}
                        onChange={handleChangeDates(item.id, 'end')}
                        style={{ width: '11.7rem', marginBottom: '1rem', marginLeft: '1rem' }}
                        error={item.endError}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: selectedItem.type !== 'pre_orcamento' && selectedItem.type !== 'planejamento'
                        }}
                      />
                    }
                  </div>
                )}
            </div>
          }
        </Dialog>
        {loadType === 'month' && from_to ?
          <Month changeItens={changeItens} openItem={openItem} selType={selType} from={from_to.from} to={from_to.to} itens={itens} isMovable={true} /> :
          loadType === 'week' && from_to ?
            <Week changeItens={changeItens} openItem={openItem} selType={selType} from={from_to.from} to={from_to.to} itens={itens} isMovable={true} /> :
            loadType === 'day' && from_to ?
              <Day changeItens={changeItens} openItem={openItem} selType={selType} from={from_to.from} to={from_to.to} itens={itens} isMovable={true} /> :
              null
        }
      </div>
    </div>
  );
}
