import React from 'react';
import CalcData1 from "./data1";
import CalcLocal from "./data_local";

class Tools {
    teste = () => {
        return 'Hello!';
    }

    calcTotal = (velocidade, nPulverizador, vazao, faixa, dsc, fabricante, tbico, bico_label, fabricante_label) => {

        const convertKMHtoMPH = (velocidade) => {
            return Number(velocidade) * 0.621371;
        }

        const convertMtoFEET = (distancia) => {
            return Number(distancia) * 3.28084;
        }

        const convertKMHtoMPM = (distancia) => {
            return Number(distancia) * 16.6666667;
        }

        const convertLPHtoGPA = (volume) => {
            return Number(volume) / 9.35396;
        }

        let area = convertKMHtoMPM(velocidade) * faixa / 10000;
        let liter = area * vazao;
        let pLiter = liter / nPulverizador;
        let data1 = fabricante === 0 || nPulverizador <= 12 ? [] :
            CalcData1(convertLPHtoGPA(vazao), convertMtoFEET(faixa),
                convertKMHtoMPH(velocidade), nPulverizador, dsc);

        let local = fabricante !== 0 && fabricante !== 99 ? [] :
            CalcLocal(pLiter, convertKMHtoMPH(velocidade), dsc, JSON.parse(JSON.stringify(data1)), nPulverizador);

        if (fabricante > 0 && fabricante !== 99) {
            data1 = data1.filter((item) => (item.fab === fabricante_label[fabricante]));
        }

        if (tbico !== 99) {
            data1 = data1.filter((item) => (item.type === bico_label[tbico]));
            let temp = [];
            for (let i = 0; i < local.length; i++) {
                if (bico_label[tbico].includes('ATOMIZADOR') || bico_label[tbico].includes('ELETROSTÁTICO')) {
                    local[i].rows = local[i].rows.filter((item) => (item[0].includes(bico_label[tbico])));
                } else if (bico_label[tbico].includes('PLANO')) {
                    local[i].rows = local[i].rows.filter((item) => (item[0].includes('LEQUE') || item[0].includes('TT11') || item[0].includes('TRAVICAR 03')));
                } else if (bico_label[tbico].includes('CÔNICO')) {
                    local[i].rows = local[i].rows.filter((item) => (item[0].includes('ELETROSTÁTICO') && !item[0].includes('TT11')));
                }
                if (local[i].rows.length > 0) {
                    temp.push(local[i]);
                }
            }
            local = temp;
        }

        return {
            vazaoPulverizador: pLiter,
            total: liter,
            coberta: area,
            list_data1: data1,
            list_local: local,
        };
    }

    montaItens = (data, call) => {
        let retHtml = [];
        let tempHtml = [];
        const list = data.rows.filter((item) => (item[3]));// && (!this.state.selected_in.includes(item[0]))));
        let tempGroup = '';
        let title = (data, key, tempGroup, retHtml, tempHtml) => {
            let htmlHeadList = '';
            htmlHeadList = <div key={key} className="form-group divContent" style={{ width: '100%', marginRight: '1rem' }}>
                <div style={{ padding: '1rem', width: '100%' }}>
                    <div><h3>{tempGroup}</h3></div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 15rem' }}>
                        <table style={{ textAlign: 'left', width: '100%' }}>
                            <thead>
                                <tr key={0}>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[1]}:</h4></th>
                                    {data.fields.type <= 1 && <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[2]}:</h4></th>}
                                    {data.fields.type === 2 && <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[9]}:</h4></th>}
                                    {data.fields.type >= 2 && <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[10]}:</h4></th>}
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[4]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[5]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[6]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[7]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields.itens[8]}:</h4></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tempHtml.map(item => item)}
                            </tbody>
                        </table>
                        <div>
                            {data.img && <img style={{ width: '11rem' }} src={data.img} alt='' />}
                        </div>
                    </div>
                </div>
            </div>;

            retHtml.push(htmlHeadList);
        }
        let iTable = 0;
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (tempGroup !== item[0]) {
                if (tempGroup !== '') {
                    title(data, iTable++, tempGroup, retHtml, tempHtml);
                }
                tempHtml = [];
                tempGroup = item[0];
            }
            let htmlList = '';
            htmlList = <tr key={i + 1} onClick={() => { if (call) call(item); }} style={{ borderTop: 'solid 1px #bababa', cursor: call ? 'pointer' : 'auto' }}>
                <td style={{ textAlign: 'center' }}><h4> {item[1]}</h4></td>
                {data.fields.type <= 1 && <td style={{ textAlign: 'center' }}><h4> {item[2]}</h4></td>}
                {data.fields.type === 2 && <td style={{ textAlign: 'center' }}><h4> {item[9]}</h4></td>}
                {data.fields.type >= 2 && <td style={{ textAlign: 'center' }}><h4> {item[10]}</h4></td>}
                <td style={{ textAlign: 'center' }}><h4> {isNaN(item[4]) ? item[4] : item[4].toFixed(1)}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {isNaN(item[5]) ? item[5] : item[5].toFixed(2)}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {isNaN(item[6]) ? item[6] : item[6].toFixed(2)}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {isNaN(item[7]) ? item[7] : item[7].toFixed(2)}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {isNaN(item[8]) ? item[8] : item[8].toFixed(2)}</h4></td>
            </tr>;
            tempHtml.push(htmlList);
        }
        if (list.length > 0 && tempGroup !== '') {
            title(data, iTable, tempGroup, retHtml, tempHtml);
        }

        return retHtml;
    }

    montaItensEx = (data, call) => {
        let retHtml = [];
        let tempHtml = [];
        const list = data.rows.filter((item) => (item[3]));// && (!this.state.selected_ex.includes(item[0]))));
        let tempGroup = '';
        let title = (data, key, tempGroup, retHtml, tempHtml) => {
            retHtml.push(<div key={key} className="form-group divContent" style={{ width: '100%', marginRight: '1rem' }}>
                <div style={{ padding: '1rem', width: '100%' }}>
                    <div><h3>{tempGroup}</h3></div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 15rem' }}>
                        <table style={{ textAlign: 'left', width: '100%' }}>
                            <thead>
                                <tr key={0}>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields[1]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields[2]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields[4]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields[6]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields[7]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields[8]}:</h4></th>
                                    <th style={{ textAlign: 'center' }}><h4>{data.fields[9]}:</h4></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tempHtml.map(item => item)}
                            </tbody>
                        </table>
                        <div>
                            {data.img && <img style={{ width: '11rem' }} src={data.img} alt='' />}
                        </div>
                    </div>
                </div>
            </div>)
        }
        let iTable = 0;
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (tempGroup !== item[0]) {
                if (tempGroup !== '') {
                    title(data, iTable++, tempGroup, retHtml, tempHtml);
                }
                tempHtml = [];
                tempGroup = item[0];
            }
            tempHtml.push(<tr key={i + 1} onClick={() => { if (call) call(item); }} style={{ borderTop: 'solid 1px #bababa', cursor: call ? 'pointer' : 'auto' }}>
                <td style={{ textAlign: 'center' }}><h4> {item[1]}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {item[2]}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {item[4]}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {item[6]}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {item[7]}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {item[8]}</h4></td>
                <td style={{ textAlign: 'center' }}><h4> {item[9]}</h4></td>
            </tr>);
        }
        if (list.length > 0 && tempGroup !== '') {
            title(data, iTable, tempGroup, retHtml, tempHtml);
        }

        return retHtml;
    }
}

const tools = new Tools();

export default tools;
