import React from 'react';
import MaterialIcon from './../../components/MaterialIcon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { notification } from 'antd';

import Config from '../../constants/appConfig';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      remenber: false
    };
  }
  componentDidMount = () => {
    localStorage.setItem('access-token-jwt', '');

    let sLogin = localStorage.getItem('remenber-me');
    this.setState({ login: sLogin });

    if (typeof sLogin === 'string' && sLogin !== '') {
      this.setState({ remenber: true });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };

    document.getElementById("loading").classList.add('load');
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleCheck(e) {
    this.setState({ remenber: !this.state.remenber })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.remenber) {
      localStorage.setItem('remenber-me', this.state.login);
    }
    else {
      localStorage.setItem('remenber-me', '');
    }

    const apiUrl = Config.server + '/authenticate';

    let options = {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw new Error(res);
        else return res.json();
      })
      .then(res => {
        if (res.status === 200) {
          if (res.guid) {
            this.props.history.push('/reset/' + res.guid);
          } else {
            localStorage.setItem('access-token-jwt', res.data);
            this.props.history.push('/app');
          }
        } else if (res.status === 401) {
          this.setState({
            password: ''
          });
          notification.open({
            message: this.props.res.ERRO_LOGIN,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else {
          const error = new Error(res);
          throw error;
        }
      })
      .catch(error => {
        console.log(error);
        notification.open({
          message: this.props.res.ERRO,
          description: this.props.res.ERRO_MSG,
          icon: <MaterialIcon icon="error" className="text-danger" />
        });
      })
  }

  render() {
    const { res } = this.props;
    return (
      <div className="login-page">
        <div className="login-img "></div>
        <div className="login-div">
          <div style={{ width: '100%' }}>
            <div className="login-logo"></div>
          </div>
          <div className="login-container">
            <div className="login-form">
              <h2>{res.LOGIN}</h2>
              <p className="lead">{res.LOGIN_TOP}</p>
              <form onSubmit={this.handleSubmit} className="form-v1">
                <div className="form-group">
                  <div className="input-group-v1">
                    <div className="input-group-icon">
                      <MaterialIcon icon="account_circle" />
                    </div>
                    <TextField
                      id="login2-login"
                      label={res.LOGIN}
                      name="login"
                      value={this.state.login}
                      onChange={this.handleInputChange}
                      fullWidth
                      autoComplete="off"
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 45)
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group-v1">
                    <div className="input-group-icon">
                      <MaterialIcon icon="lock" />
                    </div>
                    <TextField
                      id="login2-password"
                      label={res.SENHA}
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      fullWidth
                      autoComplete="off"
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 45)
                      }}
                    />
                  </div>
                </div>
                <div className="bitMoreSpace">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        color="primary"
                        onChange={e => this.handleCheck(e)}
                        checked={this.state.remenber}
                      />
                    }
                    label={res.LEMBRE}
                  />
                </div>
                <div className="form-group bitMoreSpace">
                  <Button variant="contained" color="primary" type="submit" className="login-button">
                    {res.LOG_IN}
                  </Button>
                </div>
              </form>
              <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
                <button
                  style={{ backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => this.props.history.push('/forgotPassword')}
                >
                  {res.ESQUECEU_SENHA}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
//<p className="bitMoreSpace">Forgot your username or password? <a href="/pass">Reset password</a></p>

export default LoginPage
