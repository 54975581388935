const APPCONFIG = {
  version: '1.1.6',
  server: process.env.REACT_APP_APP_SERVER,
  link: process.env.REACT_APP_APP_LINK,
  place: JSON.parse(process.env.REACT_APP_APP_PLACE),
  //map config
  tile_google: `http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}`,
  tile_google_config: {
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  },
  //tile_mapbox: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_APP_MAPBOX_KEY}`,
  tile_mapbox: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/512/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_APP_MAPBOX_KEY}`,
  tile_google_mapbox: {
    maxNativeZoom: 17,
    maxZoom: 19,
    tileSize: 512,
    zoomOffset: -1
  },
};


export default APPCONFIG;
