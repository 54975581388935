import React from 'react';
import MenuList from './menu';

import { Menu } from 'antd';
import Button from '@material-ui/core/Button';

const SubMenu = Menu.SubMenu;

class Sidenav extends React.Component {
    name = "menuBot";
    open = { display: 'grid', gridTemplateRows: '4rem auto 10rem' };
    close = { display: 'grid', gridTemplateRows: '4rem auto 2.5rem' };
    state = {
        size: this.name,
        isUp: false,
        sidenavLayout: this.close
    }

    subHelp = () => {
        if (!this.state.isUp) {
            this.setState({ size: this.name, isUp: true, sidenavLayout: this.open });
        } else {
            this.setState({ size: '', isUp: false, sidenavLayout: this.close });
        }
    }
    handleClick = (url, hide, link) => {
        hide();
        link(url);
    }

    render() {
        const { show, hide, link, res, profile } = this.props;
        return (
            <div className={show} style={this.state.sidenavLayout}>
                <div className="sidenav-header">
                    <div className="menu-logo"></div>
                </div>
                <MenuList profile={profile} hide={hide} link={link} res={res} size={this.state.size} handleClick={this.handleClick} />
                <div className="sidenav-footer">
                    <Menu
                        className=".nav-menu outThere"
                        mode="inline"
                        theme="dark"
                        /*defaultOpenKeys={['sub1']}*/
                    >
                        <SubMenu
                            className=".nav-menu"
                            key="sub1"
                            onTitleClick={event => this.subHelp()}
                            title={
                                <span style={{ marginLeft: '1rem' }}>{'Ferramentas'}</span>
                            }
                        >
                            <Menu.Item key="00.97">
                                <Button className="nav-item" onClick={event => { this.handleClick('/app/calibracao', hide, link) }}>
                                    <span>{'Calibração'}</span>
                                </Button>
                            </Menu.Item>
                            <Menu.Item key="00.98">
                                <Button className="nav-item" onClick={event => { this.handleClick('/app/custo', hide, link) }}>
                                    <span>{res.CUSTOHORA}</span>
                                </Button>
                            </Menu.Item>
                            <Menu.Item key="00.99">
                                <Button className="nav-item" onClick={event => { this.handleClick('/app/view', hide, link) }}>
                                    <span>{res.APLICACAO + ' KMZ/KML'}</span>
                                </Button>
                            </Menu.Item>

                        </SubMenu>
                    </Menu>

                </div>
            </div>
        )
    }
}

export default Sidenav;