import img1 from './../../assets/images/marker/waypoint/building.png';
import img2 from './../../assets/images/marker/waypoint/cow.png';
import img3 from './../../assets/images/marker/waypoint/crop.png';
import img4 from './../../assets/images/marker/waypoint/desert.png';
import img5 from './../../assets/images/marker/waypoint/factory.png';
import img6 from './../../assets/images/marker/waypoint/fire.png';
import img7 from './../../assets/images/marker/waypoint/flag-green.png';
import img8 from './../../assets/images/marker/waypoint/flag-red.png';
import img9 from './../../assets/images/marker/waypoint/forest.png';
import img10 from './../../assets/images/marker/waypoint/hangar.png';
import img11 from './../../assets/images/marker/waypoint/inflight.png';
import img12 from './../../assets/images/marker/waypoint/marker-icon.png';
import img13 from './../../assets/images/marker/waypoint/marker-icon-green.png';
import img14 from './../../assets/images/marker/waypoint/mountain.png';
import img15 from './../../assets/images/marker/waypoint/pine.png';
import img16 from './../../assets/images/marker/waypoint/power.png';
import img17 from './../../assets/images/marker/waypoint/sea.png';
import img18 from './../../assets/images/marker/waypoint/tree.png';
import img19 from './../../assets/images/marker/waypoint/volcano.png';
import img20 from './../../assets/images/marker/waypoint/water.png';
import img21 from './../../assets/images/marker/obstacle/hazard.png';
import img22 from './../../assets/images/marker/obstacle/bee.png';
import img23 from './../../assets/images/marker/waypoint/stationConnected.png';
import img24 from './../../assets/images/marker/waypoint/stationDisconnected.png';
import img25 from './../../assets/images/marker/waypoint/weather.png';

const img = [
    {key: 0, desc: ['marker-icon'], img: img12}, 
    {key: 1, desc: ['marker-icon-green', 'greenarrow', 'standard'], img: img13}, 
    {key: 2, desc: ['building'], img: img1}, 
    {key: 3, desc: ['cow'], img: img2}, 
    {key: 4, desc: ['crop'], img: img3}, 
    {key: 5, desc: ['desert'], img: img4}, 
    {key: 6, desc: ['factory'], img: img5}, 
    {key: 7, desc: ['fire'], img: img6}, 
    {key: 8, desc: ['flag-green', 'greenflag'], img: img7}, 
    {key: 9, desc: ['flag-red', 'redflag'], img: img8}, 
    {key: 10, desc: ['forest'], img: img9}, 
    {key: 11, desc: ['hangar'], img: img10}, 
    {key: 12, desc: ['inflight'], img: img11},     
    {key: 13, desc: ['mountain'], img: img14}, 
    {key: 14, desc: ['pine'], img: img15}, 
    {key: 15, desc: ['power'], img: img16}, 
    {key: 16, desc: ['sea'], img: img17}, 
    {key: 17, desc: ['tree'], img: img18}, 
    {key: 18, desc: ['volcano'], img: img19}, 
    {key: 19, desc: ['water'], img: img20},
    {key: 20, desc: ['hazard'], img: img21}, 
    {key: 21, desc: ['bee'], img: img22},
    {key: 22, desc: ['stationConnected'], img: img23},
    {key: 23, desc: ['stationDisconnected'], img: img24},
    {key: 24, desc: ['weather'], img: img25},
  ];


export default img;

/*
  MAP_MARKERS: {
    "standard":   { src: "img/marker/marker-icon.png", anchor: [0.5, 1.0] },
    "greenarrow": { src: "img/marker/marker-icon-green.png", anchor: [0.5, 1.0] },
    "hangar":     { src: "img/marker/hangar.png", anchor: [0.5, 0.5] },
    "redflag":    { src: "img/marker/flag-red.png", anchor: [0.25, 1.0] },
    "greenflag":  { src: "img/marker/flag-green.png", anchor: [0.25, 1.0] },
    "building":   { src: "img/marker/building.png", anchor: [0.5, 0.8] },
    "factory":    { src: "img/marker/factory.png", anchor: [0.5, 0.8] },
    "power":      { src: "img/marker/power.png", anchor: [0.5, 0.8] },
    "mountain":   { src: "img/marker/mountain.png", anchor: [0.5, 0.75] },
    "volcano":    { src: "img/marker/volcano.png", anchor: [0.5, 0.75] },
    "desert":     { src: "img/marker/desert.png", anchor: [0.5, 1.0] },
    "forest":     { src: "img/marker/forest.png", anchor: [0.5, 0.8] },
    "tree":       { src: "img/marker/tree.png", anchor: [0.5, 0.8] },
    "pine":       { src: "img/marker/pine.png", anchor: [0.5, 0.8] },
    "crop":       { src: "img/marker/crop.png", anchor: [0.5, 0.8] },
    "cow":        { src: "img/marker/cow.png", anchor: [0.5, 0.8] },
    "fire":       { src: "img/marker/fire.png", anchor: [0.5, 0.8] },
    "water":      { src: "img/marker/water.png", anchor: [0.5, 0.8] },
    "sea":        { src: "img/marker/sea.png", anchor: [0.5, 1.0] },
    "inflight":   { src: "img/marker/inflight.png", anchor: [0.5, 0.5] }
  },

  MAP_HAZARDS: {
    "hazard":     { src: "img/marker/hazard.png", anchor: [0.5, 0.75] },
    "bee":        { src: "img/marker/bee.png", anchor: [0.5, 0.75] }
  },
  */
















