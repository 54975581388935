import img1 from './../../assets/images/marker/waypoint/building.png';
import img2 from './../../assets/images/marker/waypoint/cow.png';
import img3 from './../../assets/images/marker/waypoint/crop.png';
import img4 from './../../assets/images/marker/waypoint/desert.png';
import img5 from './../../assets/images/marker/waypoint/factory.png';
import img6 from './../../assets/images/marker/waypoint/fire.png';
import img7 from './../../assets/images/marker/waypoint/flag-green.png';
import img8 from './../../assets/images/marker/waypoint/flag-red.png';
import img9 from './../../assets/images/marker/waypoint/forest.png';
import img10 from './../../assets/images/marker/waypoint/hangar.png';
import img11 from './../../assets/images/marker/waypoint/inflight.png';
import img12 from './../../assets/images/marker/waypoint/marker-icon.png';
import img13 from './../../assets/images/marker/waypoint/marker-icon-green.png';
import img14 from './../../assets/images/marker/waypoint/mountain.png';
import img15 from './../../assets/images/marker/waypoint/pine.png';
import img16 from './../../assets/images/marker/waypoint/power.png';
import img17 from './../../assets/images/marker/waypoint/sea.png';
import img18 from './../../assets/images/marker/waypoint/tree.png';
import img19 from './../../assets/images/marker/waypoint/volcano.png';
import img20 from './../../assets/images/marker/waypoint/water.png';

import img21 from './../../assets/images/icone_aircraft.png';
import img22 from './../../assets/images/icone_carro.png';

import img23 from './../../assets/images/info.png';

import img24 from './../../assets/images/marker/waypoint/marker-icon-yellow.png';
import img25 from './../../assets/images/marker/waypoint/hangar-d.png';

const img = [
    {key: 1, desc: 'marker-icon', img: img12}, 
    {key: 2, desc: 'marker-icon-green', img: img13}, 
    {key: 3, desc: 'building', img: img1}, 
    {key: 4, desc: 'cow', img: img2}, 
    {key: 5, desc: 'crop', img: img3}, 
    {key: 6, desc: 'desert', img: img4}, 
    {key: 7, desc: 'factory', img: img5}, 
    {key: 8, desc: 'fire', img: img6}, 
    {key: 9, desc: 'flag-green', img: img7}, 
    {key: 10, desc: 'flag-red', img: img8}, 
    {key: 11, desc: 'forest', img: img9}, 
    {key: 12, desc: 'hangar', img: img10}, 
    {key: 13, desc: 'inflight', img: img11},     
    {key: 14, desc: 'mountain', img: img14}, 
    {key: 15, desc: 'pine', img: img15}, 
    {key: 16, desc: 'power', img: img16}, 
    {key: 17, desc: 'sea', img: img17}, 
    {key: 18, desc: 'tree', img: img18}, 
    {key: 19, desc: 'volcano', img: img19}, 
    {key: 20, desc: 'water', img: img20},
    {key: 21, desc: 'icone_aircraft', img: img21}, 
    {key: 22, desc: 'icone_carro', img: img22},
    {key: 23, desc: 'info', img: img23},
    {key: 24, desc: 'marker-icon-yellow', img: img24},
    {key: 25, desc: 'hangar-d', img: img25}
];


export default img;