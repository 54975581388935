import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  getDate = () => {
    let date = new Date();
    let ret = date.getFullYear() + '-';
    ret += (date.getMonth() + 1).toString().padStart(2, '0') + '-';
    ret += date.getDate().toString().padStart(2, '0');
    //ret += date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
    return ret;
  }
  constructor(props) {
    super(props);

    //const cid = props.id;

    this.state = {
      date: this.getDate(),
      device_id: '',
      list_data: [],
    };
  }

  componentDidMount() {
    this.updateList();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  send_update = () => {
    let apiUrl = Config.server + '/updategps';
    let method = 'POST';
    if (!this.state.id_device) {
      notification.open({
        message: 'id_device necessário.',
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return;
    }

    const formData = new URLSearchParams();
    let date = new Date(this.state.date);
    //date.setDate(date.getDate() + 1);
    formData.append('date', date.getTime());
    formData.append('id_device', this.state.id_device);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.updateList();
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }
  isOn = false;
  updateList = () => {
    const apiUrl = Config.server + '/updategps';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if(result.length > 0){
            if(!this.isOn){
              setInterval(() => {
                this.updateList();
              }, 60000);
              this.isOn = true;
            }
          }          
          this.setState({
            list_data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  render() {
    const { classes, res } = this.props;

    return (
      <div>
        <div className="form-group notTooBig">
          <TextField
            id="id_device"
            label={'id_device'}
            className={classes.textField}
            value={this.state.id_device}
            onChange={this.handleChange('id_device')}
            margin="normal"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="date"
            type="date"
            label={res.LBLDATA}
            value={this.state.date}
            onChange={this.handleChange('date')}
            margin="normal"
          />
          <Button onClick={() => this.send_update()}>
            update
          </Button>
          <br />
          {this.state.list_data.map((item) => {
            return <div>{item.id_device + ' - ' + item.date}</div>;
          })}
        </div>
      </div>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

