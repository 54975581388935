import React from 'react';
//import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { notification } from 'antd';

import Button from '@material-ui/core/Button';
import jszip from 'jszip';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from "@material-ui/icons/Search";
import Tab from '../../components/Tab/tab';
//import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import MenuList from '@material-ui/core/Menu';

import geo from '../../components/util/geo';

import saveAs from 'file-saver';
import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import SvgIcon from '@material-ui/core/SvgIcon';

import L from 'leaflet';
import 'leaflet-draw';

import Checkbox from '@material-ui/core/Checkbox';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SPW from "string-pixel-width";

import tools from '../../components/util/tools';
import { useParams } from "react-router";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DialogActions from "@material-ui/core/DialogActions";

import ListFilter from '../../components/ImportFile/wizard';
//import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditProperty from '../client/property/Edit';
import Dialog from '@material-ui/core/Dialog';

import TurfOverlap from '@turf/boolean-overlap';
import TurfContains from '@turf/boolean-contains';

class polygon {
    constructor() {
        this.id = null;
        this.id_region = null;
        this.guid = '';
        this.name = '';
        this.area = 0;
        this.radius = 0;
        this.point = '';
        this.points = '';
        this.x = '';
        this.old = null;
        this.type = '';
    }
}

class EditDraw extends React.Component {
    tabID = [
        'polygons',
        'areas_client'
    ];
    state = {
        anchorEl: null,
        clients: [],
        planes: [],
        selectedSendPlanes: [],
        [this.tabID[1]]: [],
        fazenda_client: [],
        fazenda_clientPup: [],
        id_propertyPup: 0,
        areaPup: null,
        id_clientPup: 0,
        id_client: 0,
        showForm: false,
        id_property: null,
        tab: '0',
        filtro: '',
        name: '',
        local: this.props.local,
        [this.tabID[0]]: [],
        sel_poly: [],
        sel_merge: false,
        sel_x: false,
        create: false,
        edit: false,
        delete: false,
        nameP: '',
        valid_nameP: true,
        merge: false,
        split: false,
        type: 2,
        goto: false,
        edit_name: false,
        lat: {
            value: '',
            error: false,
            input: ''
        },
        lng: {
            value: '',
            error: false,
            input: ''
        },
        new_error: true,
        gap_size: 10,
        //progress: false,
        showImport: false,
        itensImport: [],
        namesC: [],

        ruler_a: false,
        label: true,
        label_text: '',
        home: Config.place,
        disable: false,
        typeUser: [],

        selector: false,
        cPoly: true,
        sPoly: true,

        anchorMN: null,
        anchorPL: null,
        anchorAPO: null,
        typeTool: 'create',
        nameR: ''
    };

    tabItens = [
        { label: 'Sem Vínculo', value: '0' },//this.props.res.LOCALATUAL
        { label: this.props.res.CLIENTE, value: '1' },
    ]
    clickItem = false;
    past_merge = false;
    ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
    upType = 'P';
    lastType = 2;

    markClick = false;
    featureGroupP = null;
    featureGroupM = null;
    featureGroupPoly = null;
    drawControl = null;
    drawCreateToolbar = null;
    drawEditToolbar = null;
    drawDeleteToolbar = null;
    r = null;
    i = 0;
    polyindex = 0;
    text_polygon = 'new poly ';
    polyClick = false;
    sel_save = [];

    itens_import = [];

    color_default = '#47839e';
    color_selected = '#E67E22';
    color_x = '#ff002b';
    color_edit = '#3388ff';

    shift = false;

    componentDidMount() {
        this.update();
        this.getAreas();
        this.updateListPlanes();
        //this.loadLimit();
        //this.loadUser();
        this.loadClient();
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    };

    update = () => {
        let local_ini = Config.place;
        if (this.state.local) {
            let pos = this.state.local.split(',');
            if (!isNaN(pos[1]) && !isNaN(pos[1])) {
                local_ini = pos;
            }
        }
        navigator.geolocation.getCurrentPosition((position) => {
            if (this.state.polygons.length <= 0) {
                if (!this.state.local) {
                    let lat = this.round_coord(position.coords.latitude);
                    let lng = this.round_coord(position.coords.longitude);
                    this.validate('lat', geo.toDMS(lat, 'latitude', 4));
                    this.validate('lng', geo.toDMS(lng, 'longitude', 4));
                    window.history.replaceState(null, "Travicar", "/app/draw/" + this.round_coord(position.coords.latitude) + ',' + this.round_coord(position.coords.longitude));
                    this.mymap.setView([this.round_coord(position.coords.latitude), this.round_coord(position.coords.longitude)]);
                }
            }
            this.setState({ home: [this.round_coord(position.coords.latitude), this.round_coord(position.coords.longitude)] });
        });

        this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(local_ini, 14);

        L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

        L.control.scale({ imperial: false }).addTo(this.mymap);

        this.featureGroupP = L.featureGroup();
        this.featureGroupP.addTo(this.mymap);
        this.featureGroupM = L.featureGroup();
        this.featureGroupP.addTo(this.mymap);
        this.featureGroupPoly = L.featureGroup();
        this.featureGroupPoly.addTo(this.mymap);

        this.drawControl = new L.Control.Draw({
            draw: {
                polyline: false,
                polygon: false,
                circle: false,
                rectangle: false,
                marker: false,
                circlemarker: false
            },
            edit: false
        });
        this.drawControl.addTo(this.mymap);

        this.mymap.on('draw:created', (e) => {
            this.markClick = false;
            if (this.state.selector) {
                this.completeSelector(e.layer.toGeoJSON());
            } else if (this.state.create || this.state.create_circle) {
                const guid = tools.newGuid();
                const name = this.state.nameP ? this.state.nameP : this.nextIndex(this.state.polygons);
                e.layer.on("click", () => { this.handleClickP(guid, this.tabID[Number(this.state.tab)]); });
                e.layer._leaflet_id = guid;
                e.layer.setStyle({ color: this.color_default });

                let tempPoly = new polygon();
                tempPoly.guid = guid;
                tempPoly.name = name;
                tempPoly.type = 'N';
                let latlng = [];
                if (e.layer._mRadius) {
                    tempPoly.radius = e.layer._mRadius;
                    tempPoly.point = [e.layer._latlng.lat, e.layer._latlng.lng];
                    latlng = geo.cicleToPolygon([e.layer._latlng.lng, e.layer._latlng.lat], e.layer._mRadius);
                } else {
                    for (let c = 0; c < e.layer._latlngs[0].length; c++) {
                        const element = e.layer._latlngs[0][c];
                        latlng = latlng.concat([[element.lat, element.lng]]);
                    }
                }
                if (e.layer._mRadius) {
                    tempPoly.area = e.layer._mRadius * e.layer._mRadius * Math.PI;
                } else {
                    tempPoly.area = L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]);
                }
                tempPoly.points = JSON.stringify(latlng);
                this.updateArea(tempPoly);
                this.setState({ polygons: this.state.polygons.concat(tempPoly) });
                //this.savePolygons(this.state.polygons.concat(tempPoly));
                // Each time a feaute is created, it's added to the over arching feature group
                this.featureGroupP.addLayer(e.layer);

                e.layer.bindTooltip(name,
                    { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' }
                ).openTooltip();

                this.setState({ create: false, create_circle: false });
            } else {
                e.layer._leaflet_id = 'spliLine';
                this.featureGroupP.addLayer(e.layer);
                this.setState({ merge: true, type: '6' });
                this.editSplit();
            }
        });

        this.mymap.on('draw:canceled', () => {
            this.setState({ create: false, create_circle: false });
            this.markClick = false;
            this.regua(false);
        });

        this.mymap.on('draw:edited', (e) => {
            this.setState({ create: false });
            let tempPolygons = this.state[this.tabID[Number(this.state.tab)]];
            let layers = e.layers;
            layers.eachLayer((feature) => {
                for (let j = 0; j < tempPolygons.length; j++) {
                    if (tempPolygons[j].guid === feature._leaflet_id) {
                        let latlng = [];
                        if (feature._mRadius) {
                            tempPolygons[j].radius = feature._mRadius;
                            tempPolygons[j].point = [feature._latlng.lat, feature._latlng.lng];
                            latlng = geo.cicleToPolygon([feature._latlng.lng, feature._latlng.lat], feature._mRadius);
                        } else {
                            for (let c = 0; c < feature._latlngs[0].length; c++) {
                                const element = feature._latlngs[0][c];
                                latlng = latlng.concat([[element.lat, element.lng]]);
                            }
                        }
                        tempPolygons[j].points = JSON.stringify(latlng);
                        if (feature._mRadius) {
                            tempPolygons[j].area = feature._mRadius * feature._mRadius * Math.PI;
                        } else {
                            tempPolygons[j].area = L.GeometryUtil.geodesicArea(feature.getLatLngs()[0]);
                        }
                        this.updateArea(tempPolygons[j]);
                        break;
                    }
                }
            });
            //this.savePolygons(tempPolygons);
        });

        this.mymap.on('draw:deleted', (e) => {
            let tempPolygons = this.state[this.tabID[Number(this.state.tab)]];
            let layers = e.layers;
            if (layers) {
                let ids = [];
                layers.eachLayer((feature) => {
                    for (let j = 0; j < tempPolygons.length; j++) {
                        const element = tempPolygons[j];
                        if (element.guid === feature._leaflet_id) {
                            tempPolygons.splice(tempPolygons.indexOf(element), 1);
                            ids.push(element.id);
                            //verifica se existe duplicado
                            let tempPX = tempPolygons.filter(item => { return item.guid.includes(element.guid); });
                            for (let k = 0; k < tempPX.length; k++) {
                                tempPolygons.splice(tempPolygons.indexOf(tempPX[k]), 1);
                                ids.push(tempPX[k].id);
                            }
                            break;
                        }
                    }

                });
                if (ids.length > 0) {
                    this.deleteArea(ids);
                }
            }
        });

        this.mymap.on('click', () => {
            if (this.state.selector) {
                this.markClick = false;
            } else {
                this.handleClickP(null, this.tabID[Number(this.state.tab)]);
                if (this.state.create_circle) {
                    this.setState({ create_circle: false });
                    this.markClick = false;
                }
            }
        });

        this.mymap.on('mouseup', e => {
            if (this.state.ruler_a && !this.mymap.dragging._enabled) {
                this.mymap.dragging.enable();
                if (this.ruler_p.second.lat === null) {
                    this.drawCreateToolbar.addVertex(e.latlng);
                }
            }
        });

        this.mymap.on('moveend', (e) => {
            let lat = { error: false, input: geo.toDMS(this.mymap.getCenter().lat, 'latitude', null, this.props.res), value: this.mymap.getCenter().lat };
            let lng = { error: false, input: geo.toDMS(this.mymap.getCenter().lng, 'longitude', null, this.props.res), value: this.mymap.getCenter().lng };
            this.setState({ lat: lat, lng: lng });
            window.history.replaceState(null, "Travicar", "/app/draw/" + this.round_coord(this.mymap.getCenter().lat) + ',' + this.round_coord(this.mymap.getCenter().lng));
        });

        this.mymap.on('zoomend', () => {
            if (this.mymap.getZoom() < 12) {
                tools.cssShow('editable', false);
            } else {
                tools.cssShow('editable', true);
            }
        });

        this.mymap.on('draw:drawvertex', (e) => {
            if (this.drawCreateToolbar.type === 'polyline' && this.state.ruler_a) {
                let layerIds = Object.keys(e.layers._layers);
                if (layerIds.length > 1) {
                    let v2 = e.layers._layers[layerIds[1]];
                    this.ruler_p.second.lat = v2._latlng.lat;
                    this.ruler_p.second.lng = v2._latlng.lng;
                    requestAnimationFrame(() => this.completeRuler());
                } else if (layerIds.length === 1) {
                    let v1 = e.layers._layers[layerIds[0]];
                    this.ruler_p.first.lat = v1._latlng.lat;
                    this.ruler_p.first.lng = v1._latlng.lng;
                }
            }
        });

        this.drawEditToolbar = new L["EditToolbar"].Edit(this.mymap, {
            featureGroup: this.featureGroupP
        });

        this.drawDeleteToolbar = new L["EditToolbar"].Delete(this.mymap, {
            featureGroup: this.featureGroupP
        });

        L.drawLocal.draw.handlers.polygon.tooltip.start = '';
        L.drawLocal.draw.handlers.polygon.tooltip.cont = '';
        L.drawLocal.draw.handlers.polygon.tooltip.end = '';

        L.drawLocal.edit.handlers.edit.tooltip.text = '';
        L.drawLocal.edit.handlers.edit.tooltip.subtext = '';

        L.drawLocal.edit.handlers.remove.tooltip.text = '';

        L.drawLocal.draw.handlers.polyline.tooltip.start = '';
        L.drawLocal.draw.handlers.polyline.tooltip.cont = '';

        L.drawLocal.draw.handlers.circle.tooltip.start = this.props.res.CLICK_CIRCULO;
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleChangeCoordinates = name => event => {
        this.validate(name, event.target.value);
    };

    validate = (name, value) => {
        //regex: /\d{1,2}º\d\d'(\d\d|\d\d\.\d{1,4}) (N|S)/
        //geo.toCoordinate(this.state.lat_input);
        //geo.toDMS(this.state.lat, 'latitude', 4);
        let update = this.state[name];
        if (name === 'lat' || name === 'latL') {
            update.input = value;
            if (/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(N|S)/.test(value)) {
                update.value = geo.toCoordinate(geo.toFixDMS(value));
                update.error = false;
            } else {
                update.error = true;
            }
        } else if (name === 'lng' || name === 'lngL') {
            update.input = value;
            //TODO:fix res
            if ((/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(W|E)/.test(value))
                || (/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(O|L)/.test(value))) {
                update.value = geo.toCoordinate(geo.toFixDMS(value));
                update.error = false;
            } else {
                update.error = true;
            }
        }

        this.setState(update);
    }

    formatRegua = (valor) => {
        let text = '';
        if (valor < 1000) {
            text = Math.round(valor) + ' m';
        } else {
            text = (Math.round(valor / 10) / 100) + ' km';
        }

        return text;
    };

    round_coord = (coord) => {
        return Math.round(coord * 10000000) / 10000000;
    };

    handleClickP = (guid, from) => {
        if (!this.state.create && !this.state.edit && !this.state.delete && !this.state.selector && !this.state.split) {
            if (guid) {
                this.polyClick = true;
                let array = [];
                //remove selecao
                if (this.state.sel_poly.includes(guid)) {
                    //remove item do array
                    const sel_poly = this.state[from].filter(item => (guid === item.x || guid === item.guid));
                    array = this.state.sel_poly.filter(item => {
                        let ret = true;
                        for (let i = 0; i < sel_poly.length; i++) {
                            if (sel_poly[i].guid === item) {
                                ret = false;
                                break;
                            }
                        }
                        return ret;
                    });

                    this.setState({ sel_poly: array }, () => {
                        if (this.past_merge) {
                            this.showItem();
                        }
                    });
                    for (let i = 0; i < sel_poly.length; i++) {
                        if (sel_poly[i].type === 'X' || sel_poly[i].type === 'XL') {
                            this.mymap._layers[sel_poly[i].guid].setStyle({ color: this.color_x });
                        } else {
                            this.mymap._layers[sel_poly[i].guid].setStyle({ color: this.color_default });
                        }
                    }

                    if (array.length === 1) {
                        let sel = this.state[from].filter(item => {
                            return (item.x);
                        });
                        if (sel.length > 0) {
                            this.setState({ sel_x: true });
                        } else {
                            this.setState({ sel_x: false });
                        }
                    } else {
                        this.setState({ sel_x: false });
                    }

                    if (array.length === 0) {
                        this.setState({ sel_merge: false });
                        this.showName(false);
                    }
                }
                //seleciona
                else {
                    let sel = [];
                    if (this.shift && this.state.sel_poly.length > 0) {
                        let lastGuid = this.state.sel_poly[this.state.sel_poly.length - 1];
                        let indexLast = this.state[from].findIndex(x => x.guid === lastGuid);
                        let indexNow = this.state[from].findIndex(x => x.guid === guid);

                        if (indexNow > indexLast) {
                            let c = indexNow;
                            indexNow = indexLast;
                            indexLast = c;
                        }
                        let polys = this.state[from];
                        for (let i = indexNow; i <= indexLast; i++) {
                            if (!this.state.sel_poly.includes(polys[i].guid)) {
                                let layerG = this.mymap._layers[polys[i].guid];
                                sel.push({ guid: polys[i].guid, layer: layerG });
                            }
                        }
                    } else {
                        sel.push({ guid: guid, layer: this.mymap._layers[guid] });
                    }
                    array = this.state.sel_poly;
                    for (let i = 0; i < sel.length; i++) {
                        array = array.concat(sel[i].guid);
                        let areaX = this.state[from].filter(item => {
                            return (sel[i].guid === item.x);
                        });
                        if (areaX.length > 0) {
                            for (let j = 0; j < areaX.length; j++) {
                                const item = areaX[j];
                                if (item.guid !== sel[i].guid) {
                                    array.push(item.guid);
                                    this.mymap._layers[item.guid].setStyle({ color: this.color_selected });
                                }
                            }
                        }
                        sel[i].layer.setStyle({ color: this.color_selected });
                    }
                    this.setState({ sel_poly: array }, () => {
                        if (this.past_merge) {
                            this.showItem();
                        }
                    });

                    if (array.length === 1) {
                        let sel = this.state[from].filter(item => {
                            return (item.guid === array[0] && item.x && item.guid.includes(item.x));
                        });
                        if (sel.length > 0) {
                            this.setState({ sel_x: true });
                        } else {
                            this.setState({ sel_x: false });
                        }
                    } else {
                        this.setState({ sel_x: false });
                    }
                }

                let area_text = 0;
                for (let i = 0; i < array.length; i++) {
                    const element = array[i];
                    let areaX = this.state[from].filter(item => {
                        return (item.guid === element && (item.old))
                    });
                    if (areaX.length > 0) {
                        this.setState({ sel_merge: true });
                    }
                    let areaS = this.state[from].filter(item => {
                        return (item.guid === element && (item.type !== 'X'))
                    });
                    if (areaS.length > 0) {
                        let area = 0;
                        if (areaS[0].area) {
                            area = areaS[0].area;
                        } else {
                            let layerP = this.mymap._layers[areaS[0].guid];
                            area = L.GeometryUtil.geodesicArea(layerP.getLatLngs()[0]);
                        }
                        area_text += area;
                    }
                }
                if (area_text > 0 && array.length > 0) {
                    this.state.edit_name && this.updateName(array);
                    let text = Math.round(area_text / 100) / 100 + ' ha';
                    this.setState({ label_text: text });
                } else {
                    this.setState({ label_text: '' });
                }

            } else if (this.polyClick === false) {
                if (this.state[from].length > 0) {
                    for (let i = 0; i < this.state[from].length; i++) {
                        if (this.state[from][i].type === 'X' || this.state[from][i].type === 'XL') {
                            let temp = this.mymap._layers[this.state[from][i].guid];
                            if (temp) {
                                this.mymap._layers[this.state[from][i].guid].setStyle({ color: this.color_x });
                            }
                        } else {
                            let temp = this.mymap._layers[this.state[from][i].guid];
                            if (temp) {
                                temp.setStyle({ color: this.color_default });
                            }
                        }
                    }
                    this.setState({ sel_poly: [], sel_merge: false, sel_x: false, label_text: '' });
                    this.showName(false);
                }
            } else {
                this.polyClick = false;
            }
        }
        this.shift = false;
    };

    zoomInL = () => {
        this.mymap.zoomIn();
    };

    zoomOutL = () => {
        this.mymap.zoomOut();
    };

    criaPoly = () => {
        this.resetClick();

        if (!this.markClick) {
            this.setState({ create: true, nameP: this.nextIndex(this.state.polygons), tab: '0' }, this.resetPolygons);
            this.drawCreateToolbar = new L.Draw.Polygon(this.mymap, { allowIntersection: false, showArea: true, metric: ['ha'] });
            this.drawCreateToolbar.enable();

        }
        this.markClick = true;
    };

    saveCreate = () => {
        if (!this.state.valid_nameP) {
            this.drawCreateToolbar.completeShape();
        }
    };

    deleteLastCreate = () => {
        this.drawCreateToolbar.deleteLastVertex();
    };

    cancelCreate = () => {
        this.markClick = false;
        this.setState({ create: false });
        this.drawCreateToolbar.disable();
    };

    criaCirculo = () => {
        this.resetClick();

        if (!this.markClick) {
            this.setState({ create_circle: true, nameP: this.nextIndex(this.state.polygons), tab: '0' }, this.resetPolygons);
            this.drawCreateToolbar = new L.Draw.Circle(this.mymap, { showArea: true, metric: ['ha'] });
            this.drawCreateToolbar.enable();

        }
        this.markClick = true;
    };

    cancelCreateCirculo = () => {
        this.markClick = false;
        this.setState({ create_circle: false });
        this.drawCreateToolbar.disable();
    };

    editPoly = () => {
        this.resetClick();

        this.setState({ edit: true, sel_poly: [] });
        if (this.drawEditToolbar) {
            this.drawEditToolbar.enable();
        }
        this.state[this.tabID[Number(this.state.tab)]].forEach(item => {
            this.mymap._layers[item.guid].editing.disable();
        });
        this.state.sel_poly.forEach(item => {
            this.mymap._layers[item].editing.enable();
            this.mymap._layers[item].setStyle({ color: this.color_edit });
        });
    };

    saveEdit = () => {
        this.setState({ edit: false, label_text: '' });
        if (this.drawEditToolbar) {
            this.drawEditToolbar.save();
            this.drawEditToolbar.disable();
        }
        for (let i = 0; i < this.state[this.tabID[Number(this.state.tab)]].length; i++) {
            const element = this.state[this.tabID[Number(this.state.tab)]][i];
            let layer = this.mymap._layers[element.guid];
            if (layer) {
                if (element.x && element.guid.includes(element.x)) {
                    layer.setStyle({ color: this.color_x });
                } else {
                    layer.setStyle({ color: this.color_default });
                }
            }
        }
    };

    cancelEdit = () => {
        this.setState({ edit: false, label_text: '' });
        if (this.drawEditToolbar) {
            this.drawEditToolbar.revertLayers();
            this.drawEditToolbar.disable();
        }
        for (let i = 0; i < this.state[this.tabID[Number(this.state.tab)]].length; i++) {
            const element = this.state[this.tabID[Number(this.state.tab)]][i];
            let layer = this.mymap._layers[element.guid];
            if (layer) {
                if (element.x && element.guid.includes(element.x)) {
                    layer.setStyle({ color: this.color_x });
                } else {
                    layer.setStyle({ color: this.color_default });
                }
            }
        }
    };

    deletePoly = () => {
        this.resetClick();
        this.sel_save = this.state.sel_poly;
        for (let i = 0; i < this.state.sel_poly.length; i++) {
            const element = this.state.sel_poly[i];
            this.removeLayer(this.featureGroupP, this.mymap._layers[element]);
        }
        this.setState({ delete: true, sel_poly: [] });
        this.drawDeleteToolbar.enable();
    };

    saveDelete = () => {
        this.setState({ delete: false, label_text: '' });
        let polygons = this.state[this.tabID[Number(this.state.tab)]];
        let ids = [];
        this.sel_save.forEach(feature => {
            let tempPolygons = polygons.filter(item => { return item.guid.includes(feature); });
            for (let j = 0; j < tempPolygons.length; j++) {
                polygons.splice(polygons.indexOf(tempPolygons[j]), 1);
                ids.push(tempPolygons[j].id);
            }
            //this.savePolygons(polygons);
        });
        if (ids.length > 0) {
            this.deleteArea(ids);
        }
        this.drawDeleteToolbar.save();
        this.drawDeleteToolbar.disable();
    };

    cancelDelete = () => {
        this.setState({ delete: false, label_text: '' });
        if (this.state[this.tabID[Number(this.state.tab)]].length > this.sel_save.length) {
            this.drawDeleteToolbar.revertLayers()
        }
        this.drawDeleteToolbar.disable();

        this.sel_save.forEach(feature => {
            let tempPolygons = this.state[this.tabID[Number(this.state.tab)]].filter(item => { return feature.includes(item.guid); });
            for (let j = 0; j < tempPolygons.length; j++) {
                this.drawPolygon(tempPolygons[j], this.tabID[Number(this.state.tab)]);
            }
        });
    };

    resetClick = () => {
        if (this.state.create) {
            this.cancelCreate();
        }
        if (this.state.create_circle) {
            this.cancelCreateCirculo();
        }
        if (this.state.ruler_a) {
            this.cancelRuler();
        }
        if (this.state.edit) {
            this.cancelEdit();
        }
        if (this.state.delete) {
            this.cancelDelete();
        }
    };

    handlePropertyListItemClick = (divId, itemId, guid) => {
        let elementDiv = document.getElementById(divId);
        let elementItem = document.getElementById(itemId);
        elementItem.classList.toggle("active");
        var panel = elementDiv.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        //this.handleListItemClick(guid);
    }

    handleListItemClick = (guid, from) => {
        if (this.clickItem) {
            this.clickItem = false;
        } else {
            //let poly = this.mymap._layers[guid];
            this.handleClickP(guid, from);
        }
    };

    showMenu = () => {
        let dF = document.getElementById('divFloat');
        if (!dF.className.includes('div-listFloatC')) {
            dF.classList.toggle("div-listFloatC");
        } else {
            dF.classList.remove("div-listFloatC");
        }
    };

    showItem = () => {
        this.showName(true, this.state.sel_poly);
        if (this.state.sel_poly.length === 1) {
            let item = this.state[this.tabID[Number(this.state.tab)]].filter(item => { return item.guid === this.state.sel_poly[0]; });
            if (item.length > 0)
                this.setState({ nameP: item[0].name });
        }
    };

    saveItem = () => {
        let names = this.state.namesC;
        //let polygons = this.state.polygons;
        for (let i = 0; i < names.length; i++) {
            let itens = this.state[this.tabID[Number(this.state.tab)]].filter(item => { return names[i].guid === item.guid });
            itens.forEach((element, key) => {
                element.name = names[i].name;
                let poly = this.mymap._layers[element.guid];
                poly.setTooltipContent(element.name);
                this.updateArea(element);
            });
        }
        //this.savePolygons(polygons);
        this.showName(false);
        if (this.past_merge) {
            this.past_merge = false;
        }
    };

    cancelItem = () => {
        this.showName(false);
        if (this.past_merge) {
            this.setState({ sel_poly: [] });
            this.past_merge = false;
            this.desfazer();
        }
    };

    nextIndex = (listP) => {
        let lastI = 1;
        let retorno = this.text_polygon + lastI;
        for (let i = 0; i < listP.length; i++) {
            if (listP[i].name === retorno) {
                i = -1;
                lastI++;
            }
            retorno = this.text_polygon + lastI;
        }
        return retorno;
    };

    getNewName = (name, list) => {
        let new_name = name;
        let temp_name = list.filter(item => (item.name.includes(name)));
        let run = (n) => {
            return temp_name.find(item => (item.name === n));
        }
        for (let i = 1; run(new_name); i++) {
            new_name = `${name} (${i})`;
        }
        return new_name;
    }

    fileMap = (tFeatures) => {
        this.showPro(true);
        let polys = JSON.parse(JSON.stringify(this.state.polygons));
        for (let i = 0; i < tFeatures.length; i++) {
            //const element = turfS(track.features[i], {tolerance: 0.00005, highQuality: true});
            let features = tFeatures[i];
            if (features.geometry.type === 'Polygon') {
                polys = this.loadPolyFile(features.properties, features.geometry.coordinates[0], polys);
            } else if (features.geometry.type === 'MultiPolygon') {
                for (let i = 0; i < features.geometry.coordinates.length; i++) {
                    features.properties.type = 'Polygon';
                    polys = this.loadPolyFile(features.properties, features.geometry.coordinates[i][0], polys);
                }
            } else if (features.geometry.type === 'GeometryCollection') {
                let geometries = features.geometry.geometries;
                for (let j = 0; j < geometries.length; j++) {
                    let element = geometries[j];
                    if (element.type === 'Polygon') {
                        polys = this.loadPolyFile(features.properties, element.coordinates[0], polys);
                    } else if (element.type === 'LineString') {
                        polys = this.loadPolyFile(features.properties, element.coordinates, polys);
                    }
                }
            } else if (features.geometry.type === 'LineString') {
                const arr1 = features.geometry.coordinates[0];
                const arr2 = features.geometry.coordinates[features.geometry.coordinates.length - 1];
                if (arr1[0] === arr2[0] && arr1[1] === arr2[1]) {
                    polys = this.loadPolyFile(features.properties, features.geometry.coordinates, polys);
                }
            }
        }
        let temp_polys = polys.filter(item => !this.state.polygons.find(po => po.guid === item.guid));
        for (let i = 0; i < temp_polys.length; i++) {
            const element = temp_polys[i];
            this.updateArea(element, true);
        }
        //this.savePolygons(polys);
        if (polys.length > 0) {
            //this.setState({ polygons: polys });
            this.mymap.fitBounds(this.featureGroupP.getBounds());
        }
        this.showPro(false);
    };

    loadPolyFile = (props, coordinates, polys) => {
        try {
            let tempPX = this.state.polygons.filter(item => { return item.guid === props.guid });
            let element = geo.simplify(coordinates, 0.00005);
            let latlngs = this.geojsonToLeafletArray(element.geometry.coordinates[0]);
            let guid = props.guid && tempPX.length === 0 ? props.guid : tools.newGuid();
            let name = props.name ? props.name : props.recibo ? props.recibo : this.nextIndex(polys);

            let tempPoly = new polygon();
            tempPoly.guid = guid;
            tempPoly.name = this.getNewName(name, polys);
            tempPoly.type = props.type !== 'X' ? 'N' : 'X';
            tempPoly.x = props.type === 'X' ? guid.split('_xcl')[0] : '';

            tempPoly.points = JSON.stringify(latlngs);

            this.drawPolygon(tempPoly, this.tabID[0]);

            return polys.concat(tempPoly);
        } catch (e) {
            notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
            });
            return polys;
        }
    };

    geojsonToLeafletArray = (poly) => {
        let array = [];
        for (let i = 0; i < poly.length; i++) {
            const element = poly[i];
            if (element) {
                array = array.concat([[element[1], element[0]]]);
            } else {

            }
        }
        return array
    };

    showGoto = () => {
        if (this.state.goto) {
            this.setState({ goto: false });
        } else {
            let lat = { error: false, input: geo.toDMS(this.mymap.getCenter().lat, 'latitude', null, this.props.res), value: this.mymap.getCenter().lat };
            let lng = { error: false, input: geo.toDMS(this.mymap.getCenter().lng, 'longitude', null, this.props.res), value: this.mymap.getCenter().lng };
            this.setState({ goto: true, lat: lat, lng: lng });
            window.history.replaceState(null, "Travicar", "/app/draw/" + this.round_coord(this.mymap.getCenter().lat) + ',' + this.round_coord(this.mymap.getCenter().lng));
        }
    };

    goto = (type) => {
        this.setState({ goto: false });
        if (!type) {
            this.mymap.setView([this.state.lat.value, this.state.lng.value]);
        } else if (type === 'poly') {
            let bounds = this.featureGroupP.getBounds();
            bounds.isValid() && this.mymap.fitBounds(bounds);
        } else if (type === 'home') {
            if (this.state.home !== Config.place) {
                this.mymap.setView(this.state.home);
            }
        } else if (type === 'sel_poly') {
            if (this.state.sel_poly.length > 0) {
                let featureGroupZoom = L.featureGroup();
                for (let i = 0; i < this.state.sel_poly.length; i++) {
                    const element = this.state.sel_poly[i];
                    featureGroupZoom.addLayer(this.mymap._layers[element]);
                }
                let bounds = featureGroupZoom.getBounds();
                bounds.isValid() && this.mymap.fitBounds(bounds);
            }
        }
    };

    getOrigem = (oPoly) => {
        let polys = [];

        let inP = (poly) => {
            //let polyx = this.state.polygons.filter(itemP => { return itemP.guid.includes(oPoly.guid); });
            //if (poly.old === null || polyx.length === 1) {
            polys.push(L.polygon(JSON.parse(poly.points)).toGeoJSON());
            /*} else {
                for (let i = 0; i < poly.old.ob.length; i++) {
                    if (poly.old.ob[i].type !== 'X') {
                        inP(poly.old.ob[i]);
                    }
                }
            }*/
        }

        inP(oPoly);

        return polys;
    };

    junta = () => {
        this.showPro(true);
        this.setState({ merge: false });
        if (this.state.sel_poly.length > 1) {
            let apiUrl = Config.server + '/merge_polygons';
            let method = 'POST';

            const formData = new URLSearchParams();

            let polys = [];
            let polysX = [];
            this.state.sel_poly.forEach(element => {
                const oPoly = this.state[this.tabID[Number(this.state.tab)]].filter(item => {
                    return (item.guid === element);
                });

                if (oPoly[0].type === 'XL' || oPoly[0].type === 'X') {
                    if (oPoly[0].radius > 0) {
                        const myLayer = L.polygon(JSON.parse(oPoly[0].points));
                        const geoj = myLayer.toGeoJSON();
                        //polysX.push({ poly: geo.simplify(geoj.geometry.coordinates[0], 0.00005), name: oPoly[0].name });
                        polysX.push(geo.simplify(geoj.geometry.coordinates[0], 0.00005));
                    } else {
                        //polysX.push({ poly: (this.mymap._layers[element]).toGeoJSON(), name: oPoly[0].name });
                        polysX.push((this.mymap._layers[element]).toGeoJSON());
                    }
                } else {
                    if (oPoly.length > 0) {
                        //origem = origem.concat(this.getOrigem(oPoly[0]));
                        if (oPoly[0].radius > 0) {
                            const myLayer = L.polygon(JSON.parse(oPoly[0].points));
                            const geoj = myLayer.toGeoJSON();
                            //tempPoly.properties.origem = this.getOrigem(oPoly[0]);
                            //polys.push({ poly: geo.simplify(geoj.geometry.coordinates[0], 0.00005), name: oPoly[0].name });
                            polys.push(geo.simplify(geoj.geometry.coordinates[0], 0.00005));
                        } else {
                            const geoj = (this.mymap._layers[element]).toGeoJSON();
                            //tempPoly.properties.origem = this.getOrigem(oPoly[0]);
                            //polys.push({ poly: geo.simplify(geoj.geometry.coordinates[0], 0.00005), name: oPoly[0].name });
                            polys.push(geo.simplify(geoj.geometry.coordinates[0], 0.00005));
                        }

                    }
                }
            });
            formData.append('polygons', JSON.stringify(polys));
            //formData.append('origem', JSON.stringify(origem));
            formData.append('polygons_xcl', JSON.stringify(polysX));
            formData.append('size', this.state.gap_size);
            formData.append('type', this.state.type);

            let options = {
                method: method,
                body: formData,
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            }

            fetch(apiUrl, options)
                .then((res) => {
                    if (res.status !== 200) throw res;
                    else return res.json();
                })
                .then(
                    (result) => {
                        this.showPro(false);
                        //this.setState({ progress: false });
                        if (result.msgErro) {
                            notification.open({
                                message: result.msgErro,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        } else if (result.poly) {
                            this.merge(result);
                        } else {
                            notification.open({
                                message: this.props.res.ERRO,
                                description: this.props.res.ERRO_MSG,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        }
                    },
                    (error) => {
                        this.showPro(false);
                        //this.setState({ progress: false });
                        if (error.status === 405) {
                            notification.open({
                                message: this.props.res.PERMISSAO,
                                description: this.props.res.PERMISSAO_MSG,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        } else if (error.status === 401 || error.status === 403) {
                            console.log(error);
                            this.props.link('/login');
                        }
                        else {
                            console.log(error);
                            notification.open({
                                message: this.props.res.ERRO,
                                description: this.props.res.ERRO_MSG,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        }
                    }
                );
        } else {
            this.showPro(false);
            //this.setState({ progress: false });
        }
    };

    removeLayer = (map, layer) => {
        if (layer) {
            map.removeLayer(layer)
        }
    };

    merge = (polyFinal) => {
        let simpl = geo.simplify(polyFinal.poly.geometry.coordinates[0], 0.00005);
        let latlngs = this.geojsonToLeafletArray(simpl.geometry.coordinates[0]);

        let guid = tools.newGuid();
        let name = this.nextIndex(this.state.polygons);

        let polys = { ob: [] };
        let ids = [];
        this.state.sel_poly.forEach(element => {
            let l = this.mymap._layers[element];
            this.removeLayer(this.featureGroupP, l);

            let temp_poly = this.state.polygons.filter(itemP => { return itemP.guid === element; });
            if (temp_poly.length > 0) {
                polys.ob = polys.ob.concat(temp_poly);

                if (this.state.tab === '0') {
                    ids.push(temp_poly[0].id);
                }
            }
        });
        if (ids.length > 0) {
            this.deleteArea(ids);
        }

        let itens = this.state.polygons.filter(item => { return !this.state.sel_poly.includes(item.guid); });

        let tempRumo = new polygon();
        tempRumo.guid = guid;
        tempRumo.name = name;
        tempRumo.points = JSON.stringify(latlngs);
        tempRumo.old = polys;

        this.drawPolygon(tempRumo, this.tabID[0]);

        itens.push(tempRumo);
        this.updateArea(tempRumo);
        this.setState({ sel_poly: [] });
        let select = [guid];

        if(false){
        //area de exclusao 
        let x = polyFinal.x;
        if (x) {
            if (x.type === 'Feature') {
                x = { features: [x] };
            }
            if (Array.isArray(x)) {
                x = { features: x };
            }
            if (x.features) {
                for (let i = 0; i < x.features.length; i++) {
                    const element = x.features[i];
                    simpl = geo.simplify(element.geometry.coordinates[0], 0.00005);
                    let latlngs2 = this.geojsonToLeafletArray(simpl.geometry.coordinates[0]);

                    let tempRumoX = new polygon();
                    tempRumoX.guid = tools.newGuid();
                    tempRumoX.name = 'xcl' + (i + 1);
                    tempRumoX.points = JSON.stringify(latlngs2);
                    tempRumoX.x = guid;
                    tempRumoX.type = 'X';

                    this.drawPolygon(tempRumoX, this.tabID[0]);
                    this.updateArea(tempRumoX);
                    itens.push(tempRumoX);
                    select.push(tempRumoX.guid);
                }
            }
        }

        //area de teste 
        let polys2 = polyFinal.polys;
        if (polys2) {
            for (let i = 0; i < polys2.length; i++) {
                const element = polys2[i];
                simpl = geo.simplify(element.geometry.coordinates[0], 0.00005);
                let latlngs2 = this.geojsonToLeafletArray(simpl.geometry.coordinates[0]);

                let tempRumoX = new polygon();
                tempRumoX.guid = tools.newGuid();
                tempRumoX.name = 'N_' + (i + 1);
                tempRumoX.points = JSON.stringify(latlngs2);
                tempRumoX.x = guid;
                tempRumoX.type = 'N';

                this.drawPolygon(tempRumoX, this.tabID[0]);
                this.updateArea(tempRumoX);
                itens.push(tempRumoX);
                select.push(tempRumoX.guid);
            }
        }
    }
        //this.savePolygons(itens);
        this.setState({ polygons: itens, tab: '0', sel_poly: [] }, () => {
            this.past_merge = true;
            //let myLayer = this.mymap._layers[guid];
            this.handleClickP(guid, this.tabID[0]);
        });

    };
    //desfazer merge
    desfazer = () => {
        if (this.state.sel_poly.length > 0) {
            let polygons = this.state[this.tabID[Number(this.state.tab)]];
            let poly = polygons.filter(item => {
                return (this.state.sel_poly.includes(item.guid) && item.old)
            });
            let addRemove = [];
            poly.forEach((element) => {
                if (!addRemove.includes(element.guid)) {
                    let remove = [];

                    let idRemove = [];
                    polygons.forEach(item => {
                        if (element.old === item.old || item.x === element.guid) {
                            remove.push(item.guid);
                            if (item.id) {
                                idRemove.push(item.id);
                            }
                        }
                    });
                    if (idRemove.length > 0) {
                        this.deleteArea(idRemove);
                    }

                    addRemove = addRemove.concat(remove);
                    remove.forEach(item => {
                        this.removeLayer(this.featureGroupP, this.mymap._layers[item]);
                    });

                    for (let j = 0; j < element.old.ob.length; j++) {
                        let polyItem = polygons.filter(item => element.old.ob[j].guid === item.guid);
                        if (polyItem.length > 0) {
                            polyItem[j] = element.old.ob[j];
                            this.removeLayer(this.featureGroupP, this.mymap._layers[polyItem[0].guid]);
                        } else {
                            element.old.ob[j].id = null;
                            polygons.push(element.old.ob[j]);
                            this.updateArea(element.old.ob[j]);
                            this.drawPolygon(element.old.ob[j], this.tabID[Number(this.state.tab)]);
                        }
                    }

                    polygons = polygons.filter(item => !remove.includes(item.guid));
                }
            });
            this.setState({ sel_merge: false, label_text: '', sel_poly: [], polygons: polygons });
        }
    };

    download = () => {
        if (this.state.sel_poly.length > 0) {
            let polys = [];
            this.state.sel_poly.forEach((sel) => {
                let item = this.mymap._layers[sel].toGeoJSON();
                let poly = this.state[this.tabID[Number(this.state.tab)]].filter(itemP => { return itemP.guid === sel; })[0];
                let name = poly.name;

                if (item.type === 'FeatureCollection') {
                    item = item.features[0];
                }
                if (item.geometry.type === 'Point') {
                    let myLayer = L.polygon(JSON.parse(poly.points));
                    item = myLayer.toGeoJSON();
                    item.properties = {
                        name: name,
                        type: poly.x || poly.type === 'X' ? 'X' : 'N',
                        guid: poly.guid,
                        point: poly.point,
                        radius: poly.radius
                    };
                } else {
                    item.properties = {
                        name: name,
                        type: poly.x || poly.type === 'X' ? 'X' : 'N',
                        guid: poly.guid
                    };
                }


                polys.push(item);
            });

            let zip = new jszip();
            let file_name = Date.now() + '.kml';
            let data = geo.toKml(file_name, polys);
            zip.file(file_name, data);

            zip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: { level: 4 } }).then((content) => {
                saveAs(content, "areas.kmz");
            });
        }
    };

    changeType = (from) => {
        let sel = this.state.sel_poly;
        console.log('sel', sel);
        this.setState({ sel_poly: [] });
        sel.forEach(item => {
            let poly = this.state[from].filter(i => { return i.guid === item })[0];
            if ((!poly.x || poly.x === poly.guid) && !poly.old) {
                if (poly.type !== 'N') {
                    poly.type = 'N';
                } else {
                    poly.type = 'X';
                }
                let layer = this.mymap._layers[item];
                this.removeLayer(this.featureGroupP, layer);
                this.drawPolygon(poly, from);
                this.updateArea(poly, false);
            }
        });
    };

    loadPolygons = (polys) => {
        if (polys) {
            try {
                polys.forEach(element => {
                    // FIX Poly with _xcl id, but N type
                    if (element.guid.includes('_xcl')) {
                        element.type = 'X';
                    }
                    this.drawPolygon(element, this.tabID[0]);
                });
                if (polys.length > 0) {
                    this.mymap.fitBounds(this.featureGroupP.getBounds());
                    this.setState({ polygons: polys });
                    //????   reload tooltip   ??????
                    this.mymap.eachLayer((l) => {
                        if (l.getTooltip) {
                            var toolTip = l.getTooltip();
                            if (toolTip) {
                                this.mymap.closeTooltip(toolTip);
                                setTimeout(() => {
                                    this.mymap.addLayer(toolTip);
                                }, 500);
                            }
                        }
                    });
                }
            }
            catch (e) { }
        }
    };

    drawPolygon = (poly, from) => {
        let myStyle = {
            "color": this.color_default,
            "weight": 4,
            "opacity": 0.5
        };

        if (poly.type === 'X' || poly.x) {
            myStyle = {
                "color": this.color_x,
                "weight": 2,
                "opacity": 1
            };
        }
        let myLayer = null;
        if (poly.radius > 0) {
            let temp_myStyle = {
                color: myStyle.color,
                weight: myStyle.weight,
                opacity: myStyle.opacity,
                radius: poly.radius
            }
            myLayer = L.circle(poly.point, temp_myStyle);
            poly.area = poly.radius * poly.radius * Math.PI;
        } else {
            myLayer = L.polygon(JSON.parse(poly.points), myStyle);
            poly.area = L.GeometryUtil.geodesicArea(myLayer.getLatLngs()[0]);
        }

        //let myLayer = L.geoJSON(element, { style: myStyle });
        let guid = poly.guid;
        let name = poly.name;
        myLayer.on("click", () => { this.handleClickP(guid, from); });
        myLayer._leaflet_id = guid;

        myLayer.bindTooltip(name, { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' });

        myLayer.addTo(this.featureGroupP);
    };

    limitChar = (string, limit) => {
        let ret = string;
        let size = SPW(string, { font: "Arial", size: 16 });
        if (size > limit) {
            ret = string.slice(0, (string.length * (limit - 3) / size)) + '...';
        }
        return ret;
    };

    handleSelectAllClick = (event, checked) => {
        let polys = this.state[this.tabID[Number(this.state.tab)]].filter(item => {
            if (item.name) {
                return item.name.toUpperCase().includes(this.state.filtro.toUpperCase());
            } else {
                return this.state.filtro ? null : item;
            }
        });
        if (checked) {
            let sel = [];
            for (let i = 0; i < polys.length; i++) {
                sel.push(polys[i].guid);
                let layer = this.mymap._layers[polys[i].guid];
                layer.setStyle({ color: this.color_selected });
            }
            this.setState({ sel_poly: sel });
            //this.setState({ sel_poly: this.state.polygons.map(x => { return x.guid; }) });
        } else {
            for (let i = 0; i < polys.length; i++) {
                let layer = this.mymap._layers[polys[i].guid];
                if (layer) {
                    if (polys[i].type === 'XL' || polys[i].type === 'X') {
                        layer.setStyle({ color: this.color_x });
                    } else {
                        layer.setStyle({ color: this.color_default });
                    }
                }
            }
            this.setState({ sel_poly: [] });
        }
    };

    importList = (itens) => {
        this.setState({ itensImport: [], showImport: false });
        setTimeout(() => { this.fileMap(itens) }, 10);
    };

    showPro = (b) => {
        let dF = document.getElementById('divPro');
        dF.classList.remove("sr-only");
        if (!b) {
            dF.classList.toggle("sr-only");
        }
    };

    showName = (show, sel_poly) => {
        if ((sel_poly && sel_poly.length > 0) || !show) {
            if (show) {
                this.updateName(sel_poly);
            } else {
                this.setState({ namesC: [] });
            }
            this.setState({ edit_name: show });
        }
    };

    updateName = (sel_poly) => {
        let array = [];
        let sel = this.state[this.tabID[Number(this.state.tab)]].filter(item => (sel_poly.includes(item.guid)));
        let last = this.state.namesC;
        let temp_poly = last.concat(this.state[this.tabID[Number(this.state.tab)]].filter(item => (!last.find(il => item.guid === il.guid))));

        for (let i = 0; i < sel.length; i++) {
            let exists = last.filter(item => { return item.guid === sel[i].guid });
            if (exists.length === 0) {
                array.push({
                    guid: sel[i].guid,
                    name: sel[i].name,
                    valid: temp_poly.filter(item => (item.guid !== sel[i].guid && item.name === sel[i].name)).length === 0
                });
            } else {
                array.push({
                    guid: exists[0].guid,
                    name: exists[0].name,
                    valid: temp_poly.filter(item => (item.guid !== exists[0].guid && item.name === exists[0].name)).length === 0
                });
            }
        }

        this.setState({ namesC: array });
    };

    handleChangeNameList = guid => event => {
        let temp_poly = this.state.namesC.concat(this.state[this.tabID[Number(this.state.tab)]].filter(item => (!this.state.namesC.find(il => item.guid === il.guid))));
        let valid = temp_poly.filter(item => (item.name === event.target.value && item.guid !== guid)).length === 0;
        let array = this.state.namesC;
        for (let i = 0; i < array.length; i++) {
            if (array[i].guid === guid) {
                array[i].name = event.target.value;
                array[i].valid = temp_poly.filter(item => (item.guid !== guid && item.name === event.target.value)).length === 0;
            } else {
                array[i].valid = temp_poly.filter(item =>
                    (item.guid !== array[i].guid && item.name === array[i].name) || (item.guid === array[i].guid && item.name === event.target.value)
                ).length === 0;
            }
        }
        this.setState({ namesC: array, valid_nameP: valid });
    };

    keyDown = (e) => {
        if (e.keyCode === 16) {
            this.shift = true;
        }
    };

    keyUp = (e) => {
        this.shift = false;
    };

    regua = (start) => {
        if (this.state.create) {
            this.cancelCreate();
        }
        if (this.state.edit) {
            this.cancelEdit();
        }

        this.removeLayer(this.featureGroupPoly, this.mymap._layers['regua']);
        this.removeLayer(this.featureGroupPoly, this.mymap._layers['reguaM1']);
        this.removeLayer(this.featureGroupPoly, this.mymap._layers['reguaM2']);

        this.ruler_p.second.lat = null;
        this.ruler_p.second.lng = null;
        if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
            this.setState({ ruler_a: false });
            this.mymap.dragging.enable();
            this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
            if (this.drawCreateToolbar) {
                this.drawCreateToolbar.disable();
            }
        } else {
            this.setState({ ruler_a: true });
            this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
            this.drawCreateToolbar.enable();
            if (start) {
                this.drawCreateToolbar.addVertex(start);
            }
        }
    };

    completeRuler = () => {
        if (this.drawCreateToolbar) {
            this.drawCreateToolbar.disable();
        }

        let latlngs = [
            [this.ruler_p.first.lat, this.ruler_p.first.lng],
            [this.ruler_p.second.lat, this.ruler_p.second.lng]
        ];
        let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
        let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
        let text = latlngF.distanceTo(latlngS);


        let polyline = L.polyline(latlngs);

        polyline._leaflet_id = 'regua';
        polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
        polyline.addTo(this.featureGroupPoly);

        let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
            icon: new L.DivIcon({
                iconSize: new L.Point(10, 10),
                className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
            })
        })
        m1.on('mousedown', (e) => {
            this.mymap.dragging.disable();
            this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
        })
        m1._leaflet_id = 'reguaM1';
        m1.addTo(this.featureGroupPoly);

        let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
            icon: new L.DivIcon({
                iconSize: new L.Point(10, 10),
                className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
            })
        });
        m2.on('mousedown', (e) => {
            this.mymap.dragging.disable();
            this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
        })
        m2._leaflet_id = 'reguaM2';
        m2.addTo(this.featureGroupPoly);
    };

    cancelRuler = () => {
        if (this.ruler_p.first.lat) {
            this.regua();
        }
    };

    loadLimit = () => {
        const apiUrl = Config.server + '/company_config';
        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        if (result[0].limit_orc_ha <= 0) {
                            this.setState({ disable: true });
                        }
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: "PERMISSAO",
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: "ERRO",
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    loadUser = () => {
        const apiUrl = Config.server + '/currentProfile';
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    let array = [];
                    result.forEach(element => {
                        array.push(element.id_profile);
                    });
                    this.setState({ typeUser: array });
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    loadClient = () => {
        const apiUrl = Config.server + '/customer';
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    this.setState({ clients: result });
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else if (error.status === 400) {
                        error.json().then(
                            (errCode) => {
                                notification.open({
                                    message: this.props.res[errCode.code],
                                    icon: <MaterialIcon icon="error" className="text-danger" />
                                });
                            });
                    } else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    loadProperty = (id_client, callback) => {
        const apiUrl = Config.server + '/properties/' + id_client;
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    callback(result);
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    loadAreas = (id, callback) => {
        const apiUrl = Config.server + '/areas/' + id;
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    //console.log(result);
                    if (id === 0) {
                        if (callback) {
                            callback(result);
                        }
                    } else {
                        this.setState({ areas_client: result }, this.resetPolygons);
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    updateArea = (area, update) => {
        let apiUrl = Config.server + '/area';
        let method = 'PUT';
        let poly = area;
        let tab = area.id_client ? 0 : 1;

        const formData = new URLSearchParams();
        if (poly.id) {
            formData.append('id', poly.id);
        }
        if (poly.id_client) {
            formData.append('id_client', poly.id_client);
            formData.append('id_property', poly.id_property);
        }
        if (poly.id_region) {
            formData.append('id_region', poly.id_region);
        }
        formData.append('guid', poly.guid);
        formData.append('points', poly.points);
        formData.append('type', poly.type);
        formData.append('name', poly.name);

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (update) {
                        if (tab === 0) {
                            let temp_here = this.state[this.tabID[0]].filter(item => { return item.guid !== poly.guid; });
                            let temp_there = this.state[this.tabID[1]];
                            temp_there.push(poly);
                            this.setState({ [this.tabID[0]]: temp_here, [this.tabID[1]]: temp_there });
                        } else {
                            let temp_here = this.state[this.tabID[1]].filter(item => { return item.guid !== poly.guid; });
                            let temp_there = this.state[this.tabID[0]];
                            temp_there.push(poly);
                            this.setState({ [this.tabID[1]]: temp_here, [this.tabID[0]]: temp_there });
                        }
                    }
                    if (result) {
                        poly.id = result;
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        console.log(error);
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    moveAreas = (areas, id_property) => {
        let apiUrl = Config.server + '/areas';
        let method = 'PUT';
        let poly = areas;
        let tab = id_property ? 0 : 1;

        const formData = new URLSearchParams();
        formData.append('ids', poly.map((item) => (item.id)));
        formData.append('id_property', id_property);

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (tab === 0) {
                        let temp_here = this.state[this.tabID[0]].filter(item => poly.findIndex(sel => sel === item) === -1);
                        let temp_there = this.state[this.tabID[1]];
                        temp_there = temp_there.concat(poly);
                        this.setState({ [this.tabID[0]]: temp_here, [this.tabID[1]]: temp_there, sel_poly: [] });
                    } else {
                        let temp_here = this.state[this.tabID[1]].filter(item => poly.findIndex(sel => sel === item) === -1);
                        let temp_there = this.state[this.tabID[0]];
                        temp_there = temp_there.concat(poly);
                        this.setState({ [this.tabID[1]]: temp_here, [this.tabID[0]]: temp_there, sel_poly: [] });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        console.log(error);
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    deleteArea = (ids, callback) => {
        let apiUrl = Config.server + '/area';
        let method = 'DELETE';

        const formData = new URLSearchParams();
        formData.append('ids', ids);

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (callback) {
                        callback();
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        console.log(error);
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    openSplit = () => {
        this.resetClick();
        this.setState({ merge: false, split: true });
        this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
        this.drawCreateToolbar.enable();
    }

    useSplitLine = () => {
        if (this.drawCreateToolbar && this.drawCreateToolbar._markers.length > 1) {
            this.drawCreateToolbar.completeShape();
            this.editSplit();

            this.setState({ merge: true, type: '6', gap_size: '0' });
        }
    }

    split = () => {
        let item = this.mymap._layers['spliLine'];

        this.showPro(true);
        let apiUrl = Config.server + '/merge_polygons';
        let method = 'POST';

        const formData = new URLSearchParams();

        let polys = [];
        this.state[this.tabID[Number(this.state.tab)]].forEach(element => {
            if (element.type !== 'XL' && element.type !== 'X') {
                let geoj = null;
                if (element.radius > 0) {
                    const myLayer = L.polygon(JSON.parse(element.points));
                    geoj = myLayer.toGeoJSON();
                } else {
                    geoj = (this.mymap._layers[element.guid]).toGeoJSON();
                }
                polys.push({ poly: geo.simplify(geoj.geometry.coordinates[0], 0.00005), name: element.name, guid: element.guid });
            }
        });
        formData.append('polygons', JSON.stringify(polys));
        formData.append('origem', JSON.stringify([]));
        formData.append('polygons_xcl', JSON.stringify(geo.turfLine(item._latlngs)));
        formData.append('size', this.state.gap_size);
        formData.append('type', this.state.type);

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    this.showPro(false);
                    //this.setState({ progress: false });
                    if (result.msgErro) {
                        notification.open({
                            message: result.msgErro,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (result.poly) {
                        this.splited(result);
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                },
                (error) => {
                    this.showPro(false);
                    //this.setState({ progress: false });
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        console.log(error);
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );

        this.cancelSplit();
    }

    splited = (result) => {
        let remove = result.x;

        if (this.drawEditToolbar) {
            this.drawEditToolbar.save();
            this.drawEditToolbar.disable();
        }
        let changeTab = true;
        let polys = { ob: [] };
        let ids = [];
        remove.forEach(element => {
            let l = this.mymap._layers[element];
            //polys.layers.push(l);
            let temp_poly = this.state.polygons.filter(itemP => { return itemP.guid === element; });
            if (temp_poly.length > 0) {
                changeTab = false;
                polys.ob = polys.ob.concat(temp_poly);
                this.removeLayer(this.featureGroupP, l);

                if (this.state.tab === '0') {
                    ids.push(temp_poly[0].id);
                }
            }
        });
        if (ids.length > 0) {
            this.deleteArea(ids);
        }

        let itens = this.state.polygons.filter(item => { return !remove.includes(item.guid); });
        for (let i = 0; i < result.poly.length; i++) {
            for (let j = 0; j < result.poly[i].polys.length; j++) {
                const element = result.poly[i].polys[j];
                let guid = tools.newGuid();

                let tempRumo = new polygon();
                tempRumo.guid = guid;
                tempRumo.name = result.poly[i].name + ' (' + (j + 1) + ')';
                tempRumo.points = JSON.stringify(this.geojsonToLeafletArray(element.geometry.coordinates[0]));
                tempRumo.old = polys;

                this.drawPolygon(tempRumo, this.tabID[0]);
                this.updateArea(tempRumo);
                itens.push(tempRumo);
            }
        }
        this.setState({ polygons: itens, tab: '0' }, () => {
            if (changeTab) {
                this.resetPolygons();
            }
        });
        //this.savePolygons(itens);
    }

    editSplit = () => {
        if (this.drawEditToolbar) {
            this.drawEditToolbar.enable();
        }
        this.state.polygons.forEach(item => {
            let layer = this.mymap._layers[item.guid];
            if (layer) {
                layer.editing.disable();
            }
        });
        let layer = this.mymap._layers['spliLine'];
        if (layer) {
            layer.editing.enable();
        }
    }

    cancelSplit = () => {
        this.setState({ merge: false, split: false });
        if (this.drawCreateToolbar) {
            this.drawCreateToolbar.disable();
        }
        if (this.drawEditToolbar) {
            this.drawEditToolbar.save();
            this.drawEditToolbar.disable();
        }
        let item = this.mymap._layers['spliLine'];
        if (item) {
            this.removeLayer(this.featureGroupP, item);
        }
    }

    clear = () => {
        this.setState({
            filtro: ''
        });
    };

    handleChangeTab = name => (event, newValue) => {
        this.setState({
            [name]: newValue,
        }, this.resetPolygons);
    };

    resetPolygons = () => {
        this.setState({ sel_poly: [] });
        this.featureGroupP.clearLayers();
        if (this.state.tab === '1') {
            for (let i = 0; i < this.state.areas_client.length; i++) {
                this.drawPolygon(this.state.areas_client[i], this.tabID[1]);
            }
        } else {
            for (let i = 0; i < this.state.polygons.length; i++) {
                this.drawPolygon(this.state.polygons[i], this.tabID[0]);
            }
        }
    }

    handleChangeClient = (name, ref, loadArea) => event => {
        this.setState({
            [name]: event.target.value,
            new_error: event.target.value === 0
        }, () => {
            if (event.target.value !== 0) {
                this.loadProperty(event.target.value, (res) => {
                    if (ref) {
                        this.setState({ [ref]: res }, () => {
                            if (loadArea) this.loadAreas(this.state.id_client);
                        });
                    } else {
                        if (loadArea) this.loadAreas(this.state.id_client);
                    }
                });
            }
        });
    };

    handleItem = (guid) => {
        this.clickItem = true;
    }

    onCloseEditProperty = () => {
        this.setState({
            showForm: false,
            id_property: null
        });
    };

    openModal = () => {
        this.setState({ showForm: true });
    };

    editProperty = id => {
        this.setState({ id_property: id });
        this.openModal();
    };

    saveProperty = () => {
        this.loadProperty(this.state.id_client, res => {
            this.setState({
                fazenda_client: res
            }, () => {
                this.loadAreas(this.state.id_client, result => {
                    this.setState({
                        ini: result
                    });
                });
            });
        });
    }

    moveArea = () => {
        if (this.state.id_clientPup <= 0) {
            return;
        }
        this.setState({ anchorEl: null });
        if (this.state.sel_poly.length > 0) {
            const elements = this.state[this.tabID[Number(this.state.tab)]].filter(item => this.state.sel_poly.findIndex(sel => sel === item.guid) >= 0);
            let id_property = this.state.id_clientPup > 0 ? this.state.id_propertyPup : null;
            this.moveAreas(elements, id_property);
        } else {
            let area = this.state.areaPup;
            let item_client = this.state.id_clientPup;
            area.id_client = item_client > 0 ? item_client : null;
            let id_property = item_client > 0 ? this.state.id_propertyPup : null;
            this.moveAreas([area], id_property);
        }
    }

    getAreas = () => {
        const montaPoly = (areas, polys) => {
            let polygons = [];
            for (let i = 0; i < areas.length; i++) {
                const area = areas[i];
                let tempPoly = new polygon();
                tempPoly.id = area.id;
                tempPoly.guid = area.guid;
                tempPoly.name = area.name;
                tempPoly.type = area.type;
                tempPoly.points = area.points;
                polygons.push(tempPoly);
            }
            polygons = polygons.concat(polys);
            this.loadPolygons(polygons);
        }
        this.loadAreas(0, (areas) => {
            //varifica se tem poly on localStorage
            let polys = localStorage.getItem('c-polygons');
            if (polys) polys = JSON.parse(polys);
            if (polys && polys.length > 0) {
                //console.log('[p]', areas);
                let polygons = [];
                for (let j = 0; j < polys.length; j++) {
                    let update = true;
                    for (let i = 0; i < areas.length; i++) {
                        if (polys[j].guid === areas[i].guid) {
                            update = false;
                            break;
                        }
                    }
                    if (update) {
                        this.updateArea(polys[j], true);
                        localStorage.setItem('c-polygons', JSON.stringify([]));
                        polygons.push(polys[j]);
                    }
                }
                montaPoly(areas, polygons);
            } else {
                //console.log('[]', areas);
                montaPoly(areas, []);
            }
        });
    };

    selector = (start) => {
        this.resetClick();
        this.markClick = true;
        if (this.state.create) {
            this.cancelCreate();
        }
        if (this.state.edit) {
            this.cancelEdit();
        }
        if (this.state.ruler_a) {
            this.cancelRuler();
        }

        if (!start && this.state.selector) {
            this.cancelSelector();
        } else {
            this.setState({ selector: true });

            const options = {
                showArea: false,
                showLength: false,
                shapeOptions: {
                    stroke: true,
                    color: '#3388ff',
                    weight: 3,
                    opacity: 0.5,
                    fill: false,
                    fillColor: null, //same as color by default
                    fillOpacity: 0,
                    clickable: false,
                    dashArray: '10, 10',
                    dashOffset: '10'
                },
                // Whether to use the metric measurement system (truthy) or not (falsy).
                // Also defines the units to use for the metric system as an array of
                // strings (e.g. `['ha', 'm']`).
                metric: true,
                feet: true, // When not metric, to use feet instead of yards for display.
                nautic: false, // When not metric, not feet use nautic mile for display
                // Defines the precision for each type of unit (e.g. {km: 2, ft: 0}
                precision: {}
            };
            this.drawCreateToolbar = new L.Draw.Polygon(this.mymap, options);
            this.drawCreateToolbar.enable();
        }
    };

    selectorRet = (start) => {
        this.resetClick();
        this.markClick = true;
        if (this.state.create) {
            this.cancelCreate();
        }
        if (this.state.edit) {
            this.cancelEdit();
        }
        if (this.state.ruler_a) {
            this.cancelRuler();
        }

        if (!start && this.state.selector) {
            this.cancelSelector();
        } else {
            this.setState({ selector: true });

            const options = {
                shapeOptions: {
                    stroke: true,
                    color: '#3388ff',
                    weight: 3,
                    opacity: 0.5,
                    fill: false,
                    fillColor: null, //same as color by default
                    fillOpacity: 0,
                    showArea: false,
                    clickable: false,
                    dashArray: '10, 10',
                    dashOffset: '10'
                },
                metric: true // Whether to use the metric measurement system or imperial
            };
            this.drawCreateToolbar = new L.Draw.Rectangle(this.mymap, options);
            this.drawCreateToolbar.enable();
        }
    };

    completeSelector = (selector) => {
        let sel = [];
        this.cancelSelector();
        let area = 0;
        for (let i = 0; i < this.state[this.tabID[Number(this.state.tab)]].length; i++) {
            const polys = this.state[this.tabID[Number(this.state.tab)]][i];
            const myLayer = (polys.radius > 0) ? L.polygon(JSON.parse(polys.points)) : this.mymap._layers[polys.guid];
            const geoj = myLayer.toGeoJSON();
            if (TurfOverlap(geoj, selector) || TurfContains(selector, geoj)) {
                sel.push(polys.guid);
                let layer = this.mymap._layers[polys.guid];
                layer.setStyle({ color: this.color_selected });
                let tArea = 0;
                if (polys.area) {
                    tArea = polys.area;
                } else {
                    tArea = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                }
                area += tArea;
            }
        }
        let update = { sel_poly: sel };
        if (area > 0) {
            let text = Math.round(area / 100) / 100 + ' ha';
            update.label_text = text;
        } else {
            update.label_text = '';
        }
        this.setState(update);
    };

    cancelSelector = () => {
        setTimeout(() => { this.setState({ selector: false }) }, 20);
        if (this.drawCreateToolbar) this.drawCreateToolbar.disable();
        let s = this.mymap._layers['selector'];
        this.removeLayer(this.featureGroupP, s);
        let sM = this.mymap._layers['selectorM'];
        this.removeLayer(this.featureGroupP, sM);
        this.selector_l = [];
    };

    setAnchor = (type) => (event) => {
        if (event) {
            this.setState({ anchorMN: event.currentTarget, typeTool: type });
        } else {
            this.setState({ anchorMN: null }, () => setTimeout(() => this.setState({ typeTool: type }), 200));
        }
    };

    abrirPlanejamento = () => {
        let polyPlan = [];
        const sel_poly = this.state.sel_poly;
        for (let i = 0; i < sel_poly.length; i++) {
            const element = this.state[this.tabID[Number(this.state.tab)]].filter(item => item.guid === sel_poly[i]);
            if (element.length > 0) {
                polyPlan.push({
                    "guid": element[0].guid,
                    "name": element[0].name,
                    "points": element[0].points,
                    "type": element[0].type,
                    "id_property": element[0].id_property
                });
            }
        }
        //let d_estimate = localStorage.getItem('f-estimate');
        let new_estimate = {
            polygons: polyPlan
        };
        localStorage.setItem('f-estimate', JSON.stringify(new_estimate));
        this.props.link('/app/estimativa');
    };

    selectSendPlanes = (id) => {
        let selPlanes = this.state.selectedSendPlanes;
        let i = selPlanes.indexOf(id);
        if (i > -1) {
            selPlanes.splice(i, 1);
        } else {
            selPlanes.push(id);
        }
        this.setState({ selectedSendPlanes: selPlanes });
    };

    updateListPlanes = () => {
        const apiUrl = Config.server + '/plane_dgps';

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    this.setState({ planes: result });
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        console.log(error);
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    sendFile = () => {
        let list_area = this.state.sel_poly;
        let list_plane = this.state.selectedSendPlanes;

        this.setState({ progress: true, anchorPL: null });
        let apiUrl = Config.server + '/transfer';
        let method = 'POST';

        let ids = [];
        let polys = this.state[this.tabID[Number(this.state.tab)]];
        for (let i = 0; i < list_area.length; i++) {
            const element = polys.filter(p => (list_area[i] === p.guid));
            if (element.length > 0) {
                ids.push(element[0].id);
            }
        }

        const formData = new URLSearchParams();
        formData.append('list_plane', JSON.stringify(list_plane));
        formData.append('list_area', JSON.stringify(ids));
        formData.append('type', 'region');
        formData.append('name', this.state.nameR);

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((resData) => {
                if (resData.status !== 200) throw resData;
                else return resData.json();
            })
            .then(
                (result) => {
                    this.setState({ progress: false });
                    if (result.msg) {
                        notification.open({
                            message: 'Enviando Áreas',
                            icon: <MaterialIcon icon="check_circle" className="text-success" />
                        });
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                },
                (error) => {
                    this.setState({ progress: false });
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    render() {

        const { res, link } = this.props;

        const disabledButton = {
            color: 'lightgray'
        }

        return (
            <div className="gridEst maxH">
                <div className="divContent">
                    <div className="containerTitle">
                        <div><h1>{res.AREAS}</h1></div>
                        <div className="centerTop"></div>
                        <div></div>
                    </div>
                </div>
                <div style={{ position: 'relative' }}>
                    <div className="rowMapCO maxVH">
                        <div id="divPro" className="sr-only back-drop" style={{ backgroundColor: '#fff', zIndex: 1002 }}>
                            <div className="in-back-drop">
                                <CircularProgress color="inherit" disableShrink />
                            </div>
                        </div>
                        <Dialog onClose={this.onCloseEditProperty} aria-labelledby="simple-dialog-title" open={this.state.showForm}>
                            <EditProperty link={link} res={res} modal={true} idClient={this.state.id_client} idProperty={this.state.id_property} onSave={this.saveProperty}></EditProperty>
                        </Dialog>
                        <ListFilter res={res} files={this.state.itensImport} open={this.state.showImport} index={1003} listItens={this.importList} onClose={() => { this.setState({ showImport: false }) }} />
                        <div id="divFloat" className="div-list_p divContentList" style={{ display: 'grid', gridTemplateRows: '4rem 4rem auto', height: '100%' }}>
                            <div style={{ padding: '.5rem', display: 'flex' }}>
                                <Tab value={this.state.tab} handleChange={this.handleChangeTab} name="tab" itens={this.tabItens} />
                            </div>
                            <div style={{ padding: '.5rem', display: 'flex', borderBottom: 'solid 1px #c9c9c9', margin: '.5rem' }}>
                                <div style={{ width: '90%', position: 'relative' }}>
                                    <SearchIcon style={{ marginRight: '.5rem' }} />
                                    <TextField
                                        placeholder="Filtro"
                                        margin="normal"
                                        style={{ marginTop: '-0.3rem' }}
                                        value={this.state.filtro}
                                        onChange={this.handleChange('filtro')}
                                        id="campoFiltro"
                                    />
                                    {(this.state.filtro !== '') &&
                                        <IconButton style={{ marginTop: '-.8rem' }} onClick={() => this.clear()}>
                                            <MaterialIcon icon="clear" />
                                        </IconButton>
                                    }
                                </div>
                                <div style={{ width: '10%', display: 'flex' }}>
                                    <div style={{ width: '30%', textAlign: 'right' }}>
                                        <Checkbox
                                            disableRipple
                                            className="checkMap"
                                            indeterminate={this.state.sel_poly.length > 0 && this.state.sel_poly.length < this.state[this.tabID[Number(this.state.tab)]].length}
                                            checked={this.state.sel_poly.length === this.state[this.tabID[Number(this.state.tab)]].length}
                                            onChange={this.handleSelectAllClick}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: 'relative', width: '100%', overflow: 'auto' }}>
                                {this.state.tab === '1' && <div style={{ width: '100%', padding: '1rem' }}>
                                    <Select
                                        value={this.state.id_client}
                                        style={{ width: '100%' }}
                                        onChange={this.handleChangeClient('id_client', 'fazenda_client', true)}
                                        inputProps={{
                                            id: 'id_client',
                                        }}
                                    >
                                        <MenuItem key={0} value={0}>{'Selecione'}</MenuItem>
                                        {this.state.clients.map((item, key) => {
                                            return <MenuItem key={key + '_client'} value={item.id}>{item.name}</MenuItem>;
                                        })}
                                    </Select>
                                </div>}
                                {<MenuList
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={(e) => { this.setState({ anchorEl: null }); }}
                                >
                                    <div style={{ width: '15rem', padding: '.5rem 1rem', fontSize: '1rem' }}>
                                        {this.state.sel_poly.length <= 1 ?
                                            <span>{this.limitChar(this.state.areaPup ? this.state.areaPup.name : '', 190)}</span>
                                            : <span>({this.state.sel_poly.length})  Selecionados</span>
                                        }
                                    </div>
                                    <div style={{ width: '15rem', padding: '.5rem 1rem', fontSize: '1rem' }}>
                                        <b>Mover Para:</b>
                                    </div>
                                    <div className="divider"></div>
                                    <div style={{ padding: '1rem' }}>
                                        <Select
                                            value={this.state.id_clientPup}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChangeClient('id_clientPup', 'fazenda_clientPup', false)}
                                            inputProps={{
                                                id: 'id_clientPup',
                                            }}
                                        >
                                            <MenuItem key={0} value={0}>{this.state.tab === '1' ? this.tabItens[0].label : 'Selecione'}</MenuItem> :
                                            {this.state.clients.map((item, key) => {
                                                return <MenuItem key={key + '_clientPup'} value={item.id}>{item.name}</MenuItem>;
                                            })}
                                        </Select>
                                    </div>
                                    {this.state.id_clientPup > 0 &&
                                        <div style={{ padding: '.5rem 1rem' }}>
                                            <Select
                                                value={this.state.id_propertyPup}
                                                style={{ width: '100%' }}
                                                onChange={this.handleChangeClient('id_propertyPup', null, false)}
                                                inputProps={{
                                                    id: 'id_propertyPup',
                                                }}
                                            >
                                                <MenuItem key={0} value={0}>{'Selecione'}</MenuItem>
                                                {this.state.fazenda_clientPup.map((item, key) => {
                                                    return <MenuItem key={key + "_propertyPup"} value={item.id}>{item.name}</MenuItem>;
                                                })}
                                            </Select>
                                        </div>
                                    }
                                    <div style={{ textAlign: 'right', padding: '.5rem 1rem' }}>
                                        <Button variant="contained" color="primary" className="popUpButton" onClick={this.moveArea} disabled={(this.state.tab === '0' && this.state.id_clientPup === 0) || (this.state.id_propertyPup === 0 && this.state.id_clientPup > 0)}>
                                            {'Mover'}
                                        </Button>
                                    </div>
                                </MenuList>}
                                {this.state.tab === '0' ?
                                    <List
                                        onKeyDown={this.keyDown}
                                        onKeyUp={this.keyUp}
                                        component="nav"
                                        aria-labelledby="areas"
                                        style={{ position: 'absolute', width: '100%' }}
                                    >
                                        {this.state.polygons
                                            .filter(item => {
                                                if (item.name) {
                                                    return item.name.toUpperCase().includes(this.state.filtro.toUpperCase());
                                                } else {
                                                    return this.state.filtro ? null : item;
                                                }
                                            })
                                            .map((item, index) => {
                                                return (<div key={index + "_divpa"} style={{ display: 'flex' }}>
                                                    <ListItem key={index + 'pa1'} button
                                                        className={(this.state.sel_poly.indexOf(item.guid) >= 0) ? 'SelItem' : ''}
                                                        onClick={event => this.handleListItemClick(item.guid, this.tabID[0])}
                                                        title={item.name}
                                                    >
                                                        <ListItemText id={index} primary={this.limitChar(item.name, 300)} />
                                                    </ListItem>
                                                    {(this.state.sel_poly.length === 0 || this.state.sel_poly.includes(item.guid)) &&
                                                        <ListItem key={index + 'pa2'} button
                                                            style={(this.state.sel_poly.indexOf(item.guid) >= 0) ? { width: '3rem', height: '3rem', backgroundColor: '#f0f0f0' } : { width: '3rem', height: '3rem' }}
                                                            onClick={event => { this.setState({ anchorEl: event.currentTarget, areaPup: item }); }}
                                                            title={item.name}
                                                        >
                                                            <h2>...</h2>
                                                        </ListItem>
                                                    }
                                                </div>)
                                            })}
                                    </List>
                                    : <div>
                                        <ListItem key={-1} button
                                            disabled={this.state.new_error}
                                            style={{ justifyContent: 'right', borderRight: '6px solid #666', backgroundColor: '#f0f0f0', padding: '.5rem 0' }}
                                            onClick={event => this.openModal()}
                                        >
                                            <span style={{ marginRight: '.5rem' }}>{'Nova Propriedade'} </span>
                                            <AddIcon />
                                        </ListItem>
                                        <List
                                            onKeyDown={this.keyDown}
                                            onKeyUp={this.keyUp}
                                            component="nav"
                                            aria-labelledby="areas"
                                            style={{ position: 'absolute', width: '100%' }}
                                        >
                                            {this.state.fazenda_client
                                                .filter(item => {
                                                    if (item.name) {
                                                        return item.name.toUpperCase().includes(this.state.filtro.toUpperCase());
                                                    } else {
                                                        return this.state.filtro ? null : item;
                                                    }
                                                })
                                                .map((item, index) => {
                                                    return (<div key={index}>
                                                        <div style={{ display: 'flex' }} id={'itemdiv' + index}>
                                                            <ListItem button
                                                                id={'item' + index}
                                                                onClick={event => this.handlePropertyListItemClick('itemdiv' + index, 'item' + index, item.guid)}
                                                                title={item.name}
                                                            >
                                                                <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                                            </ListItem>
                                                        </div>
                                                        <List
                                                            onKeyDown={this.keyDown}
                                                            onKeyUp={this.keyUp}
                                                            component="nav"
                                                            className='panel'
                                                            aria-labelledby="areas"
                                                            style={{
                                                                position: 'relative', width: 'calc(100% - 2rem)', marginLeft: '2rem',
                                                                //borderBottom: (this.state.sel_poly.indexOf(item.guid) >= 0) ? 'solid -2px #f0f0f0' : '0',
                                                                borderLeft: 'solid 2px #f0f0f0', padding: '0 0'
                                                            }}
                                                        >
                                                            {this.state.areas_client
                                                                .filter(itemA => {
                                                                    let teste = item.id === itemA.id_property;
                                                                    if (itemA.name) {
                                                                        return teste && item.name.toUpperCase().includes(this.state.filtro.toUpperCase());
                                                                    } else {
                                                                        return !teste || this.state.filtro ? null : itemA;
                                                                    }
                                                                })
                                                                .map((itemA, index) => {
                                                                    return (<div style={{ display: 'flex' }} key={'i' + index} >
                                                                        <ListItem button
                                                                            id={'item' + index}
                                                                            className={(this.state.sel_poly.indexOf(itemA.guid) >= 0) ? 'SelItem' : ''}
                                                                            title={itemA.name}
                                                                            onClick={event => this.handleListItemClick(itemA.guid, this.tabID[1])}
                                                                        >
                                                                            <ListItemText id={index} primary={this.limitChar(itemA.name, 202)} />
                                                                        </ListItem>
                                                                        {(this.state.sel_poly.length === 0 || this.state.sel_poly.includes(itemA.guid)) &&
                                                                            <ListItem button
                                                                                style={(this.state.sel_poly.indexOf(itemA.guid) >= 0) ? { width: '3rem', height: '3rem', backgroundColor: '#f0f0f0' } : { width: '3rem', height: '3rem' }}
                                                                                onClick={event => { this.setState({ anchorEl: event.currentTarget, areaPup: itemA }); }}
                                                                                title={itemA.name}
                                                                            >
                                                                                <h2>...</h2>

                                                                            </ListItem>
                                                                        }
                                                                    </div>)
                                                                })}
                                                        </List>
                                                    </div>)
                                                })}
                                        </List>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="divContent divRelative map-p">
                            <div className="containerTop">
                                <div className="leftTop">
                                    <MenuList
                                        id="app-header-menu"
                                        className="popup-list"
                                        anchorEl={this.state.anchorMN}
                                        open={Boolean(this.state.anchorMN)}
                                        onClose={() => this.setAnchor()()}
                                        style={{ marginRight: '2rem' }}
                                    >
                                        {this.state.typeTool === 'create' ?
                                            <div>
                                                <MenuItem className="popup-item" onClick={event => { this.setState({ cPoly: true }); this.setAnchor()(); this.criaPoly() }}>
                                                    <div style={{ paddingRight: '.7rem' }}>
                                                        <SvgIcon viewBox="80 9 16 42" className="iconMap" style={{ verticalAlign: 'middle' }} >
                                                            <path id="polygon" d="M 100 23.4 L 97 36.9 L 83 39.6 L 76 27 L 86 18 Z" />
                                                        </SvgIcon>
                                                        {res.CRIAR_POLY}
                                                    </div>
                                                </MenuItem>
                                                <MenuItem className="popup-item" onClick={event => { this.setState({ cPoly: false }); this.setAnchor()(); this.criaCirculo() }}>
                                                    <div style={{ paddingRight: '.7rem' }}>
                                                        <MaterialIcon icon="fiber_manual_record" className="iconMap" />
                                                        {res.CRIAR_CIRCULO}
                                                    </div>
                                                </MenuItem>
                                            </div> :
                                            <div>
                                                <MenuItem className="popup-item" onClick={event => { this.setState({ sPoly: true }); this.setAnchor()(); this.selectorRet() }}>
                                                    <div style={{ paddingRight: '.7rem' }}>
                                                        <SvgIcon viewBox="80 9 16 42" className="iconMap" style={{ verticalAlign: 'middle' }} >
                                                            <path id="polygon" d="M 99 19 L 99 39 L 78 39 L 78 19 z M 97 34 L 99 34 L 99 30 L 97 30 L 97 28 L 99 28 L 99 24 L 97 24 L 97 21 L 94 21 L 94 19 L 90 19 L 90 21 L 87 21 L 87 19 L 83 19 L 83 21 L 80 21 L 80 24 L 78 24 L 78 28 L 80 28 L 80 30 L 78 30 L 78 34 L 80 34 L 80 37 L 83 37 L 83 39 L 87 39 L 87 37 L 90 37 L 90 39 L 94 39 L 94 37 L 97 37 z" />
                                                        </SvgIcon>
                                                        {res.SELECAO_RET}
                                                    </div>
                                                </MenuItem>
                                                <MenuItem className="popup-item" onClick={event => { this.setState({ sPoly: false }); this.setAnchor()(); this.selector() }}>
                                                    <div style={{ paddingRight: '.7rem' }}>
                                                        <SvgIcon viewBox="80 9 16 42" className="iconMap" style={{ verticalAlign: 'middle' }} >
                                                            <path id="polygon" d="M 100.003 22.997 L 97 36.9 L 83 40 L 76 27 L 86 18 z M 96.109 31.635 L 98.032 32.276 L 99.037 27.914 L 97.244 27.441 L 98.012 24.052 L 93.677 22.653 L 94.387 21.017 L 89.851 19.328 L 89.103 20.931 L 86.352 19.81 L 84 22 L 82.872 20.815 L 79.821 23.569 L 80.914 24.761 L 78.146 27.234 L 80.103 31.055 L 78.578 31.723 L 80.773 35.814 L 81.876 35.026 L 83.672 38.046 L 85.935 37.489 L 86.25 39.282 L 91.621 38.046 L 91.033 36.229 L 95.53 35.204 z" />
                                                        </SvgIcon>
                                                        {res.SELECAO_LIVRE}
                                                    </div>
                                                </MenuItem>
                                            </div>
                                        }
                                    </MenuList>

                                    <Button className="buttonS buttonMenu buttonMenuSmall" onClick={event => this.showMenu()}>
                                        <MaterialIcon icon="menu" className="iconMapF" />
                                    </Button>
                                    {this.state.cPoly ?
                                        < Button className={this.state.create ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={this.state.disable || this.state.split} onClick={event => this.criaPoly()} title={res.CRIAR_POLY}>
                                            <SvgIcon viewBox="79 18 21 26" className="iconMapP" >
                                                <path id="polygon" d="M 100 23.4 L 97 36.9 L 83 39.6 L 76 27 L 86 18 Z" />
                                            </SvgIcon>
                                        </Button> :
                                        <Button className={this.state.create_circle ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={this.state.disable || this.state.split} onClick={event => this.criaCirculo()} title={res.CRIAR_CIRCULO}>
                                            <MaterialIcon icon="fiber_manual_record" className="iconMap" />
                                        </Button>
                                    }
                                    <Button className={"buttonS buttonMenu buttonMenuPS"} onClick={this.setAnchor('create')}>
                                        <MaterialIcon icon="arrow_drop_down" className="iconMap" />
                                    </Button>
                                    {this.state.sPoly ?
                                        < Button className={this.state.selector ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={this.state.disable || this.state.split} onClick={event => this.selectorRet()} title={'Selector'}>
                                            <SvgIcon viewBox="79 18 21 26" className="iconMapP" >
                                                <path id="polygon" d="M 99 19 L 99 39 L 78 39 L 78 19 z M 97 34 L 99 34 L 99 30 L 97 30 L 97 28 L 99 28 L 99 24 L 97 24 L 97 21 L 94 21 L 94 19 L 90 19 L 90 21 L 87 21 L 87 19 L 83 19 L 83 21 L 80 21 L 80 24 L 78 24 L 78 28 L 80 28 L 80 30 L 78 30 L 78 34 L 80 34 L 80 37 L 83 37 L 83 39 L 87 39 L 87 37 L 90 37 L 90 39 L 94 39 L 94 37 L 97 37 z" />
                                            </SvgIcon>
                                        </Button> :
                                        <Button className={this.state.selector ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={this.state.disable || this.state.split} onClick={event => this.selector()} title={'Selector'}>
                                            <SvgIcon viewBox="79 18 21 26" className="iconMapP" >
                                                <path id="polygon" d="M 100.003 22.997 L 97 36.9 L 83 40 L 76 27 L 86 18 z M 96.109 31.635 L 98.032 32.276 L 99.037 27.914 L 97.244 27.441 L 98.012 24.052 L 93.677 22.653 L 94.387 21.017 L 89.851 19.328 L 89.103 20.931 L 86.352 19.81 L 84 22 L 82.872 20.815 L 79.821 23.569 L 80.914 24.761 L 78.146 27.234 L 80.103 31.055 L 78.578 31.723 L 80.773 35.814 L 81.876 35.026 L 83.672 38.046 L 85.935 37.489 L 86.25 39.282 L 91.621 38.046 L 91.033 36.229 L 95.53 35.204 z" />
                                            </SvgIcon>
                                        </Button>
                                    }
                                    <Button className={"buttonS buttonMenu buttonMenuPS"} onClick={this.setAnchor('select')}>
                                        <MaterialIcon icon="arrow_drop_down" className="iconMap" />
                                    </Button>

                                    <Button className={this.state.edit ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={!(this.state.sel_poly.length > 0) || this.state.disable || this.state.split} onClick={event => this.editPoly()} title={res.EDITAR_POLY}>
                                        <MaterialIcon icon="edit" className="iconMap" />
                                    </Button>

                                    <Button className={this.state.delete ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={(this.state.polygons.length === 0 && this.state.areas_client.length === 0) || this.state.disable || this.state.split} onClick={event => this.deletePoly()} title={res.DELETAR_POLY}>
                                        <MaterialIcon icon="delete" className="iconMap" />
                                    </Button>
                                    <Button className={this.state.ruler_a ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={(this.state.create || this.state.edit || this.state.delete || this.state.split) || this.state.disable} onClick={event => this.regua()} title={res.REGUA}>
                                        <MaterialIcon icon="settings_ethernet" className="iconMap highlightSVG" />
                                    </Button>

                                    <Button className="buttonS buttonMenu" disabled={!(this.state.sel_poly.length > 0) || this.state.disable || this.state.split} onClick={event => this.showItem()} title={res.EDITAR_NOME}>
                                        <MaterialIcon icon="format_shapes" className="iconMap" />
                                    </Button>
                                    <Button className="buttonS buttonMenu" disabled={this.state.disable} onClick={event => this.openSplit()} title={res.CORTAR}>
                                        <MaterialIcon icon="label_off" className="iconMap" />
                                    </Button>
                                    {this.state.sel_poly.length > 1 &&
                                        <Button className="buttonS buttonMenu" disabled={!(this.state.sel_poly.length > 1) || this.state.disable || this.state.split} onClick={event => this.setState({ merge: true, type: this.lastType })} title={res.AGRUPAR}>
                                            <MaterialIcon icon="view_compact" className="iconMap" />
                                        </Button>
                                    }
                                    {this.state.sel_merge &&
                                        <Button className="buttonS buttonMenu" disabled={this.state.disable || this.state.split} onClick={event => this.desfazer()} title={res.DESFAZER}>
                                            <MaterialIcon icon="undo" className="iconMap" />
                                        </Button>
                                    }
                                    {(this.state.sel_poly.length === 1 && !this.state.sel_merge) &&
                                        <span>
                                            {!this.state.sel_x ?
                                                <Button className="buttonS buttonMenu" disabled={this.state.disable || this.state.split} onClick={event => this.changeType(this.tabID[Number(this.state.tab)])} title={res.SET_X}>
                                                    <MaterialIcon icon="close" className="iconMap" />
                                                </Button>
                                                :
                                                <Button className="buttonS buttonMenu" disabled={this.state.disable || this.state.split} onClick={event => this.changeType(this.tabID[Number(this.state.tab)])} title={res.SET_N}>
                                                    <MaterialIcon icon="check" className="iconMap" />
                                                </Button>
                                            }
                                        </span>
                                    }
                                </div>
                                <div className="centerTop"></div>
                                <div className="rightTop">
                                    {/*<label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                                        <input
                                            accept=".kml,.kmz,.shp,.zip,.zjob"
                                            style={{ display: 'none' }}
                                            id="contained-button-file"
                                            disabled={this.state.disable}
                                            multiple
                                            type="file" onChange={this.loadFile}
                                        />
                                    <label htmlFor="contained-button-file">*/}
                                    <Button className="buttonS buttonMenu" type="polygon" title={res.IMPORTAR_AREAS} disabled={this.state.disable} onClick={() => { this.setState({ showImport: true }); }}>
                                        <MaterialIcon icon="attach_file" className="iconMap" />
                                    </Button>
                                    {/*<Button component="span" className="buttonS buttonMenu" style={this.state.typeUser.includes(2) ? {} : { display: 'none' }} title={res.IMPORTAR_FAIXAS} disabled={this.state.disable} onClick={() => { this.upType = 'F'; }}>
                                                <SvgIcon viewBox="79 18 21 26" className="iconMapP" >
                                                    <path id="polygon" d="M 86 18 L 86 37 L 78 37 L 78 35 L 84 35 L 84 20 L 78 20 L 78 18 Z M 90 18 L 90 37 L 98 37 L 98 35 L 92 35 L 92 20 L 98 20 L 98 18 Z" />
                                                </SvgIcon>
                                            </Button>
                                        </label>
                                    </label>*/}
                                    <Button className="buttonS buttonMenu" onClick={event => this.download()} disabled={((this.state.sel_poly.length > 0) ? false : true) || this.state.disable} title={res.BAIXAR_SELECIONADOS}>
                                        <MaterialIcon icon="save" className="iconMap" />
                                    </Button>
                                    <Button className="buttonS buttonMenu" onClick={event => { this.setState({ anchorPL: event.currentTarget }); }} disabled={((this.state.sel_poly.length > 0) ? false : true) || this.state.disable} title={'Enviar Selecionados'}>
                                        <SvgIcon viewBox="0 96 960 960" style={{ width: '1.7rem', height: '1.7rem', marginRight: '-.1rem' }}>
                                            <path d="M253.308 875.999q-80.877 0-137.092-56.562-56.214-56.562-56.214-137.13 0-74.384 49.538-129.614 49.538-55.231 122.461-62.23 11.923-81.693 77.577-145.039 65.653-63.346 148.346-63.346 18.23 0 31.807 10.423 13.576 10.423 13.576 27.653v304.77l87.231-87.615 32.615 32.999-142.538 142.153-142.152-142.153 32.614-32.999 86.847 87.615V324q-82.923 6.385-135.193 70.692Q270.462 459 270.462 534.385h-19.385q-60.044 0-102.868 42.615t-42.824 105.692q0 63.077 43.846 105.5t104.183 42.423h494.509q44.231 0 75.461-31.293 31.231-31.293 31.231-75.269 0-43.976-31.231-75.207-31.23-31.231-75.597-31.231h-62.095v-83.23q0-63-29.923-110.384-29.923-47.385-77.691-75.616v-49.999q70.614 28.616 111.806 93.308 41.192 64.691 41.192 142.691v37.847h12.308q65.461-2.385 111.038 40.999 45.577 43.385 45.577 110.692 0 62.461-44.808 107.268-44.807 44.808-107.268 44.808H253.308ZM480 555.539Z" />
                                        </SvgIcon>
                                    </Button>
                                    <Button className="buttonS buttonMenu" onClick={event => this.abrirPlanejamento()} disabled={((this.state.sel_poly.length > 0) ? false : true) || this.state.disable} title={'Abrir Selecionados no Planejamento'}>
                                        <MaterialIcon icon="flight_takeoff" className="iconMap" />
                                    </Button>
                                    <Button className="buttonS buttonMenu" onClick={event => this.showGoto()} title={res.IR_PARA}>
                                        <MaterialIcon icon="search" className="iconMap" />
                                    </Button>
                                    <Button className="buttonS buttonMenu" disabled={this.state.sel_poly.length <= 0} onClick={event => { this.resetPolygons(); this.goto("sel_poly") }} title={res.SELECIONADO}>
                                        <SvgIcon viewBox="79 18 21 26" className="iconMapP" style={this.state.sel_poly.length <= 0 ? disabledButton : {}} >
                                            <path id="polygon" d="M 100 23.4 L 97 36.9 L 88 40 L 88 27 L 76 27 L 86 18 z M 76 29 L 86 29 L 86 40 L 83 40 Z" />
                                        </SvgIcon>
                                    </Button>
                                    <Button className="buttonS buttonMenu" onClick={event => { this.setState({ anchorAPO: event.currentTarget }); }} title={'Opções'}>
                                        <h2 style={{ marginLeft: '-.7rem', fontWeight: 'bold', transform: 'rotate(270deg)', marginTop: '.6rem', fontSize: '23px' }}>...</h2>
                                    </Button>
                                </div>
                            </div>
                            <MenuList
                                id="app-options-menu"
                                className="popup-list"
                                anchorEl={this.state.anchorAPO}
                                open={Boolean(this.state.anchorAPO)}
                                onClose={(Event) => { this.setState({ anchorAPO: null }) }}
                            >
                                <MenuItem key={'apo1'} onClick={() => this.resetPolygons()} className="popup-item" >
                                    {'Refresh MAP'}
                                </MenuItem>
                            </MenuList>
                            <MenuList
                                id="app-header-menu"
                                className="popup-list"
                                anchorEl={this.state.anchorPL}
                                open={Boolean(this.state.anchorPL)}
                                onClose={(Event) => { this.setState({ anchorPL: null }) }}
                            >
                                <DialogActions onKeyDown={(e) => e.stopPropagation()} style={{ width: '16rem' }}>
                                    <TextField
                                        id="nameR"
                                        label={'Nome da Região'}
                                        margin="none"
                                        className="textI"
                                        size="small"
                                        fullWidth
                                        onChange={this.handleChange('nameR')}
                                        value={this.state.nameR}
                                        error={this.state.nameR === ''}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toString().slice(0, 45)
                                        }}
                                    />
                                </DialogActions>
                                {this.state.planes
                                    .filter(item => item.id_dgps)
                                    .map((item, key) => (
                                        <MenuItem key={key + 'pl'} onClick={() => this.selectSendPlanes(item.id)} className="popup-item" style={{ minWidth: '10rem', display: 'grid', gridTemplateColumns: '3rem auto', justifyItems: 'center' }}>
                                            <Checkbox checked={this.state.selectedSendPlanes.indexOf(item.id) > -1}
                                            />
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                <DialogActions>
                                    <Button type="submit" disabled={this.state.selectedSendPlanes.length === 0 || this.state.nameR === ''} variant="contained" color={"primary"} onClick={e => { this.sendFile() }}>
                                        {'Enviar'}
                                    </Button>
                                </DialogActions>
                            </MenuList>
                            <div className="wrapperMap">
                                <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '1111', display: !this.state.disable ? 'none' : 'block' }}></div>
                                <div id="mapid"></div>
                                <div className="zoomB">
                                    <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                                        <MaterialIcon icon="add" className="iconMap" />
                                    </Button>
                                    <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                                        <MaterialIcon icon="remove" className="iconMap" />
                                    </Button>
                                </div>
                                <div className={(!this.state.merge && this.state.split) || this.state.edit || this.state.delete ? "divContent floatDiv floatDivItens" : "sr-only"}>
                                    <label className="textDiv">
                                        {res.CLICK_DESFAZER}
                                    </label>
                                    <Button size="small" className="popUpButton" onClick={event => this.state.split ? this.useSplitLine() : this.state.edit ? this.saveEdit() : this.saveDelete()}>
                                        {res.SALVAR}
                                    </Button>
                                    <Button size="small" className="popUpButton" onClick={event => this.state.split ? this.cancelSplit() : this.state.edit ? this.cancelEdit() : this.cancelDelete()}>
                                        {res.CANCELAR}
                                    </Button>
                                </div>
                                <div className={this.state.edit_name || this.state.create || this.state.create_circle ? "divContent floatDiv floatDivItens popUpF" : "sr-only"}>
                                    <div style={{ overflowY: 'auto' }}>
                                        {this.state.create || this.state.create_circle ?
                                            <TextField
                                                id="name"
                                                label={res.NOME}
                                                margin="none"
                                                className="textI"
                                                size="small"
                                                fullWidth
                                                onChange={this.handleChange('nameP')}
                                                value={this.state.nameP}
                                                error={this.state.valid_nameP}
                                                onKeyDown={e => {
                                                    if (e.keyCode === 13) { this.saveCreate() }
                                                }}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.toString().slice(0, 45)
                                                }}
                                            />
                                            :
                                            this.state.namesC
                                                .map((item, index) => {
                                                    return (
                                                        <TextField
                                                            key={index + '_names'}
                                                            id="namePoly"
                                                            label={res.NOME}
                                                            margin="none"
                                                            className="textI"
                                                            size="small"
                                                            fullWidth
                                                            onChange={this.handleChangeNameList(item.guid)}
                                                            value={item.name}
                                                            error={!item.valid}
                                                            onKeyDown={e => {
                                                                if (e.keyCode === 13) { this.saveItem() }
                                                            }}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.toString().slice(0, 45)
                                                            }}
                                                        />
                                                    )
                                                })

                                        }
                                    </div>
                                    <div className="divForm">
                                        {!this.state.create_circle &&
                                            <Button size="small" className="popUpButton" disabled={!this.state.valid_nameP || this.state.namesC.filter(item => (!item.valid)).length > 0} onClick={event => this.state.create ? this.saveCreate() : this.saveItem()}>
                                                {res.SALVAR}
                                            </Button>
                                        }
                                        {this.state.create &&
                                            <Button size="small" className="popUpButton" onClick={event => this.deleteLastCreate()}>
                                                {res.DESFAZER}
                                            </Button>
                                        }
                                        <Button size="small" className="popUpButton"
                                            onClick={event =>
                                                this.state.create ?
                                                    this.cancelCreate() :
                                                    this.state.create_circle ?
                                                        this.cancelCreateCirculo() :
                                                        this.cancelItem()}//fix
                                        >
                                            {res.CANCELAR}
                                        </Button>
                                    </div>
                                </div>
                                {this.state.merge &&
                                    <div className="divContent floatW divIA">
                                        <div>
                                            <FormControl className="notTooBig notTooClose">
                                                <InputLabel htmlFor="age-simple">{this.state.split ? '' : res.AGRUPAR}</InputLabel>
                                                <Select
                                                    value={this.state.type}
                                                    onChange={event => { this.setState({ type: event.target.value }, a => { if ((this.state.type + '') === '2' || (this.state.type + '') === '5') this.lastType = this.state.type; }); }}
                                                    inputProps={{
                                                        id: 'type',
                                                    }}
                                                >
                                                    {!this.state.split && <MenuItem key="2" value="2">{res.LIGAR_AREA}</MenuItem>}
                                                    {!this.state.split && <MenuItem key="5" value="5">{res.ANEXAR_AREA}</MenuItem>}
                                                    {this.state.split && <MenuItem key="6" value="6">Split Área</MenuItem>}

                                                </Select>
                                            </FormControl>
                                        </div>
                                        {(this.state.type === '3' || this.state.type === '5' || this.state.type === '6') &&
                                            <div className="">
                                                <TextField
                                                    id="name"
                                                    label={res.TAMANHO}
                                                    margin="normal"
                                                    className="textI"
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    onChange={this.handleChange('gap_size')}
                                                    value={this.state.gap_size}
                                                    onKeyDown={e => {
                                                        if (e.keyCode === 13) { this.junta(); }
                                                    }}
                                                    onInput={(e) => {
                                                        e.target.value = isNaN(e.target.value) ? this.state.gap_size.toString().slice(0, 3).trim() : e.target.value.toString().slice(0, 3).trim()
                                                    }}
                                                />
                                                <span style={{ position: 'relative', bottom: '-1.3rem' }}>  {res.METROS}</span>
                                            </div>}
                                        <div style={{ padding: '1rem' }}></div>
                                        {!this.state.split ?
                                            <Button size="small" className="popUpButton" disabled={!(this.state.sel_poly.length > 1)} onClick={event => { this.junta() }}>
                                                {res.SALVAR}
                                            </Button>
                                            :
                                            <Button size="small" className="popUpButton" onClick={event => { this.split() }}>
                                                {'SPLIT'}
                                            </Button>
                                        }
                                        <Button size="small" className="popUpButton" onClick={event => { this.state.split ? this.cancelSplit() : this.setState({ merge: false }) }}>
                                            {res.CANCELAR}
                                        </Button>
                                    </div>}
                                {this.state.goto &&
                                    <div className="divContent floatW divS" style={{ width: '15rem' }}>
                                        <div>
                                            <span>{res.IRPARA}</span>
                                            {/*                                             <Button size="small" className="popUpButton blButton" disabled={this.state.polygons.length <= 0} onClick={event => this.goto("poly")}>
                                                <SvgIcon viewBox="76 18 21 26" className="iconMapP" >
                                                    <path id="polygon" d="M 100 23.4 L 97 36.9 L 88 40 L 88 38 L 86 38 L 86 36 L 88 36 L 88 33 L 86 33 L 86 31 L 88 31 L 88 29 L 86 29 L 86 27 L 84 27 L 84 29 L 82 29 L 82 27 L 80 27 L 80 29 L 78 29 L 78 27 L 76 27 L 86 18 z M 76 29 L 86 29 L 86 40 L 83 40 Z" />
                                                </SvgIcon>
                                            </Button> */}
                                            <Button size="small" className="popUpButton blButton" disabled={this.state.sel_poly.length <= 0} onClick={event => this.goto("sel_poly")} title={res.SELECIONADO}>
                                                <SvgIcon viewBox="79 18 21 26" className="iconMapP" style={this.state.sel_poly.length <= 0 ? disabledButton : {}} >
                                                    <path id="polygon" d="M 100 23.4 L 97 36.9 L 88 40 L 88 27 L 76 27 L 86 18 z M 76 29 L 86 29 L 86 40 L 83 40 Z" />
                                                </SvgIcon>
                                            </Button>

                                            <Button size="small" className="popUpButton blButton" disabled={(this.state.polygons.length === 0 && this.state.areas_client.length === 0)} onClick={event => this.goto("poly")} title={res.POLIGONO}>
                                                <SvgIcon viewBox="79 18 21 26" className="iconMapP" style={(this.state.polygons.length === 0 && this.state.areas_client.length === 0) ? disabledButton : {}} >
                                                    <path id="polygon" d="M 100 23.4 L 97 36.9 L 83 39.6 L 76 27 L 86 18 Z" />
                                                </SvgIcon>
                                            </Button>
                                            <Button size="small" className="popUpButton blButton" disabled={this.state.home === Config.place} onClick={event => this.goto("home")} title={res.REFERENCIA}>
                                                <MaterialIcon icon="home" className="iconMap" />
                                            </Button>
                                        </div>

                                        <TextField
                                            id="lat"
                                            label="LAT"
                                            margin="normal"
                                            size='small'
                                            error={this.state.lat.error}
                                            onChange={this.handleChangeCoordinates('lat')}
                                            value={this.state.lat.input}
                                            placeholder={geo.toDMS(this.state.lat.value, 'latitude', null, res)}
                                            onKeyDown={e => {
                                                if (e.keyCode === 13) { this.goto() }
                                            }}
                                        />
                                        <TextField
                                            id="lng"
                                            label="LNG"
                                            margin="normal"
                                            size='small'
                                            error={this.state.lng.error}
                                            onChange={this.handleChangeCoordinates('lng')}
                                            value={this.state.lng.input}
                                            placeholder={geo.toDMS(this.state.lng.value, 'longitude', null, res)}
                                            onKeyDown={e => {
                                                if (e.keyCode === 13) { this.goto() }
                                            }}
                                        />
                                        <div className="divForm">
                                            <Button size="small" className="popUpButton" onClick={event => this.goto()}>
                                                {res.IR}
                                            </Button>
                                            <Button size="small" className="popUpButton" onClick={event => this.setState({ goto: false })}>
                                                {res.CANCELAR}
                                            </Button>
                                        </div>
                                    </div>}

                                {this.state.label &&
                                    <div className='tool-tip-H'
                                        style={{
                                            right: '1rem',
                                            position: 'absolute',
                                            bottom: '0.3rem',
                                            zIndex: 1099,
                                            textAlign: 'right',
                                            color: '#edb83b'
                                        }}>
                                        {this.state.label_text}
                                    </div>}
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        );
    };
}

const TextFields1 = EditDraw;

const Box = (props) => {
    const { link, res } = props;
    let { local } = useParams();
    return (
        <div className="rowForm maxH">
            <TextFields1 link={link} local={local} res={res} />
        </div>)
}

export default Box;

