import React, { useState, useEffect } from 'react';
import togeojson from '@mapbox/togeojson';
import shpjs from 'shpjs';
import jszip from 'jszip';
import SPW from "string-pixel-width";
import MaterialIcon from '../../components/MaterialIcon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { notification } from 'antd';
import Config from '../../constants/appConfig';
import * as turf from '@turf/turf';
//import { InsertLinkRounded } from '@material-ui/icons';

class AppList extends React.Component {
    state = {
        listItens: [],
        selectItens: [],
        selectedTypeName: '2',
        selectedTypeColumn: '0',
        hasName: true,
        listTypes: [],
        filtro: ''
    };
    shift = false;

    componentDidMount() {

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open) {
            if (this.props.selected) this.selecedItens(this.props.selected);
        }
    }
    //upload de arquivos KML e KMZ
    loadFile = file => {
        this.itens_import = [];
        this.listItens = [];
        this.name_i = -1;
        this.setState({ listItens: [] });
        for (let index = 0; index < file.target.files.length; index++) {
            const filePath = file.target.files[index];
            setTimeout(() => {
                let ext = fileName => fileName.split(".").pop();
                if (ext(filePath.name.toLowerCase()) === 'kml') {
                    const fileReader = new FileReader();
                    fileReader.onloadend = ((e) => {
                        const content = fileReader.result;
                        let parser = new DOMParser();
                        let xmlDoc = parser.parseFromString(content, "text/xml");
                        let track = new togeojson['kml'](xmlDoc);

                        this.writeFileMap(track);
                    });
                    fileReader.readAsText(filePath);
                } else if (ext(filePath.name.toLowerCase()) === 'kmz') {
                    let getDom = xml => (new DOMParser()).parseFromString(xml, "text/xml")
                    let getKmlDom = (kmzFile) => {
                        let zip = new jszip()
                        return zip.loadAsync(kmzFile)
                            .then(zip => {
                                let kmlDom = null
                                zip.forEach((relPath, file) => {
                                    if (ext(relPath) === 'kml') {
                                        kmlDom = file.async("string").then(getDom)
                                    }
                                })
                                return kmlDom || Promise.reject("No kml file found")
                            });
                    }
                    let geoJson = getKmlDom(filePath).then(kmlDom => {
                        let geoJsonObject = new togeojson['kml'](kmlDom);
                        return geoJsonObject
                    })
                    geoJson.then(gj => this.writeFileMap(gj))
                } else if (ext(filePath.name.toLowerCase()) === 'zip') {
                    const fileReader = new FileReader();
                    fileReader.onloadend = ((e) => {
                        shpjs(fileReader.result)
                            .then((geojson) => {
                                if (geojson.length > 0) {
                                    geojson.forEach(element => {
                                        this.writeFileMap(element);
                                    });
                                } else {
                                    this.writeFileMap(geojson);
                                }
                            })
                            .catch(e => {
                                notification.open({
                                    message: this.props.res.ERRO,
                                    description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
                                    icon: <MaterialIcon icon="error" className="text-danger" />
                                });
                            });
                    });
                    fileReader.readAsArrayBuffer(filePath);
                } else if (ext(filePath.name.toLowerCase()) === 'shp') {
                    const fileReader = new FileReader();
                    fileReader.onloadend = ((e) => {
                        const features = [];
                        let polyLength = 0;
                        shpjs.parseShp(fileReader.result).forEach(element => {
                            const geometry = { type: element.type, coordinates: element.coordinates };
                            features.push({ properties: { guid: undefined, name: undefined, type: element.type }, geometry: geometry });
                            if (element.type === "Polygon") {
                                polyLength++;
                            } else if (element.type === "MultiPolygon") {
                                polyLength += element.coordinates.length;
                            }
                        });
                        this.writeFileMap({ features: features, polyLength: polyLength });
                    });
                    fileReader.readAsArrayBuffer(filePath);
                } else if (ext(filePath.name.toLowerCase()) === 'zjob') {
                    this.uploadFile(filePath);
                } else {
                    notification.open({
                        message: this.props.res.ERRO,
                        description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                }
            }, 50);
        };

        document.getElementById('contained-button-file').value = null;
        //
    };

    uploadFile = (file) => {
        //this.setState({ progress: true });

        let apiUrl = Config.server + '/getPolygon';
        let method = 'POST';

        const formData = new FormData();//URLSearchParams

        formData.append('type', 'P');
        formData.append('file', file);

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }


        fetch(apiUrl, options)
            .then((fRes) => {
                if (fRes.status !== 200) throw fRes;
                else return fRes.json();
            })
            .then(
                (result) => {
                    if (result.polygons) {
                        console.log('result', result);
                        if (result.polygons.length > 0) {
                            let list = [];
                            for (let i = 0; i < result.polygons.length; i++) {
                                const element = result.polygons[i];
                                element.poly.properties.name = element.name;
                                list.push(element.poly);
                            }
                            this.writeFileMap(turf.featureCollection(list));
                        } else {
                            notification.open({
                                message: this.props.res.ERRO,
                                description: this.props.res.ERRO_MSG,
                                icon: <MaterialIcon icon="error" className="text-danger" />
                            });
                        }
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        //link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    //monta lista de polygons do arquivo 
    itens_import = [];
    listItens = [];
    name_i = -1;
    writeFileMap = (track) => {
        let hasName = true;
        let listTypes = [];//this.state.listTypes;
        let selectedTypeColumn = '';
        for (let i = 0; i < track.features.length; i++) {
            let feature = track.features[i];
            if (feature.geometry.type === 'Polygon' ||
                feature.geometry.type === 'GeometryCollection' ||
                feature.geometry.type === 'LineString' ||
                feature.geometry.type === 'MultiPolygon') {
                const check = (feature) => {
                    if (feature.type === 'Polygon') {
                        /*let area = turf.area(feature);
                        if (area < 5000) {
                            return false;
                        }*/
                    } else if (feature.type === 'MultiPolygon') {
                        /*let cooods = [];
                        for (let j = 0; j < feature.coordinates.length; j++) {
                            const p = turf.polygon([feature.coordinates[j]]);
                            let area = turf.area(p);
                            if (area >= 5000) {
                                cooods.push(feature.coordinates[j]);
                            }
                        }
                        if (cooods.length === 0) {
                            return false;
                        }
                        feature.coordinates = cooods;*/
                    } else if (feature.type === 'LineString') {
                        const arr1 = feature.coordinates[0];
                        const arr2 = feature.coordinates[feature.coordinates.length - 1];
                        if (arr1[0] !== arr2[0] && arr1[1] !== arr2[1]) {
                            feature.coordinates.push(feature.coordinates[0]);
                        }
                    }
                    return true;
                };
                if (feature.geometry.type === 'GeometryCollection') {
                    let geos = [];
                    let geometries = feature.geometry.geometries;                    
                    for (let j = 0; j < geometries.length; j++) {
                        let element = geometries[j];
                        if (check(element)) {
                            geos.push(element);
                        }
                    }
                    if (geos.length === 0) {
                        this.listItens.push(false);
                        continue;
                    }
                    feature.geometry.geometries = geos;
                } else if (feature.geometry.type === 'MultiPolygon') {
                    feature.geometry.type = 'GeometryCollection';
                    let geos = [];
                    let geometries = feature.geometry.coordinates;   
                     
                    for (let j = 0; j < geometries.length; j++) {
                        let element = {
                            "type": "Polygon",
                            "coordinates": geometries[j]
                        };
                        if (check(element)) {
                            geos.push(element);
                        }
                    }
                    if (geos.length === 0) {
                        this.listItens.push(false);
                        continue;
                    }
                    feature.geometry.geometries = geos;
                } else {
                    if (!check(feature.geometry)) {
                        this.listItens.push(false);
                        continue;
                    }
                }

                feature.properties.index = ++this.name_i;
                feature.properties.gname = 'new poly ' + (this.name_i + 1)
                if (!feature.properties.name) {
                    hasName = false;
                }
                this.listItens.push(feature.properties);
                if (listTypes.length === 0) {
                    for (var key in feature.properties) {
                        if (key !== 'gname' && key !== 'index') {
                            listTypes.push(key);
                            if (!selectedTypeColumn) {
                                selectedTypeColumn = key;
                            }
                        }
                    }
                }
            }
        }
        this.itens_import = this.itens_import.concat(track.features);
        this.setState({ listItens: this.listItens, hasName: hasName, listTypes: listTypes, selectedTypeColumn: selectedTypeColumn });
    };

    limitChar = (string, limit) => {
        let ret = string;
        let size = SPW(string, { font: "Arial", size: 16 });
        if (size > limit) {
            ret = string.slice(0, (string.length * (limit - 16) / size)) + '...';
        }
        return ret;
    };

    keyDown = (e) => {
        if (e.keyCode === 16) {
            this.shift = true;
        }
    };

    keyUp = (e) => {
        this.shift = false;
    };

    clickList = (item) => {
        let list = this.state.selectItens;
        if (this.shift) {
            let lastGuid = this.state.selectItens[this.state.selectItens.length - 1];

            let indexLast = lastGuid;
            let indexNow = item;

            if (indexNow > indexLast) {
                let c = indexNow;
                indexNow = indexLast;
                indexLast = c;
            }
            for (let i = indexNow; i <= indexLast; i++) {
                if (!this.state.selectItens.includes(i)) {
                    list.push(i);
                }
            }
        } else {
            let index = list.indexOf(item);
            if (index > -1) {
                list.splice(index, 1);
            } else {
                list.push(item);
            }
        }
        this.shift = false;
        this.setState({ selectItens: list })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    close = () => {
        this.setState({
            listItens: [],
            selectItens: [],
            selectedTypeName: '2',
            selectedTypeColumn: '0',
            hasName: true,
            listTypes: [],
            filtro: ''
        });
        this.props.onClose();
    };

    go = () => {
        if (this.state.listItens.length > 0) {
            let list = [];
            for (let i = 0; i < this.state.listItens.length; i++) {
                if (this.state.listItens[i]) {
                    const element = this.itens_import[this.state.listItens[i].index];
                    if (!element.properties.name) {
                        element.properties.name = this.state.selectedTypeName === '1' ? this.state.listItens[i][this.state.selectedTypeColumn] : this.state.listItens[i].gname;
                    }
                    if (element.properties.type !== 'N' || element.properties.type !== 'X') {
                        element.properties.type = 'N';
                    }
                    list.push(element);
                }
            }
            this.props.listItens(list);
            this.close();
        } else {
            this.close();
        }
    };

    render() {
        const { res, open, index } = this.props;

        return (
            <div className="back-quest" style={{ zIndex: index, display: open ? 'flex' : 'none' }} >
                <div className="divContentList" style={{ zIndex: index + 1, padding: '1rem' }}>
                    <div style={{ display: 'flex', width: '18rem', marginBottom: '1rem' }}>
                        <h2>{res.IMPORTAR_AREAS}</h2>
                        <div style={{ width: '50%', textAlign: 'right' }}>
                            <Button type="submit" variant="contained" color={this.state.listItens.length > 0 ? "primary" : "default"} className="popUpButton" onClick={this.go}>
                                {this.state.listItens.length > 0 ? res.IMPORTAR : res.CANCELAR}
                            </Button>
                        </div>
                    </div>
                    <label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                        <input
                            accept=".kml,.kmz,.shp,.zip,.zjob"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            multiple
                            type="file" onChange={this.loadFile}
                        />
                        <label htmlFor="contained-button-file">
                            <Button size="small" component="span" variant="contained" color="primary" className="popUpButton" title={res.IMPORTAR_KMZ}>
                                <MaterialIcon icon="attach_file" className="iconMap" />
                            </Button>
                            {this.state.listItens.length > 0 &&
                                <span style={{ fontSize: '16px', padding: '.5rem' }}>
                                    {this.state.listItens.length} polígonos encontrados
                                </span>
                            }
                        </label>
                    </label>
                    {(!this.state.hasName && this.state.listTypes.length > 0) &&
                        <div style={{ padding: '.5rem', borderBottom: 'solid 1px #c9c9c9', margin: '.5rem' }}>
                            <FormControl
                                style={{ padding: ".4rem 0 .4rem 0" }}
                                fullWidth
                                component="fieldset">
                                <div className='titleTextRel'>{'Nome do polígono'}</div>
                                <RadioGroup id="selectedTypeName" aria-label="pessoa" name="pessoa1" value={this.state.selectedTypeName} onChange={this.handleChange('selectedTypeName')}>
                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Gerar Nome" />
                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Selecionar atributo" />
                                </RadioGroup>
                            </FormControl>
                            {this.state.selectedTypeName === "1" &&
                                <Select
                                    value={this.state.selectedTypeColumn}
                                    fullWidth
                                    onChange={this.handleChange('selectedTypeColumn')}
                                    inputProps={{
                                        id: 'id_clientPup',
                                    }}
                                >
                                    {this.state.listTypes.map((item, key) => {
                                        return <MenuItem key={key} value={item}>{item}</MenuItem>;
                                    })}
                                </Select>
                            }
                        </div>
                    }
                    <List
                        style={{ maxHeight: '60vh', overflow: 'auto' }}
                        onKeyDown={this.keyDown}
                        onKeyUp={this.keyUp}
                        component="nav"
                        aria-labelledby="areas"
                        fullWidth
                    >
                        {this.state.selectItens && this.state.listItens
                            //.filter((item) => {
                            //  return (item.name.toUpperCase().includes(filtro.toUpperCase()));
                            //})
                            .map((item, index) => {
                                let name = item.name ? item.name :
                                    this.state.selectedTypeName === '1' ? item[this.state.selectedTypeColumn] : item.gname;
                                return (
                                    <ListItem key={index} button
                                        style={(this.state.selectItens.indexOf(item.index) >= 0) ? { backgroungColor: '#f0f0f0 !important' } : {}}
                                        onClick={event => this.clickList(item.index)}
                                        title={name}
                                    >
                                        <ListItemText id={index + 'i'} primary={name} />
                                    </ListItem>
                                )
                            })}
                    </List>
                </div>
                <div className="div-select" onClick={this.close}></div>
            </div>
        )
    }
}

export default AppList;
