import * as turf from '@turf/turf';

class Geo {
    constructor() {
        this.r = null;
        this.i = 0;
    }

    teste = () => {
        return 'Hello!';
    }

    geojsonToLeafletArray = (poly) => {
        let array = [];
        for (let i = 0; i < poly.length; i++) {
            const element = poly[i];
            array = array.concat([[element[1], element[0]]]);
        }
        return array
    }

    lineForCut = (line, size, type) => {

        let yaw = null;
        let c = null
        if (type === 0) {
            yaw = turf.bearing(line.geometry.coordinates[0], line.geometry.coordinates[1]);
            c = line.geometry.coordinates[0];
        } else {
            yaw = turf.bearing(line.geometry.coordinates[line.geometry.coordinates.length - 1], line.geometry.coordinates[line.geometry.coordinates.length - 2]);
            c = line.geometry.coordinates[line.geometry.coordinates.length - 1];
        }
        let rLine = turf.lineString([c, [c[0] + size, c[1]]]);
        let options = { pivot: c };
        let fLine = turf.transformRotate(rLine, (yaw), options);
        let fLine2 = turf.transformRotate(rLine, (180 + yaw), options);
        let rLine2 = turf.lineString([fLine.geometry.coordinates[1], fLine2.geometry.coordinates[1]]);

        return rLine2;
    }

    polygonCut = (polygon, line, idPrefix) => {
        const THICK_LINE_UNITS = 'kilometers';
        const THICK_LINE_WIDTH = 0.001;
        let j, intersectPoints, lineCoords, forCut, forSelect;
        let thickLineString, thickLinePolygon, clipped, polyg, intersect;
        let polyCoords = [];
        let cutPolyGeoms = [];
        let cutFeatures = [];
        let offsetLine = [];
        let retVal = null;

        if (((polygon.type !== 'Polygon') && (polygon.type !== 'MultiPolygon')) || (line.type !== 'LineString')) {
            return retVal;
        }

        if (typeof (idPrefix) === 'undefined') {
            idPrefix = '';
        }

        intersectPoints = turf.lineIntersect(polygon, line);
        if (intersectPoints.features.length === 0) {
            return retVal;
        }

        lineCoords = turf.getCoords(line);
        if ((turf.booleanWithin(turf.point(lineCoords[0]), polygon) ||
            (turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)))) {
            return retVal;
        }

        offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });
        offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });

        for (let i = 0; i <= 1; i++) {
            forCut = i;
            forSelect = (i + 1) % 2;
            polyCoords = [];
            for (j = 0; j < line.coordinates.length; j++) {
                polyCoords.push(line.coordinates[j]);
            }
            for (j = (offsetLine[forCut].geometry.coordinates.length - 1); j >= 0; j--) {
                polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
            }
            polyCoords.push(line.coordinates[0]);

            thickLineString = turf.lineString(polyCoords);
            thickLinePolygon = turf.lineToPolygon(thickLineString);
            clipped = turf.difference(polygon, thickLinePolygon);

            cutPolyGeoms = [];
            for (j = 0; j < clipped.geometry.coordinates.length; j++) {

                //arruma um erro estranho                    
                let cItem = null;
                if (clipped.geometry.coordinates[j].length > 1) {
                    cItem = [clipped.geometry.coordinates[j]];
                } else {
                    cItem = clipped.geometry.coordinates[j];
                }

                polyg = turf.polygon(cItem);
                intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
                if (intersect.features.length > 0) {
                    cutPolyGeoms.push(polyg.geometry.coordinates);
                };
            };

            cutPolyGeoms.forEach((geometry, index) => {
                let id = idPrefix + (i + 1) + '.' + (index + 1);
                cutFeatures.push(turf.polygon(geometry, { id: id }));
            });
        }

        if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);

        return retVal;
    };

    larguraZoom(map, largura) {
        let metresPerPixel = 40075016.686 * Math.abs(Math.cos(map.getCenter().lat * Math.PI / 180)) / Math.pow(2, map.getZoom() + 8);
        return ((2 / metresPerPixel) * largura);
    };

    toKml(name, polygons) {
        let ret = "";
        let name_ex = "";
        let type_ex = "";
        let guid_ex = "";
        ret += '<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom"><Document><name>' + name + '</name>';

        polygons.forEach(element => {
            if (element.type === 'FeatureCollection') {
                name_ex = element.properties.name;
                type_ex = element.properties.type;
                guid_ex = element.properties.guid;
                element.features.forEach((item, key) => {
                    if (item.geometry.type === 'Polygon') {
                        ret += '<Placemark><name>' + name_ex + '_' + (key + 1) + '</name>';
                        ret += '<ExtendedData><Data name="type"><value>' + type_ex + '</value></Data>';
                        ret += '<Data name="guid"><value>' + guid_ex + '</value></Data></ExtendedData>';
                        ret += '<Polygon>' + ((type_ex === "X") ? "<innerBoundaryIs>" : "<outerBoundaryIs>") + '<LinearRing><coordinates>';
                        ret += JSON.stringify(item.geometry.coordinates).replaceAll('],[', ' ').replaceAll('[', '').replaceAll(']', '');//fix:
                        ret += '</coordinates></LinearRing>' + ((type_ex === "X") ? "</innerBoundaryIs>" : "</outerBoundaryIs>") + '</Polygon></Placemark>';
                    }
                });
            } else if (element.geometry && element.geometry.type === 'Polygon') {
                ret += '<Placemark><name>' + element.properties.name + '</name>';
                ret += '<ExtendedData><Data name="type"><value>' + element.properties.type + '</value></Data>';
                ret += '<Data name="guid"><value>' + element.properties.guid + '</value></Data></ExtendedData>';
                ret += '<Polygon>' + ((element.properties.type === "X") ? "<innerBoundaryIs>" : "<outerBoundaryIs>") + '<LinearRing><coordinates>';
                ret += JSON.stringify(element.geometry.coordinates).replaceAll('],[', ' ').replaceAll('[', '').replaceAll(']', '');//fix:
                ret += '</coordinates></LinearRing>' + ((element.properties.type === "X") ? "</innerBoundaryIs>" : "</outerBoundaryIs>") + '</Polygon></Placemark>';
            }
        });

        ret += '</Document></kml>';
        return ret;
    };

    simplify = function (/*LatLng[]*/ coordinates, /*Number*/ tolerance) {
        if (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
            coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
            coordinates.push(coordinates[0]);
        }
        let points = coordinates;
        if (!tolerance || !points.length) {
            return points.slice();
        }
        var sqTolerance = tolerance * tolerance;

        // stage 1: vertex reduction
        points = this.reducePoints(points, sqTolerance);

        // stage 2: Douglas-Peucker simplification
        points = this.simplifyDP(points, sqTolerance);

        return points.length > 3 ? turf.polygon([points]) : turf.polygon([coordinates]);
    }
    turfLine = function (/*leaflet LatLngs*/ coordinates,) {
        if (!coordinates) {
            return false;
        }
        let ret = [];
        for (let i = 0; i < coordinates.length; i++) {
            ret.push([coordinates[i].lng, coordinates[i].lat]);
        }
        return turf.lineString(ret);
    }

    // Douglas-Peucker simplification, see http://en.wikipedia.org/wiki/Douglas-Peucker_algorithm
    simplifyDP(points, sqTolerance) {

        var len = points.length,
            ArrayConstructor = typeof Uint8Array !== undefined + '' ? Uint8Array : Array,
            markers = new ArrayConstructor(len);

        markers[0] = markers[len - 1] = 1;

        this.simplifyDPStep(points, markers, sqTolerance, 0, len - 1);

        var i,
            newPoints = [];

        for (i = 0; i < len; i++) {
            if (markers[i]) {
                newPoints.push(points[i]);
            }
        }

        return newPoints;
    }
    simplifyDPStep(points, markers, sqTolerance, first, last) {

        var maxSqDist = 0,
            index, i, sqDist;

        for (i = first + 1; i <= last - 1; i++) {
            sqDist = this.sqClosestPointOnSegment(points[i], points[first], points[last], true);

            if (sqDist > maxSqDist) {
                index = i;
                maxSqDist = sqDist;
            }
        }

        if (maxSqDist > sqTolerance) {
            markers[index] = 1;

            this.simplifyDPStep(points, markers, sqTolerance, first, index);
            this.simplifyDPStep(points, markers, sqTolerance, index, last);
        }
    }

    // reduce points that are too close to each other to a single point
    reducePoints(points, sqTolerance) {
        var reducedPoints = [points[0]];

        for (var i = 1, prev = 0, len = points.length; i < len; i++) {
            if (this.sqDist(points[i], points[prev]) > sqTolerance) {
                reducedPoints.push(points[i]);
                prev = i;
            }
        }
        if (prev < len - 1) {
            reducedPoints.push(points[len - 1]);
        }
        return reducedPoints;
    }

    // return closest point on segment or distance to that point
    sqClosestPointOnSegment(p, p1, p2, sqDist) {
        var lng = p1[0],
            lat = p1[1],
            dlng = p2[0] - lng,
            dlat = p2[1] - lat,
            dot = dlng * dlng + dlat * dlat,
            t;

        if (dot > 0) {
            t = ((p[0] - lng) * dlng + (p[1] - lat) * dlat) / dot;

            if (t > 1) {
                lng = p2[0];
                lat = p2[1];
            } else if (t > 0) {
                lng += dlng * t;
                lat += dlat * t;
            }
        }

        dlng = p[0] - lng;
        dlat = p[1] - lat;

        return sqDist ? dlng * dlng + dlat * dlat : [lng, lat];
    }

    // square distance (to avoid unnecessary Math.sqrt calls)
    sqDist = function (p1, p2) {
        var dlng = p2[0] - p1[0],
            dlat = p2[1] - p1[1];
        return dlng * dlng + dlat * dlat;
    }

    kmldom2json = (kml) => {
        if (kml.childNodes[0].innerHTML.includes('Travicar')) {
            let ret = {
                name: '',
                description: '',
                folder: []
            };
            kml.childNodes.forEach(top => {
                top.childNodes.forEach(doc => {
                    if (doc.nodeName === 'Document') {
                        doc.childNodes.forEach(document => {
                            if (document.nodeName === 'name') {
                                ret.name = document.textContent;
                            } else if (document.nodeName === 'description') {
                                ret.description = document.textContent;
                            } else if (document.nodeName === 'Folder') {
                                let parte = {
                                    title: document.textContent,
                                    name: '',
                                    description: '',
                                    placemark: []
                                }
                                document.childNodes.forEach(folder => {
                                    if (folder.nodeName === 'name') {
                                        parte.name = folder.textContent;
                                    } else if (folder.nodeName === 'description') {
                                        parte.description = folder.textContent;
                                    } else if (folder.nodeName === 'Placemark') {
                                        let place = {
                                            name: '',
                                            desc: folder.textContent,
                                            polygon: [],
                                            linestring: []
                                        }
                                        folder.childNodes.forEach(placemark => {
                                            if (placemark.nodeName === 'name') {
                                                place.name = placemark.textContent;
                                            } else if (placemark.nodeName === 'Polygon') {
                                                let poly = {
                                                    tessellate: '',
                                                    extrude: '',
                                                    altitudemode: '',
                                                    'gx:draworder': '',
                                                    outerboundaryis: null
                                                }
                                                placemark.childNodes.forEach(polygon => {
                                                    if (polygon.nodeName === 'tessellate') {
                                                        poly.tessellate = polygon.textContent;
                                                    } else if (polygon.nodeName === 'extrude') {
                                                        poly.extrude = polygon.textContent;
                                                    } else if (polygon.nodeName === 'altitudeMode') {
                                                        poly.altitudemode = polygon.textContent;
                                                    } else if (polygon.nodeName === 'gx:drawOrder') {
                                                        poly['gx:draworder'] = polygon.textContent;
                                                    } else if (polygon.nodeName === 'outerBoundaryIs') {
                                                        polygon.childNodes.forEach(boundary => {
                                                            if (boundary.nodeName === 'LinearRing') {
                                                                let bound = {
                                                                    altitudemode: '',
                                                                    tessellate: '',
                                                                    extrude: '',
                                                                    'gx:altitudeoffset': '',
                                                                    coordinates: []
                                                                }
                                                                boundary.childNodes.forEach(ring => {
                                                                    if (ring.nodeName === 'altitudeMode') {
                                                                        bound.tessellate = ring.textContent;
                                                                    } else if (ring.nodeName === 'tessellate') {
                                                                        bound.extrude = ring.textContent;
                                                                    } else if (ring.nodeName === 'extrude') {
                                                                        bound.altitudemode = ring.textContent;
                                                                    } else if (ring.nodeName === 'gx:altitudeOffset') {
                                                                        bound['gx:altitudeoffset'] = ring.textContent;
                                                                    } else if (ring.nodeName === 'coordinates') {
                                                                        let coord = ring.textContent.split(' ');
                                                                        for (let i = 0; i < coord.length; i++) {
                                                                            let c = coord[i].split(',');
                                                                            if (c.length > 1) {
                                                                                bound.coordinates.push([c[1], c[0]]);
                                                                            }
                                                                        }
                                                                    }
                                                                });
                                                                poly.outerboundaryis = bound;
                                                            }
                                                        });
                                                    }
                                                });
                                                place.polygon.push(poly);
                                            } else if (placemark.nodeName === 'MultiGeometry') {
                                                let line = {
                                                    lines: []
                                                }
                                                placemark.childNodes.forEach(mult => {
                                                    if (mult.nodeName === 'LineString') {
                                                        let lineS = {
                                                            tessellate: '',
                                                            extrude: '',
                                                            altitudemode: '',
                                                            'gx:altitudeoffset': '',
                                                            coordinates: []
                                                        }
                                                        mult.childNodes.forEach(line => {
                                                            if (line.nodeName === 'altitudeMode') {
                                                                lineS.tessellate = line.textContent;
                                                            } else if (line.nodeName === 'tessellate') {
                                                                lineS.extrude = line.textContent;
                                                            } else if (line.nodeName === 'extrude') {
                                                                lineS.altitudemode = line.textContent;
                                                            } else if (line.nodeName === 'gx:altitudeOffset') {
                                                                lineS['gx:altitudeoffset'] = line.textContent;
                                                            } else if (line.nodeName === 'coordinates') {
                                                                let coord = line.textContent.split(' ');
                                                                for (let i = 0; i < coord.length; i++) {
                                                                    let c = coord[i].split(',');
                                                                    if (c.length > 1) {
                                                                        lineS.coordinates.push([c[1], c[0], c[2]]);
                                                                    }
                                                                }
                                                            }
                                                        });
                                                        line.lines.push(lineS);
                                                    }
                                                });
                                                place.linestring.push(line);
                                            }
                                        });
                                        parte.placemark.push(place);
                                    }
                                });
                                ret.folder.push(parte);
                            }
                        });
                    }
                });
            });
            return ret
        } else return null;
    }

    cicleToPolygon = (point, radius) => {
        let p = turf.point(point);
        /*let size = radius * .05;
        let r = Math.sin(size / radius) * 100;
        let r1 = 180 / Math.round(180 / r);*/
        let r1 = 360 / 64;
        let poly = [];
        for (let i = 0; i <= 360; i += r1) {
            let temp_p = turf.destination(p, radius / 1000, i);
            poly.push([temp_p.geometry.coordinates[1], temp_p.geometry.coordinates[0]]);
        }
        return poly;
    }

    toCoordinate = function (value) {
        // Return immediately if number is already of float type
        if (typeof (value) === "number") return value;

        let ConvertDMSToDD = (degrees, minutes, seconds, direction) => {
            var dd = degrees + minutes / 60 + seconds / (60 * 60);
                
            if (direction == "S" || direction == "W") {
                dd = dd * -1;
            } // Don't do anything for N or E
            return dd;
        }
        
        let parts = value.split(/[^\d\w\.]+/);
        return ConvertDMSToDD(Number(parts[0]), Number(parts[1]), Number(parts[2]), parts[3]);
    };

    toDMS = function (value, data, maxdigits, res) {
        const LW = res ? [`"${res.E}`, `"${res.W}`] : ['"L', '"W'];
        const suffix = [['"N', '"S'], LW];
        const zeroPad = function (i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        };
        const truncate = function (value, digits) {
            var t = value.toString(),
                p = t.indexOf(".");

            return (p === -1) ? value : (t.substring(0, p) +
                (parseFloat("0" + t.substr(p)).toFixed(digits)).substring(1));
        };
        const countDecimals = function (value, significant) {
            // regex /.*\.(\d*)/
            var text = value.toString(),
                pos = text.indexOf(".") + 1;
            if (pos === 0) return (0);
            if (typeof (significant) !== "number") return (text.substring(pos).length);

            var last = 0,
                nines = 0,
                num = 0;
            for (var k = 0; k < significant; k++) {
                num = text.substr(pos + k, 1) | 0;
                if (num !== 0) last = k + 1;
                if (num === 9) { nines++; continue; }
                nines = 0;
            }
            return (nines > 1) ? last - nines : last;
        };
        let n = this.toCoordinate(value);
        if (isNaN(n)) return "";

        // Decimal
        if (this.coordinate === 2) return (n.toFixed(6));

        // DMS
        let p = Math.abs(n),
            d = Math.floor(p),
            m = Math.floor((p * 60) % 60),
            s = (p * 3600) % 60,
            g = (n < 0) | 0,
            a = (data === "longitude") | 0,
            //raw = d + "\xBA" + zeroPad(Math.floor(m)) + "\'" + zeroPad(s.toString()),
            raw = d + "°" + zeroPad(Math.floor(m)) + "'" + zeroPad(s.toString()),
            dec = countDecimals(raw, maxdigits || 4);

        return (truncate(raw, dec) + suffix[a][g]);
    };

    toFixDMS = function (value) {
        value = value.trim();
        value = value.replaceAll('O', 'W');
        value = value.replaceAll('L', 'E');
        let dms = value.split('"');
        return `${dms[0]} ${dms[1]}`;
    };
}

var geo = new Geo();

export default geo;