import React from 'react';
import useResizeObserver from "use-resize-observer";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { notification } from 'antd';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import L from 'leaflet';
import 'leaflet-draw';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import tools from '../../components/util/tools';
import geo from '../../components/util/geo';

import Backd from '../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import saveAs from 'file-saver';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  layouts = {
    line2: { gridTemplateColumns: 'auto auto' },
    line1: { gridTemplateColumns: 'auto auto auto auto' },
    line13: { gridTemplateColumns: 'auto auto auto' },
  }
  state = {
    areas: [0, 0, 0, 0],

    stack: false,
    stack_value: [1, 1, 1],
    progress: false,

    ruler_a: false,
    disable: false,
    layout: this.layouts.line1,

    poly_plan: [],
    poly_exe: [],
    poly_qua: [],
    poly_ret: [],
  };

  myStylePoly = {
    color: '#47839e',
    weight: 4,
    opacity: 0.5,
    fillOpacity: 0
  };

  ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };

  map1 = { mymap: null, type: 'planejamento', featureGroup: null, state: 'poly_plan' };
  map2 = { mymap: null, type: 'execucao', featureGroup: null, state: 'poly_exe' };
  map3 = { mymap: null, type: 'area', featureGroup: null, state: 'poly_qua' };
  map4 = { mymap: null, type: 'retoque', featureGroup: null, state: 'poly_ret' };

  map = [this.map1, this.map2, this.map3, this.map4];
  areas = [0, 0, 0, 0];

  componentDidMount() {
    this.boot();
    //this.loadLimit();
    this.getAreas(0, true);
    this.getAreas(1, true);
    this.getAreas(2, true);
    this.getAreas(3, true);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  boot = () => {
    this.map1.mymap = L.map('mapid1', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);
    this.map2.mymap = L.map('mapid2', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);
    this.map3.mymap = L.map('mapid3', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);
    this.map4.mymap = L.map('mapid4', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);

    let setPos = {
      maxNativeZoom: 17,
      maxZoom: 19,
      tileSize: 512,
      zoomOffset: -1
    };
    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.map1.mymap);
    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.map2.mymap);
    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.map3.mymap);
    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.map4.mymap);

    this.map1.featureGroup = L.featureGroup();
    this.map1.featureGroup.addTo(this.map1.mymap);
    this.map2.featureGroup = L.featureGroup();
    this.map2.featureGroup.addTo(this.map2.mymap);
    this.map3.featureGroup = L.featureGroup();
    this.map3.featureGroup.addTo(this.map3.mymap);
    this.map4.featureGroup = L.featureGroup();
    this.map4.featureGroup.addTo(this.map4.mymap);

    new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: false
    }).addTo(this.map1.mymap);

    this.map1.mymap.on('draw:drawvertex', e => {
      if (this.drawCreateToolbar.type === 'polyline') {
        let layerIds = Object.keys(e.layers._layers);
        if (layerIds.length > 1) {
          let v2 = e.layers._layers[layerIds[1]];
          this.ruler_p.second.lat = v2._latlng.lat;
          this.ruler_p.second.lng = v2._latlng.lng;
          requestAnimationFrame(() => this.completeRuler());
        } else if (layerIds.length === 1) {
          let v1 = e.layers._layers[layerIds[0]];
          this.ruler_p.first.lat = v1._latlng.lat;
          this.ruler_p.first.lng = v1._latlng.lng;
        }
      }
    });

    this.map1.mymap.on('moveend', (e) => {
      if (this.run1 && this.run1 > 0) {
        this.run1--;
      } else {
        this.run2 ? this.run2++ : this.run2 = 1;
        this.map2.mymap.panTo(this.map1.mymap.getCenter());
        this.run3 ? this.run3++ : this.run3 = 1;
        this.map3.mymap.panTo(this.map1.mymap.getCenter());
        this.run4 ? this.run4++ : this.run4 = 1;
        this.map4.mymap.panTo(this.map1.mymap.getCenter());
      }
    });
    this.map2.mymap.on('moveend', (e) => {
      if (this.run2 && this.run2 > 0) {
        this.run2--;
      } else {
        this.run1 ? this.run1++ : this.run1 = 1;
        this.map1.mymap.panTo(this.map2.mymap.getCenter());
        this.run3 ? this.run3++ : this.run3 = 1;
        this.map3.mymap.panTo(this.map2.mymap.getCenter());
        this.run4 ? this.run4++ : this.run4 = 1;
        this.map4.mymap.panTo(this.map2.mymap.getCenter());
      }
    });
    this.map3.mymap.on('moveend', (e) => {
      if (this.run3 && this.run3 > 0) {
        this.run3--;
      } else {
        this.run1 ? this.run1++ : this.run1 = 1;
        this.map1.mymap.panTo(this.map3.mymap.getCenter());
        this.run2 ? this.run2++ : this.run2 = 1;
        this.map2.mymap.panTo(this.map3.mymap.getCenter());
        this.run4 ? this.run4++ : this.run4 = 1;
        this.map4.mymap.panTo(this.map3.mymap.getCenter());
      }
    });
    this.map4.mymap.on('moveend', (e) => {
      if (this.run4 && this.run4 > 0) {
        this.run4--;
      } else {
        this.run1 ? this.run1++ : this.run1 = 1;
        this.map1.mymap.panTo(this.map4.mymap.getCenter());
        this.run2 ? this.run2++ : this.run2 = 1;
        this.map2.mymap.panTo(this.map4.mymap.getCenter());
        this.run3 ? this.run3++ : this.run3 = 1;
        this.map3.mymap.panTo(this.map4.mymap.getCenter());
      }
    });

    this.map1.mymap.on('zoomend', () => {
      if (this.runz1 && this.runz1 > 0) {
        this.runz1--;
      } else {
        this.run1 ? this.run1++ : this.run1 = 1;
        this.runz2 ? this.runz2++ : this.runz2 = 1;
        this.run2 ? this.run2++ : this.run2 = 1;
        this.map2.mymap.setView(this.map1.mymap.getCenter(), this.map1.mymap.getZoom());
        this.runz3 ? this.runz3++ : this.runz3 = 1;
        this.run3 ? this.run3++ : this.run3 = 1;
        this.map3.mymap.setView(this.map1.mymap.getCenter(), this.map1.mymap.getZoom());
        this.runz4 ? this.runz4++ : this.runz4 = 1;
        this.run4 ? this.run4++ : this.run4 = 1;
        this.map4.mymap.setView(this.map1.mymap.getCenter(), this.map1.mymap.getZoom());
      }
    });
    this.map2.mymap.on('zoomend', () => {
      if (this.runz2 && this.runz2 > 0) {
        this.runz2--;
      } else {
        this.run2 ? this.run2++ : this.run2 = 1;
        this.runz1 ? this.runz1++ : this.runz1 = 1;
        this.run1 ? this.run1++ : this.run1 = 1;
        this.map1.mymap.setView(this.map2.mymap.getCenter(), this.map2.mymap.getZoom());
        this.runz3 ? this.runz3++ : this.runz3 = 1;
        this.run3 ? this.run3++ : this.run3 = 1;
        this.map3.mymap.setView(this.map2.mymap.getCenter(), this.map2.mymap.getZoom());
        this.runz4 ? this.runz4++ : this.runz4 = 1;
        this.run4 ? this.run4++ : this.run4 = 1;
        this.map4.mymap.setView(this.map2.mymap.getCenter(), this.map2.mymap.getZoom());
      }
    });
    this.map3.mymap.on('zoomend', () => {
      if (this.runz3 && this.runz3 > 0) {
        this.runz3--;
      } else {
        this.run3 ? this.run3++ : this.run3 = 1;
        this.runz2 ? this.runz2++ : this.runz2 = 1;
        this.run2 ? this.run2++ : this.run2 = 1;
        this.map2.mymap.setView(this.map3.mymap.getCenter(), this.map3.mymap.getZoom());
        this.runz1 ? this.runz1++ : this.runz1 = 1;
        this.run1 ? this.run1++ : this.run1 = 1;
        this.map1.mymap.setView(this.map3.mymap.getCenter(), this.map3.mymap.getZoom());
        this.runz4 ? this.runz4++ : this.runz4 = 1;
        this.run4 ? this.run4++ : this.run4 = 1;
        this.map4.mymap.setView(this.map3.mymap.getCenter(), this.map3.mymap.getZoom());
      }
    });

    this.map4.mymap.on('zoomend', () => {
      if (this.runz4 && this.runz4 > 0) {
        this.runz4--;
      } else {
        this.run4 ? this.run4++ : this.run4 = 1;
        this.runz2 ? this.runz2++ : this.runz2 = 1;
        this.run2 ? this.run2++ : this.run2 = 1;
        this.map2.mymap.setView(this.map4.mymap.getCenter(), this.map4.mymap.getZoom());
        this.runz1 ? this.runz1++ : this.runz1 = 1;
        this.run1 ? this.run1++ : this.run1 = 1;
        this.map1.mymap.setView(this.map4.mymap.getCenter(), this.map4.mymap.getZoom());
        this.runz3 ? this.runz3++ : this.runz3 = 1;
        this.run3 ? this.run3++ : this.run3 = 1;
        this.map3.mymap.setView(this.map4.mymap.getCenter(), this.map4.mymap.getZoom());
      }
    });

    L.drawLocal.draw.handlers.polyline.tooltip.start = '';
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';
  };

  formatRegua = (valor) => {
    let text = '';
    if (valor < 1000) {
      text = Math.round(valor) + ' m';
    } else {
      text = (Math.round(valor / 10) / 100) + ' km';
    }

    return text;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  zoomInL = () => {
    this.map1.mymap.zoomIn();
    this.map2.mymap.zoomIn();
    this.map3.mymap.zoomIn();
    this.map4.mymap.zoomIn();
  }

  zoomOutL = () => {
    this.map1.mymap.zoomOut();
    this.map2.mymap.zoomOut();
    this.map3.mymap.zoomOut();
    this.map4.mymap.zoomOut();
  }
  handleListItemClick = guid => {
    let poly = this.map1.mymap._layers[guid];
    this.handleClickP(poly, guid);
  }

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (!dF.className.includes('div-listFloatC')) {
      dF.classList.toggle("div-listFloatC");
    } else {
      dF.classList.remove("div-listFloatC");
    }
  }

  handleStackClick = (tipo) => {
    let stack_value = this.state.stack_value;
    if (tipo === 1) {
      if (stack_value[0] === 1) {
        stack_value[0] = 0;
        tools.cssShow('aplicacao', false);
      } else {
        stack_value[0] = 1;
        tools.cssShow('aplicacao', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 2) {
      if (stack_value[1] === 1) {
        stack_value[1] = 0;
        tools.cssShow('caminho', false);
      } else {
        stack_value[1] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 3) {
      if (stack_value[2] === 1) {
        stack_value[2] = 0;
        tools.cssShow('caminho', true);
      } else {
        stack_value[2] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    }
  }

  regua = (start) => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }

    this.map1.featureGroup.removeLayer(this.map1.mymap._layers['regua']);
    this.map1.featureGroup.removeLayer(this.map1.mymap._layers['reguaM1']);
    this.map1.featureGroup.removeLayer(this.map1.mymap._layers['reguaM2']);

    this.map2.featureGroup.removeLayer(this.map2.mymap._layers['regua']);
    this.map2.featureGroup.removeLayer(this.map2.mymap._layers['reguaM1']);
    this.map2.featureGroup.removeLayer(this.map2.mymap._layers['reguaM2']);

    this.map3.featureGroup.removeLayer(this.map3.mymap._layers['regua']);
    this.map3.featureGroup.removeLayer(this.map3.mymap._layers['reguaM1']);
    this.map3.featureGroup.removeLayer(this.map3.mymap._layers['reguaM2']);

    this.map4.featureGroup.removeLayer(this.map4.mymap._layers['regua']);
    this.map4.featureGroup.removeLayer(this.map4.mymap._layers['reguaM1']);
    this.map4.featureGroup.removeLayer(this.map4.mymap._layers['reguaM2']);

    if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
      this.setState({ ruler_a: false });
      this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
      this.drawCreateToolbar.disable();
    } else {
      this.setState({ ruler_a: true });
      this.drawCreateToolbar = new L.Draw.Polyline(this.map1.mymap);
      this.drawCreateToolbar.enable();
      if (start) {
        this.drawCreateToolbar.addVertex(start);
      }
    }
  }

  completeRuler = () => {
    this.drawCreateToolbar.disable();

    let latlngs = [
      [this.ruler_p.first.lat, this.ruler_p.first.lng],
      [this.ruler_p.second.lat, this.ruler_p.second.lng]
    ];
    let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    let text = latlngF.distanceTo(latlngS);


    let polyline = L.polyline(latlngs);

    polyline._leaflet_id = 'regua';
    polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
    polyline.addTo(this.featureGroupPoly);

    let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    })
    m1.on('click', (e) => {
      this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    })
    m1._leaflet_id = 'reguaM1';
    m1.addTo(this.featureGroupPoly);

    let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    });
    m2.on('click', (e) => {
      this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    })
    m2._leaflet_id = 'reguaM2';
    m2.addTo(this.featureGroupPoly);
  }

  cancelRuler = () => {
    if (this.ruler_p.second.lat) {
      this.regua();
    }
  }

  showPro = (b) => {
    this.setState({ progress: b });
  }

  refreshMap = () => {
    this.map1.mymap.invalidateSize();
    this.map2.mymap.invalidateSize();
    this.map3.mymap.invalidateSize();
    this.map4.mymap.invalidateSize();
  }

  getAreas = (from, bound) => {
    this.showPro(true);
    let apiUrl = Config.server + '/job_area/' + this.props.idJob + '/' + this.map[from].type;
    let method = 'GET';

    let options = {
      method: method,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ areas: [0, 0, 0, 0] });
          this.refreshMap();
          this.showPro(false);
          if (result.msgErro) {
            notification.open({
              message: result.msgErro,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else {
            let list = [];
            let temp;
            for (let i = 0; i < result.length; i++) {
              if(!temp || temp === result[i].id_job_link){
                temp = result[i].id_job_link;
                list.push(result[i]);
              }
            }
            this.drawPolygon(list, from, bound);
            this.update();
          }
        },
        (error) => {
          this.showPro(false);
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  drawPolygon = (list, from, bound) => {
    let myStyle;
    let area = 0;

    for (let i = 0; i < list.length; i++) {
      const poly = list[i];
      this.state[this.map[from].state].push(poly);

      if (poly.type === 'X' || poly.x) {
        myStyle = {
          "color": '#ff002b',
          "weight": 2,
          "opacity": 1
        };
      } else {
        myStyle = {
          "color": '#47839e',
          "weight": 4,
          "opacity": 0.5
        }
      }
      let myLayer = null;
      if (poly.radius > 0) {
        let temp_myStyle = {
          color: myStyle.color,
          weight: myStyle.weight,
          opacity: myStyle.opacity,
          radius: poly.radius
        }
        myLayer = L.circle(poly.point, temp_myStyle);
        poly.area = poly.radius * poly.radius * Math.PI;
      } else {
        myLayer = L.polygon(JSON.parse(poly.points), myStyle);
        poly.area = L.GeometryUtil.geodesicArea(myLayer.getLatLngs()[0]);
      }

      //let myLayer = L.geoJSON(element, { style: myStyle });
      let guid = poly.guid;
      let name = poly.name;
      //myLayer.on("click", () => { this.handleClickP(guid, from); });
      myLayer._leaflet_id = guid;

      myLayer.bindTooltip(name, { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' });

      area += poly.area;

      myLayer.addTo(this.map[from].featureGroup);
    }
    this.areas[from] = area;
    this.setState({ areas: this.areas });
    if (bound && list.length) {
      setTimeout(() => { this.map[from].mymap.fitBounds(this.map[from].featureGroup.getBounds()) }, 200);
    }
  };

  lastSize;
  update = (size) => {
    let changeLimit = 2;
    let changeSize = 1335;
    if (!size)
      size = this.lastSize;
    else
      this.lastSize = size;

    let layout = this.layouts.line1;
    let dF3 = document.getElementById('div3');
    let dF4 = document.getElementById('div4');
    if (this.state.areas[3] === 0) {
      layout = this.layouts.line13;
      if (dF4 && !dF4.className.includes('sr-only')) {
        dF4.classList.toggle("sr-only");
      }
      changeLimit = 1.5;
      changeSize = 1050;
    }

    if ((size.width / size.height < changeLimit) || (size.width < changeSize)) {
      layout = this.layouts.line2;
      if (dF3 && !dF3.className.includes('gridDoble')) {
        dF3.classList.toggle("gridDoble");
      }
    } else {
      if (dF3 && dF3.className.includes('gridDoble')) {
        dF3.classList.remove("gridDoble");
      }
    }

    if (this.state.areas[3] > 0) {
      if (dF4 && dF4.className.includes('sr-only')) {
        dF4.classList.remove("sr-only");
      }
      if (dF3 && dF3.className.includes('gridDoble')) {
        dF3.classList.remove("gridDoble");
      }
    }

    this.setState({ layout: layout });
    this.refreshMap();
  };

  download = (type) => {
    if (this.state[this.map[type].state].length > 0) {
      let polys = [];
      this.state[this.map[type].state].forEach((poly) => {
        let item = this.map[type].mymap._layers[poly.guid].toGeoJSON();
        let name = poly.name;

        item.properties = {
          name: name,
          type: poly.type,
          guid: poly.guid
        };

        polys.push(item);
      });

      let file_name = Date.now() + '.kml';
      let data = geo.toKml(file_name, polys);

      let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      saveAs(blob, file_name);
    }
  };

  render() {
    const { res, onClose, changeView, divRef, updateLayout } = this.props;
    updateLayout.call = this.update;
    const title2 = {
      fontSize: '16px'
    }
    const bZoom = {
      position: 'absolute',
      zIndex: '401',
      width: '3.7rem',
      padding: '1rem',
      top: '1.3rem',
    }
    const bSave = {
      zIndex: 999,
      position: 'absolute',
      left: '1rem',
      top: '1rem',
    }
    const bFirstSave = {
      zIndex: 999,
      position: 'absolute',
      left: '.92rem',
      top: '6rem'
    }

    return (
      <div className="maxH" style={{ display: 'grid', gridTemplateColumns: 'auto', gridAutoRows: '4rem auto', gap: '1rem' }} ref={divRef}>
        <Backd style={{ zIndex: 1002, color: '#fff' }} open={this.state.progress}>
          <CircularProgress color="inherit" disableShrink />
        </Backd>
        <div style={{ display: 'flex' }} className="divContentList">
          <div style={{ display: 'flex', alignItems: 'center', width: '15rem', height: '4rem', paddingLeft: '2rem' }}>
            <h2>{res.AREAS}</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '4rem', paddingRight: '2rem', width: 'calc(100% - 15rem)' }}>
            <Button type="submit" variant="contained" style={{ marginRight: '1rem' }} color={"default"} className="popUpButton" onClick={e => { onClose(); }}>
              {res.CANCELAR}
            </Button>
            <Button type="submit" variant="contained" color={"primary"} className="popUpButton" onClick={e => { changeView(); }}>
              {res.IMPORTAR}
            </Button>
          </div>
        </div>
        <div className="divContent divRelative">
          <div style={{ height: '100%', width: '100%' }} className="wrapperMap">
            <div style={this.state.layout} className="modalPanel">
              <div>
                <div><span style={title2}>{res.AREAS_PLANEJAMENTO}</span></div>
                <div style={{ height: 'calc(100% - .5rem)' }}>
                  <div id="mapid1">
                    <Button className="buttonS buttonMenu" style={bFirstSave} disabled={this.state[this.map[0].state].length === 0} onClick={event => this.download(0)} title={res.BAIXAR_SELECIONADOS}>
                      <MaterialIcon icon="save" className="iconMap" />
                    </Button>
                    <div className='tool-tip-H' style={{
                      right: '1rem',
                      position: 'absolute',
                      bottom: '0.3rem',
                      zIndex: 1099,
                      textAlign: 'right',
                      color: '#edb83b',
                      display: this.state.areas[0] > 0 ? 'block' : 'none'
                    }}>{Math.round(this.state.areas[0] / 100) / 100 + ' ha'}</div>
                  </div>
                </div>
              </div>
              <div>
                <div><span style={title2}>{res.AREAS_EXECUCAO}</span></div>
                <div style={{ height: 'calc(100% - .5rem)' }}>
                  <div id="mapid2">
                    <Button className="buttonS buttonMenu" style={bSave} disabled={this.state[this.map[1].state].length === 0} onClick={event => this.download(1)} title={res.BAIXAR_SELECIONADOS}>
                      <MaterialIcon icon="save" className="iconMap" />
                    </Button>
                    <div className='tool-tip-H' style={{
                      right: '1rem',
                      position: 'absolute',
                      bottom: '0.3rem',
                      zIndex: 1099,
                      textAlign: 'right',
                      color: '#edb83b',
                      display: this.state.areas[1] > 0 ? 'block' : 'none'
                    }}>{Math.round(this.state.areas[1] / 100) / 100 + ' ha'}</div>
                  </div>
                </div>
              </div>
              <div id='div3'>
                <div><span style={title2}>{res.AREAS_ANALISE}</span></div>
                <div style={{ height: 'calc(100% - .5rem)' }}>
                  <div id="mapid3">
                    <Button className="buttonS buttonMenu" style={bSave} disabled={this.state[this.map[2].state].length === 0} onClick={event => this.download(2)} title={res.BAIXAR_SELECIONADOS}>
                      <MaterialIcon icon="save" className="iconMap" />
                    </Button>
                    <div className='tool-tip-H' style={{
                      right: '1rem',
                      position: 'absolute',
                      bottom: '0.3rem',
                      zIndex: 1099,
                      textAlign: 'right',
                      color: '#edb83b',
                      display: this.state.areas[2] > 0 ? 'block' : 'none'
                    }}>{Math.round(this.state.areas[2] / 100) / 100 + ' ha'}</div>
                  </div>
                </div>
              </div>
              <div id='div4'>
                <div><span style={title2}>{res.AREAS_RETOQUE}</span></div>
                <div style={{ height: 'calc(100% - .5rem)' }}>
                  <div id="mapid4">
                    <Button className="buttonS buttonMenu" style={bSave} disabled={this.state[this.map[3].state].length === 0} onClick={event => this.download(3)} title={res.BAIXAR_SELECIONADOS}>
                      <MaterialIcon icon="save" className="iconMap" />
                    </Button>
                    <div className='tool-tip-H' style={{
                      right: '1rem',
                      position: 'absolute',
                      bottom: '0.3rem',
                      zIndex: 1099,
                      textAlign: 'right',
                      color: '#edb83b',
                      display: this.state.areas[3] > 0 ? 'block' : 'none'
                    }}>{Math.round(this.state.areas[3] / 100) / 100 + ' ha'}</div>
                  </div>
                </div>
              </div>
            </div>
            <div style={bZoom}>
              <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                <MaterialIcon icon="add" className="iconMap" />
              </Button>
              <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                <MaterialIcon icon="remove" className="iconMap" />
              </Button>
            </div>
            {this.state.stack &&
              <div className="divContent floatW divS" onfocusout={event => this.setState({ stack: false })}>
                <List
                  component="nav"
                  aria-labelledby="stack"
                >
                  <ListItem key={2} button
                    className={(this.state.stack_value[1] === 1) ? 'SelItem' : ''}
                    onClick={event => this.handleStackClick(2)}
                  >
                    <ListItemText id={2} primary="Caminho" />
                  </ListItem>

                  <ListItem key={1} button
                    className={(this.state.stack_value[0] === 1) ? 'SelItem' : ''}
                    onClick={event => this.handleStackClick(1)}
                  >
                    <ListItemText id={1} primary="Aplicação" />
                  </ListItem>
                </List>
              </div>}
          </div>
        </div>
      </div >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res, onClose, changeView, idJob } = props;
  const updateLayout = {
    call: null
  };
  const { ref } = useResizeObserver({
    onResize: (size) => {
      if (updateLayout.call) updateLayout.call(size);
    }
  });

  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} id={idJob} divRef={ref} updateLayout={updateLayout} res={res} onClose={onClose} changeView={changeView} idJob={idJob} />
    </div>)
}
export default Box;

