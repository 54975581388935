const calc_data = (spray_rate_nozzles, velocidade, dsc, list_base, n_nozzles) => {
    let ind = 0;
    const dsc_label = ['MUITO FINA', 'FINA', 'MÉDIA', 'GROSSA', 'MUITO GROSSA', 'EXTREMAMENTE GROSSA', 'ULT. GROSSA'];
    const between = (x, min, max) => {
        return x >= min && x < max;
    }

    let value = null;
    let label = null;
    let ret = [];
    let temp_itens = null;
    let temp_pressao = null;
    let temp_condicao = null;
    const defaultValue = 'N/A';
    if (n_nozzles > 12) {
        const fields = { type: 0, itens: ['Nozzle', 'Orifício', 'Angulo', 'P Tst ', 'Pressao (psi)', 'DSC', 'DV01', 'DV05', 'DV09'] };
        value = ((1806.31134512 * spray_rate_nozzles ** 2) - (205.9937382 * spray_rate_nozzles) + (14.28506784));
        label = between(value, 20, 40) ? dsc_label[1] : dsc_label[0];
        ret.push({ fields: fields, rows: [], img: require('./../../assets/images/pics/travicar_eletrostatico_PONTEIRA_ceramica.png') });
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR',
                ' SPE-0',
                0,
                (spray_rate_nozzles >= 0.163 && spray_rate_nozzles < 0.333),
                value,
                label, defaultValue, defaultValue, defaultValue
            ]);

        value = (spray_rate_nozzles >= 0.217 && spray_rate_nozzles < 0.355) ? (-(13888.7198219 * (spray_rate_nozzles ** 3)) + (11467.75625161 * (spray_rate_nozzles ** 2)) - (2768.28462061 * spray_rate_nozzles) + (231.38275873))
            : (spray_rate_nozzles >= 0.355 && spray_rate_nozzles <= 0.456) ? ((52187642.1503906 * (spray_rate_nozzles ** 5)) - (107151009.55675 * (spray_rate_nozzles ** 4)) + (87885911.4276006 * (spray_rate_nozzles ** 3)) - (35994415.54701 * (spray_rate_nozzles ** 2)) + (7361546.27674663 * spray_rate_nozzles) - (601437.70514861)) : 0;
        label = between(value, 20, 40) ? dsc_label[1] : dsc_label[0];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-1', 0,
                (spray_rate_nozzles >= 0.217 && spray_rate_nozzles < 0.355) || (spray_rate_nozzles >= 0.355 && spray_rate_nozzles <= 0.456),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        value = ((-245104.76172 * (spray_rate_nozzles ** 6)) + (747522.70445 * (spray_rate_nozzles ** 5)) - (940520.05287 * spray_rate_nozzles ** 4) + (624554.29555 * spray_rate_nozzles ** 3) - (230342.33859 * spray_rate_nozzles ** 2) + (44866.65355 * spray_rate_nozzles) - (3591.63616));
        label = between(value, 20, 60) ? dsc_label[1] : dsc_label[0];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-2', 0,
                (spray_rate_nozzles >= 0.326 && spray_rate_nozzles < 0.688),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        value = ((-16.75396 * (spray_rate_nozzles ** 5)) + (3.35081 * spray_rate_nozzles ** 4) + (90.8176 * spray_rate_nozzles ** 3) + (47.08561 * spray_rate_nozzles ** 2) + (52.62457 * spray_rate_nozzles) - (9.84782));
        label = between(value, 20, 60) ? dsc_label[1] : dsc_label[0];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-3', 0,
                (spray_rate_nozzles >= 0.431 && spray_rate_nozzles < 0.937),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        value = (-27585.87001 * (spray_rate_nozzles ** 6) + 139703.37885 * (spray_rate_nozzles ** 5) - 291035.28351 * (spray_rate_nozzles ** 4) + 319126.90388 * (spray_rate_nozzles ** 3) - 194077.07926 * (spray_rate_nozzles ** 2) + 62142.35444 * spray_rate_nozzles - 8170.00367);
        label = between(value, 20, 70) ? dsc_label[1] : dsc_label[0];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-4', 0,
                (spray_rate_nozzles >= 0.539 && spray_rate_nozzles < 1.19),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        value = (1558.31228 * spray_rate_nozzles ** 6 - 9775.89384 * spray_rate_nozzles ** 5 + 25156.55876 * spray_rate_nozzles ** 4 - 33957.42282 * spray_rate_nozzles ** 3 + 25422.68741 * spray_rate_nozzles ** 2 - 9922.22381 * spray_rate_nozzles + 1589.15245);
        label = between(value, 20, 70) ? dsc_label[1] : dsc_label[0];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-5', 0,
                (spray_rate_nozzles >= 0.647 && spray_rate_nozzles < 1.42),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        value = (-125.69071 * spray_rate_nozzles ** 6 + 1244.16463 * spray_rate_nozzles ** 5 - 5060.04968 * spray_rate_nozzles ** 4 + 10810.45648 * spray_rate_nozzles ** 3 - 12747.36264 * spray_rate_nozzles ** 2 + 7912.60559 * spray_rate_nozzles - 2002.56429);
        label = between(value, 20, 100) ? dsc_label[1] : dsc_label[0];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-6', 0,
                (spray_rate_nozzles >= 0.966 && spray_rate_nozzles < 2.16),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        value = (-5.09639 * spray_rate_nozzles ** 6 + 58.43361 * spray_rate_nozzles ** 5 - 271.83893 * spray_rate_nozzles ** 4 + 654.77159 * spray_rate_nozzles ** 3 - 840.27962 * spray_rate_nozzles ** 2 + 576.95363 * spray_rate_nozzles - 155.02423);
        label = dsc_label[1];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-7', 0,
                (spray_rate_nozzles >= 1.27 && spray_rate_nozzles < 2.88),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        value = (-0.00013 * spray_rate_nozzles ** 5 - 0.07988 * spray_rate_nozzles ** 4 + 0.3104 * spray_rate_nozzles ** 3 + 15.20328 * spray_rate_nozzles ** 2 - 1.48741 * spray_rate_nozzles + 0.73699);
        label = dsc_label[1];
        ret[ind].rows.push(
            ['ELETROSTÁTICO CERÂMICO TRAVICAR', 'SPE-8', 0,
                (spray_rate_nozzles >= 1.4 && spray_rate_nozzles < 3.12),
                value, label, defaultValue, defaultValue, defaultValue
            ]);

        ind = ret.length;
        ret.push({ fields: fields, rows: [], img: require('./../../assets/images/pics/PONTEIRA_DOSADORA_LEQUE.png') });

        value = ((75.90482635 * (spray_rate_nozzles ** 2)) - (6.56453755 * spray_rate_nozzles) + (1.57612135));
        label = dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8001', 0, (spray_rate_nozzles >= 0.538 && spray_rate_nozzles < 0.922),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = ((75.90482635 * (spray_rate_nozzles ** 2)) - (6.56453755 * spray_rate_nozzles) + (1.57612135));
        label = dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8002', 0, (spray_rate_nozzles >= 0.538 && spray_rate_nozzles < 0.922),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = ((408.75831 * (spray_rate_nozzles ** 5)) - (1889.90997 * spray_rate_nozzles ** 4) + (3376.01938 * spray_rate_nozzles ** 3) - (2879.94773 * spray_rate_nozzles ** 2) + (1209.19569 * spray_rate_nozzles) - (193.21575));
        label = dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8003', 0, (spray_rate_nozzles >= 0.985 && spray_rate_nozzles < 1.428),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = ((-86.38621 * (spray_rate_nozzles ** 5)) + (564.19757 * spray_rate_nozzles ** 4) - (1412.6106 * spray_rate_nozzles ** 3) + (1706.67171 * spray_rate_nozzles ** 2) - (963.59403 * spray_rate_nozzles) + (209.68385));
        label = between(value, 20, 50) ? dsc_label[2] : dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8004', 0, (spray_rate_nozzles >= 1.055 && spray_rate_nozzles < 1.84),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (8.75934 * spray_rate_nozzles ** 5 - 64.40418 * spray_rate_nozzles ** 4 + 186.55083 * spray_rate_nozzles ** 3 - 258.04961 * spray_rate_nozzles ** 2 + 193.63538 * spray_rate_nozzles - 54.65089);
        label = between(value, 20, 60) ? dsc_label[2] : dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8005', 0, (spray_rate_nozzles >= 1.32 && spray_rate_nozzles < 2.351),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (7.20291 * spray_rate_nozzles ** 5 - 67.97631 * spray_rate_nozzles ** 4 + 244.65576 * spray_rate_nozzles ** 3 - 409.31201 * spray_rate_nozzles ** 2 + 335.90083 * spray_rate_nozzles - 102.72622);
        label = dsc_label[2];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8006', 0, (spray_rate_nozzles >= 1.634 && spray_rate_nozzles < 2.778),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (2.94048 * spray_rate_nozzles ** 5 - 39.7646 * spray_rate_nozzles ** 4 + 207.79553 * spray_rate_nozzles ** 3 - 517.1227 * spray_rate_nozzles ** 2 + 625.06009 * spray_rate_nozzles - 285.40367);
        label = between(value, 20, 40) ? dsc_label[3] : dsc_label[2];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8008', 0, (spray_rate_nozzles >= 2.23 && spray_rate_nozzles < 3.703),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (0.09281 * spray_rate_nozzles ** 5 - 1.36687 * spray_rate_nozzles ** 4 + 7.56837 * spray_rate_nozzles ** 3 - 16.40711 * spray_rate_nozzles ** 2 + 21.58465 * spray_rate_nozzles - 8.43858);
        label = between(value, 20, 40) ? dsc_label[3] : dsc_label[2];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8010', 0, (spray_rate_nozzles >= 2.71 && spray_rate_nozzles < 4.62),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (-0.01515 * spray_rate_nozzles ** 5 + 0.38543 * spray_rate_nozzles ** 4 - 3.77413 * spray_rate_nozzles ** 3 + 18.91843 * spray_rate_nozzles ** 2 - 39.49421 * spray_rate_nozzles + 33.51803);
        label = dsc_label[3];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8015', 0, (spray_rate_nozzles >= 4.02 && spray_rate_nozzles < 6.98),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (0.0015 * spray_rate_nozzles ** 5 - 0.05911 * spray_rate_nozzles ** 4 + 0.87565 * spray_rate_nozzles ** 3 - 5.37559 * spray_rate_nozzles ** 2 + 19.69248 * spray_rate_nozzles - 23.85717);
        label = between(value, 20, 30) ? dsc_label[4] : dsc_label[3];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8020', 0, (spray_rate_nozzles >= 5.37 && spray_rate_nozzles < 9.23),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (0.1112 * spray_rate_nozzles ** 1.99984);
        label = between(value, 20, 40) ? dsc_label[4] : dsc_label[3];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8050', 0, (spray_rate_nozzles >= 13.4 && spray_rate_nozzles < 23.24),
            value, label, defaultValue, defaultValue, defaultValue]);


        ind = ret.length;
        ret.push({ fields: fields, rows: [], img: require('./../../assets/images/pics/travicar_eletrostatico_bico_LEQUE.png') });
        value = ((75.90482635 * (spray_rate_nozzles ** 2)) - (6.56453755 * spray_rate_nozzles) + (1.57612135));
        label = dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8001', 0, (spray_rate_nozzles >= 0.538 && spray_rate_nozzles < 0.922),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = ((75.90482635 * (spray_rate_nozzles ** 2)) - (6.56453755 * spray_rate_nozzles) + (1.57612135));
        label = dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8002', 0, (spray_rate_nozzles >= 0.538 && spray_rate_nozzles < 0.922),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = ((408.75831 * (spray_rate_nozzles ** 5)) - (1889.90997 * spray_rate_nozzles ** 4) + (3376.01938 * spray_rate_nozzles ** 3) - (2879.94773 * spray_rate_nozzles ** 2) + (1209.19569 * spray_rate_nozzles) - (193.21575));
        label = dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8003', 0, (spray_rate_nozzles >= 0.985 && spray_rate_nozzles < 1.428),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = ((-86.38621 * (spray_rate_nozzles ** 5)) + (564.19757 * spray_rate_nozzles ** 4) - (1412.6106 * spray_rate_nozzles ** 3) + (1706.67171 * spray_rate_nozzles ** 2) - (963.59403 * spray_rate_nozzles) + (209.68385));
        label = between(value, 20, 50) ? dsc_label[2] : dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8004', 0, (spray_rate_nozzles >= 1.055 && spray_rate_nozzles < 1.84),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (8.75934 * spray_rate_nozzles ** 5 - 64.40418 * spray_rate_nozzles ** 4 + 186.55083 * spray_rate_nozzles ** 3 - 258.04961 * spray_rate_nozzles ** 2 + 193.63538 * spray_rate_nozzles - 54.65089);
        label = between(value, 20, 60) ? dsc_label[2] : dsc_label[1];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8005', 0, (spray_rate_nozzles >= 1.32 && spray_rate_nozzles < 2.351),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (7.20291 * spray_rate_nozzles ** 5 - 67.97631 * spray_rate_nozzles ** 4 + 244.65576 * spray_rate_nozzles ** 3 - 409.31201 * spray_rate_nozzles ** 2 + 335.90083 * spray_rate_nozzles - 102.72622);
        label = dsc_label[2];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8006', 0, (spray_rate_nozzles >= 1.634 && spray_rate_nozzles < 2.778),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (2.94048 * spray_rate_nozzles ** 5 - 39.7646 * spray_rate_nozzles ** 4 + 207.79553 * spray_rate_nozzles ** 3 - 517.1227 * spray_rate_nozzles ** 2 + 625.06009 * spray_rate_nozzles - 285.40367);
        label = between(value, 20, 40) ? dsc_label[3] : dsc_label[2];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8008', 0, (spray_rate_nozzles >= 2.23 && spray_rate_nozzles < 3.703),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (0.09281 * spray_rate_nozzles ** 5 - 1.36687 * spray_rate_nozzles ** 4 + 7.56837 * spray_rate_nozzles ** 3 - 16.40711 * spray_rate_nozzles ** 2 + 21.58465 * spray_rate_nozzles - 8.43858);
        label = between(value, 20, 40) ? dsc_label[3] : dsc_label[2];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8010', 0, (spray_rate_nozzles >= 2.71 && spray_rate_nozzles < 4.62),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (-0.01515 * spray_rate_nozzles ** 5 + 0.38543 * spray_rate_nozzles ** 4 - 3.77413 * spray_rate_nozzles ** 3 + 18.91843 * spray_rate_nozzles ** 2 - 39.49421 * spray_rate_nozzles + 33.51803);
        label = dsc_label[3];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8015', 0, (spray_rate_nozzles >= 4.02 && spray_rate_nozzles < 6.98),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (0.0015 * spray_rate_nozzles ** 5 - 0.05911 * spray_rate_nozzles ** 4 + 0.87565 * spray_rate_nozzles ** 3 - 5.37559 * spray_rate_nozzles ** 2 + 19.69248 * spray_rate_nozzles - 23.85717);
        label = between(value, 20, 30) ? dsc_label[4] : dsc_label[3];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8020', 0, (spray_rate_nozzles >= 5.37 && spray_rate_nozzles < 9.23),
            value, label, defaultValue, defaultValue, defaultValue]);

        value = (0.1112 * spray_rate_nozzles ** 1.99984);
        label = between(value, 20, 40) ? dsc_label[4] : dsc_label[3];
        ret[ind].rows.push(['PONTEIRA DOSADORA LEQUE', '8050', 0, (spray_rate_nozzles >= 13.4 && spray_rate_nozzles < 23.24),
            value, label, defaultValue, defaultValue, defaultValue]);


        ind = 3;//['Nozzle', 'Tipo', 'Angulo', 'P Tst ', 'Pressao (psi)', 'DSC']
        ret.push({ fields: fields, rows: [], img: require('./../../assets/images/pics/tt11.png') });
        temp_itens = list_base.filter((item) => item.rows.filter((i2) => i2[0] === 'CP11TT 40º Flat Fan').length > 0);
        if (temp_itens.length > 0) {
            ret[ind].rows = ret[ind].rows.concat(temp_itens[0].rows.filter((item) => item[0] === 'CP11TT 40º Flat Fan')
                .map((i2) => ['TRAVICAR TT11', 4000 + Number(i2[1]), i2[2], i2[3], i2[4], i2[5],
                    defaultValue,
                    defaultValue,
                    defaultValue]));
        }
        /*ret[ind].rows.push(['TRAVICAR TT11', '4004', 0, (spray_rate_nozzles >= 1.05 && spray_rate_nozzles < 1.99),
            ((-86.38621455 * (spray_rate_nozzles ** 5)) + (564.19756823 * spray_rate_nozzles ** 4) - (1412.61060482 * spray_rate_nozzles ** 3) + (1706.67170641 * spray_rate_nozzles ** 2) - (963.59403034 * spray_rate_nozzles) + (209.68384732))]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4006', 0, (spray_rate_nozzles >= 1.63 && spray_rate_nozzles < 2.99),
            ((7.2029 * (spray_rate_nozzles ** 5)) + (-67.976 * spray_rate_nozzles ** 4) + (244.66 * spray_rate_nozzles ** 3) + (-409.31 * spray_rate_nozzles ** 2) + (335.9 * spray_rate_nozzles) - (102.73))]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4008', 0, (spray_rate_nozzles >= 2.23 && spray_rate_nozzles < 3.99),
            ((2.94048 * (spray_rate_nozzles ** 5)) + (-39.7646 * spray_rate_nozzles ** 4) + (207.79553 * spray_rate_nozzles ** 3) + (-517.1227 * spray_rate_nozzles ** 2) + (625.06009 * spray_rate_nozzles) - (285.40367))]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4010', 0, (spray_rate_nozzles >= 2.71 && spray_rate_nozzles < 4.98),
            ((0.09281 * (spray_rate_nozzles ** 5)) + (-1.36687 * spray_rate_nozzles ** 4) + (7.56837 * spray_rate_nozzles ** 3) + (-16.40711 * spray_rate_nozzles ** 2) + (21.58465 * spray_rate_nozzles) - (8.43858))]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4012', 0, (spray_rate_nozzles >= 3.2 && spray_rate_nozzles < 6.16),
            (2.1367 * spray_rate_nozzles ** 1.9205)]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4015', 0, (spray_rate_nozzles >= 4.02 && spray_rate_nozzles < 7.63),
            ((-0.0152 * (spray_rate_nozzles ** 5)) + (0.3854 * spray_rate_nozzles ** 4) + (-3.7741 * spray_rate_nozzles ** 3) + (18.918 * spray_rate_nozzles ** 2) + (-39.494 * spray_rate_nozzles) + (33.518))]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4020', 0, (spray_rate_nozzles >= 5.35 && spray_rate_nozzles < 10.01),
            (0.6934 * spray_rate_nozzles ** 2.004)]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4025', 0, (spray_rate_nozzles >= 6.69 && spray_rate_nozzles < 12.79),
            (0.5063 * spray_rate_nozzles ** 1.9344)]);
    
        ret[ind].rows.push(['TRAVICAR TT11', '4030', 0, (spray_rate_nozzles >= 8.05 && spray_rate_nozzles < 14.99),
            (0.3015 * spray_rate_nozzles ** 2.0124)]);*/


        ind = ret.length;
        ret.push({ fields: fields, rows: [], img: require('./../../assets/images/pics/TT11 ELETROSTÁTICO.png') });
        temp_itens = list_base.filter((item) => item.rows.filter((i2) => i2[0] === 'CP11TT 40º Flat Fan').length > 0);
        if (temp_itens.length > 0) {
            ret[ind].rows = ret[ind].rows.concat(temp_itens[0].rows.filter((item) => item[0] === 'CP11TT 40º Flat Fan' && (item[2] >= 0 && item[2] <= 30))
                .map((i2) => ['ELETROSTÁTICO TT11 TRAVICAR', 4000 + Number(i2[1]), i2[2], i2[3], i2[4], i2[5],
                    defaultValue,
                    defaultValue,
                    defaultValue]));
        }
        /*ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4004', 0, (spray_rate_nozzles >= 1.05 && spray_rate_nozzles < 1.99),
            ((-86.38621455 * (spray_rate_nozzles ** 5)) + (564.19756823 * spray_rate_nozzles ** 4) - (1412.61060482 * spray_rate_nozzles ** 3) + (1706.67170641 * spray_rate_nozzles ** 2) - (963.59403034 * spray_rate_nozzles) + (209.68384732))]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4006', 0, (spray_rate_nozzles >= 1.63 && spray_rate_nozzles < 2.99),
            ((7.2029 * (spray_rate_nozzles ** 5)) + (-67.976 * spray_rate_nozzles ** 4) + (244.66 * spray_rate_nozzles ** 3) + (-409.31 * spray_rate_nozzles ** 2) + (335.9 * spray_rate_nozzles) - (102.73))]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4008', 0, (spray_rate_nozzles >= 2.23 && spray_rate_nozzles < 3.99),
            ((2.94048 * (spray_rate_nozzles ** 5)) + (-39.7646 * spray_rate_nozzles ** 4) + (207.79553 * spray_rate_nozzles ** 3) + (-517.1227 * spray_rate_nozzles ** 2) + (625.06009 * spray_rate_nozzles) - (285.40367))]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4010', 0, (spray_rate_nozzles >= 2.71 && spray_rate_nozzles < 4.98),
            ((0.09281 * (spray_rate_nozzles ** 5)) + (-1.36687 * spray_rate_nozzles ** 4) + (7.56837 * spray_rate_nozzles ** 3) + (-16.40711 * spray_rate_nozzles ** 2) + (21.58465 * spray_rate_nozzles) - (8.43858))]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4012', 0, (spray_rate_nozzles >= 3.2 && spray_rate_nozzles < 6.16),
            (2.1367 * spray_rate_nozzles ** 1.9205)]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4015', 0, (spray_rate_nozzles >= 4.02 && spray_rate_nozzles < 7.63),
            ((-0.0152 * (spray_rate_nozzles ** 5)) + (0.3854 * spray_rate_nozzles ** 4) + (-3.7741 * spray_rate_nozzles ** 3) + (18.918 * spray_rate_nozzles ** 2) + (-39.494 * spray_rate_nozzles) + (33.518))]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4020', 0, (spray_rate_nozzles >= 5.35 && spray_rate_nozzles < 10.01),
            (0.6934 * spray_rate_nozzles ** 2.004)]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4025', 0, (spray_rate_nozzles >= 6.69 && spray_rate_nozzles < 12.79),
            (0.5063 * spray_rate_nozzles ** 1.9344)]);
    
        ret[ind].rows.push(['ELETROSTÁTICO TT11 TRAVICAR', '4030', 0, (spray_rate_nozzles >= 8.05 && spray_rate_nozzles < 14.99),
            (0.3015 * spray_rate_nozzles ** 2.0124)]);*/


        ind = ret.length;
        //  const fields = ['Nozzle', 'Orifício', 'Angulo', 'P Tst ', 'Pressao (psi)', 'DSC', 'DV01', 'DV05', 'DV09'];
        ret.push({ fields: { type: 1, itens: ['Nozzle', 'Orifício', 'Angulo', 'P Tst ', 'Pressao (psi)', 'DSC', 'DV01', 'DV05', 'DV09', 'Orificio (IN)', 'Angulação', 'DV0.5', 'AR', 'V<100µm', 'V<200µm'] }, rows: [], img: require('./../../assets/images/pics/pulverizador_03.png') });
        //ret.push({ fields: ['Nozzle', 'Orifício', 'Angulo', 'P Tst ', 'Pressao (psi)', 'Orificio (IN)', 'Angulação', 'DV0.5', 'AR', 'V<100µm', 'V<200µm', 'DSC'], rows: [], img: require('./../../assets/images/pics/pulverizador_03.png') });
        const trav_data = [
            ['DV0.5', 617.902207, 1502.890853, 1.007039, 0.388476, -4.172958, -3409.202286, 5.190083, -0.01227, 11.818182, -0.012591, -0.005782, -9.393939, -0.003182, 0, 0.008819],
            ['RS', 3.942901, 2.319565, -0.020159, 0.003739, -0.051185, 10.957511, -0.019752, 0.000114, -0.004545, 0.000020455, 0.000152, -0.034848, 0.000076212, -0.000125, 0.000255],
            ['%V<100µm', 91.717272, 117.347387, -0.81154, 0.53497, -1.622896, 9.793844, -1.425372, 0.003839, -0.813636, 0.001066, 0.001043, -0.243939, 0.004616, -0.004658, 0.007569],
            ['%V<200µm', 122.974395, -18.315253, -0.917087, 1.432295, -2.458597, 569.393427, -2.85314, 0.006483, -3.118182, 0.002019, 0.007809, 0.874242, 0.004717, -0.015367, 0.01378]
        ];
        const param = [30, 55, 90];
        const getData = (data, orificio, angulo, pressao, valocidade) => {
            //=Y29+Z29*B28+AA29*B40+AB29*B58+AC29*B60+AD29*B28*B28+AE29*B40*B28+AF29*B40*B40+AG29*B58*B28+AH29*B58*B40+AI29*B58*B58+AJ29*B60*B28+AK29*B60*B40+AL29*B60*B58+AM29*B60*B60
            return (data[1] + data[2] * orificio + data[3] * angulo + data[4] * pressao + data[5] * valocidade +
                data[6] * orificio * orificio + data[7] * angulo * orificio + data[8] * angulo * angulo + data[9] *
                pressao * orificio + data[10] * pressao * angulo + data[11] * pressao * pressao + data[12] * valocidade *
                orificio + data[13] * valocidade * angulo + data[14] * valocidade * pressao + data[15] * valocidade * valocidade);
        };
        const getType = (param) => {

            return (param >= 655 ? dsc_label[5] : (param >= 531 ? dsc_label[4] : (param >= 429 ? dsc_label[3] : (param >= 280 ? dsc_label[2] : (param >= 182 ? dsc_label[1] : (param > 0 ? dsc_label[0] : ''))))))
        }

        let temp_ori = 0.062;
        temp_pressao = (spray_rate_nozzles >= 1.75 && spray_rate_nozzles < 2.58) ? ((-9.14 + (10 * spray_rate_nozzles)) / 0.416) :
            ((spray_rate_nozzles >= 2.58 && spray_rate_nozzles <= 3.17) ? ((-28 + (20 * spray_rate_nozzles)) / 0.59) : 0);
        temp_condicao = (spray_rate_nozzles >= 1.75 && spray_rate_nozzles < 2.58) || (spray_rate_nozzles >= 2.58 && spray_rate_nozzles <= 3.17);
        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['TRAVICAR 03', 'O - 1', 0, temp_condicao,
                temp_pressao,
                getType(temp_size),
                defaultValue,
                defaultValue,
                defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }

        temp_ori = 0.078;
        temp_pressao = (spray_rate_nozzles >= 2.73 && spray_rate_nozzles <= 4.01) ? ((-14.43 + (10 * spray_rate_nozzles)) / 0.643) :
            ((spray_rate_nozzles > 4.01 && spray_rate_nozzles <= 5) ? ((-40.6 + (20 * spray_rate_nozzles)) / 0.99) : 0);
        temp_condicao = (spray_rate_nozzles >= 2.73 && spray_rate_nozzles <= 4.01) || (spray_rate_nozzles > 4.01 && spray_rate_nozzles <= 5);
        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['TRAVICAR 03', 'O - 2', 0, temp_condicao,
                temp_pressao,
                getType(temp_size), defaultValue, defaultValue, defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }

        temp_ori = 0.125;
        temp_pressao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) ? ((-64.56 + (20 * spray_rate_nozzles)) / 3.024) :
            ((spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38) ? ((-101.6 + (20 * spray_rate_nozzles)) / 2.1) : 0);
        temp_condicao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) || (spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38);

        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['TRAVICAR 03', 'O - 3', 0, temp_condicao,
                temp_pressao,
                getType(temp_size), defaultValue, defaultValue, defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }

        temp_ori = 0.172;
        temp_pressao = (spray_rate_nozzles >= 8.52 && spray_rate_nozzles <= 11.06) ? ((-34.4 + (10 * spray_rate_nozzles)) / 2.54) :
            ((spray_rate_nozzles > 11.06 && spray_rate_nozzles < 14.26) ? ((-125.2 + (20 * spray_rate_nozzles)) / 3.24) :
                ((spray_rate_nozzles >= 14.26 && spray_rate_nozzles <= 15.62) ? ((-74.6 + (10 * spray_rate_nozzles)) / 1.36) : 0));
        temp_condicao = (spray_rate_nozzles >= 8.52 && spray_rate_nozzles <= 11.06) || (spray_rate_nozzles > 11.06 && spray_rate_nozzles < 14.26) || (spray_rate_nozzles >= 14.26 && spray_rate_nozzles <= 15.62);
        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['TRAVICAR 03', 'O - 4', 0, temp_condicao,
                temp_pressao,
                getType(temp_size), defaultValue, defaultValue, defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }


        ind = ret.length;
        ret.push({ fields: ret[ind - 1].fields, rows: [], img: require('./../../assets/images/pics/pulverizador_leque.png') });
        temp_ori = 0.062;
        temp_pressao = (spray_rate_nozzles >= 1.75 && spray_rate_nozzles < 2.58) ? ((-9.14 + (10 * spray_rate_nozzles)) / 0.416) :
            ((spray_rate_nozzles >= 2.58 && spray_rate_nozzles <= 3.17) ? ((-28 + (20 * spray_rate_nozzles)) / 0.59) : 0);
        temp_condicao = (spray_rate_nozzles >= 1.75 && spray_rate_nozzles < 2.58) || (spray_rate_nozzles >= 2.58 && spray_rate_nozzles <= 3.17);
        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['PULVERIZADOR LEQUE', 'O - 1', 0, temp_condicao,
                temp_pressao,
                getType(temp_size), defaultValue, defaultValue, defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }

        temp_ori = 0.078;
        temp_pressao = (spray_rate_nozzles >= 2.73 && spray_rate_nozzles <= 4.01) ? ((-14.43 + (10 * spray_rate_nozzles)) / 0.643) :
            ((spray_rate_nozzles > 4.01 && spray_rate_nozzles <= 5) ? ((-40.6 + (20 * spray_rate_nozzles)) / 0.99) : 0);
        temp_condicao = (spray_rate_nozzles >= 2.73 && spray_rate_nozzles <= 4.01) || (spray_rate_nozzles > 4.01 && spray_rate_nozzles <= 5);
        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['PULVERIZADOR LEQUE', 'O - 2', 0, temp_condicao,
                temp_pressao,
                getType(temp_size), defaultValue, defaultValue, defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }

        temp_ori = 0.125;
        temp_pressao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) ? ((-64.56 + (20 * spray_rate_nozzles)) / 3.024) :
            ((spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38) ? ((-101.6 + (20 * spray_rate_nozzles)) / 2.1) : 0);
        temp_condicao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) || (spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38);
        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['PULVERIZADOR LEQUE', 'O - 3', 0, temp_condicao,
                temp_pressao,
                getType(temp_size), defaultValue, defaultValue, defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }

        temp_ori = 0.172;
        temp_pressao = (spray_rate_nozzles >= 8.52 && spray_rate_nozzles <= 11.06) ? ((-34.4 + (10 * spray_rate_nozzles)) / 2.54) :
            ((spray_rate_nozzles > 11.06 && spray_rate_nozzles < 14.26) ? ((-125.2 + (20 * spray_rate_nozzles)) / 3.24) :
                ((spray_rate_nozzles >= 14.26 && spray_rate_nozzles <= 15.62) ? ((-74.6 + (10 * spray_rate_nozzles)) / 1.36) : 0));
        temp_condicao = (spray_rate_nozzles >= 8.52 && spray_rate_nozzles <= 11.06) || (spray_rate_nozzles > 11.06 && spray_rate_nozzles < 14.26) || (spray_rate_nozzles >= 14.26 && spray_rate_nozzles <= 15.62);
        for (let i = 0; i < param.length; i++) {
            const temp_ang = param[i];
            const temp_size = getData(trav_data[1], temp_ori, temp_ang, temp_pressao, velocidade);
            ret[ind].rows.push(['PULVERIZADOR LEQUE', 'O - 4', 0, temp_condicao,
                temp_pressao,
                getType(temp_size), defaultValue, defaultValue, defaultValue,
                temp_ori, temp_ang,
                getData(trav_data[0], temp_ori, temp_ang, temp_pressao, velocidade),
                temp_size,
                getData(trav_data[2], temp_ori, temp_ang, temp_pressao, velocidade),
                getData(trav_data[3], temp_ori, temp_ang, temp_pressao, velocidade)]);
        }

    } else if (n_nozzles <= 12) {
        const tabela_gota = (n) => {
            if (n < 150) {
                return dsc_label[0];
            } else if (n >= 150 && n < 250) {
                return dsc_label[1];
            } else if (n >= 250 && n < 350) {
                return dsc_label[2];
            } else if (n >= 350 && n < 425) {
                return dsc_label[3];
            } else if (n >= 425 && n < 500) {
                return dsc_label[4];
            } else if (n >= 500) {
                return dsc_label[5];
            }
        };
        let pAngTela = (list, itens) => {
            let temp_rpm = (5700 * velocidade + 24000) / 70;
            let temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (0.0000000000563 * velocidade ** 4 - 0.0000000212 * velocidade ** 3 + 0.0000031 * velocidade ** 2 - 0.00022 * velocidade + 0.014))));

            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 12000 && velocidade >= 40 && velocidade <= 110 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '90mm', 'PÁ 35°', itens[1]]);

            temp_rpm = (5550 * velocidade + 30000) / 90;
            temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (0.0000000000351 * velocidade ** 4 - 0.0000000144 * velocidade ** 3 + 0.0000024 * velocidade ** 2 - 0.00019 * velocidade + 0.013))));
            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 12000 && velocidade >= 40 && velocidade <= 130 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '90mm ', 'PÁ 45°', itens[1]]);

            temp_rpm = (6400 * velocidade + 10000) / 140;
            temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (-0.00000000000346 * velocidade ** 4 + 0.00000000186 * velocidade ** 3 - 0.000000381 * velocidade ** 2 + 0.0000367 * velocidade + 0.00693))));
            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 12000 && velocidade >= 40 && velocidade <= 180 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '90mm ', 'PÁ 55°', itens[1]]);

            temp_rpm = (4750 * velocidade + 18000) / 160;
            temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (0.00000000000785 * velocidade ** 4 - 0.00000000462 * velocidade ** 3 + 0.00000103 * velocidade ** 2 - 0.000108 * velocidade + 0.0164))));
            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 12000 && velocidade >= 40 && velocidade <= 200 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '90mm ', 'PÁ 65°', itens[1]]);

            temp_rpm = (2300 * velocidade + 4000) / 160;
            temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (0.0000000000234 * velocidade ** 4 - 0.0000000137 * velocidade ** 3 + 0.00000303 * velocidade ** 2 - 0.000314 * velocidade + 0.0293))));
            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 10000 && velocidade >= 40 && velocidade <= 200 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '90mm', ' PÁ 75°', itens[1]]);


            temp_rpm = 43.333 * velocidade + 133.33;
            temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (0.00000000000138 * velocidade ** 4 - 0.000000000961 * velocidade ** 3 + 0.000000262 * velocidade ** 2 - 0.000035 * velocidade + 0.00711))));
            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 12000 && velocidade >= 80 && velocidade <= 200 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '70mm ', ' PÁ 55°', itens[1]]);

            temp_rpm = 30 * velocidade + 50;
            temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (-0.0000000000101 * velocidade ** 4 + 0.00000000703 * velocidade ** 3 - 0.00000191 * velocidade ** 2 + 0.000254 * velocidade - 0.0082))));
            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 12000 && velocidade >= 80 && velocidade <= 200 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '70mm', ' PÁ 65°', itens[1]]);

            temp_rpm = 18.333 * velocidade + 33.333;
            temp_rpm2 = (temp_rpm * (1 - (spray_rate_nozzles * (0.00000000000191 * velocidade ** 4 - 0.00000000133 * velocidade ** 3 + 0.000000361 * velocidade ** 2 - 0.000048 * velocidade + 0.00813))));
            list.push([itens[0], itens[2], 0,
            itens[3] && temp_rpm2 >= 2000 && temp_rpm2 <= 10000 && velocidade >= 80 && velocidade <= 200 && spray_rate_nozzles >= 0 && spray_rate_nozzles <= 20,
            itens[4], tabela_gota(2000000 * temp_rpm2 ** -1.0773), defaultValue, defaultValue, defaultValue, '70mm', ' PÁ 75°', itens[1]]);
        }

        ind = ret.length;
        ret.push({ fields: { type: 2, itens: ['Nozzle', 'Orifício', 'Angulo', 'P Tst ', 'Pressao (psi)', 'DSC', 'DV01', 'DV05', 'DV09', 'Tamanho Pá', 'Angulo Pá', 'Valvula'] }, rows: [], img: require('./../../assets/images/pics/travicar_atomizador.png') });
        temp_pressao = (spray_rate_nozzles >= 0.65 && spray_rate_nozzles < 0.85) ? (((spray_rate_nozzles - 0.25)) / 0.02) : ((spray_rate_nozzles >= 0.85 && spray_rate_nozzles <= 1.1) ? (((spray_rate_nozzles - 0.1)) / 0.025) : 0);
        temp_condicao = (spray_rate_nozzles >= 0.65 && spray_rate_nozzles < 0.85) || (spray_rate_nozzles >= 0.85 && spray_rate_nozzles <= 1.1);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 1', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 1 && spray_rate_nozzles <= 1.5) ? (((spray_rate_nozzles)) / 0.05) : ((spray_rate_nozzles > 1.5 && spray_rate_nozzles <= 1.81) ? (((spray_rate_nozzles - 0.57)) / 0.031) : 0);
        temp_condicao = (spray_rate_nozzles >= 1 && spray_rate_nozzles <= 1.5) || (spray_rate_nozzles > 1.5 && spray_rate_nozzles <= 1.81);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 2', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) ? ((-64.56 + (20 * spray_rate_nozzles)) / 3.024) : ((spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38) ? ((-101.6 + (20 * spray_rate_nozzles)) / 2.1) : 0);
        temp_condicao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) || (spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 3', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 1.9 && spray_rate_nozzles <= 2.8) ? (((spray_rate_nozzles - 0.1)) / 0.09) : ((spray_rate_nozzles > 2.8 && spray_rate_nozzles <= 3.6) ? (((spray_rate_nozzles - 0.4)) / 0.08) : 0);
        temp_condicao = (spray_rate_nozzles >= 1.9 && spray_rate_nozzles <= 2.8) || (spray_rate_nozzles > 2.8 && spray_rate_nozzles <= 3.6);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 4', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 3.2 && spray_rate_nozzles <= 3.6) ? (((spray_rate_nozzles - 2.4)) / 0.04) : ((spray_rate_nozzles > 3.6 && spray_rate_nozzles <= 5.9) ? (((spray_rate_nozzles + 3.3)) / 0.23) : 0);
        temp_condicao = (spray_rate_nozzles >= 3.2 && spray_rate_nozzles <= 3.6) || (spray_rate_nozzles > 3.6 && spray_rate_nozzles <= 5.9);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 5', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 4 && spray_rate_nozzles <= 6.8) ? (((spray_rate_nozzles + 1.6)) / 0.28) : ((spray_rate_nozzles > 6.8 && spray_rate_nozzles <= 8.8) ? (((spray_rate_nozzles - 0.8)) / 0.2) : 0);
        temp_condicao = (spray_rate_nozzles >= 4 && spray_rate_nozzles <= 6.8) || (spray_rate_nozzles > 6.8 && spray_rate_nozzles <= 8.8);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 6', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 6.5 && spray_rate_nozzles <= 9.8) ? (((spray_rate_nozzles + 0.1)) / 0.33) : ((spray_rate_nozzles > 9.8 && spray_rate_nozzles <= 12.3) ? (((spray_rate_nozzles - 2.3)) / 0.25) : 0);
        temp_condicao = (spray_rate_nozzles >= 6.5 && spray_rate_nozzles <= 9.8) || (spray_rate_nozzles > 9.8 && spray_rate_nozzles <= 12.3);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 7', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 7.8 && spray_rate_nozzles <= 11.2) ? (((spray_rate_nozzles - 1)) / 0.34) : ((spray_rate_nozzles > 11.2 && spray_rate_nozzles <= 17.4) ? (((spray_rate_nozzles + 7.4)) / 0.62) : 0);
        temp_condicao = (spray_rate_nozzles >= 7.8 && spray_rate_nozzles <= 11.2) || (spray_rate_nozzles > 11.2 && spray_rate_nozzles <= 17.4);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 8', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 10 && spray_rate_nozzles <= 16.3) ? (((spray_rate_nozzles + 2.6)) / 0.63) : ((spray_rate_nozzles > 16.3 && spray_rate_nozzles <= 21.3) ? (((spray_rate_nozzles - 1.3)) / 0.5) : 0);
        temp_condicao = (spray_rate_nozzles >= 10 && spray_rate_nozzles <= 16.3) || (spray_rate_nozzles > 16.3 && spray_rate_nozzles <= 21.3);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 9', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 10.5 && spray_rate_nozzles <= 13.8) ? (((spray_rate_nozzles - 3.9)) / 0.33) : ((spray_rate_nozzles > 13.8 && spray_rate_nozzles <= 23.9) ? (((spray_rate_nozzles + 16.5)) / 1.01) : 0);
        temp_condicao = (spray_rate_nozzles >= 10.5 && spray_rate_nozzles <= 13.8) || (spray_rate_nozzles > 13.8 && spray_rate_nozzles <= 23.9);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 10', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 11.5 && spray_rate_nozzles <= 17) ? (((spray_rate_nozzles - 0.5)) / 0.55) : ((spray_rate_nozzles > 17 && spray_rate_nozzles <= 26.4) ? (((spray_rate_nozzles + 11.2)) / 0.94) : 0);
        temp_condicao = (spray_rate_nozzles >= 11.5 && spray_rate_nozzles <= 17) || (spray_rate_nozzles > 17 && spray_rate_nozzles <= 26.4);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM V.R.U ESFERA', 'VRU 11', temp_condicao, temp_pressao]);



        temp_pressao = (spray_rate_nozzles >= 1.95 && spray_rate_nozzles < 2.41) ? ((spray_rate_nozzles - 1.024) / 0.0461) : ((spray_rate_nozzles >= 2.41 && spray_rate_nozzles <= 2.78) ? ((spray_rate_nozzles - 1.282) / 0.0375) : 0);
        temp_condicao = (spray_rate_nozzles >= 1.95 && spray_rate_nozzles < 2.41) || (spray_rate_nozzles >= 2.41 && spray_rate_nozzles <= 2.78);
        //ret[ind].rows.push(['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 5', temp_condicao, 0, temp_pressao]);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 5', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 3.02 && spray_rate_nozzles <= 3.53) ? ((spray_rate_nozzles - 1.9907) / 0.0514) : ((spray_rate_nozzles > 3.53 && spray_rate_nozzles <= 4.2) ? ((spray_rate_nozzles - 1.5367) / 0.0665) : 0);
        temp_condicao = (spray_rate_nozzles >= 3.02 && spray_rate_nozzles <= 3.53) || (spray_rate_nozzles > 3.53 && spray_rate_nozzles <= 4.2);
        //ret[ind].rows.push(['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 6', temp_condicao, 0, temp_pressao]);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 6', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 3.99 && spray_rate_nozzles <= 4.6) ? ((spray_rate_nozzles - 2.7547) / 0.0616) : ((spray_rate_nozzles > 4.6 && spray_rate_nozzles <= 5.37) ? ((spray_rate_nozzles - 2.2867) / 0.0772) : 0);
        temp_condicao = (spray_rate_nozzles >= 3.99 && spray_rate_nozzles <= 4.6) || (spray_rate_nozzles > 4.6 && spray_rate_nozzles <= 5.37);
        //ret[ind].rows.push(['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 7', temp_condicao, 0, temp_pressao]);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 7', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 4.86 && spray_rate_nozzles <= 5.96) ? ((spray_rate_nozzles - 2.6667) / 0.1099) : ((spray_rate_nozzles > 5.96 && spray_rate_nozzles <= 6.89) ? ((spray_rate_nozzles - 3.1917) / 0.0924) : 0);
        temp_condicao = (spray_rate_nozzles >= 4.86 && spray_rate_nozzles <= 5.96) || (spray_rate_nozzles > 5.96 && spray_rate_nozzles <= 6.89);
        //ret[ind].rows.push(['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 8', temp_condicao, 0, temp_pressao]);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 8', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 7.31 && spray_rate_nozzles <= 8.85) ? ((spray_rate_nozzles - 4.213) / 0.1547) : ((spray_rate_nozzles > 8.85 && spray_rate_nozzles <= 10.02) ? ((spray_rate_nozzles - 5.351) / 0.1168) : 0);
        temp_condicao = (spray_rate_nozzles >= 7.31 && spray_rate_nozzles <= 8.85) || (spray_rate_nozzles > 8.85 && spray_rate_nozzles <= 10.02);
        //ret[ind].rows.push(['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 10', temp_condicao, 0, temp_pressao]);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 10', temp_condicao, temp_pressao]);

        temp_pressao = (spray_rate_nozzles >= 9.86 && spray_rate_nozzles <= 11.95) ? ((spray_rate_nozzles - 5.677) / 0.209) : ((spray_rate_nozzles > 11.95 && spray_rate_nozzles <= 12.32) ? ((spray_rate_nozzles - 10.838) / 0.037) : 0);
        temp_condicao = (spray_rate_nozzles >= 9.86 && spray_rate_nozzles <= 11.95) || (spray_rate_nozzles > 11.95 && spray_rate_nozzles <= 12.32);
        //ret[ind].rows.push(['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 12', temp_condicao, 0, temp_pressao]);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 12', temp_condicao, temp_pressao]);

        //ret[ind].rows.push(['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 14', (spray_rate_nozzles >= 12.56 && spray_rate_nozzles <= 14.75), 0,
        //((spray_rate_nozzles - 5.9947) / 0.2188)]);
        pAngTela(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'VALVULA COM DISCO', 'D - 14', (spray_rate_nozzles >= 12.56 && spray_rate_nozzles <= 14.75),
            ((spray_rate_nozzles - 5.9947) / 0.2188)]);


        let pAngDisco = (list, itens) => {
            //let temp_rpm = (velocidade - 20.911) / 0.0068;
            list.push(
                [itens[0], itens[1], itens[2],
                itens[3] && velocidade >= 90 && velocidade <= 140,
                itens[4], tabela_gota(80), itens[6], itens[7], itens[8], itens[9], 'PÁ 35°', itens[11]]);

            //temp_rpm = (velocidade - 24.718) / 0.0116;
            list.push(
                [itens[0], itens[1], itens[2],
                itens[3] && velocidade >= 90 && velocidade <= 140,
                itens[4], tabela_gota(120), itens[6], itens[7], itens[8], itens[9], 'PÁ 45°', itens[11]]);

            //temp_rpm = (velocidade - 28.816) / 0.0198;
            list.push(
                [itens[0], itens[1], itens[2],
                itens[3] && velocidade >= 90 && velocidade <= 140,
                itens[4], tabela_gota(180), itens[6], itens[7], itens[8], itens[9], 'PÁ 60°', itens[11]]);

            //temp_rpm = (velocidade - 31.684) / 0.0396;
            list.push(
                [itens[0], itens[1], itens[2],
                itens[3] && velocidade >= 90 && velocidade <= 140,
                itens[4], tabela_gota(220), itens[6], itens[7], itens[8], itens[9], 'PÁ 75°', itens[11]]);

        }
        ind = ret.length;
        ret.push({ fields: { type: 3, itens: ['Nozzle', 'Orifício', 'Angulo', 'P Tst ', 'Pressao (psi)', 'DSC', 'DV01', 'DV05', 'DV09', 'Tamanho Pá', 'Angulo Pá', 'Valvula'] }, rows: [], img: require('./../../assets/images/pics/atomizador_rotativo_disco.png') });
        temp_pressao = (spray_rate_nozzles >= 1.75 && spray_rate_nozzles < 2.58) ? ((-9.14 + (10 * spray_rate_nozzles)) / 0.416) : ((spray_rate_nozzles >= 2.58 && spray_rate_nozzles <= 3.17) ? ((-28 + (20 * spray_rate_nozzles)) / 0.59) : 0);
        temp_condicao = (spray_rate_nozzles >= 1.75 && spray_rate_nozzles < 2.58) || (spray_rate_nozzles >= 2.58 && spray_rate_nozzles <= 3.17);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'O - 1', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM V.R.U']);

        temp_pressao = (spray_rate_nozzles >= 2.73 && spray_rate_nozzles <= 4.01) ? ((-14.43 + (10 * spray_rate_nozzles)) / 0.643) : ((spray_rate_nozzles > 4.01 && spray_rate_nozzles <= 5) ? ((-40.6 + (20 * spray_rate_nozzles)) / 0.99) : 0);
        temp_condicao = (spray_rate_nozzles >= 2.73 && spray_rate_nozzles <= 4.01) || (spray_rate_nozzles > 4.01 && spray_rate_nozzles <= 5);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'O - 2', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM V.R.U']);

        temp_pressao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) ? ((-64.56 + (20 * spray_rate_nozzles)) / 3.024) : ((spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38) ? ((-101.6 + (20 * spray_rate_nozzles)) / 2.1) : 0);
        temp_condicao = (spray_rate_nozzles >= 6.25 && spray_rate_nozzles <= 9.28) || (spray_rate_nozzles > 9.28 && spray_rate_nozzles <= 11.38);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'O - 3', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM V.R.U']);

        temp_pressao = (spray_rate_nozzles >= 8.52 && spray_rate_nozzles <= 11.06) ? ((-34.4 + (10 * spray_rate_nozzles)) / 2.54) : ((spray_rate_nozzles > 11.06 && spray_rate_nozzles < 14.26) ? ((-125.2 + (20 * spray_rate_nozzles)) / 3.24) : ((spray_rate_nozzles >= 14.26 && spray_rate_nozzles <= 15.62) ? ((-74.6 + (10 * spray_rate_nozzles)) / 1.36) : 0));
        temp_condicao = (spray_rate_nozzles >= 8.52 && spray_rate_nozzles <= 11.06) || (spray_rate_nozzles >= 14.26 && spray_rate_nozzles <= 15.62) || (spray_rate_nozzles > 11.06 && spray_rate_nozzles < 14.26);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'O - 4', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM V.R.U']);


        temp_pressao = (spray_rate_nozzles >= 1.95 && spray_rate_nozzles < 2.41) ? ((spray_rate_nozzles - 1.024) / 0.0461) : ((spray_rate_nozzles >= 2.41 && spray_rate_nozzles <= 2.78) ? ((spray_rate_nozzles - 1.282) / 0.0375) : 0);
        temp_condicao = (spray_rate_nozzles >= 1.95 && spray_rate_nozzles < 2.41) || (spray_rate_nozzles >= 2.41 && spray_rate_nozzles <= 2.78);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'D - 5', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM DISCO']);

        temp_pressao = (spray_rate_nozzles >= 3.02 && spray_rate_nozzles <= 3.53) ? ((spray_rate_nozzles - 1.9907) / 0.0514) : ((spray_rate_nozzles > 3.53 && spray_rate_nozzles <= 4.2) ? ((spray_rate_nozzles - 1.5367) / 0.0665) : 0);
        temp_condicao = (spray_rate_nozzles >= 3.02 && spray_rate_nozzles <= 3.53) || (spray_rate_nozzles > 3.53 && spray_rate_nozzles <= 4.2);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'D - 6', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM DISCO']);

        temp_pressao = (spray_rate_nozzles >= 3.99 && spray_rate_nozzles <= 4.6) ? ((spray_rate_nozzles - 2.7547) / 0.0616) : ((spray_rate_nozzles > 4.6 && spray_rate_nozzles <= 5.37) ? ((spray_rate_nozzles - 2.2867) / 0.0772) : 0);
        temp_condicao = (spray_rate_nozzles >= 3.99 && spray_rate_nozzles <= 4.6) || (spray_rate_nozzles > 4.6 && spray_rate_nozzles <= 5.37);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'D - 7', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM DISCO']);

        temp_pressao = (spray_rate_nozzles >= 4.86 && spray_rate_nozzles <= 5.96) ? ((spray_rate_nozzles - 2.6667) / 0.1099) : ((spray_rate_nozzles > 5.96 && spray_rate_nozzles <= 6.89) ? ((spray_rate_nozzles - 3.1917) / 0.0924) : 0);
        temp_condicao = (spray_rate_nozzles >= 4.86 && spray_rate_nozzles <= 5.96) || (spray_rate_nozzles > 5.96 && spray_rate_nozzles <= 6.89);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'D - 8', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM DISCO']);

        temp_pressao = (spray_rate_nozzles >= 7.31 && spray_rate_nozzles <= 8.85) ? ((spray_rate_nozzles - 4.213) / 0.1547) : ((spray_rate_nozzles > 8.85 && spray_rate_nozzles <= 10.02) ? ((spray_rate_nozzles - 5.351) / 0.1168) : 0);
        temp_condicao = (spray_rate_nozzles >= 7.31 && spray_rate_nozzles <= 8.85) || (spray_rate_nozzles > 8.85 && spray_rate_nozzles <= 10.02);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'D - 10', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM DISCO']);

        temp_pressao = (spray_rate_nozzles >= 9.86 && spray_rate_nozzles <= 11.95) ? ((spray_rate_nozzles - 5.677) / 0.209) : ((spray_rate_nozzles > 11.95 && spray_rate_nozzles <= 12.32) ? ((spray_rate_nozzles - 10.838) / 0.037) : 0);
        temp_condicao = (spray_rate_nozzles >= 9.86 && spray_rate_nozzles <= 11.95) || (spray_rate_nozzles > 11.95 && spray_rate_nozzles <= 12.32);
        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'D - 12', 0, temp_condicao, temp_pressao, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM DISCO']);

        pAngDisco(ret[ind].rows, ['ATOMIZADOR ROTATIVO', 'D - 14', 0, (spray_rate_nozzles >= 12.56 && spray_rate_nozzles <= 14.75),
            ((spray_rate_nozzles - 5.9947) / 0.2188), defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, defaultValue, 'VALVULA COM DISCO']);
    }

    let lista = ret.filter((item) => {
        if (dsc !== 99) {
            item.rows = item.rows.filter((i2) => i2[5] === dsc_label[dsc] || i2[5] === undefined);
            return item.rows.length > 0;
        } else {
            return dsc === 99;
        }
    });
    return lista;
};

export default calc_data;
