import React from 'react';//, { useState, useEffect }

export default function Customizeditem(props) {
  const { value, label, duration, color } = props;
  //const dur = duration ? duration : '.2s';
  const c = color ? color : value < 10 ? '#26c326' : value < 30 ? '#e0bc07' : '#df3737';

  const rotateR = value >= 50 ? 0 : 180 + (180 * value / 50);
  const rotateL = value <= 50 ? 180 : value >= 100 ? 0 : 180 + (180 * (value - 50) / 50);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
      <div style={{ position: 'absolute', top: '0', left: '50%', width: '50%', height: '100%', overflow: 'hidden' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%', transform: `rotate(${rotateR}deg)`, transformOrigin: '0% 50%', overflow: 'hidden' }}>
          <div style={{ width: '200%', height: '100%', marginLeft: '-100%', border: `solid 3px ${c}`, borderRadius: '50%' }}></div>
        </div>
      </div>
      <div style={{ position: 'absolute', top: '0', left: '0', width: '50%', height: '100%', overflow: 'hidden' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%', transform: `rotate(${rotateL}deg)`, transformOrigin: '100% 50%', overflow: 'hidden' }}>
          <div style={{ width: '200%', height: '100%', border: `solid 3px ${c}`, borderRadius: '50%' }}></div>
        </div>
      </div>
      <div style={{ position: 'absolute', left: '50%', top: '20%' }}><div style={{ position: 'relative', left: '-50%', top: '.1rem' }}>{label.length < 2 ? '&nbsp;' + label + '&nbsp;' : label}</div></div>
    </div >
  );
}

/**
 * 
 export default function Customizeditem(props) {
  const { value, duration, color } = props;
  const dur = duration ? duration : '.2s';
  const c = color ? color : value > 50 ? '#26c326' : '#df3737';

  const rotateR = value >= 50 ? 180 : 180 * value / 50;
  const rotateL = value <= 50 ? 0 : value >= 100 ? 180 : (180 * (value - 50) / 50);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%', border: `solid 2px ${c}`, borderRadius: '50%' }}></div>
      <div style={{ width: '50%', height: '100%', position: 'absolute', top: '0rem', left: '50%', transform: `rotate(${rotateR}deg)`, transformOrigin: '0% 50%' }}></div>
      <div style={{ width: '50%', height: '100%', position: 'absolute', top: '0rem', overflow: 'hidden' }}>
        <div style={{ width: '200%', height: '100%', position: 'absolute', top: '0rem', border: `solid 2px ${c}`, borderRadius: '50%' }}></div>
      </div>
      <div style={{ width: '50%', height: '100%', position: 'absolute', top: '0rem', transform: `rotate(${rotateL}deg)`, transformOrigin: '100% 50%' }}></div>
      <div style={{ position: 'absolute', left: '50%', top: '20%' }}><div style={{ position: 'relative', left: '-50%' }}>{value.length < 2 ? '&nbsp;' + value + '&nbsp;' : value}</div></div>
    </div>
  );
}
 */