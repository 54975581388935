import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import MaterialIcon from '../MaterialIcon';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";

import SPW from "string-pixel-width";
//import { InsertLinkRounded } from '@material-ui/icons';

class AppList extends React.Component {
    state = {
        listFim: [],
        selectIni: [],
        selectFim: [],
        filtro: '',
        page: 0,

    };

    rowsPerPage = 50;
    lan = '';

    d = {
        zIndex: this.props.index + 1,
    };

    b = {
        textAlign: 'center',
        margin: 'auto',
        padding: '10px'
    };

    shift = false;

    lastClick = { guid: '', time: 0 };
    intervalClick = 300;

    componentDidMount() {

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open) {
            if(this.props.selected) this.selecedItens(this.props.selected);
        }
    }

    moveRight = (item) => {
        let list = this.state.listFim;
        if (item) {
            list.push(item);
        } else {
            list = list.concat(this.state.selectIni);
        }

        this.setState({ selectIni: [], listFim: list });
    }
    moveLeft = (item) => {
        let list = this.state.listFim;
        if (item) {
            let index = list.indexOf(item);
            if (index => 0) {
                list.splice(index, 1);
            }
        } else {
            for (let i = 0; i < this.state.selectFim.length; i++) {
                let index = list.indexOf(this.state.selectFim[i]);
                if (index => 0) {
                    list.splice(index, 1);
                }
            }
        }

        this.setState({ selectFim: [], listFim: list });
    }

    clickLeft = (item) => {
        let list = this.state.selectIni;
        if (this.shift) {
            let lastGuid = this.state.selectIni[this.state.selectIni.length - 1];

            let indexLast = lastGuid;
            let indexNow = item;

            if (indexNow > indexLast) {
                let c = indexNow;
                indexNow = indexLast;
                indexLast = c;
            }
            for (let i = indexNow; i <= indexLast; i++) {
                if (!this.state.selectIni.includes(i)) {
                    list.push(i);
                }
            }
        } else {
            if (this.lastClick.guid === item && this.lastClick.time > (Date.now() - this.intervalClick)) {
                this.moveRight(item);
            }
            this.lastClick.guid = item;
            this.lastClick.time = Date.now();

            let index = list.indexOf(item);
            if (index > -1) {
                list.splice(index, 1);
            } else {
                list.push(item);
            }
        }
        this.shift = false;
        this.setState({ selectIni: list });
    }
    clickRight = (item) => {
        let list = this.state.selectFim;

        if (this.lastClick.guid === item && this.lastClick.time > (Date.now() - this.intervalClick)) {
            this.moveLeft(item);
            return;
        }
        this.lastClick.guid = item;
        this.lastClick.time = Date.now();

        const index = list.indexOf(item);
        if (index > -1) {
            list.splice(index, 1);
        } else {
            list.push(item);
        }
        this.setState({ selectFim: list });
    }

    handleSelectAllIni = (event, checked) => {
        if (checked) {
            //let list = [];
            let p = this.props.itens.filter((item) => {
                return !(
                    this.state.listFim.includes(item.index)
                ) && (item.name.toUpperCase().includes(this.state.filtro.toUpperCase()) || this.state.filtro === '');
            })
                .slice(this.state.page * this.rowsPerPage, this.state.page * this.rowsPerPage + this.rowsPerPage)
                .map(n => n.index);
            this.setState({ selectIni: p });
            return;
        }
        this.setState({ selectIni: [] });
    }
    handleSelectAllFim = (event, checked) => {
        if (checked) {
            let list = [];
            let p = this.props.itens.filter((item) => {
                return (
                    this.state.listFim.includes(item.index)
                );
            });
            for (let i = 0; i < p.length; i++) {
                list.push(p[i].index);
            }
            this.setState({ selectFim: list });
            return;
        }
        this.setState({ selectFim: [] });
    }

    limitChar = (string, limit) => {
        let ret = string;
        let size = SPW(string, { font: "Arial", size: 16 });
        if (size > limit) {
            ret = string.slice(0, (string.length * (limit - 16) / size)) + '...';
        }
        return ret;
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            page: 0
        });
    };

    limpaCampo = () => {
        document.getElementById('campoFiltro').value = '';
        this.clear();
    }

    clear = () => {
        this.setState({
            filtro: ''
        });
    };

    changePage = (to) => {
        if (to === 'P') {
            this.setState({ page: this.state.page - 1 });
        } else if (to === 'N') {
            this.setState({ page: this.state.page + 1 });
        }
    }

    keyDown = (e) => {
        if (e.keyCode === 16) {
            this.shift = true;
        }
    }

    keyUp = (e) => {
        this.shift = false;
    }

    selecedItens = (selected) => {
        if (this.props.open) {
            let listFim = [];
            for (let i = 0; i < selected.length; i++) {
                const element = this.props.itens.filter(item => selected[i].id === item.id);
                if (element.length > 0) {
                    listFim.push(element[0].index);
                }
            }
            this.setState({ listFim: listFim, selectIni: [], selectFim: [], });
        }
    }

    render() {
        const { listFilter, open, index, itens, res, shift, onClose } = this.props;

        return (
            <div className="back-quest" style={{
                zIndex: index,
                display: open ? 'flex' : 'none'
            }} >
                <div className="divContentList div-select-f" style={this.d}>
                    <div className="divContentList list-p-filter" style={{ gridTemplateRows: '5rem auto 2.3rem' }}>
                        <div style={{ padding: '.5rem', position: 'relative' }} className="gridFiltro">
                            <div style={{ width: '50%' }}>{res.ITENS}{shift ? '1' : '2'}</div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                                <Checkbox
                                    disableRipple
                                    className="checkMap"
                                    indeterminate={this.state.selectIni.length > 0 && this.state.selectIni.length < itens.filter((item) => {
                                        return !(
                                            this.state.listFim.includes(item.index)
                                        ) && (item.name.toUpperCase().includes(this.state.filtro.toUpperCase()) || this.state.filtro === '');
                                    }).length}
                                    checked={this.state.selectIni.length === itens.filter((item) => {
                                        return !(
                                            this.state.listFim.includes(item.index)
                                        ) && (item.name.toUpperCase().includes(this.state.filtro.toUpperCase()) || this.state.filtro === '');
                                    }).slice(this.state.page * this.rowsPerPage, this.state.page * this.rowsPerPage + this.rowsPerPage).length}
                                    onChange={this.handleSelectAllIni}
                                />
                            </div>
                            <div style={{ width: '14rem' }}>
                                <SearchIcon style={{ marginRight: '.5rem', marginTop: '1.5rem' }} />
                                <TextField
                                    placeholder={res.FILTRO}
                                    margin="normal"
                                    onChange={this.handleChange('filtro')}
                                    id="campoFiltro"
                                    type="search"
                                />
                                {(this.state.filtro === '') ? <div></div> :
                                    <MaterialIcon icon="clear" style={{ position: 'absolute', top: '2.3rem', right: '1.2rem', cursor: 'pointer' }} onClick={() => this.limpaCampo()} />
                                }
                            </div>
                        </div>
                        <div className="div-list_a">
                            <List

                                onKeyDown={this.keyDown}
                                onKeyUp={this.keyUp}
                                component="nav"
                                aria-labelledby="areas"
                                style={{ position: 'absolute', width: '100%' }}
                            >
                                {itens
                                    .filter((item) => {
                                        return !(
                                            this.state.listFim.includes(item.index)
                                        ) && (item.name.toUpperCase().includes(this.state.filtro.toUpperCase()));
                                    })
                                    .slice(this.state.page * this.rowsPerPage, this.state.page * this.rowsPerPage + this.rowsPerPage)
                                    .map((item, index) => {
                                        return (
                                            <ListItem key={index} button
                                                className={(this.state.selectIni.indexOf(item.index) >= 0) ? 'SelItem' : ''}
                                                onClick={event => this.clickLeft(item.index)}
                                                title={item.name}
                                            >
                                                <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                                {
                                                    item.x && <div style={{ height: '1rem', width: '.5rem', backgroundColor: this.color_x }}></div>
                                                }
                                            </ListItem>
                                        )
                                    })}
                            </List>
                        </div>
                        <div className="page-filter">
                            <div>
                                <Button className="buttonS buttonMenu" disabled={this.state.page < 1} onClick={event => this.changePage('P')} title={res.DELETAR_POLY}>
                                    <MaterialIcon icon="chevron_left" className="iconMap" />
                                </Button>
                            </div>
                            <div style={{ textAlign: 'center', padding: '.5rem' }} >{this.state.page + 1}/{Math.ceil(itens.filter((item) => {
                                return !(
                                    this.state.listFim.includes(item.index)
                                ) && (item.name.toUpperCase().includes(this.state.filtro.toUpperCase()));
                            }).length / this.rowsPerPage)}</div>
                            <div>
                                <Button className="buttonS buttonMenu" disabled={(this.state.page + 1) >= Math.ceil(itens.filter((item) => {
                                    return !(
                                        this.state.listFim.includes(item.index)
                                    ) && (item.name.toUpperCase().includes(this.state.filtro.toUpperCase()));
                                }).length / this.rowsPerPage)} onClick={event => this.changePage('N')} title={res.DELETAR_POLY}>
                                    <MaterialIcon icon="chevron_right" className="iconMap" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={this.b}>
                        <Button className="buttonS buttonMenu" disabled={false} onClick={event => this.moveRight()} title={res.EDITAR_NOME}>
                            <MaterialIcon icon="navigate_next" className="iconMap" />
                        </Button>
                        <Button className="buttonS buttonMenu" disabled={false} onClick={event => this.moveLeft()} title={res.EDITAR_NOME}>
                            <MaterialIcon icon="navigate_before" className="iconMap" />
                        </Button>

                    </div>
                    <div className="divContentList list-p">
                        <div style={{ padding: '.5rem', display: 'flex' }}>
                            <div style={{ width: '50%' }}>
                                <Checkbox
                                    disableRipple
                                    className="checkMap"
                                    indeterminate={this.state.selectFim.length > 0 && this.state.selectFim.length < itens.filter((item) => {
                                        return (
                                            this.state.listFim.includes(item.index)
                                        );
                                    }).length}
                                    checked={this.state.selectFim.length === itens.filter((item) => {
                                        return (
                                            this.state.listFim.includes(item.index)
                                        );
                                    }).length}
                                    onChange={this.handleSelectAllFim}
                                />
                            </div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                                <Button type="submit" variant="contained" color={this.state.listFim.length > 0 ? "primary" : "default"} className="popUpButton" onClick={e => {
                                    listFilter(itens.filter((item) => {
                                        return (
                                            this.state.listFim.includes(item.index)
                                        );
                                    }));
                                }}>
                                    {this.state.listFim.length > 0 ? res.IMPORTAR : res.CANCELAR}
                                </Button>
                            </div>
                        </div>
                        <div className="div-list_a">
                            <List
                                component="nav"
                                aria-labelledby="areas"
                                style={{ position: 'absolute', width: '100%' }}
                            >
                                {itens
                                    .filter((item) => {
                                        return (
                                            this.state.listFim.includes(item.index)
                                        );
                                    })
                                    .map((item, index) => {
                                        return (
                                            <ListItem key={index} button
                                                className={(this.state.selectFim.indexOf(item.index) >= 0) ? 'SelItem' : ''}
                                                onClick={event => this.clickRight(item.index)}
                                                title={item.name}
                                            >
                                                <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                                {
                                                    item.x && <div style={{ height: '1rem', width: '.5rem', backgroundColor: this.color_x }}></div>
                                                }
                                            </ListItem>
                                        )
                                    })}
                            </List>
                        </div>
                    </div>
                </div>
                <div className="div-select" onClick={onClose}></div>
            </div>
        )
    }
}

export default AppList;
