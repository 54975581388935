import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import Skeleton from '@material-ui/lab/Skeleton';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';

import { notification } from 'antd';

import { useParams } from "react-router";


const styles = theme => ({});

class List extends React.Component {
    state = {
        jobs: [],
        expandedYears: {},
        filteredJobs: [],
        selectedMonths: {},
        reports: [],
        selectedJobs: new Set(),
        selectedJobsByYear: {},
        downloading: null,
        isLoading: true,
    };

    componentDidMount() {
        this.readJobDetail();
        this.readReports();

        const savedSelectedJobsByYearString = localStorage.getItem('selectedJobsByYear');
        if (savedSelectedJobsByYearString) {
            const savedSelectedJobsByYear = JSON.parse(savedSelectedJobsByYearString);

            if (savedSelectedJobsByYear && typeof savedSelectedJobsByYear === 'object') {
                const selectedJobsByYear = Object.fromEntries(
                    Object.entries(savedSelectedJobsByYear).map(([year, jobArray]) => {
                        if (Array.isArray(jobArray)) {
                            return [year, new Set(jobArray)];
                        } else {
                            return [year, new Set()];
                        }
                    })
                );

                this.setState({ selectedJobsByYear });
            }
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    };

    readJobDetail = () => {
        const apiUrl = `${Config.server}/job_detail`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then((result) => {
                this.setState(prevState => {
                    const updatedSelectedJobsByYear = { ...prevState.selectedJobsByYear };
                    result.forEach(job => {
                        const year = new Date(job.startDate).getFullYear();
                        if (!updatedSelectedJobsByYear[year]) {
                            updatedSelectedJobsByYear[year] = new Set();
                        }
                    });
                    return { jobs: result, selectedJobsByYear: updatedSelectedJobsByYear, isLoading: false }
                })
            })
            .catch((error) => {
                if (error.status === 405) {
                    notification.open({
                        message: this.props.res.PERMISSAO,
                        description: this.props.res.PERMISSAO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                } else if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                } else {
                    notification.open({
                        message: this.props.res.ERRO,
                        description: this.props.res.ERRO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                }
            });
    };

    toggleYearExpand = (year) => {
        this.setState(prevState => ({
            expandedYears: {
                ...prevState.expandedYears,
                [year]: !prevState.expandedYears[year],
            },
        }));
    };

    renderMonths = (year) => {
        const { res, link } = this.props;

        const selectedMonth = this.state.selectedMonths[year];
        let filteredJobs = this.state.jobs.filter((job) => {
            const jobDate = new Date(job.startDate);
            return jobDate.getFullYear() === year && jobDate.getMonth() === selectedMonth;
        });
        filteredJobs = filteredJobs.sort((a, b) => {
            const endDateA = new Date(a.endDate);
            const endDateB = new Date(b.endDate);
            return endDateB - endDateA;
        });
        const jobIdsWithReports = filteredJobs.filter(job => this.state.reports.some(report => report.id_job === job.id)).map(job => job.id);
        const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
        const areAllJobsSelected = jobIdsWithReports.length > 0 && jobIdsWithReports.every(id => this.state.selectedJobsByYear[year]?.has(id));

        return (
            <TableRow key={`months-${year}`}>
                <TableCell colSpan={9} style={{ textAlign: 'center', padding: '0.5rem 1rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ paddingTop: '0.7rem' }}
                                onClick={() => this.downloadRelMAPA(year)}
                                className="popUpButton"
                                title={res.BAIXAR_RELATORIO_ANUAL}
                                disabled={this.state.downloading === year}
                            >
                                {this.state.downloading === year ? <CircularProgress size={24} /> : <GetAppIcon className="iconMapS" />}
                            </Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {months.map((month, index) => (
                                <Button
                                    key={`${year}-${month}`}
                                    variant='contained'
                                    style={{ margin: '0.5rem', fontWeight: '600' }}
                                    onClick={() => this.handleMonthClick(year, index)}
                                    color={selectedMonth === index ? 'primary' : 'default'}
                                >
                                    {month}
                                </Button>
                            ))}
                        </div>
                        {selectedMonth !== undefined ? (
                            filteredJobs.length > 0 ? (
                                <Table style={{ marginTop: '2rem' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={jobIdsWithReports.length > 0 && !areAllJobsSelected}
                                                    checked={areAllJobsSelected}
                                                    onChange={this.handleSelectAllJobs(year, selectedMonth)}
                                                    disabled={jobIdsWithReports.length === 0}
                                                />
                                            </TableCell>
                                            <TableCell>{res.TITULO}</TableCell>
                                            <TableCell>{res.CLIENTE}</TableCell>
                                            <TableCell>{res.AREA_EXECUTADA}</TableCell>
                                            <TableCell>{res.DATA_INICIO}</TableCell>
                                            <TableCell>{res.DATA_FIM}</TableCell>
                                            <TableCell>{res.AERONAVE}</TableCell>
                                            <TableCell>{res.PILOTO}</TableCell>
                                            <TableCell>{res.ACOES}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredJobs.map((job) => {
                                            const formatTimestampToDateTime = (timestamp) => {
                                                var date = new Date(timestamp);
                                                return (date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()) + " "
                                                    + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
                                            };
                                            const isReportAvailable = this.state.reports.some(report => report.id_job === job.id);

                                            return (
                                                <TableRow key={job.id}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={this.state.selectedJobsByYear[year] && this.state.selectedJobsByYear[year].has(job.id) && isReportAvailable}
                                                            onChange={() => this.handleSelectJob(year, job.id)}
                                                            disabled={!isReportAvailable}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{job.title}</TableCell>
                                                    <TableCell>{job.client_name}</TableCell>
                                                    <TableCell>{job.spray ? `${job.spray} ha` : null}</TableCell>
                                                    <TableCell>{formatTimestampToDateTime(job.startDate)}</TableCell>
                                                    <TableCell>{formatTimestampToDateTime(job.endDate)}</TableCell>
                                                    <TableCell>{job.plane_name}</TableCell>
                                                    <TableCell>{job.pilot_name}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            style={{ marginRight: '0.5rem' }}
                                                            variant="contained"
                                                            color={isReportAvailable ? "primary" : "inherit"}
                                                            title={res.GERAR_RELATORIO}
                                                            onClick={() => (link('/app/relatorio-mapa/edit/' + job.id))}
                                                        >
                                                            <DescriptionIcon />
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color={job.type === 'report' || job.type === 'analise' ? "primary" : "inherit"}
                                                            style={{ cursor: (job.type === 'report' || job.type === 'analise') ? 'pointer' : 'not-allowed' }}
                                                            onClick={() => (
                                                                job.type === 'report' ? link(`/app/relatorio/${job.guid}`) :
                                                                    job.type === 'analise' ? link(`/app/qualidade/${job.guid}`) :
                                                                        null
                                                            )}
                                                            title={res.IR_RELATORIO}
                                                        >
                                                            <FindInPageIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            ) : (
                                <div style={{ marginTop: '2rem' }}>{res.SEM_TRABALHOS}</div>
                            )
                        ) : (
                            <div style={{ marginTop: '2rem' }}>{res.SELECIONAR_MES}</div>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    handleMonthClick = (year, index) => {
        this.setState(prevState => {
            const yearSelected = prevState.selectedMonths[year] === index ? null : index;
            const updatedSelectedMonths = {
                ...prevState.selectedMonths,
                [year]: yearSelected,
            };

            return { selectedMonths: updatedSelectedMonths };
        });
    };

    readReports = () => {
        const apiUrl = `${Config.server}/reports_mapa`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then((result) => {
                this.setState(prevState => {
                    const selectedJobsByYear = { ...prevState.selectedJobsByYear };
                    result.forEach(report => {
                        const job = prevState.jobs.find(job => job.id === report.id_job);
                        if (job) {
                            const year = new Date(job.startDate).getFullYear();
                            if (!selectedJobsByYear[year]) {
                                selectedJobsByYear[year] = new Set();
                            }
                            selectedJobsByYear[year].add(job.id);
                        }
                    });

                    return {
                        reports: result,
                        selectedJobsByYear: selectedJobsByYear,
                    };
                });
            })
            .catch((error) => {
                if (error.status === 405) {
                    notification.open({
                        message: this.props.res.PERMISSAO,
                        description: this.props.res.PERMISSAO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                } else if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                }
            });
    };

    getYear = (jobs) => {
        const years = new Set();
        jobs.forEach(job => {
            if (job.startDate) {
                years.add(new Date(job.startDate).getFullYear());
            }
        });
        return Array.from(years);
    };

    downloadRelMAPA = (year) => {
        this.setState({ downloading: year });
        let apiUrl = Config.server + '/relmapaPlanilha';
        const formData = new URLSearchParams();

        const selectedYearJobs = this.state.jobs.filter(job =>
            this.state.selectedJobsByYear[year] &&
            this.state.selectedJobsByYear[year].has(job.id) &&
            new Date(job.startDate).getFullYear() === year
        );
        const filteredReports = this.state.reports.filter(report =>
            selectedYearJobs.find(job => job.id === report.id_job)
        );

        formData.append('jobData', JSON.stringify(selectedYearJobs));
        formData.append('jobReport', JSON.stringify(filteredReports));
        formData.append('year', year);

        let options = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt'),
            }
        };

        fetch(apiUrl, options)
            .then(res => {
                if (res.status !== 200) throw res;
                return res.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Relatorio-MAPA-${year}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                this.setState({ downloading: null });
            })
            .catch((error) => {
                notification.open({
                    message: this.props.res.ERRO,
                    description: this.props.res.ERRO_MSG,
                    icon: <MaterialIcon icon="error" className="text-danger" />
                });
                this.setState({ downloading: null });
            });
    };

    handleSelectJob = (year, jobId) => {
        this.setState(prevState => {
            const updatedJobSet = new Set(prevState.selectedJobsByYear[year] || new Set());

            if (updatedJobSet.has(jobId)) {
                updatedJobSet.delete(jobId);
            } else {
                updatedJobSet.add(jobId);
            }

            const newSelectedJobsByYear = { ...prevState.selectedJobsByYear, [year]: updatedJobSet };

            localStorage.setItem('selectedJobsByYear', JSON.stringify(
                Object.fromEntries(
                    Object.entries(newSelectedJobsByYear).map(([year, jobSet]) => [year, Array.from(jobSet)])
                )
            ));

            return {
                selectedJobsByYear: newSelectedJobsByYear,
            };
        });
    };

    handleSelectAllJobs = (year, month) => event => {
        const isChecked = event.target.checked;
        this.setState(prevState => {
            const updatedJobSet = new Set(prevState.selectedJobsByYear[year]);
            const reportAvailableJobs = this.state.jobs
                .filter(job => {
                    const jobDate = new Date(job.startDate);
                    return jobDate.getFullYear() === year && jobDate.getMonth() === month && this.state.reports.some(report => report.id_job === job.id);
                });

            reportAvailableJobs.forEach(job => {
                if (isChecked) {
                    updatedJobSet.add(job.id);
                } else {
                    updatedJobSet.delete(job.id);
                }
            });

            const newSelectedJobsByYear = { ...prevState.selectedJobsByYear, [year]: updatedJobSet };

            localStorage.setItem('selectedJobsByYear', JSON.stringify(
                Object.fromEntries(
                    Object.entries(newSelectedJobsByYear).map(([year, jobSet]) => [year, Array.from(jobSet)])
                )
            ));

            return {
                selectedJobsByYear: newSelectedJobsByYear,
            };
        });
    };

    render() {
        const { res } = this.props;

        return (
            <div>
                <div>
                    <h1>{res.RELATORIO_MAPA}</h1>
                </div>
                <div>
                    {this.state.isLoading
                        ?
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Skeleton variant="text" width="100%" height={40} />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="text" width="100%" height={40} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...Array(3)].map((_, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell style={{ width: '96%' }}>
                                                <Skeleton variant="text" width="100%" height={40} />
                                            </TableCell>
                                            <TableCell style={{ width: '4%', textAlign: 'center' }}>
                                                <Skeleton variant="circular" width={24} height={24} />
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                        :
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.getYear(this.state.jobs).sort((a, b) => b - a).map((year) => (
                                    <React.Fragment key={year}>
                                        <TableRow onClick={() => this.toggleYearExpand(year)}>
                                            <TableCell style={{ width: '96%', borderBottom: this.state.expandedYears[year] ? 'none' : '' }}>
                                                <h2 style={{ fontSize: '1.75rem' }}>{year}</h2>
                                            </TableCell>
                                            <TableCell style={{ width: '4%', textAlign: 'center', borderBottom: this.state.expandedYears[year] ? 'none' : '' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


                                                    {this.state.expandedYears[year]
                                                        ?
                                                        <KeyboardArrowUpIcon className="iconMapS" />
                                                        :
                                                        <KeyboardArrowDownIcon className="iconMapS" />
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {this.state.expandedYears[year] ? this.renderMonths(year) : null}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    }
                </div>
            </div>
        );
    }
}

List.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(List);

const Box = (props) => {
    const { link, res } = props;
    let { id } = useParams();
    return (
        <div className="rowForm">
            <div className="divContent">
                <TextFields1 link={link} id={id} res={res} />
            </div>
        </div>)
}

export default Box;