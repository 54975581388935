import React, { useState, useEffect, useRef, createRef } from 'react';

export default (props) => {
  const { value, handleChange, name, itens, size } = props;

  const ref = useRef(itens.map(() => createRef()));
  const [border, setState] = useState({ backgroundColor: '#1890ff', height: '2px', position: 'absolute', width: '90px', left: '0px', bottom: '9px', transition: '.15s' });

  const styleSel = { transition: '.15s', marginLeft: '-.3rem', marginRight: '-.3rem', opacity: '1', color: '#1890ff', fontWeight: '500', whiteSpace: 'nowrap' };
  const styleNor = { transition: '.15s', marginLeft: '-.3rem', marginRight: '-.3rem', opacity: '.7', fontWeight: '400', whiteSpace: 'nowrap' };
  const styleDis = { transition: '.15s', marginLeft: '-.3rem', marginRight: '-.3rem', opacity: '.7', color: '#e0e0e0', fontWeight: '400', whiteSpace: 'nowrap' };

  useEffect(() => {
    if (ref.current[0]) {
      let left = 0;
      let width = 0;
      const nextWidths = ref.current.map(
        item => !item.current ? null : item.current.getBoundingClientRect().width
      );
      for (let i = 0; i < itens.length; i++) {
        if(itens[i].value === null){
          break;
        }else        if (itens[i].value === value) {
          width = nextWidths[i];
          break;
        } else {
          left += nextWidths[i];
        }
      }
      if (width > 0) {
        setState(border => ({
          ...border,
          left: left,
          width: width
        }));
      }
    }
  }, [itens, value]);

  return (
    <div style={{ widows: '100%', height: '100%', position: 'relative' }}>
      <div style={{ display: 'flex', widows: '100%', height: 'calc(100% - .5rem)', borderBottom: '1px solid #e8e8e8', marginTop: size ? '0' : '.5rem' }}>
        {itens.map((item, index) => (
          <div key={index} className="LocalTab-root" ref={ref.current[index]} style={{ flexGrow: '1', textAlign: 'center', cursor: 'pointer' }} onClick={(event) => { if (item.active || item.active === undefined) handleChange(name)(event, item.value) }}>
            <span className="LocalTab-wrapper" style={item.value === value ? styleSel : item.active || item.active === undefined ? styleNor : styleDis}> {item.label}</span>
          </div>
        ))}
      </div>
      <span style={border}></span>
    </div>
  );
}
