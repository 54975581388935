import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { notification } from 'antd';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

//import * as turf from '@turf/turf';
import L from 'leaflet';
import 'leaflet-draw';
//import togeojson from '@mapbox/togeojson';
//import xmljson from 'xml-js';

import jszip from 'jszip';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import tools from '../../components/util/tools';
import geo from '../../components/util/geo';

import { useParams } from "react-router";

import Backd from '../../components/Backdrop';
//import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    title: '',
    name: '',
    descricao1: '',
    descricao2: [],
    time: '',

    altura: 2,
    mErro: 20,

    polygons: [],

    data: null,
    totalHe: '',
    fluxoM: '',

    tiro: '',
    faixa: '',
    comprimento: '',
    area: '',
    volume: '',
    fluxo: '',


    stack: false,
    stack_value: [1, 1, 1],
    detail: [],
    progress: false,

    ruler_a: false,
    disable: false
  };

  myStylePath = {
    color: '#d0d0d0',
    weight: 1,
    opacity: 1,
    lineCap: "square",
    smoothFactor: 1,
    className: 'caminho'
  };
  myStyleApp = {
    color: "#ffd12b",
    fillOpacity: .9,
    opacity: 0,
    className: 'aplicacao'
  };

  myStylePoly = {
    color: '#47839e',
    weight: 4,
    opacity: 0.5,
    fillOpacity: 0
  };

  ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };

  featureGroupL = null;
  featureGroupP = null;
  featureGroupPoly = null;

  teste = null;

  componentDidMount() {
    this.boot();
    this.loadLimit();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  boot = () => {
    this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);

    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

    this.featureGroupL = L.featureGroup();
    this.featureGroupL.addTo(this.mymap);
    this.featureGroupP = L.featureGroup();
    this.featureGroupP.addTo(this.mymap);
    this.featureGroupPoly = L.featureGroup();
    this.featureGroupPoly.addTo(this.mymap);

    new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: false
    }).addTo(this.mymap);



    this.mymap.on('draw:drawvertex', e => {
      if (this.drawCreateToolbar.type === 'polyline') {
        let layerIds = Object.keys(e.layers._layers);
        if (layerIds.length > 1) {
          let v2 = e.layers._layers[layerIds[1]];
          this.ruler_p.second.lat = v2._latlng.lat;
          this.ruler_p.second.lng = v2._latlng.lng;
          requestAnimationFrame(() => this.completeRuler());
        } else if (layerIds.length === 1) {
          let v1 = e.layers._layers[layerIds[0]];
          this.ruler_p.first.lat = v1._latlng.lat;
          this.ruler_p.first.lng = v1._latlng.lng;
        }
      }
    });

    L.drawLocal.draw.handlers.polyline.tooltip.start = '';
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';
  };

  formatRegua = (valor) => {
    let text = '';
    if (valor < 1000) {
      text = Math.round(valor) + ' m';
    } else {
      text = (Math.round(valor / 10) / 100) + ' km';
    }

    return text;
  }

  handleClickP = (layer, prop) => {
    console.log(prop);
    let tiro = '';
    let faixa = '';
    let comprimento = '';
    let area = '';
    let volume = '';
    let fluxo = '';

    let desc = prop.desc.split('\n      ');
    for (let i = 0; i < desc.length; i++) {
      const element = desc[i].trim();
      if (element.includes('#section')) {
        tiro = desc[i + 1].trim();
      } else if (element === 'Faixa') {
        faixa = element + ': ' + desc[i + 1].trim();
      } else if (element === 'Comprimento') {
        comprimento = element + ': ' + desc[i + 1].trim();
      } else if (element === 'Área coberta') {
        area = element + ': ' + desc[i + 1].trim();
      } else if (element === 'Volume') {
        volume = element + ': ' + desc[i + 1].trim();
      } else if (element === 'Fluxo médio') {
        fluxo = element + ': ' + desc[i + 1].trim();
      }
    }
    this.setState({ tiro: tiro, faixa: faixa, comprimento: comprimento, area: area, volume: volume, fluxo: fluxo });

    this.featureGroupP.eachLayer((item) => {
      item.setStyle({ color: '#ffd12b' });
    });

    layer.setStyle({ color: '#ff8800' });
  }


  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  zoomInL = () => {
    this.mymap.zoomIn();
  }

  zoomOutL = () => {
    this.mymap.zoomOut();
  }
  handleListItemClick = guid => {
    let poly = this.mymap._layers[guid];
    this.handleClickP(poly, guid);
  }

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (!dF.className.includes('div-listFloatC')) {
      dF.classList.toggle("div-listFloatC");
    } else {
      dF.classList.remove("div-listFloatC");
    }
  }

  handleStackClick = (tipo) => {
    let stack_value = this.state.stack_value;
    if (tipo === 1) {
      if (stack_value[0] === 1) {
        stack_value[0] = 0;
        tools.cssShow('aplicacao', false);
      } else {
        stack_value[0] = 1;
        tools.cssShow('aplicacao', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 2) {
      if (stack_value[1] === 1) {
        stack_value[1] = 0;
        tools.cssShow('caminho', false);
      } else {
        stack_value[1] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 3) {
      if (stack_value[2] === 1) {
        stack_value[2] = 0;
        tools.cssShow('caminho', true);
      } else {
        stack_value[2] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    }
  }

  //upload de arquivos KML e KMZ
  loadFile = file => {
    //clear map
    this.featureGroupL.clearLayers();
    this.featureGroupP.clearLayers();
    this.featureGroupPoly.clearLayers();

    if (file.target.files.length > 0) {
      this.setState({ progress: true });
      let filePath = file.target.files[0];
      let ext = fileName => fileName.split(".").pop();

      if (ext(filePath.name.toLowerCase()) === 'kml') {
        this.fileReader = new FileReader();
        this.fileReader.onloadend = this.handleFileRead;
        this.fileReader.readAsText(filePath);
      } else if (ext(filePath.name.toLowerCase()) === 'kmz') {
        let getDom = xml => (new DOMParser()).parseFromString(xml, "text/xml")
        let getKmlDom = (kmzFile) => {
          let zip = new jszip()
          return zip.loadAsync(kmzFile)
            .then(zip => {
              let kmlDom = null
              zip.forEach((relPath, file) => {
                if (ext(relPath) === 'kml') {
                  kmlDom = file.async("string").then(getDom);
                }
              })
              return kmlDom || Promise.reject("No kml file found")
            });
        }

        let geoJson = getKmlDom(filePath).then(kmlDom => {
          //let geoJsonObject = new togeojson['kml'](kmlDom);
          let geoJsonObject = geo.kmldom2json(kmlDom);

          //kmlDom.all.document.name

          return geoJsonObject
        })
        geoJson.then(gj => this.writeFileMap(gj))
        /*} else if (ext(filePath.name.toLowerCase()) === 'zjob') {
          this.uploadFile(filePath);*/
      } else {
        notification.open({
          message: this.props.res.ERRO,
          description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
          icon: <MaterialIcon icon="error" className="text-danger" />
        });
        this.setState({ progress: false });
      }
    }
  };
  //
  handleFileRead = (e) => {
    const content = this.fileReader.result;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(content, "text/xml");
    //let track = new togeojson['kml'](xmlDoc);
    let track = geo.kmldom2json(xmlDoc);

    this.writeFileMap(track);
  }
  //desenha os polygons do arquivo 
  writeFileMap = (track) => {
    if (track) {
      //parse dos dados????
      let tempDesc = track.description.split(' ');
      let tempDesc2 = tempDesc[4].split('\n');
      let desc = ['Data: ' + tempDesc[0].slice(0, -1)];
      desc.push('Hora: ' + tempDesc[1].slice(0, -1));
      desc.push('Total: ' + tempDesc[3] + ' ' + tempDesc2[0]);
      desc.push(tempDesc2[1] + ' ' + tempDesc[5] + ' ' + tempDesc[6] + ' ' + tempDesc[7]);

      this.setState({ title: track.name, descricao1: "", descricao2: desc });

      for (let i = 0; i < track.folder.length; i++) {
        for (let j = 0; j < track.folder[i].placemark.length; j++) {
          let item = track.folder[i].placemark[j];

          if (item.polygon.length > 0) {
            let myLayer = L.polygon(item.polygon[0].outerboundaryis.coordinates, this.myStyleApp);
            myLayer.on("click", () => { this.handleClickP(myLayer, item); });
            myLayer.addTo(this.featureGroupP);
          } else if (item.linestring.length > 0) {
            let coord = [];
            for (let i = 0; i < item.linestring[0].lines.length; i++) {
              for (let j = 0; j < item.linestring[0].lines[i].coordinates.length; j++) {
                const element = item.linestring[0].lines[i].coordinates[j];

                coord.push([element[0], element[1]]);
              }
            }

            let myLayer = L.polyline(coord, this.myStylePath);
            myLayer.addTo(this.featureGroupL);
          }
        }
      }
      //aTlPul: area, 
      this.mymap.fitBounds(this.featureGroupL.getBounds());
    }
    this.setState({ progress: false });
  }

  uploadFile = (file) => {
    this.setState({ progress: true });

    let apiUrl = Config.server + '/loadJob';//TODO: arrumar!!!
    let method = 'POST';

    const formData = new FormData();//URLSearchParams

    formData.append('file', file, 'teste.file');

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          let title = result.client + ', ' + result.title;
          let descricao = result.date + ' total ' + result.area_total.toFixed(1) + ' hectares\nFluxo médio ' + result.litros_medio.toFixed(2) + ' litros/hectare';
          this.setState({ title: title, descricao1: "", descricao2: descricao });

          for (let i = 0; i < result.polygons.length; i++) {
            const element = result.polygons[i];
            let myLayer = L.geoJSON(element.poly, { style: this.myStylePoly });
            myLayer.addTo(this.featureGroupPoly);
          }

          for (let i = 0; i < result.polyApp.length; i++) {
            const element = result.polyApp[i];

            let myLayer = L.geoJSON(element.poly, { style: this.myStyleApp });
            myLayer.on("click", () => {
              this.handleClickP(myLayer, {
                desc: '\n        #section0\n        Tiro ' + element.title +
                  '\n        \n          \n            Faixa\n            ' + element.faixa.toFixed(0) +
                  ' m\n          \n          \n            Comprimento\n            ' + element.comprimento.toFixed(1) +
                  ' m\n          \n          \n            Área coberta\n            ' + element.area.toFixed(2) +
                  ' ha\n          \n          \n            Volume\n            ' + element.volume.toFixed(2) +
                  ' L\n          \n          \n            Fluxo médio\n            ' + element.fMedio.toFixed(2) +
                  ' L/ha'
              });
            });
            myLayer.addTo(this.featureGroupP);
          }

          let myLayer = L.geoJSON(result.linha, { style: this.myStylePath });
          myLayer.addTo(this.featureGroupL);

          this.mymap.fitBounds(this.featureGroupP.getBounds());
          this.setState({ progress: false });
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  updateProp = (prop) => {

  }

  regua = (start) => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }

    this.featureGroupPoly.removeLayer(this.mymap._layers['regua']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM1']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM2']);
    if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
      this.setState({ ruler_a: false });
      this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
      this.drawCreateToolbar.disable();
    } else {
      this.setState({ ruler_a: true });
      this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
      this.drawCreateToolbar.enable();
      if (start) {
        this.drawCreateToolbar.addVertex(start);
      }
    }
  }

  completeRuler = () => {
    this.drawCreateToolbar.disable();

    let latlngs = [
      [this.ruler_p.first.lat, this.ruler_p.first.lng],
      [this.ruler_p.second.lat, this.ruler_p.second.lng]
    ];
    let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    let text = latlngF.distanceTo(latlngS);


    let polyline = L.polyline(latlngs);

    polyline._leaflet_id = 'regua';
    polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
    polyline.addTo(this.featureGroupPoly);

    let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    })
    m1.on('click', (e) => {
      this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    })
    m1._leaflet_id = 'reguaM1';
    m1.addTo(this.featureGroupPoly);

    let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    });
    m2.on('click', (e) => {
      this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    })
    m2._leaflet_id = 'reguaM2';
    m2.addTo(this.featureGroupPoly);
  }

  cancelRuler = () => {
    if (this.ruler_p.second.lat) {
      this.regua();
    }
  }

  loadLimit() {
    const apiUrl = Config.server + '/company_config';
    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            if (result[0].limit_orc_ha < 0) {
              this.setState({ disable: true });
            }
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: "PERMISSAO",
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: "ERRO",
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  render() {
    const { res } = this.props;

    return (
      <div className="rowMapC maxH">
        <Backd style={{ zIndex: 1002, color: '#fff' }} open={this.state.progress}>
          <CircularProgress color="inherit" disableShrink />
        </Backd>
        <div id="divFloat" className="div-list divContentList list-p">
          <div className="form-group">
            <div><b>{this.state.title}</b></div>
            <div><b>{this.state.name}</b></div>
            <div>{this.state.time}</div>
            <div>{this.state.descricao1}</div>
            <div>{this.state.descricao2
              .map((item, index) => {
                return (index > 0 ? <span><br />{item}</span> : <span>{item}</span>);
              })}</div>
            <br />
            <div><b>{this.state.tiro}</b></div>
            <div>{this.state.faixa}</div>
            <div>{this.state.comprimento}</div>
            <div>{this.state.area}</div>
            <div>{this.state.volume}</div>
            <div>{this.state.fluxo}</div>
            {false &&
              <div>
                <TextField
                  id="altura"
                  label="altura"
                  value={this.state.altura}
                  onChange={this.handleChange('altura')}
                  type="number"
                  className="mb-4 notTooClose"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
                <TextField
                  id="mErro"
                  label="margem de erro"
                  value={this.state.mErro}
                  onChange={this.handleChange('mErro')}
                  type="number"
                  className="mb-4 notTooClose"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </div>
            }
          </div>
        </div>
        <div className="divContent divRelative map-p">
          <div className="containerTop">
            <div className="leftTop">
              <Button className="buttonS buttonMenu buttonMenuSmall" disabled={this.state.disable} onClick={event => this.showMenu()}>
                <MaterialIcon icon="menu" className="iconMapF" />
              </Button>
              <Button className={this.state.ruler_a ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={this.state.disable} onClick={event => this.regua()} title={res.REGUA}>
                <MaterialIcon icon="settings_ethernet" className="iconMap highlightSVG" />
              </Button>
            </div>
            <div className="centerTop"></div>
            <div className="rightTop">
              <label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                <input
                  accept=".kmz"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  disabled={this.state.disable}
                  type="file"
                  onChange={this.loadFile}
                />
                <label htmlFor="contained-button-file">
                  <Button component="span" className="buttonS buttonMenu" disabled={this.state.name !== '' || this.state.disable} title={res.IMPORTAR_KMZ}>
                    <MaterialIcon icon="attach_file" className="iconMap" />
                  </Button>
                </label>
                <Button className="buttonS buttonMenu" onClick={event => this.setState({ stack: !this.state.stack })} title={res.EXIBIR_ESCONDER}>
                  <MaterialIcon icon="layers" className="iconMap" />
                </Button>
              </label>
            </div>
          </div>

          <div className="wrapperMap">
            <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '1111', display: !this.state.disable ? 'none' : 'block' }}></div>
            <div id="mapid"></div>
            <div className="zoomB">
              <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                <MaterialIcon icon="add" className="iconMap" />
              </Button>
              <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                <MaterialIcon icon="remove" className="iconMap" />
              </Button>
            </div>
            {this.state.stack &&
              <div className="divContent floatW divS" onfocusout={event => this.setState({ stack: false })}>
                <List
                  component="nav"
                  aria-labelledby="stack"
                >
                  <ListItem key={2} button
                    className={(this.state.stack_value[1] === 1) ? 'SelItem' : ''}
                    onClick={event => this.handleStackClick(2)}
                  >
                    <ListItemText id={2} primary="Caminho" />
                  </ListItem>

                  <ListItem key={1} button
                    className={(this.state.stack_value[0] === 1) ? 'SelItem' : ''}
                    onClick={event => this.handleStackClick(1)}
                  >
                    <ListItemText id={1} primary="Aplicação" />
                  </ListItem>
                </List>
              </div>}
          </div>
        </div>
      </div >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} id={id} res={res} />
    </div>)
}
export default Box;

