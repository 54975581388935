const data0 = require('./USA_20220118.json');

const refData1 =
    [{
        'CVF': 0.1,
        'VF/F': 59.5,
        'F/M': 110.3,
        'M/C': 162,
        'C/VC': 191.7,
        'VC/XC': 226.1,
        'XC/UC': 302.5,
    },
    {
        'CVF': 0.5,
        'VF/F': 134.4,
        'F/M': 248.1,
        'M/C': 357.8,
        'C/VC': 431,
        'VC/XC': 500.9,
        'XC/UC': 658.6
    },
    {
        'CVF': 0.9,
        'VF/F': 236.4,
        'F/M': 409.4,
        'M/C': 584.0,
        'C/VC': 737.1,
        'VC/XC': 819.8,
        'XC/UC': 1142.2
    }];
const speedRange = ['High', 'Low'];
const dsc_label = ['MUITO FINA', 'FINA', 'MÉDIA', 'GROSSA', 'MUITO GROSSA', 'EXTREMAMENTE GROSSA', 'ULT. GROSSA'];
//['VERY FINE', 'FINE', 'MEDIUM', 'COARSE', 'VERY COARSE', 'EXT. COARSE', 'ULT. COARSE'];
//const as_low_limit = (sR) => (sR === speedRange[0] ? 120 : 50);
//const as_high_limit = (sR) => (sR === speedRange[0] ? 180 : 120);
const pressure_limit = (sR) => (sR === speedRange[0] ? 90 : 60);
const boom_FR = (spray_rate, swath_width, airspeed) => (Math.ceil(spray_rate * swath_width * airspeed / 495));
const per_nozzle_FR = (boom_FR, n_nozzles) => (Math.ceil(boom_FR / n_nozzles * 100) / 100);

const calc_data = (spray_rate, swath_width, airspeed, n_nozzles, dsc) => {
    let ret = [];
    for (let k = 0; k < data0.length; k++) {
        let img = null
        if (data0[k].img !== '') {
            img = require('./../../assets/images/pics/' + data0[k].img + '.png')
        }
        let data1 = data0[k];
        if (data1.rows[0][0].includes('Davidon')) {
            continue;
        }
        ret.push({ fields: [], rows: [], img: img, fab: data0[k].fab, type: data0[k].type });

        for (let i = 0; i < data1.rows.length; i++) {

            const per_nozzle = per_nozzle_FR(boom_FR(spray_rate, swath_width, airspeed), n_nozzles);
            const d_temp = data1.rows[i];
            let d = [0, 0];
            for (let j = 2; j < d_temp.length; j++) {
                if (isNaN(d_temp[j].replace(',', '.'))) {
                    d[j] = d_temp[j];
                } else {
                    d[j] = Number(d_temp[j].replace(',', '.'));
                }
            }
            let psi = Math.ceil(Math.pow(per_nozzle / d[5], (1 / d[6])) * 10) / 10;
            const BA3 = d[15];
            const AO3 = ((d[3] - d[7]) / d[8]);
            const BB3 = d[16];
            const AP3 = ((airspeed - d[9]) / d[10]);
            const BC3 = d[17];
            const BD3 = d[18];
            const AQ3 = ((psi - d[11]) / d[12]);
            const BE3 = d[19];
            const AR3 = ((d[4] - d[13]) / d[14]);
            const BF3 = d[20];
            const BG3 = d[21];
            const BH3 = d[22];
            const BI3 = d[23];
            const BJ3 = d[24];
            const BK3 = d[25];
            const BL3 = d[26];
            const BM3 = d[27];
            const BN3 = d[28];
            const BO3 = d[29];

            const BP3 = d[30];
            const BQ3 = d[31];
            const BR3 = d[32];
            const BS3 = d[33];
            const BT3 = d[34];
            const BU3 = d[35];
            const BV3 = d[36];
            const BW3 = d[37];
            const BX3 = d[38];
            const BY3 = d[39];
            const BZ3 = d[40];
            const CA3 = d[41];
            const CB3 = d[42];
            const CC3 = d[43];
            const CD3 = d[44];

            const CE3 = d[45];
            const CF3 = d[46];
            const CG3 = d[47];
            const CH3 = d[48];
            const CI3 = d[49];
            const CJ3 = d[50];
            const CK3 = d[51];
            const CL3 = d[52];
            const CM3 = d[53];
            const CN3 = d[54];
            const CO3 = d[55];
            const CP3 = d[56];
            const CQ3 = d[57];
            const CR3 = d[58];
            const CS3 = d[59];

            const CT3 = d[60];
            const CU3 = d[61];
            const CV3 = d[62];
            const CW3 = d[63];
            const CX3 = d[64];
            const CY3 = d[65];
            const CZ3 = d[66];
            const DA3 = d[67];
            const DB3 = d[68];
            const DC3 = d[69];
            const DD3 = d[70];
            const DE3 = d[71];
            const DF3 = d[72];
            const DG3 = d[73];
            const DH3 = d[74];

            const AK3 = Math.ceil(BA3 + AO3 * BB3 + AP3 * BC3 + BD3 * AQ3 + BE3 * AR3 + BF3 * AO3 * AP3 + BG3 * AO3 * AQ3 + BH3 * AP3 * AQ3 + BI3 * AO3 * AR3 + BJ3 * AP3 * AR3 + AQ3 * AR3 + BK3 * AQ3 * AR3 + BL3 * AO3 * AO3 + BM3 * AP3 * AP3 + BN3 * AQ3 * AQ3 + BO3 * AR3 * AR3);
            const AL3 = Math.ceil(BP3 + AO3 * BQ3 + AP3 * BR3 + BS3 * AQ3 + BT3 * AR3 + BU3 * AO3 * AP3 + BV3 * AO3 * AQ3 + BW3 * AP3 * AQ3 + BX3 * AO3 * AR3 + BY3 * AP3 * AR3 + AQ3 * AR3 + BZ3 * AQ3 * AR3 + CA3 * AO3 * AO3 + CB3 * AP3 * AP3 + CC3 * AQ3 * AQ3 + CD3 * AR3 * AR3);
            const AM3 = Math.ceil(CE3 + AO3 * CF3 + AP3 * CG3 + CH3 * AQ3 + CI3 * AR3 + CJ3 * AO3 * AP3 + CK3 * AO3 * AQ3 + CL3 * AP3 * AQ3 + CM3 * AO3 * AR3 + CN3 * AP3 * AR3 + AQ3 * AR3 + CO3 * AQ3 * AR3 + CP3 * AO3 * AO3 + CQ3 * AP3 * AP3 + CR3 * AQ3 * AQ3 + CS3 * AR3 * AR3);
            const AN3 = Math.ceil((CT3 + AO3 * CU3 + AP3 * CV3 + CW3 * AQ3 + CX3 * AR3 + CY3 * AO3 * AP3 + CZ3 * AO3 * AQ3 + DA3 * AP3 * AQ3 + DB3 * AO3 * AR3 + DC3 * AP3 * AR3 + AQ3 * AR3 + DD3 * AQ3 * AR3 + DE3 * AO3 * AO3 + DF3 * AP3 * AP3 + DG3 * AQ3 * AQ3 + DH3 * AR3 * AR3) * 10) / 10;
            const AG3 = AK3 < 0 ? 0 : AK3;
            const AE3 = AG3 >= refData1[0]['XC/UC'] ? 6 : AG3 >= refData1[0]['VC/XC'] ? 5 : AG3 >= refData1[0]['C/VC'] ? 4 : AG3 >= refData1[0]['M/C'] ? 3 : AG3 >= refData1[0]['F/M'] ? 2 : AG3 >= refData1[0]['VF/F'] ? 1 : 0;
            const AH3 = AL3 < 0 ? 0 : AL3;
            const AF3 = AH3 >= refData1[1]['XC/UC'] ? 6 : AH3 >= refData1[1]['VC/XC'] ? 5 : AH3 >= refData1[1]['C/VC'] ? 4 : AH3 >= refData1[1]['M/C'] ? 3 : AH3 >= refData1[1]['F/M'] ? 2 : AH3 >= refData1[1]['VF/F'] ? 1 : 0;

            ret[ret.length - 1].rows.push([
                d_temp[0] + ' ' + d_temp[1], d_temp[3], d_temp[4],
                ((psi < 30 || psi > pressure_limit(speedRange[airspeed <= 120 ? 1 : 0])) ? false : true) && (dsc === AF3 || dsc === 99),
                psi, dsc_label[AE3], dsc_label[AF3], AK3, AL3, AM3, AN3,
                AK3 <= 0 ? ' ' : Math.ceil(((AM3 - AL3) / AK3 * 10)) / 10
            ]);
        }
        ret[ret.length - 1].fields = ['Nozzle', 'Orifice', 'Angle', 'P Tst ', 'Pressure (psi)', 'DSC0.1', 'DSC0.5', 'DV01', 'DV05', 'DV09', '%<100', 'RS'];
    }
    return ret;
};

export default calc_data;
