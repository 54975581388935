import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PrintIcon from '@material-ui/icons/Print';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { withRouter } from 'react-router-dom';
import Config from '../../constants/appConfig';

const styles = theme => ({
    tableContainer: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
    },
    tableRow: {
        backgroundColor: theme.palette.background.paper,
        '&:nth-of-type(odd)': {
            backgroundColor: '#343a40',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#343a40',
        },
        '& > *': {
            padding: '0.5rem',
            borderBottom: '2px solid #ff8c00',
        },
    },
    tableCell: {
        textAlign: 'center',
        color: 'white',
        wordBreak: 'break-word',
    },
    tableHeadCell: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#fa7d34',
        backgroundColor: '#343a40',
        borderBottom: '1px solid #fa7d34',
        padding: '0.75rem',
    },
    cardmiddle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
    },
    buttonGroup: {
        marginBottom: '1rem',
        justifyContent: 'center',
    },
});

const renderAlertIcon = (type, title, classes) => {
    switch (type) {
        case 'DGPS':
            return (
                <div className={classes.iconWrapper} style={{ backgroundColor: 'green' }}>
                    <PowerSettingsNewIcon style={{ color: 'white' }} />
                </div>
            );
        case 'relatorio':
            if (title.includes('Relatório Qualidade disponível')) {
                return (
                    <div className={classes.iconWrapper} style={{ backgroundColor: '#FFA500' }}>
                        <AssessmentIcon style={{ color: 'white' }} />
                    </div>
                );
            }
            return (
                <div className={classes.iconWrapper} style={{ backgroundColor: '#1E90FF' }}>
                    <InsertDriveFileIcon style={{ color: 'white' }} />
                </div>
            );
        case 'transferencia':
            return (
                <div className={classes.iconWrapper} style={{ backgroundColor: '#0000CD' }}>
                    <ImportExportIcon style={{ color: 'white' }} />
                </div>
            );
        case 'job':
            if (title.includes('Fim de Trabalho')) {
                return (
                    <div className={classes.iconWrapper} style={{ backgroundColor: '#FF6347' }}>
                        <FlightLandIcon style={{ color: 'white' }} />
                    </div>
                );
            } else if (title.includes('Inicio de Trabalho')) {
                return (
                    <div className={classes.iconWrapper} style={{ backgroundColor: '#32CD32' }}>
                        <FlightTakeoffIcon style={{ color: 'white' }} />
                    </div>
                );
            }
            return null;
        default:
            return null;
    }
};

class DiaryAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
            isLoading: true,
            selectedType: 'all',
        };
    }

    componentDidMount() {
        this.checkAlert();
    }

    checkAlert = () => {
        const startDate = Moment().startOf('day').valueOf();
        const endDate = Moment().endOf('day').valueOf();

        const apiUrl = `${Config.server}/alert/date/${startDate}/${endDate}`;
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200) throw new Error(res.status);
                return res.json();
            })
            .then((result) => {
                if (Array.isArray(result)) {
                    this.setState({ alerts: result, isLoading: false });
                }
            })
            .catch((error) => {
                console.error(error);
            });

        setTimeout(() => {
            this.checkAlert();
        }, 300000);
    };

    handleTypeSelect = (type) => {
        this.setState({ selectedType: type });
    };

    handleRedirect = (callback) => {
        window.open(`/app${callback}`, '_blank');
    };

    renderTable = () => {
        const { classes, res } = this.props;

        const filteredAlerts = this.state.selectedType === 'all'
            ? this.state.alerts
            : this.state.alerts.filter(alert => alert.type === this.state.selectedType);

        return (
            <div className={classes.tableContainer}>
                <Table className={classes.table} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>{res.TIPO}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.TITULO}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.DESCRICAO}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.DATA}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{res.ACOES}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAlerts.map(alert => (
                            <TableRow key={alert.id} className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    {renderAlertIcon(alert.type, alert.title, classes)}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{alert.title}</TableCell>
                                <TableCell className={classes.tableCell}>{alert.text}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    {Moment(alert.created).format('DD/MM/YYYY HH:mm')}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {alert.callback && alert.type === 'relatorio' ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.handleRedirect(alert.callback)} // Passa uma função de callback corretamente
                                            style={{
                                                backgroundColor: '#fa7d34',
                                                color: 'white',
                                                minWidth: '40px',
                                                height: '40px',
                                                padding: '0',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {alert.title.includes('Qualidade') ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" style={{ marginLeft: '0.5rem' }} height="2rem" fill="currentColor" viewBox="-20 -50 400 400">
                                                    <g id="_2539151417168">
                                                        <path className="fil0" d="M133 17.44c58.05,0 106.23,43.9 115.57,101.56l-27.61 0c-8.97,-41.97 -44.93,-73.35 -87.96,-73.35 -49.77,0 -90.11,41.99 -90.11,93.79 0,25.33 9.64,48.3 25.32,65.18l-19.24 19.88c-20.54,-21.98 -33.19,-51.98 -33.19,-85.06 0,-67.38 52.48,-122 117.22,-122z" />
                                                        <path className="fil0" d="M57.61 154.63l-42.68 0c-1.63,0 -2.97,-1.33 -2.97,-2.96l0 -24.46c0,-1.63 1.34,-2.96 2.97,-2.96l42.68 0c1.64,0 2.97,1.33 2.97,2.96l0 24.46c0,1.63 -1.33,2.96 -2.97,2.96z" />
                                                        <path className="fil0" d="M67.46 95.53l-32.35 -26.76c-1.42,-1.16 -1.62,-3.26 -0.45,-4.67l15.13 -18.3c1.17,-1.41 3.27,-1.61 4.68,-0.45l32.35 26.76c1.41,1.16 1.61,3.26 0.45,4.67l-15.13 18.3c-1.17,1.41 -3.28,1.61 -4.68,0.45z" />
                                                        <path className="fil0" d="M146.59 16.83l0 41.44c0,1.98 -1.62,3.59 -3.59,3.59l-23.21 0c-1.98,0 -3.59,-1.61 -3.59,-3.59l0 -41.44c0,-1.98 1.61,-3.59 3.59,-3.59l23.21 0c1.97,0 3.59,1.61 3.59,3.59z" />
                                                        <g>
                                                            <path className="fil0" d="M119.46 126.91l46.07 -48.13c5.44,-5.69 14.54,-5.89 20.22,-0.44l0.01 0c5.69,5.45 5.88,14.54 0.44,20.23l-46.08 48.13c-5.44,5.69 -14.53,5.88 -20.22,0.44l-0.01 0c-5.68,-5.45 -5.88,-14.54 -0.43,-20.23z" />
                                                            <path className="fil0" d="M130.02 114.41c12.39,0 22.42,10.03 22.42,22.42 0,12.39 -10.03,22.43 -22.42,22.43 -12.39,0 -22.42,-10.04 -22.42,-22.43 0,-12.39 10.03,-22.42 22.42,-22.42z" />
                                                        </g>
                                                        <path className="fil0" d="M219.66 183.43l-19.48 -17.87 -42.87 42.86c-5.16,4.94 -9.72,4.52 -13.8,0l-25.63 -25.63c-6.35,-6.83 -12.86,-7.46 -19.58,0l-53.12 53.12c-5.03,8.19 12.45,23.9 20.39,15.83l35.82 -35.81c4.6,-4.75 9.19,-4.55 13.81,0l26.08 26.08c6.42,6.89 13.02,6.35 19.79,0l58.59 -58.58zm-8.47 -28.53l-21.82 -22.91 61.56 -0.4 0 62.24 -19.33 -21.41 -11.94 11.01 -19.48 -17.87 11.01 -10.66z" />
                                                    </g>
                                                </svg>
                                            ) : (
                                                <PrintIcon />
                                            )}
                                        </Button>
                                    ) : alert.type === 'DGPS' ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.handleRedirect(alert.callback)} // Passa uma função de callback corretamente
                                            style={{
                                                backgroundColor: '#fa7d34',
                                                color: 'white',
                                                minWidth: '40px',
                                                height: '40px',
                                                padding: '0',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <LocationOnIcon />
                                        </Button>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    };

    render() {
        const { classes, res } = this.props;

        return (
            <div className='dashboard-card' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className='divContent'
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '.8rem',
                        backgroundColor: '#343a40',
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                        position: 'relative',
                    }}>
                    <div className='cardheader'>
                        <NotificationsActiveIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                        <span className='title'>
                            {res.ALERTAS_}
                        </span>
                    </div>

                    <ButtonGroup className={classes.buttonGroup} style={{ margin: 0 }}>
                        <Button
                            variant={this.state.selectedType === 'all' ? 'contained' : 'outlined'}
                            onClick={() => this.handleTypeSelect('all')}
                            style={{
                                borderRadius: '1rem 0 0 1rem',
                                color: this.state.selectedType === 'all' ? 'white' : '#fa7d34',
                                backgroundColor: this.state.selectedType === 'all' ? '#fa7d34' : 'transparent',
                                borderColor: '#fa7d34',
                                fontSize: this.state.selectedType === 'all' ? '1rem' : '0.625rem',
                            }}
                        >
                            {res.TODOS}
                        </Button>
                        <Button
                            variant={this.state.selectedType === 'DGPS' ? 'contained' : 'outlined'}
                            onClick={() => this.handleTypeSelect('DGPS')}
                            style={{
                                color: this.state.selectedType === 'DGPS' ? 'white' : '#fa7d34',
                                backgroundColor: this.state.selectedType === 'DGPS' ? '#fa7d34' : 'transparent',
                                borderColor: '#fa7d34',
                                fontSize: this.state.selectedType === 'DGPS' ? '1rem' : '0.625rem',
                            }}
                        >
                            {res.AERONAVE}
                        </Button>
                        <Button
                            variant={this.state.selectedType === 'relatorio' ? 'contained' : 'outlined'}
                            onClick={() => this.handleTypeSelect('relatorio')}
                            style={{
                                color: this.state.selectedType === 'relatorio' ? 'white' : '#fa7d34',
                                backgroundColor: this.state.selectedType === 'relatorio' ? '#fa7d34' : 'transparent',
                                borderColor: '#fa7d34',
                                fontSize: this.state.selectedType === 'relatorio' ? '1rem' : '0.625rem',
                            }}
                        >
                            {res.RELATORIO}
                        </Button>
                        <Button
                            variant={this.state.selectedType === 'transferencia' ? 'contained' : 'outlined'}
                            onClick={() => this.handleTypeSelect('transferencia')}
                            style={{
                                color: this.state.selectedType === 'transferencia' ? 'white' : '#fa7d34',
                                backgroundColor: this.state.selectedType === 'transferencia' ? '#fa7d34' : 'transparent',
                                borderColor: '#fa7d34',
                                fontSize: this.state.selectedType === 'transferencia' ? '1rem' : '0.625rem',
                            }}
                        >
                            {res.TRANSFERENCIA}
                        </Button>
                        <Button
                            variant={this.state.selectedType === 'job' ? 'contained' : 'outlined'}
                            onClick={() => this.handleTypeSelect('job')}
                            style={{
                                borderRadius: '0 1rem 1rem 0',
                                color: this.state.selectedType === 'job' ? 'white' : '#fa7d34',
                                backgroundColor: this.state.selectedType === 'job' ? '#fa7d34' : 'transparent',
                                borderColor: '#fa7d34',
                                fontSize: this.state.selectedType === 'job' ? '1rem' : '0.625rem',
                            }}
                        >
                            {res.TRABALHO}
                        </Button>
                    </ButtonGroup>

                    <div className={classes.cardmiddle}>
                        {this.state.isLoading ? (
                            <Skeleton variant="rect" width="100%" height={200} />
                        ) : (
                            this.renderTable()
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

DiaryAlert.propTypes = {
    classes: PropTypes.object.isRequired,
    res: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(DiaryAlert));