import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import MaterialIcon from '../../components/MaterialIcon';

import L from 'leaflet';
import Config from '../../constants/appConfig';

const Box = (props) => {
  const { res, onClose, update, item, type } = props;

  const guid = item ? item.guid : '';
  const [name, setName] = useState(item ? item.name : '');
  const [lng, setLng] = useState(item ? item.lng : '');
  const [lat, setLat] = useState(item ? item.lat : '');
  const [img_place, setImage] = useState(item ? item.img_place : '');
  const points_place = item ? item.points_place : '';

  let mymap;
  useEffect(() => {
    if (points_place) {
      mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(new L.LatLng(40.737, -73.923), 14);
      L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(mymap);

      let myLayer;
      let points = JSON.parse(points_place);
      if (points.length > 1) {
        let myStyle = {
          "color": '#47839e',
          "weight": 4,
          "opacity": 0.5
        };
        myLayer = L.polygon(points, myStyle);
        myLayer.addTo(mymap);
        mymap.fitBounds(myLayer.getBounds());
      } else {
        myLayer = L.marker(points[0]);
        myLayer.addTo(mymap);
        mymap.setView(points[0], 14);
      }
    }
  }, []);

  const handleChange = (call) => (event) => {
    call(event.target.value);
  }

  const loadImage = file => {
    if (file.target.files.length > 0) {
      let filePath = file.target.files[0];

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setImage(fileReader.result);
      };
      fileReader.readAsDataURL(filePath);
    }
  };

  const updateData = () => {
    let item = {
      guid: guid,
      name: name,
      lat: lat,
      lng: lng,
      img_place: img_place
    }
    let type = 'new';
    if (guid) {
      type = 'update';
    }
    update(item, type);
  }


  return (
    <div className="rowForm" style={{ display: 'grid', gridTemplateRows: '5rem auto' }}>
      <div className="containerTop" style={{ maxWidth: '34rem', width: '85vw' }}>
        <div><h1>{'Dados - ' + type}</h1></div>
        <div className="centerTop"></div>
        <div>
          <Button variant="contained" onClick={onClose} className="popUpButton">
            {res.CANCELAR}
          </Button> &nbsp;
          <Button type="submit" variant="contained" color="primary" className="popUpButton" onClick={updateData}>
            <SaveIcon className="iconMapS" />
            {res.SALVAR}
          </Button>
        </div>
      </div>
      <div className="notTooBig" style={{ display: 'grid', gridTemplateRows: '4rem 4rem 5rem auto', marginBottom: '2rem' }}>
        <TextField
          id="name"
          label={res.NOME}
          value={name}
          onChange={handleChange(setName)}
          margin="normal"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 45)
          }}
        />
        <TextField
          id="lat"
          label={res.LATITUDE}
          value={lat}
          onChange={handleChange(setLat)}
          margin="normal"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 45)
          }}
        />
        <TextField
          id="lng"
          label={res.LONGITUDE}
          value={lng}
          onChange={handleChange(setLng)}
          margin="normal"
          fullWidth
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 45)
          }}
        />
        <Paper elevation={3} style={{ marginTop: '2rem', height: 'calc(100vh - 27rem)' }}>
          {points_place ?
            <div id="mapid"></div>
            :
            <div style={{ height: '100%', width: '100%' }}>
              <div style={{ textAlign: 'right', padding: '1rem' }}>
                <label htmlFor="contained-button-file-area" style={{ marginBottom: 0, display: 'inline' }}>
                  <input
                    accept=".jpg, .bmp, .png"
                    style={{ display: 'none' }}
                    id="contained-button-file-area"
                    multiple
                    type="file" onChange={loadImage}
                  />
                  <label htmlFor="contained-button-file-area">
                    <Button component="span" className="buttonS buttonMenu" title={res.IMPORTAR_AREAS}>
                      <MaterialIcon icon="attach_file" className="iconMap" />
                    </Button>
                  </label>
                </label>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100% - 4.5rem)' }}>
                <img src={img_place} style={{ height: 'auto', width: 'auto', maxWidth: '90%', maxHeight: '90%' }} alt="" />
              </div>
            </div>
          }
        </Paper>
      </div>
    </div>)
}

export default Box;