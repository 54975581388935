import React, { useState, useEffect } from 'react';

export default function CustomizedGraph(props) {
  const { values, temperatura_values, humidade_values, temperatura, humidade, atual } = props;

  const [posTemp, setPosTemp] = useState(0);
  const [posHum, setPosHum] = useState(0);

  const color = [
    'red',
    '#ffcc00',
    'forestgreen',
    '#f00'
  ];
  const colorText = "#4F4F4F";

  const canvas = [
    'canvas0' + Math.round(Math.random() * 100),
    'canvas1' + Math.round(Math.random() * 100),
    'canvas2' + Math.round(Math.random() * 100),
    'canvas3' + Math.round(Math.random() * 100)
  ];

  const findIntersection = (a, b) => {
    const x1 = a[0][0];
    const y1 = a[0][1];
    const x2 = a[1][0];
    const y2 = a[1][1];
    const x3 = b[0][0];
    const y3 = b[0][1];
    const x4 = b[1][0];
    const y4 = b[1][1];

    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denominator == 0) {
      // lines are parallel
      return null;
    }

    const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
      // intersection point is outside of either line segment
      return null;
    }

    const x = x1 + ua * (x2 - x1);
    const y = y1 + ua * (y2 - y1);

    return [x, y];
  }

  const limitTemperatura = temperatura && temperatura > 0 ? temperatura < 50 ? temperatura : 50 : 0;
  const limitHumidade = humidade && humidade > 10 ? humidade < 100 ? humidade : 100 : 10;

  useEffect(() => {
    draw(1.2);
  }, []);

  const draw = (mult = 1) => {
    let range = { x: [59, 268], y: [8, 175] };
    let lines = [{
      render: [[59, 72], [78, 63], [100, 53], [120, 45], [143, 37.5], [164, 34], [184, 32.5], [205, 30.5], [227, 29], [247, 28.5], [268, 27],],
      type: '2'
    }, {
      render: [[59, 131], [78, 110], [100, 92], [120, 80], [143, 70], [164, 63], [184, 58], [205, 54], [227, 52], [247, 50], [268, 47],],
      type: '4'
    }, {
      render: [[70, 175], [79, 156], [100, 130], [120, 113], [143, 99], [164, 89], [184, 82], [205, 76], [227, 70], [247, 67], [268, 63],],
      type: '6'
    }, {
      render: [[96, 175], [110, 155], [124, 138], [142, 123], [164, 109.5], [185, 99], [205, 91.5], [227, 87], [248, 83], [268, 77],],
      type: '8'
    }, {
      render: [[120, 175], [142, 147], [164, 130], [185, 119.5], [206, 110.5], [227, 104], [248, 96.5], [268, 92],],
      type: '10'
    }, {
      render: [[138, 175], [157, 155], [185, 137], [205, 126], [248, 112], [268, 108],],
      type: '12'
    }, {
      render: [[160, 175], [185, 155], [205, 143], [248, 126], [268, 123],],
      type: '14'
    }, {
      render: [[180, 175], [205, 156], [226, 146], [248, 140], [268, 134],],
      type: '16'
    }, {
      render: [[202, 175], [230, 156], [248, 149], [268, 144],],
      type: '18'
    }, {
      render: [[219, 175], [227, 166], [248, 158], [268, 152.5],],
      type: '20'
    }]

    let canvas0 = document.getElementById(canvas[0]);
    let ctx0 = canvas0.getContext("2d");
    ctx0.strokeStyle = "#000";
    //red
    ctx0.beginPath();
    ctx0.fillStyle = color[0];
    let listG = [[range.x[1], range.y[0]], [range.x[0], range.y[0]], [range.x[0], range.y[1]], [range.x[1], range.y[1]]];
    ctx0.moveTo(listG[0][0] * mult, listG[0][1] * mult);
    for (let j = 1; j < listG.length; j++) {
      ctx0.lineTo(listG[j][0] * mult, listG[j][1] * mult);
    }
    ctx0.closePath();
    ctx0.stroke();
    ctx0.fill();
    //
    let canvas1 = document.getElementById(canvas[1]);
    let ctx1 = canvas1.getContext("2d");
    ctx1.strokeStyle = "#000";
    //yellow
    ctx1.beginPath();
    ctx1.fillStyle = color[1];
    listG = lines[4].render.concat([[range.x[1], range.y[0]], [range.x[0], range.y[0]], [range.x[0], range.y[1]]]);
    ctx1.moveTo(listG[0][0] * mult, listG[0][1] * mult);
    for (let j = 1; j < listG.length; j++) {
      ctx1.lineTo(listG[j][0] * mult, listG[j][1] * mult);
    }
    ctx1.closePath();
    ctx1.stroke();
    ctx1.fill();
    //
    let canvas2 = document.getElementById(canvas[2]);
    let ctx2 = canvas2.getContext("2d");
    ctx2.strokeStyle = "#000";
    //green    
    ctx2.beginPath();
    ctx2.fillStyle = color[2];
    listG = lines[0].render.concat(lines[3].render.reverse());
    listG = listG.concat([[range.x[0], range.y[1]]]);
    ctx2.moveTo(listG[0][0] * mult, listG[0][1] * mult);
    for (let j = 1; j < listG.length; j++) {
      ctx2.lineTo(listG[j][0] * mult, listG[j][1] * mult);
    }
    ctx2.closePath();
    ctx2.stroke();
    ctx2.fill();
    //
    let canvas3 = document.getElementById(canvas[3]);
    let ctx3 = canvas3.getContext("2d");
    ctx3.strokeStyle = "#000";
    //lines
    ctx3.fillStyle = color[3];
    ctx3.lineWidth = 2;
    ctx3.beginPath();
    ctx3.moveTo(58 * mult, 7 * mult);
    ctx3.lineTo(58 * mult, 180 * mult);
    ctx3.stroke();

    ctx3.lineWidth = .5;
    let sizeW = 21;
    for (let i = 0; i < 9; i++) {
      ctx3.beginPath();
      ctx3.moveTo((58 + (sizeW * (i + 1))) * mult, 7 * mult);
      ctx3.lineTo((58 + (sizeW * (i + 1))) * mult, 180 * mult);
      ctx3.stroke();
    }

    ctx3.lineWidth = 2;
    ctx3.beginPath();
    ctx3.moveTo(268 * mult, 7 * mult);
    ctx3.lineTo(268 * mult, 180 * mult);
    ctx3.stroke();

    ctx3.beginPath();
    ctx3.moveTo(50 * mult, 7 * mult);
    ctx3.lineTo(269 * mult, 7 * mult);
    ctx3.stroke();

    ctx3.lineWidth = .5;
    let sizeH = 18.7;
    for (let i = 0; i < 8; i++) {
      ctx3.beginPath();
      ctx3.moveTo(50 * mult, (7 + (sizeH * (i + 1))) * mult);
      ctx3.lineTo(269 * mult, (7 + (sizeH * (i + 1))) * mult);
      ctx3.stroke();
    }

    ctx3.lineWidth = 2;
    ctx3.beginPath();
    ctx3.moveTo(50 * mult, 174 * mult);
    ctx3.lineTo(269 * mult, 174 * mult);
    ctx3.stroke();
    //
    //text
    ctx3.font = 'bold ' + (12.3 * mult) + 'px Roboto';
    ctx3.fillStyle = "black";
    ctx3.textAlign = "right";
    let sizeFontH = 18 * mult;
    for (let i = 0; i < 10; i++) {
      ctx3.fillText(100 - i * 10, 47.7 * mult, (sizeFontH * i + 13.1 * mult));
    }
    ctx3.textAlign = "center";
    let sizeFontW = 20.8 * mult;
    for (let i = 1; i <= 10; i++) {
      ctx3.fillText(i * 5, (sizeFontW * i + 58 * mult), 193 * mult);
    }

    ctx3.font = (12 * mult) + 'px Roboto';
    ctx3.save();
    ctx3.translate(19.3 * mult, 84.6 * mult);
    ctx3.rotate(-Math.PI / 2);
    ctx3.fillText('Umidade Relativa %', 0, 0);
    ctx3.restore();
    ctx3.fillText('Temperatura °C', 165.4 * mult, 211.5 * mult);
    //

  };

  const xSize = 15.9;
  const ySize = 12.5;
  const floatText = {
    color: atual >= 2 && atual <= 8 ? color[2] :
      atual > 10 ? color[0] : color[1],
    align: {
      //temperatura
      x: 10,
      //humidade
      y: 10
    }
  };
  //temperatura
  const tempValues = [
    temperatura_values && temperatura_values[1] > 0 ? temperatura_values[1] : 0,
    temperatura_values && temperatura_values[0] > 0 ? temperatura_values[0] : 0,
    null,
    temperatura_values && temperatura_values[3] < 50 ? temperatura_values[3] : 50,
    temperatura_values && temperatura_values[4] < 50 ? temperatura_values[4] : 50,
  ];
  const barTemp = {
    minTolerance: xSize / 50 * tempValues[1],
    maxTolerance: xSize / 50 * tempValues[3],
    minLimit: xSize / 50 * tempValues[0],
    maxLimit: xSize / 50 * tempValues[4],
  }

  //humidade  
  const humValues = [
    humidade_values && humidade_values[1] > 10 ? humidade_values[1] : 10,
    humidade_values && humidade_values[0] > 10 ? humidade_values[0] : 10,
    null,
    humidade_values && humidade_values[3] < 100 ? humidade_values[3] : 100,
    humidade_values && humidade_values[4] < 100 ? humidade_values[4] : 100,
  ];
  const barHum = {
    minTolerance: ySize - (ySize / 90 * (humValues[3] - 10)),
    maxTolerance: ySize - (ySize / 90 * (humValues[0] - 10)),
    minLimit: ySize - (ySize / 90 * (humValues[4] - 10)),
    maxLimit: ySize - (ySize / 90 * (humValues[1] - 10)),
  }

  const abs = { position: 'absolute', left: '.5rem' };
  //green
  const floatValue2 = {
    position: `absolute`, left: `.5rem`,
    width: `calc(${barTemp.maxTolerance}rem - ${barTemp.minLimit}rem)`,
    height: `calc(${barHum.maxTolerance}rem - ${barHum.minTolerance}rem)`,

    marginLeft: `calc(4.3rem + ${barTemp.minLimit}rem)`,
    marginTop: `calc(.6rem + ${barHum.minTolerance}rem)`, overflow: `hidden`
  };
  const floatG2 = { marginTop: `calc(-.6rem + -${barHum.minTolerance}rem)`, marginLeft: `calc(-4.3rem + -${barTemp.minLimit}rem)` };
  //yellow
  const floatValue1 = {
    position: 'absolute', left: '.5rem',
    width: `calc(${barTemp.maxLimit}rem - ${barTemp.minTolerance}rem)`,
    height: `calc(${barHum.maxLimit}rem - ${barHum.minLimit}rem)`,
    marginLeft: `calc(4.3rem + ${barTemp.minTolerance}rem)`,
    marginTop: `calc(.6rem + ${barHum.minLimit}rem)`, overflow: `hidden`
  };
  const floatG1 = { marginTop: `calc(-.6rem + -${barHum.minLimit}rem)`, marginLeft: `calc(-4.3rem + -${barTemp.minTolerance}rem)` };

  useEffect(() => {
    const lTemperatura = temperatura && temperatura > 0 ? temperatura < 50 ? temperatura : 50 : 0;
    const lHumidade = humidade && humidade > 10 ? humidade < 100 ? humidade : 100 : 10;
    setPosTemp(xSize / 50 * lTemperatura);
    setPosHum(ySize - (ySize / 90 * (lHumidade - 10)));
  }, [temperatura, humidade]);
  //console.log('limitTemperatura', limitTemperatura);
  //console.log('limitHumidade', limitHumidade);
  return (
    <div style={{ marginTop: '2rem', marginLeft: '-.7rem', display: 'flex', justifyContent: 'center', height: '17rem', position: 'relative' }}>
      <div style={abs}>
        <canvas id={canvas[0]} width='400px' height='280px'></canvas>
      </div ><div style={floatValue1}>
        <canvas id={canvas[1]} width='400px' height='280px' style={floatG1}></canvas>
      </div><div style={floatValue2}>
        <canvas id={canvas[2]} width='400px' height='280px' style={floatG2}></canvas>
      </div><div style={abs}>
        <canvas id={canvas[3]} width='400px' height='280px'></canvas>
      </div>
      {
        temperatura_values ?
          <span>

            {temperatura_values[0] !== temperatura_values[1] && <div>
              <div style={{ width: '2px', height: '13rem', backgroundColor: colorText, position: 'absolute', top: '0', left: `calc(4.8rem + ${barTemp.minTolerance}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', top: '-1.2rem', left: `calc(4.8rem + ${barTemp.minTolerance}rem - 1rem)`, textAlign: 'center' }}>{temperatura_values[0]}</div>
            </div>}
            <div>
              <div style={{ width: '2px', height: '13.7rem', backgroundColor: colorText, position: 'absolute', top: '-.7rem', left: `calc(4.8rem + ${barTemp.minLimit}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', top: '-1.9rem', left: `calc(4.8rem + ${barTemp.minLimit}rem - 1rem)`, textAlign: 'center' }}>{temperatura_values[1]}</div>
            </div>
            {temperatura_values[3] !== temperatura_values[4] && <div>
              <div style={{ width: '2px', height: '13rem', backgroundColor: colorText, position: 'absolute', top: '0', left: `calc(4.7rem + ${barTemp.maxTolerance}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', top: '-1.2rem', left: `calc(4.7rem + ${barTemp.maxTolerance}rem - 1rem)`, textAlign: 'center' }}>{temperatura_values[3]}</div>
            </div>}
            <div>
              <div style={{ width: '2px', height: '13.7rem', backgroundColor: colorText, position: 'absolute', top: '-.7rem', left: `calc(4.7rem + ${barTemp.maxLimit}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', top: '-1.9rem', left: `calc(4.7rem + ${barTemp.maxLimit}rem - 1rem)`, textAlign: 'center' }}>{temperatura_values[4]}</div>
            </div>

            {humValues[3] !== humValues[4] && <div>
              <div style={{ height: '2px', width: '16.5rem', backgroundColor: colorText, position: 'absolute', left: '4.8rem', top: `calc(.5rem + ${barHum.minTolerance}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', left: '21.5rem', top: `calc(.5rem + ${barHum.minTolerance}rem - .6rem)`, textAlign: 'left' }}>{humValues[3]}</div>
            </div>}
            <div>
              <div style={{ height: '2px', width: '17.7rem', backgroundColor: colorText, position: 'absolute', left: '4.8rem', top: `calc(.5rem + ${barHum.minLimit}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', left: '22.7rem', top: `calc(.5rem + ${barHum.minLimit}rem - .6rem)`, textAlign: 'left' }}>{humValues[4]}</div>
            </div>
            {humValues[0] !== humValues[1] && < div >
              <div style={{ height: '2px', width: '16.5rem', backgroundColor: colorText, position: 'absolute', left: '4.8rem', top: `calc(.5rem + ${barHum.maxLimit}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', left: '21.5rem', top: `calc(.5rem + ${barHum.maxLimit}rem - .6rem)`, textAlign: 'left' }}>{humValues[1]}</div>
            </div>}
            <div>
              <div style={{ height: '2px', width: '17.7rem', backgroundColor: colorText, position: 'absolute', left: '4.8rem', top: `calc(.5rem + ${barHum.maxTolerance}rem)` }}></div>
              <div style={{ width: '2rem', position: 'absolute', left: '22.7rem', top: `calc(.5rem + ${barHum.maxTolerance}rem - .6rem)`, textAlign: 'left' }}>{humValues[0]}</div>
            </div>
          </span>
          :
          null
      }

      {
        atual && temperatura && humidade ?
          <div style={{
            width: '2.1rem', height: '2.1rem', display: 'flex', justifyContent: 'center', alignItems: 'center',
            border: '2px solid gray', backgroundColor: 'white', borderRadius: '1.3rem',
            position: 'absolute', top: `calc(.5rem + ${posHum}rem - 1rem)`, left: `calc(4.7rem + ${posTemp}rem - .9rem)`, fontWeight: 'bold', color: floatText.color
          }}>{isNaN(atual) ? 0 : Number(atual).toFixed(1)}</div>
          : null
      }
    </div >
  );
}
