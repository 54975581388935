import React, { createRef } from 'react';
import MenuList from '@material-ui/core/Menu';
import './style.css'

class Month extends React.Component {
    state = {
        listItem: [],
        itemHolding: null,
        count_size: 3,
        anchorEl: null,
        listDay: null,
    };
    resizeObserver = null;
    resizeElement = createRef();

    isMouseDown = false;

    typeJob = [
        '#6ec2e6',//pre_orcamento,
        '#3a8bc9',//planejamento
        '#52ab05'//execucao,
    ];

    componentDidMount() {
        this.getSize();
        this.resizeObserver = new ResizeObserver((entries) => {
            // do things

            this.getSize();
        });

        this.resizeObserver.observe(this.resizeElement.current);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.from !== this.props.from) {
            this.getSize();
        }
        if (prevProps.itens !== this.props.itens) {
            this.setState({ listItem: this.checkExtItens(this.props.itens) });
        }
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    addDay = (date, days) => {
        let temp_date = new Date(date);
        temp_date.setDate(date.getDate() + days);
        return temp_date;
    };
    diffDay = (from1, to1) => {
        //return Math.floor((to1 - from1) / (1000 * 60 * 60 * 24));
        return Math.floor((to1 - from1) / 86400000);
    };
    dateTimeReviver = (key, value) => {
        if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T/.exec(value)) {
            return new Date(value);
        }
        return value;
    };

    calcREM = (px) => {
        if (!isNaN(px)) {
            let remInPx = parseFloat(window
                .getComputedStyle(this.resizeElement.current, null)
                .getPropertyValue("font-size"));
            return (parseFloat(px) / remInPx);
        }
        return 0;
    }

    mouseClickDate = (el, date) => {
        this.setState({
            listDay: this.mountList(date),
            anchorEl: el.currentTarget,
        });
    };
    mouseClick = (itemBox) => {
        if (this.props.isMovable) {
            //'2024-05-15T12:30:00.000Z'
            const copy = JSON.parse(JSON.stringify(itemBox), this.dateTimeReviver);
            this.isMouseDown = copy;
            setTimeout(() => {
                if (this.isMouseDown) {
                    this.setState({
                        itemHolding: copy,
                        anchorEl: null,
                    });
                }
            }, 200);
        }
    };
    moveOver = (day, month, year) => {
        let ih = this.state.itemHolding;
        if (this.isMouseDown) {
            ih = this.isMouseDown;
            this.isMouseDown = false;
        }
        if (ih) {
            const dateStart = ih.item.timetable[ih.i].start;
            const from1 = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate());
            const to1 = new Date(year, month, day);
            const diffDays = this.diffDay(from1, to1);
            if (this.props.selType === 'all') {
                for (let i = 0; i < ih.item.timetable.length; i++) {
                    ih.item.timetable[i].start = this.addDay(ih.item.timetable[i].start, diffDays);
                    if (ih.item.timetable[i].end) {
                        ih.item.timetable[i].end = this.addDay(ih.item.timetable[i].end, diffDays);
                    }
                }
            } else {
                ih.item.timetable[ih.i].start = this.addDay(ih.item.timetable[ih.i].start, diffDays);
                if (ih.item.timetable[ih.i].end) {
                    ih.item.timetable[ih.i].end = this.addDay(ih.item.timetable[ih.i].end, diffDays);
                }
            }
            this.setState({ itemHolding: { ...ih } });
        }
    };
    mouseUp = (change) => {
        this.isMouseDown = false;
        if (this.state.itemHolding && change) {
            let k = null;//listItem.indexOf(it => it.id_job !== this.state.itemHolding.item.id_job);
            let list = this.state.listItem.filter((it, key) => {
                if (it.id_job !== this.state.itemHolding.item.id_job) {
                    return true;
                } else {
                    k = key;
                    return false;
                }
            });

            if (this.props.selType !== 'all') {
                const ito = this.state.listItem[k].timetable[this.state.itemHolding.i];
                const ith = this.state.itemHolding.item.timetable[this.state.itemHolding.i];
                const diff = this.diffDay(ito.start, ith.start);
                for (let i = 0; i < this.state.itemHolding.item.timetable.length; i++) {
                    const itc = this.state.itemHolding.item.timetable[i];
                    if (ito.start.getMonth() === itc.start.getMonth() &&
                        ito.start.getDate() === itc.start.getDate() &&
                        ito.start.getFullYear() === itc.start.getFullYear()) {
                        if (i !== this.state.itemHolding.i) {
                            itc.start = this.addDay(itc.start, diff);
                            itc.end = this.addDay(itc.end, diff);
                        }
                    }
                }
            }

            list.unshift(this.state.itemHolding.item);
            const itens = this.checkExtItens(list);
            this.setState({ listItem: itens });
            this.props.changeItens(itens);
        }
        this.setState({ itemHolding: null });
    };

    checkExtItens = (list) => {
        list.sort((a, b) => {
            const aStartJob = new Date(a.timetable[0].start.getFullYear(), a.timetable[0].start.getMonth(), a.timetable[0].start.getDate());
            let aEndJob;
            if (a.timetable[a.timetable.length - 1].end) {
                aEndJob = new Date(a.timetable[a.timetable.length - 1].end.getFullYear(), a.timetable[a.timetable.length - 1].end.getMonth(), a.timetable[a.timetable.length - 1].end.getDate());
            } else {
                aEndJob = new Date(a.timetable[a.timetable.length - 1].start.getFullYear(), a.timetable[a.timetable.length - 1].start.getMonth(), a.timetable[a.timetable.length - 1].start.getDate());
            }
            const bStartJob = new Date(b.timetable[0].start.getFullYear(), b.timetable[0].start.getMonth(), b.timetable[0].start.getDate());
            let bEndJob;
            if (b.timetable[b.timetable.length - 1].end) {
                bEndJob = new Date(b.timetable[b.timetable.length - 1].end.getFullYear(), b.timetable[b.timetable.length - 1].end.getMonth(), b.timetable[b.timetable.length - 1].end.getDate());
            } else {
                bEndJob = new Date(b.timetable[b.timetable.length - 1].start.getFullYear(), b.timetable[b.timetable.length - 1].start.getMonth(), b.timetable[b.timetable.length - 1].start.getDate());
            }
            return this.diffDay(bStartJob, bEndJob) - this.diffDay(aStartJob, aEndJob);
        });

        for (let i = 0; i < list.length; i++) {
            let add = [];
            let hasLeft = false;
            //remove os empty
            list[i].position = undefined;
            list[i].timetable = list[i].timetable.filter(it => !it.empty);
            list[i].timetable.sort((a, b) => { return a.start - b.start });
            //ajusta o inicio e fim da semana 
            for (let j = 0; j < list[i].timetable.length; j++) {
                const it = list[i].timetable[j];
                it.left = hasLeft;
                find: {
                    for (let j2 = j; j2 < list[i].timetable.length; j2++) {
                        const itn = list[i].timetable[j2];
                        const diff = this.diffDay(it.start, itn.start);
                        if (diff > 0) {
                            add.push({ diff: diff, i: j });
                            it.right = true;
                            hasLeft = true;
                            break find;
                        }
                    }
                    it.right = false;
                }
            }
            //add empty nos intervalos
            let plus = 1;
            for (let j = 0; j < add.length; j++) {
                for (let j2 = 1; j2 < add[j].diff; j2++) {
                    list[i].timetable.push({
                        start: this.addDay(list[i].timetable[add[j].i].start, j2),
                        left: true, right: true, empty: true
                    });
                }
                plus += add[j].diff;
            }
            list[i].timetable.sort((a, b) => { return a.start - b.start });
        }

        return list;
    };

    count_rows = 5;
    getSize = () => {
        this.count_rows = this.diffDay(this.props.from, this.props.to) / 7;
        if (this.resizeElement && this.resizeElement.current) {
            const size = (this.calcREM(this.resizeElement.current.clientHeight) / this.count_rows) - 1.5;
            this.setState({ count_size: Math.floor(size / 1.7) });
        }
    };

    mountItem = (key, itemBox, op, type) => {
        const comp = itemBox.item.timetable[itemBox.i];
        const isFirst = comp.start.getDay() === 0;
        const isLast = comp.start.getDay() === 6;
        let className;
        let showTitle = type === 'b' || !comp.empty;
        if (comp.left && comp.right && type === 'b') {
            if (comp.empty) {
                className = 'itemBoxCalendarBase itemBoxCalendarEnptyLeftEnd itemBoxCalendarEnptyRightEnd';
            } else {
                if(itemBox.item.type === 'pre_orcamento'){
                    className = 'itemBoxCalendarBase-po itemBoxCalendarLeftEnd-po itemBoxCalendarRightEnd-po';
                }else if(itemBox.item.type === 'planejamento'){
                    className = 'itemBoxCalendarBase itemBoxCalendarLeftEnd itemBoxCalendarRightEnd';
                }else{
                    className = 'itemBoxCalendarBase-exe itemBoxCalendarLeftEnd-exe itemBoxCalendarRightEnd-exe';
                }
            }
        } else if (comp.left && isFirst && comp.right) {
            if (comp.empty) {
                className = 'itemBoxCalendarBase itemBoxCalendarEnptyLeftEnd itemBoxCalendarEnptyRight';
            } else {
                if(itemBox.item.type === 'pre_orcamento'){
                    className = 'itemBoxCalendarBase-po itemBoxCalendarLeftEnd-po itemBoxCalendarRight-po';
                }else if(itemBox.item.type === 'planejamento'){
                    className = 'itemBoxCalendarBase itemBoxCalendarLeftEnd itemBoxCalendarRight';
                }else{
                    className = 'itemBoxCalendarBase-exe itemBoxCalendarLeftEnd-exe itemBoxCalendarRight-exe';
                }
            }
        } else if ((comp.left && isFirst) || (comp.left && type === 'b')) {
            if (comp.empty) {
                className = 'itemBoxCalendarBase itemBoxCalendarEnptyLeftEnd';
            } else {
                if(itemBox.item.type === 'pre_orcamento'){
                    className = 'itemBoxCalendarBase-po itemBoxCalendarLeftEnd-po';
                }else if(itemBox.item.type === 'planejamento'){
                    className = 'itemBoxCalendarBase itemBoxCalendarLeftEnd';
                }else{
                    className = 'itemBoxCalendarBase-exe itemBoxCalendarLeftEnd-exe';
                }                
            }
        } else {
            if (type === 'b' || (itemBox.i === 0 || (itemBox.item.timetable[itemBox.i - 1].empty && !comp.empty))) {
                showTitle = true;
            } else {
                showTitle = false;
            }
            if ((comp.right && isLast) || (comp.right && type === 'b')) {
                if (comp.empty) {
                    className = 'itemBoxCalendarBase itemBoxCalendarEnptyRightEnd';
                } else {
                    if(itemBox.item.type === 'pre_orcamento'){
                        className = 'itemBoxCalendarBase-po itemBoxCalendarRightEnd-po';
                    }else if(itemBox.item.type === 'planejamento'){
                        className = 'itemBoxCalendarBase itemBoxCalendarRightEnd';
                    }else{
                        className = 'itemBoxCalendarBase-exe itemBoxCalendarRightEnd-exe';
                    }                    
                }
            } else if (comp.right) {
                if (comp.empty) {
                    className = 'itemBoxCalendarBase itemBoxCalendarEnptyRight';
                } else {
                    if(itemBox.item.type === 'pre_orcamento'){
                        className = 'itemBoxCalendarBase-po itemBoxCalendarRight-po';
                    }else if(itemBox.item.type === 'planejamento'){
                        className = 'itemBoxCalendarBase itemBoxCalendarRight';
                    }else{
                        className = 'itemBoxCalendarBase-exe itemBoxCalendarRight-exe';
                    }                       
                }
            } else {
                if (comp.empty) {
                    className = 'itemBoxCalendarBase itemBoxCalendarEnpty';
                } else {
                    if(itemBox.item.type === 'pre_orcamento'){
                        className = 'itemBoxCalendarBase-po itemBoxCalendar-po';
                    }else if(itemBox.item.type === 'planejamento'){
                        className = 'itemBoxCalendarBase itemBoxCalendar';
                    }else{
                        className = 'itemBoxCalendarBase-exe itemBoxCalendar-exe';
                    }                    
                }
            }
        }
        return <div key={key}
            onMouseDown={() => {
                if (!op || (!comp.empty && this.props.selType === 'all')) {
                    this.mouseClick(itemBox);
                }
            }}
            onClick={() => { this.props.openItem(itemBox.item) }}
            className={className}
            style={{ cursor: (!op || (!comp.empty && this.props.selType === 'all')) ? 'pointer' : 'default', zIndex: 10, opacity: (!op && this.state.itemHolding && this.state.itemHolding.item.id_job === itemBox.item.id_job) ? '.5' : '1' }}>
            <span style={{ whiteSpace: 'nowrap', color: 'white', padding: '.5rem' }}>{showTitle && itemBox.item.title}</span>
        </div>;
    };

    mountGrid = () => {
        const mountWeek = (listDays) => {
            return <tr style={{ height: `${100 / this.count_rows}%` }}>{listDays}</tr>
            //return <div className='unselectable' style={{ display: 'grid', gridAutoColumns: 'minmax(0, 1fr)', gridAutoFlow: 'column' }}>{listDays}</div>;
        };
        const mountOverSize = (qtd, date) => {
            return <div onClick={(el) => this.mouseClickDate(el, date)}
                style={{ cursor: 'pointer', textAlign: 'center', height: '1.4rem', borderRadius: '3px', border: 'solid 1px #0000004a' }}
            >
                <span>Mais {qtd}</span>
            </div>;
        };
        const mountOverSizeSmall = (qtd, date) => {
            return <div onClick={(el) => this.mouseClickDate(el, date)}
                style={{ position: 'absolute', right: '.25rem', top: '0.25rem', textAlign: 'center', cursor: 'pointer', width: 'calc(50% - .15rem)', height: '1.4rem', borderRadius: '3px', border: 'solid 1px #0000004a' }}
            >
                <span>{qtd}</span>
            </div>;
        };

        if (this.props.from > this.props.to) {
            return;
        }
        //ordena itens  por dia
        let currentD = new Date(this.props.from.getFullYear(), this.props.from.getMonth(), this.props.from.getDate());
        let list_weeks = [];

        for (let i = 0; i < this.count_rows; i++) {//semanas
            let listDays = [];
            for (let j = 0; j < 7 && currentD < this.props.to; j++, currentD = this.addDay(currentD, 1)) {// dias
                const currentDay = currentD.getDate();
                const currentMonth = currentD.getMonth();
                const currentYear = currentD.getFullYear();
                const dayitens = [];
                let overSize = 0;
                const listDayItem = this.state.listItem.filter(el2 => {
                    const startJob = new Date(el2.timetable[0].start.getFullYear(), el2.timetable[0].start.getMonth(), el2.timetable[0].start.getDate());
                    let endJob;
                    if (el2.timetable[el2.timetable.length - 1].end) {
                        endJob = new Date(el2.timetable[el2.timetable.length - 1].end.getFullYear(), el2.timetable[el2.timetable.length - 1].end.getMonth(), el2.timetable[el2.timetable.length - 1].end.getDate());
                    } else {
                        endJob = new Date(el2.timetable[el2.timetable.length - 1].start.getFullYear(), el2.timetable[el2.timetable.length - 1].start.getMonth(), el2.timetable[el2.timetable.length - 1].start.getDate());
                    }

                    return (currentD >= startJob && currentD <= endJob);
                });
                //console.log('listItem', this.state.listItem);
                //console.log('listDayItem', listDayItem);

                for (let i = 0; i < listDayItem.length; i++) {//jobs
                    const el = listDayItem[i];
                    time: for (let j = 0; j < el.timetable.length; j++) {
                        const it = el.timetable[j];
                        if (currentMonth === it.start.getMonth() && currentDay === it.start.getDate() && currentYear === it.start.getFullYear()) {
                            //check existencia no mesmo dia
                            for (let k = j - 1; k >= 0; k--) {
                                const itt = el.timetable[k];
                                if (currentMonth === itt.start.getMonth() && currentDay === itt.start.getDate() && currentYear === itt.start.getFullYear()) {
                                    continue time;
                                }
                            }

                            if (isNaN(el.position)) {
                                find: {
                                    for (let i2 = 0; i2 < 99; i2++) {
                                        const findItem = listDayItem.findIndex(el2 => el2.position === i2);
                                        if (findItem === -1) {
                                            el.position = i2;
                                            break find;
                                        }
                                    }
                                }
                            }
                            dayitens.push({ item: el, i: j });
                        }
                    }
                    if (el.position >= this.state.count_size) {//check overSize
                        overSize++;
                    }
                }

                //remove o item da ultima posicao quando tem overSize
                if (overSize === 1) {
                    for (let i = 0; i < listDayItem.length; i++) {
                        const el = listDayItem[i];
                        if (el.position === this.state.count_size - 1 &&
                            listDays.length > 0 && listDays[listDays.length - 1].size_itens.length === this.state.count_size) {
                            for (let j = 0; j < el.timetable.length; j++) {
                                const it = el.timetable[j];
                                if (listDays[listDays.length - 1].currentMonth === it.start.getMonth() &&
                                    listDays[listDays.length - 1].currentDay === it.start.getDate() &&
                                    listDays[listDays.length - 1].currentYear === it.start.getFullYear()) {
                                    listDays[listDays.length - 1].size_itens.splice(-1);
                                    listDays[listDays.length - 1].overSize++;
                                    break;
                                }
                            }
                        }
                    }
                }

                let lastPos = null;
                for (let k = 0; k < dayitens.length; k++) {
                    if (!lastPos || dayitens[k].item.position > lastPos.position) {
                        lastPos = dayitens[k].item;
                    }
                }
                if (overSize === 0 && (lastPos && (lastPos.position + 1) === this.state.count_size)) {
                    if (listDays.length > 0 && listDays[listDays.length - 1].overSize > 0) {
                        //busca dia anterior na ultima posicao possivel com oversize
                        const lastDay = lastPos.timetable.findIndex(tt2 => {
                            const startJob = new Date(tt2.start.getFullYear(), tt2.start.getMonth(), tt2.start.getDate());
                            const currentM1D = new Date(currentD.getFullYear(), currentD.getMonth(), currentD.getDate() - 1);
                            return (currentM1D.getMonth() === startJob.getMonth() &&
                                currentM1D.getDate() === startJob.getDate() &&
                                currentM1D.getFullYear() === startJob.getFullYear());
                        });
                        if (lastDay > -1) {//se existe
                            overSize++;
                        }
                    }
                }

                dayitens.sort((a, b) => (a.item.position - b.item.position));

                const temp_size = overSize > 0 ? this.state.count_size - 1 : this.state.count_size
                let size_itens = [];
                for (let k = 0; k < dayitens.length && (k < temp_size || dayitens.length === 1); k++) {
                    if (dayitens[k].item.position < temp_size) {
                        size_itens[dayitens[k].item.position] = (
                            <div style={{ width: '95%', margin: '0 0 .2rem .2rem' }}>
                                {this.mountItem(i * k * j, dayitens[k])}
                            </div>
                        );
                    }
                }

                for (let k = 0, a = 0; k < temp_size; k++) {
                    if (a === dayitens.length) {
                        break;
                    }
                    if (!size_itens[k]) {
                        size_itens[k] = (
                            <div style={{ width: '95%', height: '1.4rem', margin: '0 0 .2rem .2rem' }}></div>
                        );
                    } else {
                        a++;
                    }
                }

                let holding;
                if (this.state.itemHolding) {
                    let index = this.state.itemHolding.item.timetable.findIndex((it, key) => {
                        let isDate = currentMonth === it.start.getMonth() &&
                            currentDay === it.start.getDate() &&
                            currentYear === it.start.getFullYear();
                        if (this.props.selType === 'all') {
                            return isDate;
                        } else {
                            return isDate && key === this.state.itemHolding.i;
                        }
                    });
                    holding = {
                        item: this.state.itemHolding.item,
                        i: index
                    };
                }
                listDays.push({
                    currentDay, currentMonth, currentYear, currentD, holding, count_size: this.state.count_size, temp_size, dayitens, size_itens, overSize
                });
            }
            list_weeks.push(listDays);
        }

        //monta componentes 
        let list_comp_weeks = [];
        for (let i = 0; i < list_weeks.length; i++) {
            let listDays = [];
            for (let j = 0; j < list_weeks[i].length; j++) {
                const l = list_weeks[i][j];

                listDays.push(
                    <td key={i + j + 'd'} style={{ border: 'solid 1px #0000002b', position: 'relative' }} onMouseOver={() => this.moveOver(l.currentDay, l.currentMonth, l.currentYear)}>
                        <div key={i + j + 'd2'} style={{ height: '100%' }}>
                            <div className='dayNumber-button' style={{ textAlign: l.count_size > 0 ? 'center' : 'left' }}>
                                {l.currentD.getDate()}
                            </div>
                            {l.holding && l.holding.i >= 0 &&
                                <div style={{ zIndex: 20, border: 'solid 2px white', position: 'absolute', width: '95%', marginLeft: '.1.8rem', top: '.4rem', left: '.6rem' }}>
                                    {this.mountItem(i * j + 'a', l.holding, true)}
                                </div>
                            }
                            {l.count_size > 0 && l.size_itens}
                            {l.overSize && l.overSize > 0 ?
                                l.count_size > 0 ?
                                    <div style={{ width: '95%', margin: '0 0 .2rem .2rem' }}>
                                        {mountOverSize(l.dayitens.length > l.count_size ? l.overSize + 1 : l.overSize, l.currentD)}
                                    </div> : l.size_itens.length > 0 &&
                                    <div style={{ width: '95%', margin: '0 0 .2rem .2rem' }}>
                                        {mountOverSizeSmall(l.size_itens.length, l.currentD)}
                                    </div>
                                : null
                            }
                        </div>
                    </td >);
            }
            list_comp_weeks.push(mountWeek(listDays));
        }

        return <table
            className='unselectable' style={{ height: 'calc(100% - 1.2rem)', width: '100%', tableLayout: 'fixed' }}>{list_comp_weeks}</table>;
        //return <div style={cssGrid}>{list_comp_weeks}</div>;
    };

    mountList = (date) => {
        const currentDay = date.getDate();
        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();
        const dayitens = [];
        for (let i = 0; i < this.state.listItem.length; i++) {
            const el = this.state.listItem[i];
            for (let j = 0; j < el.timetable.length; j++) {
                const it = el.timetable[j];
                if (currentMonth === it.start.getMonth() && currentDay === it.start.getDate() && currentYear === it.start.getFullYear()) {
                    dayitens.push({ item: el, i: j });
                }
            }
        }

        dayitens.sort((a, b) => (a.item.position - b.item.position));

        let size_itens = [];
        for (let k = 0; k < dayitens.length; k++) {
            size_itens.push(
                <div style={{ width: '95%', margin: '0 0 .2rem .2rem' }}>
                    {this.mountItem(k + 's', dayitens[k], true, 'b')}
                </div>
            );
        }

        return [size_itens];
    };

    render() {
        const spanCss = { color: '#00000061', textAlign: 'center' };

        return (
            <div style={{ width: 'calc(100% - 1rem)', height: '100%', position: 'relative', margin: '.5rem' }}
                onMouseLeave={() => this.mouseUp(false)} onMouseUp={() => this.mouseUp(true)}>
                <div
                    ref={ele => { this.resizeElement.current = ele }}
                    style={{ height: '100%', display: 'flex' }}
                >
                    {/* <div style={{ height: '100%', width: '1rem', backgroundColor: 'blue' }}></div> */}
                    {this.mountGrid()}
                    {/* <div style={{ height: '100%', width: '1rem', backgroundColor: 'blue' }}></div> */}
                </div>
                <MenuList
                    className="popup-list"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                    transitionDuration={{ exit: 0 }}
                >
                    <div
                        className='unselectable'
                        onMouseLeave={() => { if (this.state.itemHolding) this.state({ anchorEl: null }) }}
                        style={{ width: '20vh', height: '100%', padding: '0 .4rem' }}
                    >
                        {this.state.listDay}
                    </div>
                </MenuList>
            </div>
        );
    }
}

export default Month;
