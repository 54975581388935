import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import HelpIcon from '@material-ui/icons/HelpOutline';
import SaveIcon from '@material-ui/icons/Save';

import EspectroGota from '../../../assets/images/espectroGota.png'

import { useParams } from "react-router";
import Config from '../../../constants/appConfig';
import MaterialIcon from './../../../components/MaterialIcon';
import { notification } from 'antd';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    primary: {
        backgroundColor: '#3f51b5',
        color: 'white',
    },
    secondary: {
        marginTop: '1rem',
        marginRight: '0.5rem',
        backgroundColor: '#e0e0e0',
        color: 'white',
    },
    flowEF: {
        backgroundColor: '#ab06ab',
        fontWeight: 'bold'
    },
    flowMF: {
        backgroundColor: 'red',
        fontWeight: 'bold'
    },
    flowF: {
        backgroundColor: 'orange',
        fontWeight: 'bold'
    },
    flowM: {
        backgroundColor: 'yellow',
        fontWeight: 'bold'
    },
    flowG: {
        backgroundColor: 'dodgerblue',
        fontWeight: 'bold'
    },
    flowMG: {
        backgroundColor: 'forestgreen',
        fontWeight: 'bold'
    },
    flowEG: {
        backgroundColor: 'white',
        fontWeight: 'bold'
    },
    flowUG: {
        backgroundColor: 'black',
        color: 'white',
        fontWeight: 'bold'
    },
    buttonCalibrationTable: {
        marginTop: '2rem',
        backgroundColor: 'white',
        border: '1px solid #3f51b5',
        color: '#3f51b5'
    },
    centerTextField: {
        textAlign: 'center',
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});

class Calibration extends React.Component {
    state = {
        id: this.props.id,
        id_calibration: '',
        id_equipment: this.props.id_equipment,
        prop: this.props,
        type: '',
        sprayClassification: '',
        minimumSpeed: '',
        averageSpeed: '',
        maximumSpeed: '',
        applicationRange: '',
        applicationFee: '',
        quantityNozzles: '',
        quantityAtomizer: '',
        coveredAreaMin: 0,
        coveredAreaMed: 0,
        coveredAreaMax: 0,
        totalVolumeMin: 0,
        totalVolumeMed: 0,
        totalVolumeMax: 0,
        flowEquipmentMin: 0,
        flowEquipmentMed: 0,
        flowEquipmentMax: 0,
        sprayClassification: '',
        pressureMin: '',
        pressureMed: '',
        pressureMax: '',
        equipmentData: {},
    };

    componentDidMount() {
        this.readEquipment();
        this.readCalibration();
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    };

    readCalibration = () => {
        const { id_equipment } = this.state;

        const apiUrl = Config.server + `/plane_equipment_calibration/${id_equipment}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        let calibration;
                        const calibrationData = result.map((equipments) => {
                            return calibration = equipments;
                        })
                        this.setState({
                            id_calibration: calibration.id,
                            minimumSpeed: calibration.minimumSpeed,
                            averageSpeed: calibration.averageSpeed,
                            maximumSpeed: calibration.maximumSpeed,
                            applicationRange: calibration.applicationRange,
                            applicationFee: calibration.applicationFee,
                            quantityNozzles: calibration?.quantityNozzle,
                            quantityAtomizer: calibration?.quantityAtomizer,
                            sprayClassification: calibration.sprayClassification,
                            pressureMin: calibration.minimumPressure,
                            pressureMed: calibration.averagePressure,
                            pressureMax: calibration.maximumPressure,
                        });
                    }
                    this.calcTotal();
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    handleChangeType = (type) => {
        this.setState({ sprayClassification: type });
    };

    getFlowClass = (value) => {
        const classAcronym = value.match(/\(([^)]+)\)/);

        if (classAcronym && classAcronym[1]) {
            const acronym = classAcronym[1];
            return this.props.classes['flow' + acronym];
        }

        return this.props.classes.selectDropType;
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        }, () => {
            setTimeout(() => {
                this.calcTotal();
            }, 50)
        });
    };

    calcTotal = () => {
        const { minimumSpeed, averageSpeed, maximumSpeed, applicationFee, quantityNozzles, quantityAtomizer, applicationRange } = this.state;
        const convertKMHtoMPM = (speed) => {
            return Number(speed) * 16.6666667;
        }

        let areaMin = convertKMHtoMPM(minimumSpeed) * applicationRange / 10000;
        let areaMed = convertKMHtoMPM(averageSpeed) * applicationRange / 10000;
        let areaMax = convertKMHtoMPM(maximumSpeed) * applicationRange / 10000;
        let literMin = areaMin * applicationFee;
        let literMed = areaMed * applicationFee;
        let literMax = areaMax * applicationFee;
        let pLiterMin;
        let pLiterMed;
        let pLiterMax;
        if (quantityNozzles > 0) {
            pLiterMin = quantityNozzles > 0 ? literMin / quantityNozzles : 0;
            pLiterMed = quantityNozzles > 0 ? literMed / quantityNozzles : 0;
            pLiterMax = quantityNozzles > 0 ? literMax / quantityNozzles : 0;
        } else if (quantityAtomizer > 0) {
            pLiterMin = quantityAtomizer > 0 ? literMin / quantityAtomizer : 0;
            pLiterMed = quantityAtomizer > 0 ? literMed / quantityAtomizer : 0;
            pLiterMax = quantityAtomizer > 0 ? literMax / quantityAtomizer : 0;
        }

        this.setState({
            flowEquipmentMin: pLiterMin,
            flowEquipmentMed: pLiterMed,
            flowEquipmentMax: pLiterMax,
            totalVolumeMin: literMin,
            totalVolumeMed: literMed,
            totalVolumeMax: literMax,
            coveredAreaMin: areaMin,
            coveredAreaMed: areaMed,
            coveredAreaMax: areaMax,
        })
    }

    handleChangeSpeed = name => (event) => {
        const value = parseFloat(event.target.value);

        this.setState({ [name]: value }, () => {
            const { minimumSpeed, maximumSpeed } = this.state;
            const averageSpeed = (minimumSpeed + maximumSpeed) / 2;
            this.setState({ averageSpeed });
        });
    };

    readEquipment = () => {
        const { id_equipment } = this.state;

        const apiUrl = Config.server + `/plane_equipment/${id_equipment}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result.length > 0) {
                        let data;

                        const equipmentPlane = result.map((equipments) => {
                            return data = equipments;
                        })
                        this.setState({
                            equipmentData: data,
                            quantityNozzles: data?.quantityNozzle,
                            quantityAtomizer: data?.quantityAtomizer,
                            type: data.equipmentType,
                        });
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    registerCalibration = () => {
        const { id, id_calibration, id_equipment, minimumSpeed, averageSpeed, maximumSpeed, applicationRange, applicationFee,
            sprayClassification, pressureMin, pressureMed, pressureMax, quantityNozzles, quantityAtomizer, type, flowEquipmentMin,
            flowEquipmentMed, flowEquipmentMax, totalVolumeMin, totalVolumeMed, totalVolumeMax, coveredAreaMin, coveredAreaMed, coveredAreaMax
        } = this.state;
        let apiUrl = '';
        let method = '';
        apiUrl = Config.server + '/plane_equipment_calibration';

        const formData = new URLSearchParams();
        if (id_calibration && id_equipment) {
            apiUrl += `/${id_calibration}`;
            formData.append('id', id_calibration);
            method = 'PUT';
        } else {
            method = 'POST';
        }

        formData.append('id_plane', id);
        formData.append('id_equipment', id_equipment);
        formData.append('minimumSpeed', minimumSpeed);
        formData.append('averageSpeed', averageSpeed);
        formData.append('maximumSpeed', maximumSpeed);
        formData.append('applicationRange', applicationRange);
        formData.append('applicationFee', applicationFee);
        formData.append('sprayClassification', sprayClassification);
        formData.append('minimumCoveredArea', coveredAreaMin.toFixed(2));
        formData.append('averageCoveredArea', coveredAreaMed.toFixed(2));
        formData.append('maximumCoveredArea', coveredAreaMax.toFixed(2));
        formData.append('minimumTotalVolume', totalVolumeMin.toFixed(2));
        formData.append('averageTotalVolume', totalVolumeMed.toFixed(2));
        formData.append('maximumTotalVolume', totalVolumeMax.toFixed(2));
        formData.append('minimumEquipmentVolume', flowEquipmentMin.toFixed(2));
        formData.append('averageEquipmentVolume', flowEquipmentMed.toFixed(2));
        formData.append('maximumEquipmentVolume', flowEquipmentMax.toFixed(2));
        formData.append('minimumPressure', pressureMin);
        formData.append('averagePressure', pressureMed);
        formData.append('maximumPressure', pressureMax);
        if (type === 'Bico Hidráulico') {
            formData.append('quantityNozzle', quantityNozzles);
        } else if (type === 'Atomizador') {
            formData.append('quantityAtomizer', quantityAtomizer);
        }

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result && result.error) {
                        notification.open({
                            message: this.props.res[result.message],
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else {
                        let msg = this.props.res.ITEM_INCLUIR;
                        if (id) {
                            msg = this.props.res.ITEM_ALTERADO;
                        }
                        notification.open({
                            message: this.props.res.SUCESSO,
                            description: msg,
                            icon: <MaterialIcon icon="check_circle" className="text-success" />
                        });
                    }
                    this.readCalibration();
                    this.props.link(`/app/cadastro/aeronave/edit/${id}`)
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG_INFORMACOES,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    }

    render() {
        const { link, res, classes } = this.props;
        const { id, minimumSpeed, averageSpeed, maximumSpeed, applicationRange, applicationFee, quantityNozzles, quantityAtomizer, pressureMin,
            pressureMed, pressureMax, sprayClassification, flowEquipmentMin, flowEquipmentMed, flowEquipmentMax, totalVolumeMin, totalVolumeMed, totalVolumeMax,
            coveredAreaMin, coveredAreaMed, coveredAreaMax, type
        } = this.state;

        return (
            <div>
                <div className="containerTop">
                    <div>
                        <h1>{res.CALIBRACAO}</h1>
                    </div>
                    <div className="centerTop" />
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => (link(`/app/cadastro/aeronave/edit/${id}`))}
                            className="popUpButton"
                        >
                            {res.CANCELAR}
                        </Button> &nbsp;
                        <Button type="submit" variant="contained" color="primary" className="popUpButton" onClick={this.registerCalibration}>
                            <SaveIcon className="iconMapS" />
                            {res.SALVAR}
                        </Button>
                    </div>
                </div>
                <div className="form-group notTooBig">
                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                        <TextField
                            id="minimumSpeed"
                            label={res.VELOCIDADE_MINIMA}
                            type="number"
                            onChange={this.handleChange('minimumSpeed')}
                            value={minimumSpeed}
                            style={{ width: '100%', paddingTop: '1rem' }}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                        <TextField
                            id="averageSpeed"
                            label={res.VELOCIDADE_MEDIA}
                            type="number"
                            onChange={this.handleChange('averageSpeed')}
                            value={averageSpeed}
                            style={{ width: '100%', paddingTop: '1rem' }}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                        <TextField
                            id="maximumSpeed"
                            label={res.VELOCIDADE_MAXIMA}
                            type="number"
                            onChange={this.handleChange('maximumSpeed')}
                            value={maximumSpeed}
                            style={{ width: '100%', paddingTop: '1rem' }}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                        <TextField
                            id="applicationRange"
                            label={res.FAIXA}
                            type="number"
                            onChange={this.handleChange('applicationRange')}
                            value={applicationRange}
                            style={{ width: '100%', paddingTop: '1rem' }}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                        <TextField
                            id="applicationFee"
                            label={res.TAXA_APLICACAO}
                            type="number"
                            onChange={this.handleChange('applicationFee')}
                            value={applicationFee}
                            style={{ width: '100%', paddingTop: '1rem' }}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: '100%', paddingTop: '1rem' }}>
                        <TextField
                            id="quantityNozzles"
                            label={type === 'Bico Hidráulico' ? res.QUANTIDADE_BICOS : res.QUANTIDADE_ATOMIZADORES}
                            value={type === 'Bico Hidráulico' ? quantityNozzles : quantityAtomizer}
                            style={{ width: '100%', paddingTop: '1rem' }}
                        />
                    </FormControl>
                    <div className="containerButtonDrop">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <InputLabel htmlFor="type" className="titleItem">{res.CLASSE_PULVERIZAÇÃO}</InputLabel>
                            <Tooltip
                                title={<img src={EspectroGota} alt="Tabela classe de pulverização" style={{ width: '30rem' }} />}
                                classes={{ tooltip: classes.noMaxWidth }}
                                style={{ cursor: 'help' }}
                            >
                                <Button>
                                    <HelpIcon />
                                </Button>
                            </Tooltip>
                        </div>
                        <select
                            value={sprayClassification}
                            onChange={(event) => this.handleChangeType(event.target.value)}
                            variant="outlined"
                            className={`selectDropType ${this.getFlowClass(sprayClassification)}`}
                            style={{ fontSize: '1rem' }}
                        >
                            <option disabled style={{ color: 'black' }} value="">Selecione uma classe</option>
                            <option value="Extremamente Fina (EF)" className={classes.flowEF}>
                                {res.EXTREMAMENTE_FINA}
                            </option>
                            <option value="Muito Fina (MF)" className={classes.flowMF}>
                                {res.MUITO_FINA}
                            </option>
                            <option value="Fina (F)" className={classes.flowF}>
                                {res.FINA}
                            </option>
                            <option value="Média (M)" className={classes.flowM}>
                                {res.MEDIA}
                            </option>
                            <option value="Grossa (G)" className={classes.flowG}>
                                {res.GROSSA}
                            </option>
                            <option value="Muito Grossa (MG)" className={classes.flowMG}>
                                {res.MUITO_GROSSA}
                            </option>
                            <option value="Extremamente Grossa (EG)" className={classes.flowEG}>
                                {res.EXTREMAMENTE_GROSSA}
                            </option>
                            <option value="Ultra Grossa (UG)" className={classes.flowUG}>
                                {res.ULTRA_GROSSA}
                            </option>
                        </select>
                    </div>
                </div>
                <div>

                    {/* Eduardo está finalizando nova tabela de calibração, aguardando para adicionar modal com documento */}
                    {/* <div className="containerCalibrationTableButton">
                        <Button
                            className={classes.buttonCalibrationTable}
                            variant="contained"
                        >
                            {res.CONSULTAR_TABELA}
                        </Button>
                    </div> */}
                    <Table className="calibration-table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{res.VELOCIDADE_MINIMA}</TableCell>
                                <TableCell>{res.VELOCIDADE_MEDIA}</TableCell>
                                <TableCell>{res.VELOCIDADE_MAXIMA}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{`${res.VELOCIDADE} (km/h)`}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="minimumSpeed"
                                        name="minimumSpeed"
                                        value={minimumSpeed > 0 ? minimumSpeed : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="averageSpeed"
                                        name="averageSpeed"
                                        value={averageSpeed > 0 ? averageSpeed : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="maximumSpeed"
                                        name="maximumSpeed"
                                        value={maximumSpeed > 0 ? maximumSpeed : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{`${res.AREA_COBERTA} (hec/min)`}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="coveredAreaMin"
                                        name="coveredAreaMin"
                                        value={coveredAreaMin > 0 ? coveredAreaMin.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="coveredAreaMed"
                                        name="coveredAreaMed"
                                        value={coveredAreaMed > 0 ? coveredAreaMed.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="coveredAreaMax"
                                        name="coveredAreaMax"
                                        value={coveredAreaMax > 0 ? coveredAreaMax.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{`${res.VOLUME_TOTAL} (l/min)`}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="totalVolumeMin"
                                        name="totalVolumeMin"
                                        value={totalVolumeMin > 0 ? totalVolumeMin.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="totalVolumeMed"
                                        name="totalVolumeMed"
                                        value={totalVolumeMed > 0 ? totalVolumeMed.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="totalVolumeMax"
                                        name="totalVolumeMax"
                                        value={totalVolumeMax > 0 ? totalVolumeMax.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{quantityNozzles ? `${res.VOLUME_BICO} (l/min)` : `${res.VOLUME_ATOMIZADOR} (l/min)`}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="flowEquipmentMin"
                                        name="flowEquipmentMin"
                                        value={flowEquipmentMin > 0 ? flowEquipmentMin.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="flowEquipmentMed"
                                        name="flowEquipmentMed"
                                        value={flowEquipmentMed > 0 ? flowEquipmentMed.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="flowEquipmentMax"
                                        name="flowEquipmentMax"
                                        value={flowEquipmentMax > 0 ? flowEquipmentMax.toFixed(2) : 0}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{`${res.PRESSAO} (psi)`}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="pressureMin"
                                        name="pressureMin"
                                        onChange={this.handleChange('pressureMin')}
                                        value={pressureMin}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="pressureMed"
                                        name="pressureMed"
                                        onChange={this.handleChange('pressureMed')}
                                        value={pressureMed}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        id="pressureMax"
                                        name="pressureMax"
                                        onChange={this.handleChange('pressureMax')}
                                        value={pressureMax}
                                        InputProps={{
                                            classes: {
                                                input: classes.centerTextField,
                                            },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}

Calibration.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Calibration);

const Box = (props) => {
    const { link, res } = props;
    let { id, id_equipment } = useParams();
    return (
        <div className="rowForm">
            <div className="divContent">
                <TextFields1 link={link} id={id} id_equipment={id_equipment} res={res} />
            </div>
        </div>)
}

export default Box;

