import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import ListProperties from './property/List';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    id: this.props.id,
    name: '',
    cpf_cnpj: '',
    email: '',
    cep: '',
    uf: '',
    localidade: '',
    bairro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    fone: '',
    tipo_pessoa: 'F',
    image: '',
    imagehaschanged: false,
    data: [],
    order: 'asc',
    orderBy: 'name',
    selected: [],
    page: 0,
    rowsPerPage: 10,
    guid: null
  };

  componentDidMount() {
    if (this.state.id) {
      const apiUrl = Config.server + '/customer/' + this.state.id;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              this.setState({
                name: result[0].name,
                cpf_cnpj: result[0].cpf_cnpj,
                fone: result[0].fone,
                email: result[0].email,
                cep: result[0].cep || '',
                uf: result[0].uf || '',
                localidade: result[0].localidade || '',
                bairro: result[0].bairro || '',
                logradouro: result[0].logradouro || '',
                numero: result[0].numero || '',
                complemento: result[0].complemento || '',
                guid: result[0].guid || null,
                image: result[0].image
              });
            }
          },
          (error) => {
            if (error.status === 401) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  loadImage = file => {
    if (file.target.files.length > 0) {
      let filePath = file.target.files[0];

      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        this.setState({
          image: fileReader.result,
          imagehaschanged: true
        });
      };

      fileReader.readAsDataURL(filePath);
    }
  };

  maskedCPFCNPJ = (props) => {
    const { inputRef, ...other } = props;
    if (this.state.tipo_pessoa === "F") {
      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
        />
      );
    } else {
      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
        />
      );
    }
  };

  handleChange = name => event => {

    if (name === 'tipo_pessoa') {
      this.setState({ cpf_cnpj: '' });
    }

    this.setState({
      [name]: event.target.value,
    });
  };

  handleAircraftBrandChange = event => {
    this.setState({
      id_aircraftbrand: event.target.value,
      id_aircraftmodel: ''
    });
    if (event.target.value) {
      this.loadAircraftModels(event.target.value);
    }
  };

  handleCEP = event => {
    const limit = 8;
    const cep = event.target.value.slice(0, limit);

    this.setState({
      cep: cep,
    });

    if (cep.length < 8) {
      return;
    }

    const apiUrl = Config.server + '/cep';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'cep': cep
      }
    }

    fetch(apiUrl, options)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            uf: result.uf || '',
            localidade: result.localidade || '',
            bairro: result.bairro || '',
            logradouro: result.logradouro || ''
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      )

  };

  handleSubmit = (event) => {
    event.preventDefault();
    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/customer/';

    const formData = new URLSearchParams();
    if (this.state.id) {
      apiUrl += this.state.id;
      formData.append('id', this.state.id);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    formData.append('cpf_cnpj', this.state.cpf_cnpj);
    formData.append('name', this.state.name);
    formData.append('email', this.state.email);
    formData.append('fone', this.state.fone);
    formData.append('cep', this.state.cep);
    formData.append('uf', this.state.uf);
    formData.append('localidade', this.state.localidade);
    formData.append('bairro', this.state.bairro);
    formData.append('logradouro', this.state.logradouro);
    formData.append('numero', this.state.numero);
    formData.append('complemento', this.state.complemento);
    formData.append('guid', this.state.guid);

    if (this.state.imagehaschanged) {
      formData.append('image', this.state.image);
    }

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          let msg = this.props.res.ITEM_INCLUIR;
          if (this.state.id) {
            msg = this.props.res.ITEM_ALTERADO;
          }
          notification.open({
            message: this.props.res.SUCESSO,
            description: msg,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
          if (!this.state.id) {
            this.setState({
              name: '',
              cpf_cnpj: '',
              email: '',
              cep: '',
              logradouro: '',
              numero: '',
              bairro: '',
              localidade: '',
              uf: '',
              complemento: '',
              fone: '',
              guid: null
            });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  render() {
    const { classes, link, res } = this.props;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="containerTop">
            <div><h1>{res.CLIENTE}</h1></div>
            <div className="centerTop"></div>
            <div>
              <Button variant="contained" onClick={evemt => { link('/app/cadastro/cliente/list') }} className="popUpButton">
                {res.CANCELAR}
              </Button> &nbsp;
              <Button type="submit" variant="contained" color="primary" className="popUpButton">
                <SaveIcon className="iconMapS" />
                {res.SALVAR}
              </Button>
            </div>
          </div>
          <div className="form-group notTooBig">
            <TextField
              id="name"
              className={classes.textField}
              label={res.NOME}
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <FormControl
              className={classes.textField}
              fullWidth>

              {this.state.image && <img src={this.state.image} width={200} height={200} alt="Logo" />}
              <label htmlFor="contained-button-file" style={{ display: 'inline', textAlign: 'left', marginTop: '1rem', marginBottom: '1rem' }}>
                <input
                  accept=".jpg, .bmp, .png"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={this.loadImage}
                />
                <label htmlFor="contained-button-file">

                  <Button component="span" variant="contained" color="primary" className="popUpButton" title={this.props.res.IMPORTAR_KMZ}>
                    <MaterialIcon icon="attach_file" className="iconMap" />
                    Carregar Imagem
                  </Button>
                </label>
              </label>
            </FormControl>
            <FormControl
              style={{ padding: ".4rem 0 .4rem 0" }}
              className={classes.textField}
              fullWidth
              component="fieldset">
              <div className='titleTextRel'>{res.PESSOA}</div>
              <RadioGroup row id="tipo_pessoa" aria-label="pessoa" name="pessoa1" value={this.state.tipo_pessoa} onChange={this.handleChange('tipo_pessoa')}>
                <FormControlLabel value="F" control={<Radio color="primary" />} label={res.FISICA} />
                <FormControlLabel value="J" control={<Radio color="primary" />} label={res.JURIDICA} />
              </RadioGroup>
            </FormControl>
            <FormControl
              fullWidth
              className={classes.textField}>
              <InputLabel htmlFor="formatted-text-mask-input">{res.CPFCNPJ}</InputLabel>
              <Input
                value={this.state.cpf_cnpj || ' '}
                onChange={this.handleChange('cpf_cnpj')}
                name="textmask"
                id="formatted-text-mask-input"
                inputComponent={this.maskedCPFCNPJ}
              />
            </FormControl>
            <TextField
              id="fone"
              label={res.FONE}
              className={classes.textField}
              value={this.state.fone}
              onChange={this.handleChange('fone')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="email"
              label={res.EMAIL}
              className={classes.textField}
              value={this.state.email}
              required={true}
              onChange={this.handleChange('email')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto auto", columnGap: "1rem" }}>
              <TextField
                id="cep"
                label={res.CEP}
                type='number'
                className={classes.textField}
                value={this.state.cep}
                onChange={this.handleCEP}
                margin="normal"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
              <TextField
                id="localidade"
                label={res.LOCALIDADE}
                className={classes.textField}
                value={this.state.localidade}
                onChange={this.handleChange('localidade')}
                margin="normal"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
              <TextField
                id="bairro"
                label={res.BAIRRO}
                className={classes.textField}
                value={this.state.bairro}
                onChange={this.handleChange('bairro')}
                margin="normal"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
              <TextField
                id="logradouro"
                label={res.LOGRADOURO}
                className={classes.textField}
                value={this.state.logradouro}
                onChange={this.handleChange('logradouro')}
                margin="normal"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
              <TextField
                id="numero"
                label={res.NUMERO}
                className={classes.textField}
                value={this.state.numero}
                onChange={this.handleChange('numero')}
                margin="normal"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
              <TextField
                id="complemento"
                label={res.COMPLEMENTO}
                className={classes.textField}
                value={this.state.complemento}
                onChange={this.handleChange('complemento')}
                margin="normal"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 45)
                }}
              />
            </div>
          </div>
        </form>
        {this.state.id && <ListProperties link={link} res={res} idClient={this.state.id}></ListProperties>}
      </div>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

