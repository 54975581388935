import React from 'react';
import L from 'leaflet';
import 'leaflet-draw';
import Button from '@material-ui/core/Button';
import MaterialIcon from './../../components/MaterialIcon';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import jszip from 'jszip';
import togeojson from '@mapbox/togeojson';
import shpjs from 'shpjs';

import ListFilter from '../../components/ListFilter/filter';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

import { notification } from 'antd';

import img from './img';
import Config from '../../constants/appConfig';
import tools from '../../components/util/tools';
import geo from '../../components/util/geo';

import Backd from '../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import toGeoJSON from '@mapbox/togeojson';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Point {
  constructor() {
    this.id = '';
    this.guid = '';
    this.name = '';
    this.icon = '';
    this.alt = '';
    this.lat = '';
    this.lng = '';
    this.r = '';
    this.type = '';
  }
}

class Map extends React.Component {
  state = {
    markers: [],
    sel_marker: '',
    create: false,
    create_new: false,
    create_base: false,
    create_fuel: false,
    view: false,
    edit: false,
    delete: false,
    idL: '',
    guidL: '',
    nameL: '',
    imgL: '',
    descL: '',
    altL: '',
    rL: '',
    goto: false,
    type: 'W',
    showImport: false,
    itensImport: [],
    lat: {
      value: '',
      error: false,
      input: ''
    },
    lng: {
      value: '',
      error: false,
      input: ''
    },
    latL: {
      value: '',
      error: false,
      input: ''
    },
    lngL: {
      value: '',
      error: false,
      input: ''
    }
  };

  img_base = 11;
  img_fuel = 1;

  mymap = null;
  featureGroupP = null;
  drawControl = null;
  drawCreateToolbar = null;
  drawEditToolbar = null;
  drawDeleteToolbar = null;

  markClick = false;
  icons = null;
  tempMarker = null;

  text_polygon = 'new mark ';

  itens_import = [];

  handleListItemClick = guid => {
    let poly = this.mymap._layers[guid];
    this.handleClickP(poly, guid);
  };

  handleChangeState = div => {
    this.setState({
      [div]: true,
    });
  };

  componentDidMount = () => {
    this.icon = img;
    this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);

    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

    this.featureGroupP = L.featureGroup().addTo(this.mymap);

    this.drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: false
    }).addTo(this.mymap);

    L.drawLocal.draw.handlers.marker.tooltip.start = this.props.res.CLICK_MARCA;

    this.mymap.on('draw:created', (e) => {
      let guid = tools.newGuid();
      /* const temp_img = img[11];
      let item = this.state.markers.filter((feature) => feature.img === temp_img.img);
      if (item.length === 0 || e.layer.options.icon.options.iconUrl !== temp_img.img) { */
      const name = this.nextIndex(this.state.markers);//'mark index ' + this.markIndex++;

      let tempMark = new Point();
      tempMark.guid = guid;
      tempMark.name = name;
      tempMark.lat = e.layer._latlng.lat;
      tempMark.lng = e.layer._latlng.lng;
      tempMark.alt = '0';
      tempMark.r = '0';
      tempMark.img = e.layer.options.icon.options.iconUrl;
      for (let j = 0; j < img.length; j++) {
        const element = img[j];
        if (element.img === tempMark.img) {
          tempMark.desc = element.desc[0];
          break;
        }
      }
      this.create_edit(null, tempMark.guid, tempMark.name, tempMark.lat, tempMark.lng, '0', '0', tempMark.desc);

      this.setState({
        markers: this.state.markers.concat(tempMark),
        create: false,
        create_new: false,
        create_base: false,
        create_fuel: false,
        sel_marker: '',
        view: false
      });
      e.layer.on("click", () => { this.handleClickP(e.layer, guid); });
      e.layer._leaflet_id = guid;
      this.featureGroupP.addLayer(e.layer);
      this.markClick = false;
    });

    this.mymap.on('draw:edited', (e) => {
      let tempMarkers = this.state.markers;
      let layers = e.layers;
      layers.eachLayer((feature) => {
        for (let j = 0; j < tempMarkers.length; j++) {
          if (tempMarkers[j].guid === feature._leaflet_id) {
            tempMarkers[j].lat = feature._latlng.lat;
            tempMarkers[j].lng = feature._latlng.lng;
            if (this.state.sel_marker) {
              this.setState({
                latL: { error: false, input: geo.toDMS(feature._latlng.lat, 'latitude', null, this.props.res), value: feature._latlng.lat },
                lngL: { error: false, input: geo.toDMS(feature._latlng.lng, 'longitude', null, this.props.res), value: feature._latlng.lng }
              });
            }
            this.create_edit(tempMarkers[j].id, tempMarkers[j].guid, tempMarkers[j].name, tempMarkers[j].lat, tempMarkers[j].lng, '0', '0', tempMarkers[j].desc);
            break;
          }
        }
      });
      this.setState({ markers: tempMarkers });
    });

    this.mymap.on('draw:deleted', (e) => {
      let tempMarkers = this.state.markers;
      let layers = e.layers;
      let itens = [];
      layers.eachLayer((feature) => {
        for (let j = 0; j < tempMarkers.length; j++) {
          const element = tempMarkers[j];
          if (element.guid === feature._leaflet_id) {
            itens = itens.concat(element.id);;
            tempMarkers.splice(tempMarkers.indexOf(element), 1);
            let mr = this.mymap._layers[element.guid + 'r'];
            if (mr)
              this.featureGroupP.removeLayer(mr);
            break;
          }
        }

      });
      this.delete(itens);
      this.setState({ markers: tempMarkers });
    });

    this.mymap.on('click', () => {
      this.handleClickP();
    });

    this.mymap.on('moveend', (e) => {
      let lat = { error: false, input: geo.toDMS(this.mymap.getCenter().lat, 'latitude', null, this.props.res), value: this.mymap.getCenter().lat };
      let lng = { error: false, input: geo.toDMS(this.mymap.getCenter().lng, 'longitude', null, this.props.res), value: this.mymap.getCenter().lng };
      this.setState({ lat: lat, lng: lng });
      if (!this.state.edit && !this.state.delete) this.checkMarkers();
    });

    this.mymap.on('zoomend', () => {
      if (!this.state.edit && !this.state.delete) this.checkMarkers();
    });

    this.drawEditToolbar = new L["EditToolbar"].Edit(this.mymap, {
      featureGroup: this.featureGroupP,
      selectedPathOptions: this.drawControl.options.edit.selectedPathOptions
    });

    this.drawDeleteToolbar = new L["EditToolbar"].Delete(this.mymap, {
      featureGroup: this.featureGroupP
    });

    this.list_markers();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  remove = () => {
    this.deleteMark();
    let item = this.state.markers.filter((feature) => feature.guid === this.state.sel_marker);
    if (item.length > 0) {
      let m = this.mymap._layers[this.state.sel_marker];
      if (m) {
        this.featureGroupP.removeLayer(m);
        let mr = this.mymap._layers[this.state.sel_marker + 'r'];
        if (mr)
          this.featureGroupP.removeLayer(mr);
      }
    }
  };

  criaMark = (n) => {
    if (!this.markClick) {
      this.cancelDelete();
      this.cancelEdit();

      let img = this.state.type === 'W' ? this.icon[n ? n : 1].img : this.icon[20].img;
      let dIcon = L.icon({
        iconUrl: img,
        iconAnchor: [13, 33]
      });
      this.drawCreateToolbar = new L.Draw.Marker(this.mymap, { icon: dIcon });
      this.drawCreateToolbar.enable();

      let create = {
        nameL: this.nextIndex(this.state.markers),
        imgL: img,
        sel_marker: 'new',
        view: false,
        create: false,
        create_new: false
      };
      if (n === 1) create.create_fuel = true;
      else if (n === 11) create.create_base = true;
      else create.create = true;
      this.setState(create);
    }
    this.markClick = true;
  };

  cancelCreate = () => {
    this.drawCreateToolbar.disable();
    this.markClick = false;
    this.setState({
      create: false,
      create_new: false,
      create_base: false,
      create_fuel: false
    })
  }

  openMark = () => {
    this.setState({ create_new: !this.state.create_new, create: !this.state.create });
  };

  changeMark = (desc, image) => {
    if (this.state.sel_marker) {
      this.setState({ descL: desc, imgL: image, create_new: false, create: false });
    } else {
      let temp_img = img.filter(item => item.desc.includes(desc));
      if (temp_img.length > 0) {
        this.criaMark(temp_img[0].key);
      }
    }
  };

  zoomInL = () => {
    this.mymap.zoomIn();
  };

  zoomOutL = () => {
    this.mymap.zoomOut();
  };

  editMark = () => {
    this.cancelDelete();
    this.setState({ edit: true });
    if (this.drawEditToolbar) {
      this.drawEditToolbar.enable();
    }
    if (this.state.sel_marker) {
      this.state.markers.forEach(item => {
        this.mymap._layers[item.guid] && this.mymap._layers[item.guid].editing.disable();
      });
      this.mymap._layers[this.state.sel_marker] && this.mymap._layers[this.state.sel_marker].editing.enable();
    } else {
      this.state.markers.forEach(item => {
        this.mymap._layers[item.guid] && this.mymap._layers[item.guid].editing.enable();
      });
    }
  };

  saveEdit = () => {
    this.setState({ edit: false });
    if (this.drawEditToolbar) {
      this.drawEditToolbar.save();
      this.drawEditToolbar.disable();
    }
  };

  cancelEdit = () => {
    this.setState({ edit: false });
    if (this.drawEditToolbar) {
      this.drawEditToolbar.revertLayers();
      this.drawEditToolbar.disable();
    }
  };

  deleteMark = () => {
    this.cancelEdit();
    this.setState({ delete: true });
    this.drawDeleteToolbar.enable();
    this.setState({ sel_marker: '', view: false });
  };

  saveDelete = () => {
    this.setState({ delete: false });
    this.drawDeleteToolbar.save();
    this.drawDeleteToolbar.disable();
    if (this.tempMarker) {
      let tempMarkers = this.state.markers.filter((feature) => feature.guid !== this.tempMarker.guid);
      this.setState({ markers: tempMarkers });
      this.delete([this.tempMarker.id]);
    }
  };

  cancelDelete = () => {
    if (this.state.delete) {
      this.setState({ delete: false });
      this.drawDeleteToolbar.revertLayers()
      this.drawDeleteToolbar.disable();
      if (this.tempMarker) {
        this.drawMarker(this.tempMarker);
      }
    }
  };

  saveItem = () => {
    for (let i = 0; i < this.state.markers.length; i++) {
      const element = this.state.markers[i];
      if (element.guid === this.state.sel_marker) {
        element.name = this.state.nameL;
        element.img = this.state.imgL;
        element.desc = this.state.descL;
        element.lat = this.state.latL.value;
        element.alt = this.state.altL;
        element.lng = this.state.lngL.value;
        element.r = this.state.rL;
        element.type = this.state.type;

        this.create_edit(element.id, element.guid, element.name, element.lat, element.lng, element.alt, element.r, element.desc);

        let m = this.mymap._layers[element.guid];
        if (m) {
          this.featureGroupP.removeLayer(m);
          let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
          if (mr)
            this.featureGroupP.removeLayer(mr);
        }
        this.drawMarker(element);

        break;
      }
    }
    this.setState({ sel_marker: '', view: false });
  };

  cancelItem = () => {
    this.setState({ sel_marker: '', view: false });
    if (this.drawCreateToolbar) this.cancelCreate();
  };

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (dF.className === 'divContent div-list') {
      dF.classList.toggle("div-listFloat");
    } else {
      dF.classList.remove("div-listFloat");
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClickP = (layer, guid) => {
    if (!this.state.edit && !this.state.delete) {
      if (layer && guid && guid !== this.state.sel_marker) {
        this.setState({ sel_marker: guid, view: true });
        for (let i = 0; i < this.state.markers.length; i++) {
          const element = this.state.markers[i];
          if (element.guid === guid) {
            this.setState({
              idL: element.id,
              guidL: element.guid,
              nameL: element.name,
              imgL: element.img,
              descL: element.desc,
              latL: { error: false, input: geo.toDMS(element.lat, 'latitude', null, this.props.res), value: element.lat },
              altL: element.alt,
              lngL: { error: false, input: geo.toDMS(element.lng, 'longitude', null, this.props.res), value: element.lng },
              rL: element.r
            });
            this.tempMarker = element;
            break;
          }
        }
      } else {
        this.tempMarker = null;
        this.setState({ sel_marker: '', view: false });
      }
      this.setState({ create_new: false, create: false });
    }
  };

  list_markers = () => {
    const apiUrl = Config.server + '/point/' + this.props.type;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            let m = [];
            for (let i = 0; i < result.length; i++) {
              const element = result[i];

              let tempMark = new Point();
              tempMark.id = element.id;
              tempMark.guid = element.guid;
              tempMark.name = element.name;
              tempMark.lat = element.lat;
              tempMark.lng = element.lng;
              tempMark.alt = element.height;
              tempMark.r = element.radios;
              tempMark.desc = element.icon;
              tempMark.type = element.type;

              for (let j = 0; j < img.length; j++) {
                if (img[j].desc.includes(element.icon)) {
                  tempMark.img = img[j].img;
                  break;
                }
              }
              if (!tempMark.img) {
                tempMark.img = img[this.props.type === 'O' ? 20 : 0].img;
              }
              m.push(tempMark)
              this.drawMarker(tempMark);
            }
            this.setState({ markers: m });
            this.mymap.fitBounds(this.featureGroupP.getBounds());
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  drawMarker = (marker) => {
    let dIcon = L.icon({
      iconUrl: marker.img,
      iconAnchor: marker.type === 'O' ? [25, 30] : [13, 33],
      popupAnchor: [0, -25]
    });
    let newLatLng = new L.LatLng(marker.lat, marker.lng);
    let myLayer = L.marker(newLatLng, { icon: dIcon });

    myLayer.on("click", () => { this.handleClickP(myLayer, marker.guid); });
    myLayer._leaflet_id = marker.guid;
    myLayer.addTo(this.featureGroupP);

    if (marker.r > 0) {
      let myStyle = {
        "color": 'red',
        "weight": 4,
        "opacity": 0.5,
        "radius": marker.r
      };
      myLayer = L.circle(newLatLng, myStyle);
      myLayer._leaflet_id = marker.guid + 'r';
      myLayer.addTo(this.featureGroupP);
    }
  };

  checkMarkers = () => {
    let mapBounds = this.mymap.getBounds();
    for (let i = this.state.markers.length - 1; i >= 0; i--) {
      if (this.state.markers[i].guid) {
        let m = this.mymap._layers[this.state.markers[i].guid];
        if (!m) {
          this.drawMarker(this.state.markers[i]);
        } else {
          let shouldBeVisible = mapBounds.contains(m.getLatLng());
          if (m._icon && !shouldBeVisible) {
            this.featureGroupP.removeLayer(m);
            let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
            if (mr)
              this.featureGroupP.removeLayer(mr);
          }
        }
      }
    }
  };

  create_edit = (id, guid, name, lat, lng, height, radios, icon) => {

    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/point';//TODO: arrumar!!!

    const formData = new URLSearchParams();
    if (id) {
      apiUrl += '/' + id;
      formData.append('id', id);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    formData.append('guid', guid);
    formData.append('name', name);
    formData.append('lat', lat);
    formData.append('lng', lng);
    formData.append('height', height);
    formData.append('radios', radios);
    formData.append('icon', icon);

    let type = icon === 'hangar' ? 'H' : icon === 'marker-icon-green' ? 'P' : this.props.type;
    formData.append('type', type);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (!id) {
            let tempMarkers = this.state.markers;
            for (let i = 0; i < tempMarkers.length; i++) {
              if (tempMarkers[i].id === '') {
                tempMarkers[i].id = result;
                break;
              }
            }
            this.setState({ markers: tempMarkers });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );

  };

  delete = (ids) => {
    const apiUrl = Config.server + '/point';//TODO: arrumar!!!
    const formData = new URLSearchParams();
    formData.append('ids', ids);

    const options = {
      method: 'DELETE',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          /*notification.open({
            message: 'Successo',
            description: 'O item foi excluido',
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });*/
          //this.props.history.push('#/app/cadastro/usuario/list/');
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  nextIndex = (listP) => {
    let lastI = 1;
    let retorno = this.text_polygon + lastI;
    for (let i = 0; i < listP.length; i++) {
      if (listP[i].name === retorno) {
        i = -1;
        lastI++;
      }
      retorno = this.text_polygon + lastI;
    }
    return retorno;
  };

  showGoto = () => {
    if (this.state.goto) {
      this.setState({ goto: false });
    } else {
      let lat = { error: false, input: geo.toDMS(this.mymap.getCenter().lat, 'latitude', null, this.props.res), value: this.mymap.getCenter().lat };
      let lng = { error: false, input: geo.toDMS(this.mymap.getCenter().lng, 'longitude', null, this.props.res), value: this.mymap.getCenter().lng };
      this.setState({ goto: true, lat: lat, lng: lng });
    }
  };
  goto = () => {
    this.setState({ goto: false });
    this.mymap.setView([this.state.lat.value, this.state.lng.value]);
  };

  clear = () => {
    this.featureGroupP.clearLayers();
    this.setState({
      markers: [],
      create: false,
      create_new: false,
      create_base: false,
      create_fuel: false,
      edit: false,
      delete: false,
      goto: false,
    });
  };

  handleChangeCoordinates = name => event => {
    this.validate(name, event.target.value);
  };

  validate = (name, value) => {
    //regex: /\d{1,2}º\d\d'(\d\d|\d\d\.\d{1,4}) (N|S)/
    //geo.toCoordinate(this.state.lat_input);
    //geo.toDMS(this.state.lat, 'latitude', 4);
    let update = this.state[name];
    if (name === 'lat' || name === 'latL') {
      update.input = value;
      if (/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(N|S)/.test(value)) {
        update.value = geo.toCoordinate(geo.toFixDMS(value));
        update.error = false;
      } else {
        update.error = true;
      }
    } else if (name === 'lng' || name === 'lngL') {
      update.input = value;
      //TODO:fix res
      if ((/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(W|E)/.test(value))
        || (/\d{1,2}(°|º)\d{1,2}'(\d{1,2}|\d{1,2}\.\d{1,4})( |")(O|L)/.test(value))) {
        update.value = geo.toCoordinate(geo.toFixDMS(value));
        update.error = false;
      } else {
        update.error = true;
      }
    }

    this.setState(update);
  };

  //upload de arquivos KML e KMZ
  loadFile = file => {
    this.showPro(true);

    for (let index = 0; index < file.target.files.length; index++) {
      const filePath = file.target.files[index];
      setTimeout(() => {
        let ext = fileName => fileName.split(".").pop();
        if (ext(filePath.name.toLowerCase()) === 'kml') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            const content = this.fileReader.result;
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(content, "text/xml");
            let track = new togeojson['kml'](xmlDoc);

            this.writeFileMap(track);
          });
          this.fileReader.readAsText(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'kmz') {
          let getDom = xml => (new DOMParser()).parseFromString(xml, "text/xml")
          let getKmlDom = (kmzFile) => {
            let zip = new jszip()
            return zip.loadAsync(kmzFile)
              .then(zip => {
                let kmlDom = null
                zip.forEach((relPath, file) => {
                  if (ext(relPath) === 'kml') {
                    kmlDom = file.async("string").then(getDom)
                  }
                })
                return kmlDom || Promise.reject("No kml file found")
              });
          }
          let geoJson = getKmlDom(filePath).then(kmlDom => {
            let geoJsonObject = new togeojson['kml'](kmlDom);
            return geoJsonObject
          })
          geoJson.then(gj => this.writeFileMap(gj))
        } else if (ext(filePath.name.toLowerCase()) === 'zip') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            shpjs(this.fileReader.result)
              .then((geojson) => {
                if (geojson.length > 0) {
                  geojson.forEach(element => {
                    this.writeFileMap(element);
                  });
                } else {
                  this.writeFileMap(geojson);
                }
              })
              .catch(e => {
                this.showPro(false);
                notification.open({
                  message: this.props.res.ERRO,
                  description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          });
          this.fileReader.readAsArrayBuffer(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'shp') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            const features = [];
            let polyLength = 0;
            shpjs.parseShp(this.fileReader.result).forEach(element => {
              const geometry = { type: element.type, coordinates: element.coordinates };
              features.push({ properties: { guid: undefined, name: undefined, type: element.type }, geometry: geometry });
              if (element.type === "Point") {
                polyLength++;
              }
            });
            this.writeFileMap({ features: features, polyLength: polyLength });
          });
          this.fileReader.readAsArrayBuffer(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'zjob') {
          this.uploadFile(filePath);
        } else {
          notification.open({
            message: this.props.res.ERRO,
            description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
          this.showPro(false);
        }
      }, 50);
    };

    document.getElementById('contained-button-file').value = null;
    //
  };

  //desenha os polygons do arquivo 
  writeFileMap = (track) => {
    let list = this.state.itensImport;//[];
    for (let i = 0; i < track.features.length; i++) {
      let feature = track.features[i];
      if (feature.geometry.type === 'Point') {
        //let guid = feature.properties.guid ? feature.properties.guid : tools.newGuid();
        let name = feature.properties.name ? feature.properties.name : feature.properties.recibo ? feature.properties.recibo : this.nextIndex(this.state.markers);
        list.push({
          name: name,
          //guid: guid,
          index: this.itens_import.length + i
        });
      }
    }
    this.itens_import = this.itens_import.concat(track.features);
    this.setState({ itensImport: list, showImport: true });
  };

  fileMap = (tFeatures) => {
    let list_marker = [];
    for (let i = 0; i < tFeatures.length; i++) {
      //const element = turfS(track.features[i], {tolerance: 0.00005, highQuality: true});
      let features = tFeatures[i];
      if (features.geometry.type === 'Point') {
        list_marker = this.loadPolyFile(features.properties, features.geometry.coordinates, list_marker);
      }
    }

    for (let i = 0; i < list_marker.length; i++) {
      const item = list_marker[i];
      if (!this.state.markers.find(po => po.guid === item.guid)) {
        this.create_edit(item.id, item.guid, item.name, item.lat, item.lng, '0', '0', item.desc);
      }
    }
    this.setState({ markers: this.state.markers.concat(list_marker) });
    //this.savePolygons(polys);
    if (list_marker.length > 0) {
      //this.setState({ polygons: polys });
      this.mymap.fitBounds(this.featureGroupP.getBounds());
    }
    this.showPro(false);
  };

  loadPolyFile = (props, coordinates, polys) => {
    try {
      let tempPX = polys.filter(item => { return item.guid === props.guid });
      let latlngs = coordinates;
      let guid = props.guid && tempPX.length === 0 ? props.guid : tools.newGuid();
      let name = props.name ? props.name : props.recibo ? props.recibo : this.nextIndex(polys);

      let tempMark = new Point();
      tempMark.guid = guid;
      tempMark.name = name;
      tempMark.lat = latlngs[1];
      tempMark.lng = latlngs[0];
      tempMark.alt = '0';
      tempMark.r = '0';
      tempMark.img = img[1].img;
      tempMark.desc = img[1].desc[0];

      this.drawMarker(tempMark);

      return polys.concat(tempMark);
    } catch (e) {
      notification.open({
        message: this.props.res.ERRO,
        description: this.props.res.ERRO_MSG,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return polys;
    }
  };

  inportFilter = (itens) => {
    this.setState({ itensImport: [], showImport: false });
    let features = [];
    for (let i = 0; i < itens.length; i++) {
      features.push(this.itens_import[itens[i].index]);
    }
    this.itens_import = [];
    setTimeout(() => { this.fileMap(features) }, 10);
  };

  showPro = (b) => {
    this.setState({ progress: b });
  };

  render() {
    const { res, type } = this.props;

    if (this.state.type !== type) {
      this.setState({ type: type }, () => { this.clear(); this.list_markers() });
    }

    return (
      <div className="rowMapC maxH">
        <Backd style={{ zIndex: 1002, color: '#fff' }} open={this.state.progress}>
          <CircularProgress color="inherit" disableShrink />
        </Backd>
        <ListFilter res={res} itens={this.state.itensImport} open={this.state.showImport} index={1003} listFilter={this.inportFilter} />
        <div id="divFloat" className="div-list divContentList list-p">
          <div style={{ width: '100%' }}>
            <h2 style={{ margin: '1rem' }}>{type === 'O' ? res.OBSTACULO : res.WAYPOINT}</h2>
          </div>
          <div style={{ overflow: 'auto', height: 'calc(100vh - 14rem)' }}>
            <List
              component="nav"
              aria-labelledby="areas"
            >
              {this.state.markers.map((item, index) => {
                return (
                  <ListItem key={index} button
                    className={(this.state.sel_marker === item.guid) ? 'SelItem' : ''}
                    onClick={event => this.handleListItemClick(item.guid)}
                  >
                    <ListItemText id={index} primary={item.name} />
                  </ListItem>
                )
              })}
            </List>
          </div>
        </div>
        <div className="divContent divRelative map-p">
          <div className="containerTop">
            <div className="leftTop">
              {type !== 'O' && <Button className={this.state.create_base ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.criaMark(this.img_base, 'create_base')} title={res.HANGAR}>
                <MaterialIcon icon="add_location_alt" className="iconMap" />
              </Button>}

              {type !== 'O' && <Button className={this.state.create_fuel ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.criaMark(this.img_fuel, 'create_fuel')} title={res.PISTA_REABASTECIMENTO}>
                <MaterialIcon icon="add_road" className="iconMap" />
              </Button>}

              <Button className={this.state.create ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => { this.openMark() }} title={res.CUSTOMIZAVEL}>
                <MaterialIcon icon="room" className="iconMap" />
              </Button>
              <Button className={this.state.edit ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={(this.state.markers.length > 0) ? false : true} onClick={event => this.editMark()} title={res.EDITAR}>
                <MaterialIcon icon="edit" className="iconMap" />
              </Button>
              <Button className={this.state.delete ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={(this.state.markers.length > 0) ? false : true} onClick={event => this.deleteMark()} title={res.EXCLUIR}>
                <MaterialIcon icon="delete" className="iconMap" />
              </Button>
            </div>
            <div className="centerTop"></div>
            <div className="rightTop">
              <label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                <input
                  accept=".kml,.kmz,.shp,.zip,.zjob"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  disabled={this.state.disable}
                  multiple
                  type="file" onChange={this.loadFile}
                />
                <label htmlFor="contained-button-file">
                  <Button component="span" className="buttonS buttonMenu" title={res.IMPORTAR_AREAS} disabled={this.state.disable} onClick={() => { this.upType = 'P'; }}>
                    <MaterialIcon icon="attach_file" className="iconMap" />
                  </Button>
                </label>
              </label>
              <Button className="buttonS buttonMenu" onClick={event => this.showGoto()}>
                <MaterialIcon icon="search" className="iconMap" />
              </Button>
            </div>
          </div>

          <div className="wrapperMap">
            <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '1111', display: !this.state.disable ? 'none' : 'block' }}></div>
            <div id="mapid"></div>
            <div className="zoomB">
              <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                <MaterialIcon icon="add" className="iconMap" />
              </Button>
              <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                <MaterialIcon icon="remove" className="iconMap" />
              </Button>
            </div>
            {this.state.goto ?
              <div className="divContent floatW divS" style={{ width: '15rem', padding: '0' }}>
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0.5rem 0', backgroundColor: '#2D3238' }}>
                  <div style={{ textAlign: 'left', width: '33%' }}></div>
                  <div style={{ textAlign: 'center', width: '33%' }}>
                    <h3 style={{ color: 'white', margin: '0' }}>{res.LOCALIZAR}</h3>
                  </div>
                  <div className="divForm" style={{ textAlign: 'right', width: '33%' }}>
                    <Button size="small" className="popUpButton" onClick={() => this.setState({ goto: false })}>
                      <CloseIcon style={{ color: 'white' }} />
                    </Button>
                  </div>
                </div>
                <div style={{ padding: '0.5rem 0' }}>
                  <TextField
                    id="lat"
                    label={res.LATITUDE}
                    margin="normal"
                    error={this.state.lat.error}
                    onChange={this.handleChangeCoordinates('lat')}
                    value={this.state.lat.input}
                    placeholder={geo.toDMS(this.state.lat.value, 'latitude', null, res)}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                  />
                  <TextField
                    id="lng"
                    label={res.LONGITUDE}
                    margin="normal"
                    error={this.state.lng.error}
                    onChange={this.handleChangeCoordinates('lng')}
                    value={this.state.lng.input}
                    placeholder={geo.toDMS(this.state.lng.value, 'longitude', null, res)}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                  />
                  <div className="divForm">
                    <Button size="small" className="popUpButton" onClick={event => this.goto()}>
                      {res.BUSCAR}
                    </Button>
                  </div>
                </div>
              </div>
              : ''}
            <div className={this.state.edit || this.state.delete ? "divContent floatDiv floatDivItens" : "sr-only"}>
              <label className="textDiv">
                {res.CLICK_DESFAZER}
              </label>
              <Button size="small" className="popUpButton" onClick={event => this.state.edit ? this.saveEdit() : this.saveDelete()}>
                {res.SALVAR}
              </Button>
              <Button size="small" className="popUpButton" onClick={event => this.state.edit ? this.cancelEdit() : this.cancelDelete()}>
                {res.CANCELAR}
              </Button>
            </div>
            <div className={this.state.create_new ? "divContent floatDiv floatDivItens" : "sr-only"}>
              {img.filter((item) => (this.state.type === 'O' ? item.key > 19 : item.key <= 19 && item.key !== 0 && item.key !== 11)).map((item, key) => <Button key={key} className="popUpButton" onClick={event => this.changeMark(item.desc[0], item.img)}><img src={item.img} alt="mark" draggable="false" /></Button>)}
              <Button size="small" className="popUpButton" onClick={event => { this.setState({ create: false, create_new: false }) }}>
                {res.CANCELAR}
              </Button>
            </div>
            <div className={this.state.sel_marker && !this.state.edit && !this.state.delete ? "divContent floatW divI" : "sr-only"}>
              <form onSubmit={this.handleSubmit} className="formItens">
                <TextField
                  id="name"
                  label={res.NOME}
                  margin="normal"
                  className="textI"
                  onChange={this.handleChange('nameL')}
                  value={this.state.nameL}
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
                <label className="textI" style={{ position: 'relative' }}>
                  <Button className="popUpButton" style={{ position: 'absolute', bottom: '0.5rem', left: '1.2rem' }} onClick={event => { if (this.state.view) this.handleChangeState("create_new") }}><img src={this.state.imgL} alt="mark" draggable="false" /></Button>
                </label>
                {!this.state.create && !this.state.create_base && !this.state.create_fuel ?
                  <TextField
                    id="lat"
                    label={res.LATITUDE}
                    margin="normal"
                    className="textI"
                    error={this.state.latL.error}
                    onChange={this.handleChangeCoordinates('latL')}
                    value={this.state.latL.input}
                    placeholder={geo.toDMS(this.state.latL.value, 'latitude', null, res)}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.saveItem();
                      }
                    }}
                  />
                  : null}
                {!this.state.create && !this.state.create_base && !this.state.create_fuel ?
                  <TextField
                    id="alt"
                    label={res.ALTURA}
                    margin="normal"
                    className="textI"
                    onChange={this.handleChange('altL')}
                    value={this.state.altL}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.saveItem();
                      }
                    }}
                  />
                  : null}
                {!this.state.create && !this.state.create_base && !this.state.create_fuel ?
                  <TextField
                    id="lng"
                    label={res.LONGITUDE}
                    margin="normal"
                    className="textI"
                    error={this.state.lngL.error}
                    onChange={this.handleChangeCoordinates('lngL')}
                    value={this.state.lngL.input}
                    placeholder={geo.toDMS(this.state.lngL.value, 'longitude', null, res)}

                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.saveItem();
                      }
                    }}
                  />
                  : null}
                {!this.state.create && !this.state.create_base && !this.state.create_fuel ?
                  <TextField
                    id="radius"
                    label={res.RAIO}
                    margin="normal"
                    className="textI"
                    onChange={this.handleChange('rL')}
                    value={this.state.rL}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.saveItem();
                      }
                    }}
                    InputProps={{
                      readOnly: type !== 'O',
                    }}
                  />
                  : null}
              </form>
              <div className="divForm" style={{ marginTop: '.8rem' }}>
                {!this.state.create && !this.state.create_base && !this.state.create_fuel ? <span>
                  <Button size="small" className="popUpButton" onClick={event => this.saveItem()}>
                    {res.SALVAR}
                  </Button>
                  <Button size="small" className="popUpButton" onClick={event => this.editMark()}>
                    {res.MOVER}
                  </Button>
                  <Button size="small" className="popUpButton" onClick={event => this.remove()}>
                    {res.EXCLUIR}
                  </Button>
                </span> : null}
                <Button size="small" className="popUpButton" onClick={event => this.cancelItem()}>
                  {res.CANCELAR}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

Map.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Map);

const Box = (props) => {
  const { link, res, type } = props;
  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} type={type} res={res} />
    </div>)
}
export default Box;
