import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ rotate, color }) => {

    return (
        <SvgIcon viewBox="70 14 30 30" className="iconArrow"
            style={{ color: color, transform: 'rotate(' + rotate + 'deg)', width: '.9em', height: '.9em' }}>
            <path id="polygon" d="M 89 28 L 89 36 L 81 36 L 81 28 L 76 28 L 85 18 L 94 28 Z" />
        </SvgIcon>
    );
};

