import React from 'react';
import Moment from 'moment';
import ArrowBackIosIcon from '@material-ui/icons/ChevronLeft';
import ArrowForwardIosIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Skeleton from '@material-ui/lab/Skeleton';
import L from 'leaflet';
import 'leaflet-draw';
import Config from '../../constants/appConfig';

class DiaryOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentJobIndex: 0,
            numJobs: 0,
            hectaresSprayed: 0,
            hectaresTotal: 0,
            currentJob: null,
            polygons: [],
        };
        this.mapRef = React.createRef();
        this.autoSwitchInterval = null;
    }

    componentDidMount() {
        this.update();
        this.startAutoSwitch();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.jobs !== this.props.jobs || prevState.currentJobIndex !== this.state.currentJobIndex) {
            this.update();
        }

        if (this.mapRef.current && !this.map) {
            this.initializeMap();
        }
    }

    componentWillUnmount() {
        this.stopAutoSwitch();
    }

    startAutoSwitch = () => {
        this.autoSwitchInterval = setInterval(this.handleNextJob, 30000);
    }

    stopAutoSwitch = () => {
        if (this.autoSwitchInterval) {
            clearInterval(this.autoSwitchInterval);
            this.autoSwitchInterval = null;
        }
    }

    resetAutoSwitch = () => {
        this.stopAutoSwitch();
        this.startAutoSwitch();
    }

    handleNextJob = () => {
        const { jobs } = this.props;
        this.setState(prevState => {
            let nextIndex = prevState.currentJobIndex + 1;
            if (nextIndex >= jobs.length) nextIndex = 0;
            return { currentJobIndex: nextIndex };
        });
    }

    handlePrevJob = () => {
        const { jobs } = this.props;
        this.setState(prevState => {
            let prevIndex = prevState.currentJobIndex - 1;
            if (prevIndex < 0) prevIndex = jobs.length - 1;
            return { currentJobIndex: prevIndex };
        });
    }

    handleFirstJob = () => {
        this.setState({ currentJobIndex: 0 });
    }

    handleLastJob = () => {
        const { jobs } = this.props;
        this.setState({ currentJobIndex: jobs.length - 1 });
    }

    handleManualNextJob = () => {
        this.handleNextJob();
        this.resetAutoSwitch();
    }

    handleManualPrevJob = () => {
        this.handlePrevJob();
        this.resetAutoSwitch();
    }

    handleManualFirstJob = () => {
        this.handleFirstJob();
        this.resetAutoSwitch();
    }

    handleManualLastJob = () => {
        this.handleLastJob();
        this.resetAutoSwitch();
    }

    initializeMap = () => {
        if (this.mapRef.current && !this.map) {
            this.map = L.map(this.mapRef.current, {
                zoomControl: false,
                attributionControl: false
            }).setView(Config.place, 14);

            L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.map);
            L.control.scale({ imperial: false }).addTo(this.map);

            this.featureGroupPoly = L.featureGroup().addTo(this.map);

            const resizeObserver = new ResizeObserver(() => {
                this.map.invalidateSize();
            });
            resizeObserver.observe(this.mapRef.current);
        }
    }

    update = () => {
        const { jobs } = this.props;
        if (jobs && jobs.length > 0) {
            let numJobs = jobs.length;
            let hectaresSprayed = 0.0;
            let hectaresTotal = 0.0;

            jobs.forEach(job => {
                if (job.list_exe && job.list_exe.length > 0) {
                    hectaresTotal += job.list_exe[0].area_polygon || 0;
                    hectaresSprayed += job.list_exe[0].area_spray || 0;
                }
            });

            const currentJob = jobs[this.state.currentJobIndex];

            if (currentJob) {
                this.handlePolygons();
            }

            this.setState({
                numJobs,
                hectaresSprayed,
                hectaresTotal,
                currentJob,
            });
        }
    }

    handlePolygons = () => {
        const currentJob = this.props.jobs[this.state.currentJobIndex];
        const currentJobExe = currentJob && currentJob.list_exe && currentJob.list_exe.length > 0 ? currentJob.list_exe[0] : null;

        if (!currentJobExe || !currentJobExe.id_task) {
            return;
        }

        const apiUrl = `${Config.server}/getResult/${currentJobExe.id_task}`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt'),
            },
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then((result) => {
                const data = JSON.parse(result);
                const polygons = data.polygons || [];
                this.setState({ polygons }, () => {
                    this.updatePolygonsOnMap();
                });
            })
            .catch((error) => {
                if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                } else {
                    console.error(error);
                }
            });
    }

    updatePolygonsOnMap = () => {
        this.featureGroupPoly.clearLayers();

        this.state.polygons.forEach((poly) => {
            const area = L.GeometryUtil.geodesicArea(L.polygon(poly.poly.geometry.coordinates).getLatLngs()[0]);
            const areaHa = Math.round(area / 100) / 100;
            this.addPolygonToMap(poly, areaHa);
        });

        if (this.state.polygons.length > 0) {
            this.map.fitBounds(this.featureGroupPoly.getBounds());
        }
    }

    addPolygonToMap = (poly, area) => {
        let myStyle = {
            color: '#47839e',
            weight: 4,
            opacity: 0.5,
            fillColor: '#FF1493',
            fillOpacity: 0.7,
            className: 'area'
        };

        if (poly.type === 'X') {
            myStyle.color = '#ff002b';
            myStyle.fillColor = '#ff002b';
        }

        let myLayer = L.geoJSON(poly.poly, { style: myStyle });
        const areaText = `${area} ha`;
        myLayer.bindTooltip(`${poly.title} (${areaText})`, {
            permanent: true,
            direction: "center",
            className: 'tool-tip-L tool-tip-size10-L'
        });
        myLayer.addTo(this.featureGroupPoly);
    }

    render() {
        const { res } = this.props;

        return (
            <div className='dashboard-card'>
                <div className='divContent'
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        borderRadius: '.8rem',
                        backgroundColor: '#343a40',
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    <div className='cardheader'>
                        <TrendingUpIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                        <span className='title'>{res.CICLO_OPERACAO}</span>
                        <div className='navigation-buttons' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', right: '1rem' }}>
                            <FirstPageIcon onClick={this.handleManualFirstJob} style={{ cursor: 'pointer', color: '#fa7d34' }} />
                            <ArrowBackIosIcon onClick={this.handleManualPrevJob} style={{ cursor: 'pointer', color: '#fa7d34' }} />
                            <span style={{ color: '#fff' }}>{this.state.currentJob ? `${this.state.currentJobIndex + 1}/${this.state.numJobs}` : ''}</span>
                            <ArrowForwardIosIcon onClick={this.handleManualNextJob} style={{ cursor: 'pointer', color: '#fa7d34' }} />
                            <LastPageIcon onClick={this.handleManualLastJob} style={{ cursor: 'pointer', color: '#fa7d34' }} />
                        </div>
                    </div>

                    {this.state.currentJob ? (
                        <>
                            <div className='cardmiddle' style={{ flexDirection: 'column', padding: '0 1rem', overflow: 'auto', marginBottom: '0', justifyContent: 'start' }}>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.TITULO}: </span>
                                    <strong style={{ color: '#fff' }}>{this.state.currentJob.title || '-'}</strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.INICIO_APLICACAO}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob.list_exe && this.state.currentJob.list_exe.length > 0 && this.state.currentJob.list_exe[0].startDate
                                            ? Moment(this.state.currentJob.list_exe[0].startDate).format("DD/MM/YYYY HH:mm")
                                            : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.FIM_APLICACAO}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob.list_exe && this.state.currentJob.list_exe.length > 0 && this.state.currentJob.list_exe[0].endDate
                                            ? Moment(this.state.currentJob.list_exe[0].endDate).format("DD/MM/YYYY HH:mm")
                                            : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.AREAS_PLANEJADAS}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob.list_exe?.[0]?.area_polygon ? `${this.state.currentJob.list_exe[0].area_polygon} ha` : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.AREAS_EXECUTADAS}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob.list_exe?.[0]?.area_spray ? `${this.state.currentJob.list_exe[0].area_spray} ha` : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.CLIENTE}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob.list_exe && this.state.currentJob.list_exe.length > 0 && this.state.currentJob.list_exe[0].client
                                            ? this.state.currentJob.list_exe[0].client
                                            : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.AERONAVE}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob.list_exe && this.state.currentJob.list_exe.length > 0 && this.state.currentJob.list_exe[0].plane
                                            ? this.state.currentJob.list_exe[0].plane
                                            : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.PILOTO}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob.list_exe && this.state.currentJob.list_exe.length > 0 && this.state.currentJob.list_exe[0].pilot
                                            ? this.state.currentJob.list_exe[0].pilot
                                            : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.LITROS_APLICADOS_PLANEJAMENTO}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob?.list_plan?.[0]?.liters ? `${this.state.currentJob.list_plan[0].liters} L` : '-'}
                                    </strong>
                                </div>
                                <div>
                                    <span style={{ color: '#fa7d34' }}>{res.LITROS_APLICADOS_EXECUCAO}: </span>
                                    <strong style={{ color: '#fff' }}>
                                        {this.state.currentJob?.list_exe?.[0]?.liters ? `${this.state.currentJob.list_exe[0].liters} L` : '-'}
                                    </strong>
                                </div>
                            </div>

                            <div style={{ width: '100%', height: '300px', position: 'relative' }} ref={this.mapRef} id="operationMap"></div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'cemter', flexDirection: 'column', alignItems: 'center' }}>
                            <Skeleton variant="text" width={240} />
                            <Skeleton variant="text" width={240} />
                            <Skeleton variant="text" width={240} />
                            <Skeleton variant="rect" width={240} height={118} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default DiaryOperation;