import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import Button from '@material-ui/core/Button';
import saveAs from 'file-saver';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {

  state = {
    limite_maximo: ''
  };

  componentDidMount() {
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  downloadRel = () => {
    let apiUrl = Config.server + '/relsaldo';
    let method = 'POST';
    const formData = new URLSearchParams();
    formData.append('limit', this.state.limite_maximo);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.blob();
      })
      .then(
        (result) => {
          let file_name = "SLD" + Date.now() + '.pdf';
          if (result.type === 'application/pdf' && result.size > 0) {
              saveAs(result, file_name);
          } else {
              notification.open({
                  message: this.props.res.ERRO,
                  description: this.props.res.ERRO_MSG,
                  icon: <MaterialIcon icon="error" className="text-danger" />
              });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else if (error.status === 402){
            notification.open({
              message: this.props.res.PARAMETROS_INVALIDOS,
              description: this.props.res.MSG_BALANCE_001,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
          else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }


  render() {
    const { res } = this.props;

    return (
      <div>
        <div className="containerTop">
          <div><h1>{res.SALDO}</h1></div>
          <div className="centerTop"></div>
          <div>
          </div>
        </div>
        <div className="form-group notTooBig">
          <div style={{ textAlign: 'left', paddingLeft: '1rem' }}>
            <TextField
              id="limite_maximo"
              label={res.LIMITE_MAXIMO}
              value={this.state.limite_maximo}
              onChange={this.handleChange('limite_maximo')}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
            />
            <Button variant="contained" color="primary" style={{ width: '5rem', marginTop: '1.7rem'}} onClick={this.downloadRel} >
              {' ' + res.RELATORIO + ' '}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

