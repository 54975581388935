import React from 'react';
//import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { notification } from 'antd';

import Button from '@material-ui/core/Button';
import jszip from 'jszip';
import togeojson from '@mapbox/togeojson';

import shpjs from 'shpjs';

import geo from '../../components/util/geo';
//import tokml from 'tokml';
//import Turf from '@turf/turf';
import saveAs from 'file-saver';
import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import SvgIcon from '@material-ui/core/SvgIcon';

import L from 'leaflet';
import 'leaflet-draw';

import Checkbox from '@material-ui/core/Checkbox';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SPW from "string-pixel-width";

import tools from '../../components/util/tools';
import { useParams } from "react-router";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ListFilter from '../../components/ListFilter/filter';
//import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


class polygon {
  constructor() {
    this.id = '';
    this.guid = '';
    this.name = '';
    this.area = 0;
    this.radius = 0;
    this.point = '';
    this.points = '';
    this.x = '';
    this.old = null;
    this.type = '';
  }
}
var turf = require('@turf/turf');
class EditDraw extends React.Component {

  state = {
    name: '',
    local: this.props.local,
    polygons: [],
    sel_poly: [],
    sel_merge: false,
    sel_x: false,
    create: false,
    edit: false,
    delete: false,
    nameP: '',
    merge: false,
    type: 2,
    goto: false,
    edit_name: false,
    lat: 0,
    lng: 0,
    teste: '',
    gap_size: 10,
    //progress: false,
    showImport: false,
    itensImport: [],
    namesC: [],

    ruler_a: false,
    label: true,
    label_text: '',
    home: Config.place,
  };
  past_merge = false;
  ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };


  markClick = false;
  featureGroupP = null;
  featureGroupM = null;
  featureGroupPoly = null;
  drawControl = null;
  drawCreateToolbar = null;
  drawEditToolbar = null;
  drawDeleteToolbar = null;
  r = null;
  i = 0;
  polyindex = 0;
  text_polygon = 'new poly ';
  polyClick = false;
  sel_save = [];

  itens_import = null;

  color_default = '#47839e';
  color_selected = '#E67E22';
  color_x = '#ff002b';
  color_edit = '#3388ff';

  shift = false;

  componentDidMount() {
    this.update();
    this.loadPolygons();


  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  update = () => {
    let local_ini = Config.place;
    if (this.state.local) {
      let pos = this.state.local.split(',');
      if (!isNaN(pos[1]) && !isNaN(pos[1])) {
        local_ini = pos;
      }
    }

    this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(local_ini, 14);

    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

    L.control.scale({ imperial: false }).addTo(this.mymap);

    this.featureGroupP = L.featureGroup().addTo(this.mymap);
    this.featureGroupM = L.featureGroup().addTo(this.mymap);
    this.featureGroupPoly = L.featureGroup();
    this.featureGroupPoly.addTo(this.mymap);

    this.drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: false
    }).addTo(this.mymap);

    this.mymap.on('draw:created', (e) => {
      const guid = tools.newGuid();
      const name = this.state.nameP ? this.state.nameP : this.nextIndex(this.state.polygons);
      e.layer.on("click", () => { this.handleClickP(e.layer, guid); });
      e.layer._leaflet_id = guid;
      e.layer.setStyle({ color: this.color_default });

      let tempPoly = new polygon();
      tempPoly.guid = guid;
      tempPoly.name = name;
      tempPoly.type = 'N';
      let latlng = [];
      if (e.layer._mRadius) {
        tempPoly.radius = e.layer._mRadius;
        tempPoly.point = [e.layer._latlng.lat, e.layer._latlng.lng];
        latlng = geo.cicleToPolygon([e.layer._latlng.lng, e.layer._latlng.lat], e.layer._mRadius);
      } else {
        for (let c = 0; c < e.layer._latlngs[0].length; c++) {
          const element = e.layer._latlngs[0][c];
          latlng = latlng.concat([[element.lat, element.lng]]);
        }
      }
      if (e.layer._mRadius) {
        tempPoly.area = e.layer._mRadius * e.layer._mRadius * Math.PI;
      } else {
        tempPoly.area = L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]);
      }
      tempPoly.points = JSON.stringify(latlng);

      this.savePolygons(this.state.polygons.concat(tempPoly));
      // Each time a feaute is created, it's added to the over arching feature group
      this.featureGroupP.addLayer(e.layer);

      e.layer.bindTooltip(name,
        { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' }
      ).openTooltip();

      this.markClick = false;
      this.setState({ create: false, create_circle: false });
    });

    this.mymap.on('draw:canceled', () => {
      this.setState({ create: false, create_circle: false });
      this.markClick = false;
      this.regua(false);
    });

    this.mymap.on('draw:edited', (e) => {
      this.setState({ create: false });
      let tempPolygons = this.state.polygons;
      let layers = e.layers;
      layers.eachLayer(function (feature) {
        for (let j = 0; j < tempPolygons.length; j++) {
          if (tempPolygons[j].guid === feature._leaflet_id) {
            let latlng = [];
            if (feature._mRadius) {
              tempPolygons[j].radius = feature._mRadius;
              tempPolygons[j].point = [feature._latlng.lat, feature._latlng.lng];
              latlng = geo.cicleToPolygon([feature._latlng.lng, feature._latlng.lat], feature._mRadius);
            } else {
              for (let c = 0; c < feature._latlngs[0].length; c++) {
                const element = feature._latlngs[0][c];
                latlng = latlng.concat([[element.lat, element.lng]]);
              }
            }
            tempPolygons[j].points = JSON.stringify(latlng);
            if (feature._mRadius) {
              tempPolygons[j].area = feature._mRadius * feature._mRadius * Math.PI;
            } else {
              tempPolygons[j].area = L.GeometryUtil.geodesicArea(feature.getLatLngs()[0]);
            }
            break;
          }
        }
      });
      this.savePolygons(tempPolygons);
    });

    this.mymap.on('draw:deleted', (e) => {
      let tempPolygons = this.state.polygons;
      let layers = e.layers;
      let itens = [];
      if (layers) {
        layers.eachLayer(function (feature) {
          for (let j = 0; j < tempPolygons.length; j++) {
            const element = tempPolygons[j];
            if (element.guid === feature._leaflet_id) {
              itens = itens.concat(element.id);;
              tempPolygons.splice(tempPolygons.indexOf(element), 1);

              let tempPX = tempPolygons.filter(item => { return item.guid.includes(element.guid); });
              for (let k = 0; k < tempPX.length; k++) {
                tempPolygons.splice(tempPolygons.indexOf(tempPX[k]), 1);
              }

              break;
            }
          }

        });
        this.savePolygons(tempPolygons);
      }
    });

    this.mymap.on('click', () => {
      this.handleClickP();
      if (this.state.create_circle) {
        this.setState({ create_circle: false });
        this.markClick = false;
      }
    });


    this.mymap.on('zoomend', () => {
      if (this.mymap.getZoom() < 12) {
        tools.cssShow('editable', false);
      } else {
        tools.cssShow('editable', true);
      }
    });

    this.mymap.on('draw:drawvertex', (e) => {
      if (this.drawCreateToolbar.type === 'polyline') {
        let layerIds = Object.keys(e.layers._layers);
        if (layerIds.length > 1) {
          let v2 = e.layers._layers[layerIds[1]];
          this.ruler_p.second.lat = v2._latlng.lat;
          this.ruler_p.second.lng = v2._latlng.lng;
          requestAnimationFrame(() => this.completeRuler());
        } else if (layerIds.length === 1) {
          let v1 = e.layers._layers[layerIds[0]];
          this.ruler_p.first.lat = v1._latlng.lat;
          this.ruler_p.first.lng = v1._latlng.lng;
        }
      }
    });

    this.drawEditToolbar = new L["EditToolbar"].Edit(this.mymap, {
      featureGroup: this.featureGroupP,
      selectedPathOptions: { allowIntersection: false }
    });

    this.drawDeleteToolbar = new L["EditToolbar"].Delete(this.mymap, {
      featureGroup: this.featureGroupP
    });

    L.drawLocal.draw.handlers.polygon.tooltip.start = '';
    L.drawLocal.draw.handlers.polygon.tooltip.cont = '';
    L.drawLocal.draw.handlers.polygon.tooltip.end = '';

    L.drawLocal.edit.handlers.edit.tooltip.text = '';
    L.drawLocal.edit.handlers.edit.tooltip.subtext = '';

    L.drawLocal.edit.handlers.remove.tooltip.text = '';

    L.drawLocal.draw.handlers.polyline.tooltip.start = '';
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';

    L.drawLocal.draw.handlers.circle.tooltip.start = this.props.res.CLICK_CIRCULO;
  };

  formatRegua = (valor) => {
    let text = '';
    if (valor < 1000) {
      text = Math.round(valor) + ' m';
    } else {
      text = (Math.round(valor / 10) / 100) + ' km';
    }

    return text;
  };

  round_coord = (coord) => {
    return Math.round(coord * 10000000) / 10000000;
  };

  handleClickP = (layer, guid) => {
    if (!this.state.create && !this.state.edit && !this.state.delete) {
      if (layer && guid) {
        this.polyClick = true;
        let array = [];
        //remove selecao
        if (this.state.sel_poly.includes(guid)) {
          //remove item do array
          array = this.state.sel_poly.filter(item => {
            return (!item.includes(guid));
          });

          this.setState({ sel_poly: array }, () => {
            if (this.past_merge) {
              this.showItem();
            }
          });

          if (guid.includes('_xcl')) {
            layer.setStyle({ color: this.color_x });
          } else {
            layer.setStyle({ color: this.color_default });
          }

          if (array.length === 1) {
            let sel = this.state.polygons.filter(item => {
              return (item.guid === array[0] && item.x && item.guid.includes(item.x));
            });
            if (sel.length > 0) {
              this.setState({ sel_x: true });
            } else {
              this.setState({ sel_x: false });
            }
          } else {
            this.setState({ sel_x: false });
          }

          if (array.length === 0) {
            this.setState({ sel_merge: false });
            this.showName(false);
          }
        }
        //seleciona
        else {
          let sel = [];
          if (this.shift && this.state.sel_poly.length > 0) {
            let lastGuid = this.state.sel_poly[this.state.sel_poly.length - 1];
            let indexLast = this.state.polygons.findIndex(x => x.guid === lastGuid);
            let indexNow = this.state.polygons.findIndex(x => x.guid === guid);

            if (indexNow > indexLast) {
              let c = indexNow;
              indexNow = indexLast;
              indexLast = c;
            }
            let polys = this.state.polygons;
            for (let i = indexNow; i <= indexLast; i++) {
              if (!this.state.sel_poly.includes(polys[i].guid)) {
                let layerG = this.mymap._layers[polys[i].guid];
                sel.push({ guid: polys[i].guid, layer: layerG });
              }
            }
          } else {
            sel.push({ guid: guid, layer: layer });
          }
          array = this.state.sel_poly;
          for (let i = 0; i < sel.length; i++) {
            array = array.concat(sel[i].guid);
            let areaX = this.state.polygons.filter(item => {
              return (item.guid.includes(sel[i].guid) && (item.old));
            });
            let areaX2 = this.state.polygons.filter(item => {
              return (item.guid.includes(sel[i].guid) && (item.x));
            });
            areaX = areaX.concat(areaX2);
            if (areaX.length > 0) {
              for (let j = 0; j < areaX.length; j++) {
                const item = areaX[j];
                if (item.guid !== sel[i].guid) {
                  array.push(item.guid);
                }
              }
            }
            sel[i].layer.setStyle({ color: this.color_selected });
          }
          this.setState({ sel_poly: array }, () => {
            if (this.past_merge) {
              this.showItem();
            }
          });

          if (array.length === 1) {
            let sel = this.state.polygons.filter(item => {
              return (item.guid === array[0] && item.x && item.guid.includes(item.x));
            });
            if (sel.length > 0) {
              this.setState({ sel_x: true });
            } else {
              this.setState({ sel_x: false });
            }
          } else {
            this.setState({ sel_x: false });
          }
        }

        if (this.state.edit_name) {
          this.updateName();
        }

        let area_text = 0;
        for (let i = 0; i < array.length; i++) {
          const element = array[i];
          let areaX = this.state.polygons.filter(item => {
            return (item.guid === element && (item.old))
          });
          if (areaX.length > 0) {
            this.setState({ sel_merge: true });
          }
          let areaS = this.state.polygons.filter(item => {
            return (item.guid === element && (item.type !== 'X'))
          });
          if (areaS.length > 0) {
            let area = 0;
            if (areaS[0].area) {
              area = areaS[0].area;
            } else {
              let layerP = this.mymap._layers[areaS[0].guid];
              area = L.GeometryUtil.geodesicArea(layerP.getLatLngs()[0]);
            }
            area_text += area;
          }
        }
        if (area_text > 0) {
          let text = Math.round(area_text / 100) / 100 + ' ha';
          this.setState({ label_text: text });
        } else {
          this.setState({ label_text: '' });
        }

      } else if (this.polyClick === false) {
        if (this.state.polygons.length > 0) {
          for (let i = 0; i < this.state.polygons.length; i++) {
            if (this.state.polygons[i].guid.includes('_xcl')) {
              this.mymap._layers[this.state.polygons[i].guid].setStyle({ color: this.color_x });
            } else {
              this.mymap._layers[this.state.polygons[i].guid].setStyle({ color: this.color_default });
            }
          }
          this.setState({ sel_poly: [], sel_merge: false, sel_x: false, label_text: '' });
          this.showName(false);
        }
      } else {
        this.polyClick = false;
      }
    }
    this.shift = false;
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  zoomInL = () => {
    this.mymap.zoomIn();
  };

  zoomOutL = () => {
    this.mymap.zoomOut();
  };

  criaPoly = () => {
    this.resetClick();

    if (!this.markClick) {
      this.setState({ create: true, nameP: this.nextIndex(this.state.polygons) });
      this.drawCreateToolbar = new L.Draw.Polygon(this.mymap, { allowIntersection: false, showArea: true, metric: ['ha'] });
      this.drawCreateToolbar.enable();

    }
    this.markClick = true;
  };

  saveCreate = () => {
    this.drawCreateToolbar.completeShape();
  };

  deleteLastCreate = () => {
    this.drawCreateToolbar.deleteLastVertex();
  };

  cancelCreate = () => {
    this.markClick = false;
    this.setState({ create: false });
    this.drawCreateToolbar.disable();
  };

  criaCirculo = () => {
    this.resetClick();

    if (!this.markClick) {
      this.setState({ create_circle: true, nameP: this.nextIndex(this.state.polygons) });
      this.drawCreateToolbar = new L.Draw.Circle(this.mymap, { showArea: true, metric: ['ha'] });
      this.drawCreateToolbar.enable();

    }
    this.markClick = true;
  };
  cancelCreateCirculo = () => {
    this.markClick = false;
    this.setState({ create_circle: false });
    this.drawCreateToolbar.disable();
  };

  editPoly = () => {
    this.resetClick();

    this.setState({ edit: true, sel_poly: [] });


    this.drawEditToolbar.enable();
    this.state.polygons.forEach(item => {
      this.mymap._layers[item.guid].editing.disable();
    });
    this.state.sel_poly.forEach(item => {
      this.mymap._layers[item].editing.enable();
    });

    for (let i = 0; i < this.state.polygons.length; i++) {
      const element = this.state.polygons[i];
      this.mymap._layers[element.guid].setStyle({ color: this.color_edit });
    }
  };

  saveEdit = () => {
    this.setState({ edit: false, label_text: '' });
    this.drawEditToolbar.save();
    this.drawEditToolbar.disable();
    for (let i = 0; i < this.state.polygons.length; i++) {
      const element = this.state.polygons[i];
      if (element.x && element.guid.includes(element.x)) {
        this.mymap._layers[element.guid].setStyle({ color: this.color_x });
      } else {
        this.mymap._layers[element.guid].setStyle({ color: this.color_default });
      }
    }
  };

  cancelEdit = () => {
    this.setState({ edit: false, label_text: '' });
    this.drawEditToolbar.revertLayers();
    this.drawEditToolbar.disable();
    for (let i = 0; i < this.state.polygons.length; i++) {
      const element = this.state.polygons[i];
      if (element.x && element.guid.includes(element.x)) {
        this.mymap._layers[element.guid].setStyle({ color: this.color_x });
      } else {
        this.mymap._layers[element.guid].setStyle({ color: this.color_default });
      }
    }
  };

  deletePoly = () => {
    this.resetClick();
    this.sel_save = this.state.sel_poly;
    for (let i = 0; i < this.state.sel_poly.length; i++) {
      const element = this.state.sel_poly[i];
      this.featureGroupP.removeLayer(this.mymap._layers[element]);
    }
    this.setState({ delete: true, sel_poly: [] });
    this.drawDeleteToolbar.enable();
  };

  saveDelete = () => {
    this.setState({ delete: false, label_text: '' });

    let polygons = this.state.polygons;
    this.sel_save.forEach(feature => {
      let tempPolygons = polygons.filter(item => { return item.guid.includes(feature); });
      for (let j = 0; j < tempPolygons.length; j++) {
        polygons.splice(polygons.indexOf(tempPolygons[j]), 1);
      }
      this.savePolygons(polygons);
    });

    this.drawDeleteToolbar.save();
    this.drawDeleteToolbar.disable();
  };

  cancelDelete = () => {
    this.setState({ delete: false, label_text: '' });
    if (this.state.polygons.length > this.sel_save.length) {
      this.drawDeleteToolbar.revertLayers()
    }
    this.drawDeleteToolbar.disable();

    this.sel_save.forEach(feature => {
      let tempPolygons = this.state.polygons.filter(item => { return feature.includes(item.guid); });
      for (let j = 0; j < tempPolygons.length; j++) {
        this.drawPolygon(tempPolygons[j]);
      }
    });
  };

  resetClick = () => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.create_circle) {
      this.cancelCreateCirculo();
    }
    if (this.state.ruler_a) {
      this.cancelRuler();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }
    if (this.state.delete) {
      this.cancelDelete();
    }
  };

  handleListItemClick = guid => {
    let poly = this.mymap._layers[guid];
    this.handleClickP(poly, guid);
  };

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (!dF.className.includes('div-listFloatC')) {
      dF.classList.toggle("div-listFloatC");
    } else {
      dF.classList.remove("div-listFloatC");
    }
  };
  showItem = () => {
    this.showName(true);
    if (this.state.sel_poly.length === 1) {
      let item = this.state.polygons.filter(item => { return item.guid === this.state.sel_poly[0]; });
      this.setState({ nameP: item[0].name });
    }
  };

  saveItem = () => {
    let names = this.state.namesC;
    let polygons = this.state.polygons;
    for (let i = 0; i < names.length; i++) {
      let itens = this.state.polygons.filter(item => { return names[i].guid === item.guid });
      itens.forEach((element, key) => {
        element.name = names[i].name;
        let poly = this.mymap._layers[element.guid];
        poly.setTooltipContent(element.name);
      });
    }
    this.savePolygons(polygons);
    this.showName(false);
    if (this.past_merge) {
      this.past_merge = false;
    }
  };
  cancelItem = () => {
    this.showName(false);
    if (this.past_merge) {
      this.setState({ sel_poly: [] });
      this.past_merge = false;
      this.desfazer();
    }
  };

  nextIndex = (listP) => {
    let lastI = 1;
    let retorno = this.text_polygon + lastI;
    for (let i = 0; i < listP.length; i++) {
      if (listP[i].name === retorno) {
        i = -1;
        lastI++;
      }
      retorno = this.text_polygon + lastI;
    }
    return retorno;
  };

  //upload de arquivos KML e KMZ
  loadFile = file => {
    this.showPro(true);

    for (let index = 0; index < file.target.files.length; index++) {
      const filePath = file.target.files[index];
      setTimeout(() => {
        let ext = fileName => fileName.split(".").pop();
        if (ext(filePath.name.toLowerCase()) === 'kml') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            const content = this.fileReader.result;
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(content, "text/xml");
            let track = new togeojson['kml'](xmlDoc);

            this.writeFileMap(track);
          });
          this.fileReader.readAsText(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'kmz') {
          let getDom = xml => (new DOMParser()).parseFromString(xml, "text/xml")
          let getKmlDom = (kmzFile) => {
            let zip = new jszip()
            return zip.loadAsync(kmzFile)
              .then(zip => {
                let kmlDom = null
                zip.forEach((relPath, file) => {
                  if (ext(relPath) === 'kml') {
                    kmlDom = file.async("string").then(getDom)
                  }
                })
                return kmlDom || Promise.reject("No kml file found")
              });
          }
          let geoJson = getKmlDom(filePath).then(kmlDom => {
            let geoJsonObject = new togeojson['kml'](kmlDom);
            return geoJsonObject
          })
          geoJson.then(gj => this.writeFileMap(gj))
        } else if (ext(filePath.name.toLowerCase()) === 'zip') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            shpjs(this.fileReader.result)
              .then((geojson) => {
                geojson.forEach(element => {
                  this.writeFileMap(element);
                });
              })
              .catch(e => {
                console.log(e);
              });
          });
          this.fileReader.readAsArrayBuffer(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'shp') {
          this.fileReader = new FileReader();
          this.fileReader.onloadend = ((e) => {
            const features = [];
            let polyLength = 0;
            shpjs.parseShp(this.fileReader.result).forEach(element => {
              const geometry = { type: element.type, coordinates: element.coordinates };
              features.push({ properties: { guid: undefined, name: undefined, type: element.type }, geometry: geometry });
              if (element.type === "Polygon") {
                polyLength++;
              } else if (element.type === "MultiPolygon") {
                polyLength += element.coordinates.length;
              }
            });
            this.writeFileMap({ features: features, polyLength: polyLength });
          });
          this.fileReader.readAsArrayBuffer(filePath);
        } else if (ext(filePath.name.toLowerCase()) === 'zjob') {
          this.uploadFile(filePath);
        } else {
          notification.open({
            message: this.props.res.ERRO,
            description: this.props.res.MSG_ERRO_ARQUIVO_NAO_SUPORTADO,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
          this.showPro(false);
        }
      }, 50);
    };
    document.getElementById('contained-button-file').value = null;
    //
  };
  uploadFile = (file) => {
    //this.setState({ progress: true });

    let apiUrl = Config.server + '/getPolygon';//TODO: arrumar!!!
    let method = 'POST';

    const formData = new FormData();//URLSearchParams

    formData.append('name', file.name);
    formData.append('file', file, 'teste.file');

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.polygons.length > 0) {
            let polys = this.state.polygons;
            for (let i = 0; i < result.polygons.length; i++) {
              const element = result.polygons[i];
              let latlngs = JSON.parse(element.points);

              let tempPoly = new polygon();
              tempPoly.guid = element.guid;
              tempPoly.name = element.name;
              tempPoly.type = element.type;
              //tempPoly.x = props.type === 'X' ? guid.split('_xcl')[0] : '';

              tempPoly.points = JSON.stringify(latlngs);

              this.drawPolygon(tempPoly);
              polys.push(tempPoly);
            }
            this.savePolygons(polys);
            this.mymap.fitBounds(this.featureGroupP.getBounds());
          } else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
          this.showPro(false);
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
          this.showPro(false);
        }
      );
  };

  //desenha os polygons do arquivo 
  writeFileMap = (track) => {
    if (track.features.length > 50 || (track.polyLength && track.polyLength > 50)) {
      this.itens_import = track.features;
      let list = [];
      for (let i = 0; i < track.features.length; i++) {
        let feature = track.features[i];
        if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'GeometryCollection') {
          //let guid = feature.properties.guid ? feature.properties.guid : tools.newGuid();
          let name = feature.properties.name ? feature.properties.name : this.nextIndex(this.state.polygons);
          list.push({
            name: name,
            //guid: guid
            index: i
          });
        }
      }
      this.setState({ itensImport: list, showImport: true });
    } else {
      setTimeout(this.fileMap(track.features), 20);
    }
  }
  fileMap = (tFeatures) => {
    let polys = this.state.polygons;
    for (let i = 0; i < tFeatures.length; i++) {
      //const element = turfS(track.features[i], {tolerance: 0.00005, highQuality: true});
      let features = tFeatures[i];
      if (features.geometry.type === 'Polygon') {
        polys = this.loadPolyFile(features.properties, features.geometry.coordinates[0], polys);
      } else if (features.geometry.type === 'MultiPolygon') {
        for (let i = 0; i < features.geometry.coordinates.length; i++) {
          features.properties.type = 'Polygon';
          polys = this.loadPolyFile(features.properties, features.geometry.coordinates[i][0], polys);
        }
      } else if (features.geometry.type === 'GeometryCollection') {
        let geometries = features.geometry.geometries;
        for (let j = 0; j < geometries.length; j++) {
          let element = geometries[j];
          if (element.type === 'Polygon') {
            polys = this.loadPolyFile(features.properties, element.coordinates[0], polys);
          }
        }
      }
    }

    this.savePolygons(polys);
    if (polys.length > 0) {
      this.mymap.fitBounds(this.featureGroupP.getBounds());
    }
    this.showPro(false);
  };

  loadPolyFile = (props, coordinates, polys) => {
    try {
      let tempPX = this.state.polygons.filter(item => { return item.guid === props.guid });
      if (tempPX.length === 0) {
        let element = geo.simplify(coordinates, 0.00005);
        let latlngs = this.geojsonToLeafletArray(element.geometry.coordinates[0]);
        let guid = props.guid ? props.guid : tools.newGuid();
        let name = props.name ? props.name : this.nextIndex(polys);

        let tempPoly = new polygon();
        tempPoly.guid = guid;
        tempPoly.name = name;
        tempPoly.type = props.type;
        tempPoly.x = props.type === 'X' ? guid.split('_xcl')[0] : '';

        tempPoly.points = JSON.stringify(latlngs);

        this.drawPolygon(tempPoly);

        return polys.concat(tempPoly);
      } else {
        return polys;
      }
    } catch (e) {
      notification.open({
        message: this.props.res.ERRO,
        description: this.props.res.ERRO_MSG,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return polys;
    }
  };

  geojsonToLeafletArray = (poly) => {
    let array = [];
    for (let i = 0; i < poly.length; i++) {
      const element = poly[i];
      if (element) {
        array = array.concat([[element[1], element[0]]]);
      } else {

      }
    }
    return array
  };

  showGoto = () => {
    if (this.state.goto) {
      this.setState({ goto: false });
    } else {
      this.setState({ goto: true, lat: this.round_coord(this.mymap.getCenter().lat), lng: this.round_coord(this.mymap.getCenter().lng) });
      window.history.replaceState(null, "Travicar", "/app/draw/" + this.round_coord(this.mymap.getCenter().lat) + ',' + this.round_coord(this.mymap.getCenter().lng));
    }
  };

  goto = (type) => {
    this.setState({ goto: false });
    if (!type) {
      this.mymap.setView([this.state.lat, this.state.lng]);
    } else if (type === 'poly') {
      this.mymap.fitBounds(this.featureGroupP.getBounds());
    } else if (type === 'home') {
      if (this.state.home !== Config.place) {
        this.mymap.setView(this.state.home);
      }
    }
  };

  getOrigem = (oPoly) => {
    let polys = [];

    let inP = (poly) => {
      let polyx = this.state.polygons.filter(itemP => { return itemP.guid.includes(oPoly.guid); });
      if (poly.old === null || polyx.length === 1) {
        polys.push(L.polygon(JSON.parse(poly.points)).toGeoJSON());
      } else {
        for (let i = 0; i < poly.old.ob.length; i++) {
          if (!poly.old.ob[i].guid.includes('_xcl')) {
            inP(poly.old.ob[i]);
          }
        }
      }
    }

    inP(oPoly);

    return polys;
  };

  junta = () => {
    this.showPro(true);
    this.setState({ merge: false });
    if (this.state.sel_poly.length > 1) {
      let apiUrl = Config.server + '/merge_polygons';
      let method = 'POST';

      const formData = new URLSearchParams();

      let origem = [];
      let polys = [];
      let polysX = [];
      this.state.sel_poly.forEach(element => {
        if (element.includes('_xcl')) {
          let oPoly = this.state.polygons.filter(itemP => { return itemP.guid === element; })[0];
          if (oPoly.type === 'XL' || oPoly.type === 'X') {
            polysX.push((this.mymap._layers[element]).toGeoJSON());
          }
        } else {
          let oPoly = this.state.polygons.filter(itemP => { return itemP.guid === element; });
          if (oPoly.length > 0) {
            origem = origem.concat(this.getOrigem(oPoly[0]));
            if (oPoly[0].radius > 0) {
              const myLayer = L.polygon(JSON.parse(oPoly[0].points));
              const geoj = myLayer.toGeoJSON();
              polys.push(geo.simplify(geoj.geometry.coordinates[0], 0.00005));
            } else {
              const geoj = (this.mymap._layers[element]).toGeoJSON();
              polys.push(geo.simplify(geoj.geometry.coordinates[0], 0.00005));
            }

          }
        }
      });
      formData.append('polygons', JSON.stringify(polys));
      formData.append('origem', JSON.stringify(origem));
      formData.append('polygons_xcl', JSON.stringify(polysX));
      formData.append('size', this.state.gap_size);
      formData.append('type', this.state.type);

      let options = {
        method: method,
        body: formData,
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            this.showPro(false);
            //this.setState({ progress: false });
            if (result.msgErro) {
              notification.open({
                message: result.msgErro,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (result.poly) {
              this.merge(result);
            } else {
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          },
          (error) => {
            this.showPro(false);
            //this.setState({ progress: false });
            if (error.status === 405) {
              notification.open({
                message: this.props.res.PERMISSAO,
                description: this.props.res.PERMISSAO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (error.status === 401 || error.status === 403) {
              console.log(error);
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    } else {
      this.showPro(false);
      //this.setState({ progress: false });
    }
  };

  merge = (polyFinal) => {
    let simpl = geo.simplify(polyFinal.poly.geometry.coordinates[0], 0.00005);
    let latlngs = this.geojsonToLeafletArray(simpl.geometry.coordinates[0]);

    let guid = tools.newGuid();
    let name = this.nextIndex(this.state.polygons);

    let polys = { ob: [] }
    this.state.sel_poly.forEach(element => {
      let l = this.mymap._layers[element];
      //polys.layers.push(l);
      polys.ob.push(this.state.polygons.filter(itemP => { return itemP.guid === element; })[0]);
      this.featureGroupP.removeLayer(l);
    });

    let itens = this.state.polygons.filter(item => { return !this.state.sel_poly.includes(item.guid); });

    let tempRumo = new polygon();
    tempRumo.guid = guid;
    tempRumo.name = name;
    tempRumo.points = JSON.stringify(latlngs);
    tempRumo.old = polys;

    this.drawPolygon(tempRumo);

    itens.push(tempRumo);

    this.setState({ sel_poly: [] });
    let select = [guid];

    //area de exclusao 
    let x = polyFinal.x;
    if (x) {
      if (x.type === 'Feature') {
        x = { features: [x] };
      }
      if (Array.isArray(x)) {
        x = { features: x };
      }
      if (x.features) {
        for (let i = 0; i < x.features.length; i++) {
          const element = x.features[i];
          simpl = geo.simplify(element.geometry.coordinates[0], 0.00005);
          let latlngs2 = this.geojsonToLeafletArray(simpl.geometry.coordinates[0]);

          let tempRumoX = new polygon();
          tempRumoX.guid = guid + '_xcl' + (i + 1);
          tempRumoX.name = 'xcl' + (i + 1);
          tempRumoX.points = JSON.stringify(latlngs2);
          tempRumoX.x = guid;
          tempRumoX.type = 'XL';

          this.drawPolygon(tempRumoX);

          itens.push(tempRumoX);
          select.push(tempRumoX.guid);
        }
      }
    }
    this.savePolygons(itens);
    this.setState({ sel_poly: [] }, () => {
      this.past_merge = true;
      let myLayer = this.mymap._layers[guid];
      this.handleClickP(myLayer, guid);
    });

  };
  //desfazer merge
  desfazer = () => {
    if (this.state.sel_poly.length > 0) {
      let poly = this.state.polygons.filter(item => {
        return (this.state.sel_poly.includes(item.guid) && item.old)
      });
      this.handleClickP();//reset sel
      poly.forEach(element => {
        let remove = this.state.sel_poly.filter(guid => {
          return (guid.includes(element.guid))
        });
        remove.forEach(guid => {
          this.featureGroupP.removeLayer(this.mymap._layers[guid]);
        });

        let array = this.state.polygons.concat(element.old.ob);
        array = array.filter(item => {
          return (!item.guid.includes(element.guid))
        });
        this.savePolygons(array);

        element.old.ob.forEach(item => {
          this.drawPolygon(item);
        });
      });
      this.setState({ sel_merge: false, label_text: '' });
    }
  };

  download = () => {
    if (this.state.sel_poly.length > 0) {
      let polys = [];
      this.state.sel_poly.forEach((sel) => {
        let item = this.mymap._layers[sel].toGeoJSON();
        let poly = this.state.polygons.filter(itemP => { return itemP.guid === sel; })[0];
        let name = poly.name;

        if (item.type === 'FeatureCollection') {
          item = item.features[0];
        }
        if (item.geometry.type === 'Point') {
          let myLayer = L.polygon(JSON.parse(poly.points));
          item = myLayer.toGeoJSON();
          item.properties = {
            name: name,
            type: poly.x ? 'X' : 'N',
            guid: poly.guid,
            point: poly.point,
            radius: poly.radius
          };
        } else {
          item.properties = {
            name: name,
            type: poly.x ? 'X' : 'N',
            guid: poly.guid
          };
        }


        polys.push(item);
      });
      /*let collection = {
          type: "FeatureCollection",
          features: polys
      }*/

      //let zip = new jszip();
      let file_name = Date.now() + '.kml';
      let data = geo.toKml(file_name, polys);
      //saveAs(data, );

      var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      saveAs(blob, file_name);
      /*zip.file("file.kml", data);
      zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, "areas.kmz");
      });*/
    }
  };

  changeType = () => {
    //        let guid = '';
    let sel = this.state.sel_poly;
    this.setState({ sel_poly: [] });
    sel.forEach(item => {
      let polys = this.state.polygons;
      let poly = polys.filter(i => { return i.guid === item })[0];
      if ((!poly.x || poly.x === poly.guid) && !poly.old) {
        let layer = this.mymap._layers[item];
        if (layer._leaflet_id.includes('xcl')) {
          poly.guid = poly.guid.split('_xcl')[0];
          poly.x = '';
          poly.type = 'N';
        } else {
          poly.guid = poly.guid + '_xcl';
          poly.x = poly.guid;
          poly.type = 'X';
        }
        this.featureGroupP.removeLayer(layer);
        this.drawPolygon(poly);
        this.handleListItemClick(item);
      }
      this.savePolygons(polys);
    });
  };

  savePolygons = (polys) => {
    this.setState({ polygons: polys });
    localStorage.setItem('c-polygons', JSON.stringify(polys));
  };

  loadPolygons = () => {
    let polys = localStorage.getItem('c-polygons');
    if (polys) {
      polys = JSON.parse(polys);
      if (polys.length < 200) {
        try {
          polys.forEach(element => {
            this.drawPolygon(element);
          });
          if (polys.length > 0) {
            this.mymap.fitBounds(this.featureGroupP.getBounds());
            this.setState({ polygons: polys });
            //????   reload tooltip   ??????
            this.mymap.eachLayer((l) => {
              if (l.getTooltip) {
                var toolTip = l.getTooltip();
                if (toolTip) {
                  this.mymap.closeTooltip(toolTip);
                  setTimeout(() => {
                    this.mymap.addLayer(toolTip);
                  }, 500);
                }
              }
            });
          }
        }
        catch (e) { }
      } else {
        localStorage.setItem('c-polygons', JSON.stringify([]));
      }
    }
  };

  drawPolygon = (poly) => {
    let myStyle = {
      "color": this.color_default,
      "weight": 4,
      "opacity": 0.5
    };

    if (poly.type === 'X' || poly.x) {
      myStyle = {
        "color": this.color_x,
        "weight": 2,
        "opacity": 1
      };
    }
    let myLayer = null;
    if (poly.radius > 0) {
      let temp_myStyle = {
        color: myStyle.color,
        weight: myStyle.weight,
        opacity: myStyle.opacity,
        radius: poly.radius
      }
      myLayer = L.circle(poly.point, temp_myStyle);
    } else {
      myLayer = L.polygon(JSON.parse(poly.points), myStyle);
    }
    //let myLayer = L.geoJSON(element, { style: myStyle });
    let guid = poly.guid;
    let name = poly.name;
    myLayer.on("click", () => { this.handleClickP(myLayer, guid); });
    myLayer._leaflet_id = guid;

    myLayer.bindTooltip(name, { permanent: true, direction: "center", className: 'tool-tip-L tool-tip-size16-L' });

    myLayer.addTo(this.featureGroupP);
  };

  limitChar = (string, limit) => {
    let ret = string;
    let size = SPW(string, { font: "Arial", size: 16 });
    if (size > limit) {
      ret = string.slice(0, (string.length * (limit - 16) / size)) + '...';
    }
    return ret;
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      let sel = [];
      let polys = this.state.polygons;
      for (let i = 0; i < polys.length; i++) {
        sel.push(polys[i].guid);
        let layer = this.mymap._layers[polys[i].guid];
        layer.setStyle({ color: this.color_selected });
      }
      this.setState({ sel_poly: sel });
      //this.setState({ sel_poly: this.state.polygons.map(x => { return x.guid; }) });
    } else {
      let polys = this.state.polygons;
      for (let i = 0; i < polys.length; i++) {
        let layer = this.mymap._layers[polys[i].guid];
        if (polys[i].guid.includes('_xcl')) {
          layer.setStyle({ color: this.color_x });
        } else {
          layer.setStyle({ color: this.color_default });
        }
      }
      this.setState({ sel_poly: [] });
    }
  };

  inportFilter = (itens) => {
    this.setState({ showImport: false });
    let features = [];
    for (let i = 0; i < itens.length; i++) {
      features.push(this.itens_import[itens[i].index]);
    }

    setTimeout(this.fileMap(features), 10);
  };

  showPro = (b) => {
    let dF = document.getElementById('divPro');
    if (!b) {
      dF.classList.toggle("sr-only");
    } else {
      dF.classList.remove("sr-only");
    }
  };

  showName = (show) => {
    if (this.state.sel_poly.length > 0 || !show) {
      if (show) {
        this.updateName();
      } else {
        this.setState({ namesC: [] });
      }
      this.setState({ edit_name: show });
    }
  };

  updateName = () => {
    let array = [];
    let sel = this.state.polygons.filter(item => { return this.state.sel_poly.includes(item.guid); });
    let last = this.state.namesC;

    for (let i = 0; i < sel.length; i++) {
      let exists = last.filter(item => { return item.guid === sel[i].guid });
      if (exists.length === 0) {
        array.push({ guid: sel[i].guid, name: sel[i].name });
      } else {
        array.push({ guid: exists[0].guid, name: exists[0].name });
      }
    }

    this.setState({ namesC: array });
  };

  handleChangeNameList = guid => event => {
    let array = this.state.namesC;

    for (let i = 0; i < array.length; i++) {
      if (array[i].guid === guid) {
        array[i].name = event.target.value;
      }
    }

    this.setState({ namesC: array });
  };

  keyDown = (e) => {
    if (e.keyCode === 16) {
      this.shift = true;
    }
  };

  keyUp = (e) => {
    this.shift = false;
  };

  regua = (start) => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }

    this.featureGroupPoly.removeLayer(this.mymap._layers['regua']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM1']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM2']);
    if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
      this.setState({ ruler_a: false });
      this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
      this.drawCreateToolbar.disable();
    } else {
      this.setState({ ruler_a: true });
      this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
      this.drawCreateToolbar.enable();
      if (start) {
        this.drawCreateToolbar.addVertex(start);
      }
    }
  };

  completeRuler = () => {
    this.drawCreateToolbar.disable();

    let latlngs = [
      [this.ruler_p.first.lat, this.ruler_p.first.lng],
      [this.ruler_p.second.lat, this.ruler_p.second.lng]
    ];
    let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    let text = latlngF.distanceTo(latlngS);


    let polyline = L.polyline(latlngs);

    polyline._leaflet_id = 'regua';
    polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
    polyline.addTo(this.featureGroupPoly);

    let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    })
    m1.on('click', (e) => {
      this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    })
    m1._leaflet_id = 'reguaM1';
    m1.addTo(this.featureGroupPoly);

    let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    });
    m2.on('click', (e) => {
      this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    })
    m2._leaflet_id = 'reguaM2';
    m2.addTo(this.featureGroupPoly);
  };

  cancelRuler = () => {
    if (this.ruler_p.second.lat) {
      this.regua();
    }
  };

  unkink = () => {
    if (this.state.sel_poly.length === 1) {
      //let item = this.state.polygons.filter(item => { return item.guid === this.state.sel_poly[0]; });
      //item.poly = this.mymap._layers[this.state.sel_poly[0]].toGeoJSON();
      let item = this.mymap._layers[this.state.sel_poly[0]].toGeoJSON();
      console.log('fix', this.fix_kink_clean([item]));
    }
  };

  fix_kink_clean = (polys) => {
    let temp_polys = [];
    for (let i = 0; i < polys.length; i++) {
      let kinks = turf.kinks(polys[i]);
      if (kinks.features.length > 0) {
        let cut_poly = this.split_size([{ poly: polys[i], name: i + 'asd', guid: 'asd' + i }], turf.polygonToLine(polys[i]), 0);
        for (let j = 0; j < cut_poly.poly[0].polys.length; j++) {
          let contains = turf.booleanContains(polys[i], cut_poly.poly[0].polys[j]);
          if (contains) {
            temp_polys.push(cut_poly.poly[0].polys[j]);
          }
        }
      } else {
        temp_polys.push(polys[i]);
      }
    }

    return temp_polys;
  }

  split_size = (polys, line, size) => {
    let polyFinal = [];
    let x = [];
    let msg = null;
    let temp_poly = turf.buffer(line, (size / 2) + .3, { units: 'meters' });
    for (let i = 0; i < polys.length; i++) {
      let overlap = turf.booleanOverlap(temp_poly, polys[i].poly) ||
        (line.geometry.coordinates[0][0] === polys[i].poly.geometry.coordinates[0][0][0])
      if (overlap) {
        let item = { polys: [], name: polys[i].name, guid: polys[i].guid };
        x.push(polys[i].guid);
        let temp = turf.difference(polys[i].poly, temp_poly);
        for (let j = 0; j < temp.geometry.coordinates.length; j++) {
          item.polys.push(turf.polygon(temp.geometry.coordinates[j]));
        }
        polyFinal.push(item);
      }
    }
    return { poly: polyFinal, x: x, msgErro: msg };
  }

  render() {

    const { res } = this.props;

    return (
      <div className="rowMapC maxH">
        <div id="divPro" className="sr-only back-drop" style={{ backgroundColor: '#fff', zIndex: 1002 }}>
          <div className="in-back-drop">
            <CircularProgress color="inherit" disableShrink />
          </div>
        </div>
        <ListFilter res={res} itens={this.state.itensImport} open={this.state.showImport} index={1003} listFilter={this.inportFilter} />
        <div id="divFloat" className="div-list divContentList list-p">
          <div style={{ padding: '.5rem', display: 'flex' }}>
            <div style={{ width: '50%' }}>{res.AREAS}</div>
            <div style={{ width: '50%', textAlign: 'right' }}>
              <Checkbox
                disableRipple
                className="checkMap"
                indeterminate={this.state.sel_poly.length > 0 && this.state.sel_poly.length < this.state.polygons.length}
                checked={this.state.sel_poly.length === this.state.polygons.length}
                onChange={this.handleSelectAllClick}
              />
            </div>
          </div>
          <div className="div-list_h">
            <List
              onKeyDown={this.keyDown}
              onKeyUp={this.keyUp}
              component="nav"
              aria-labelledby="areas"
              style={{ position: 'absolute', width: '100%' }}
            >
              {this.state.polygons.map((item, index) => {
                return (
                  <ListItem key={index} button
                    className={(this.state.sel_poly.indexOf(item.guid) >= 0) ? 'SelItem' : ''}
                    onClick={event => this.handleListItemClick(item.guid)}
                    title={item.name}
                  >
                    <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                    {
                      item.x && <div style={{ height: '1rem', width: '.5rem', backgroundColor: this.color_x }}></div>
                    }
                  </ListItem>
                )
              })}
            </List>
          </div>
        </div>
        <div className="divContent divRelative map-p">
          <div className="containerTop">
            <div className="leftTop">
              <Button className="buttonS buttonMenu buttonMenuSmall" onClick={event => this.showMenu()}>
                <MaterialIcon icon="menu" className="iconMapF" />
              </Button>

              < Button className={this.state.create ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.criaPoly()} title={res.CRIAR_POLY}>
                <SvgIcon viewBox="76 18 21 26" className="iconMapP" >
                  <path id="polygon" d="M 100 23.4 L 97 36.9 L 83 39.6 L 76 27 L 86 18 Z" />
                </SvgIcon>
              </Button>

              <Button className={this.state.create_circle ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.criaCirculo()} title={res.CRIAR_CIRCULO}>
                <MaterialIcon icon="fiber_manual_record" className="iconMap" />
              </Button>

              <Button className={this.state.edit ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={!(this.state.sel_poly.length > 0)} onClick={event => this.editPoly()} title={res.EDITAR_POLY}>
                <MaterialIcon icon="crop_free" className="iconMap" />
              </Button>
              <Button className={this.state.delete ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={!(this.state.polygons.length > 0)} onClick={event => this.deletePoly()} title={res.DELETAR_POLY}>
                <MaterialIcon icon="delete" className="iconMap" />
              </Button>
              <Button className={this.state.ruler_a ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} disabled={(this.state.create || this.state.edit || this.state.delete)} onClick={event => this.regua()} title={res.REGUA}>
                <MaterialIcon icon="settings_ethernet" className="iconMap highlightSVG" />
              </Button>

              {(this.state.sel_poly.length > 0) &&
                <Button className="buttonS buttonMenu" disabled={!(this.state.sel_poly.length > 0)} onClick={event => this.showItem()} title={res.EDITAR_NOME}>
                  <MaterialIcon icon="format_shapes" className="iconMap" />
                </Button>
              }
              {this.state.sel_poly.length > 1 &&
                <Button className="buttonS buttonMenu" disabled={!(this.state.sel_poly.length > 1)} onClick={event => this.setState({ merge: true })} title={res.AGRUPAR}>
                  <MaterialIcon icon="view_compact" className="iconMap" />
                </Button>
              }
              {this.state.sel_merge &&
                <Button className="buttonS buttonMenu" onClick={event => this.desfazer()} title={res.DESFAZER}>
                  <MaterialIcon icon="undo" className="iconMap" />
                </Button>
              }
              {(this.state.sel_poly.length === 1 && !this.state.sel_merge) &&
                <span>
                  {!this.state.sel_x ?
                    <Button className="buttonS buttonMenu" onClick={event => this.changeType()} title={res.SET_X}>
                      <MaterialIcon icon="close" className="iconMap" />
                    </Button>
                    :
                    <Button className="buttonS buttonMenu" onClick={event => this.changeType()} title={res.SET_N}>
                      <MaterialIcon icon="check" className="iconMap" />
                    </Button>
                  }
                </span>
              }
              <Button className="buttonS buttonMenu" onClick={event => this.unkink()} title={res.SELECIONADO}>
                <SvgIcon viewBox="79 18 21 26" className="iconMapP" >
                  <path id="polygon" d="M 100 23.4 L 97 36.9 L 88 40 L 88 27 L 76 27 L 86 18 z M 76 29 L 86 29 L 86 40 L 83 40 Z" />
                </SvgIcon>
              </Button>
            </div>
            <div className="centerTop"></div>
            <div className="rightTop">
              <label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                <input
                  accept=".kml,.kmz,.shp,.zip,.zjob"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  multiple
                  type="file" onChange={this.loadFile}
                />
                <label htmlFor="contained-button-file">
                  <Button component="span" className="buttonS buttonMenu" title={res.IMPORTAR_AREAS}>
                    <MaterialIcon icon="attach_file" className="iconMap" />
                  </Button>
                </label>
              </label>
              <Button className="buttonS buttonMenu" onClick={event => this.download()} disabled={(this.state.sel_poly.length > 0) ? false : true} title={res.BAIXAR_SELECIONADOS}>
                <MaterialIcon icon="save" className="iconMap" />
              </Button>
              <Button className="buttonS buttonMenu" onClick={event => this.showGoto()} title={res.IR_PARA}>
                <MaterialIcon icon="search" className="iconMap" />
              </Button>

            </div>
          </div>
          <div className="wrapperMap">
            <div id="mapid"></div>
            <div className="zoomB">
              <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                <MaterialIcon icon="add" className="iconMap" />
              </Button>
              <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                <MaterialIcon icon="remove" className="iconMap" />
              </Button>
            </div>
            <div className={this.state.edit || this.state.delete ? "divContent floatDiv floatDivItens" : "sr-only"}>
              <label className="textDiv">
                {res.CLICK_DESFAZER}
              </label>
              <Button size="small" className="popUpButton" onClick={event => this.state.edit ? this.saveEdit() : this.saveDelete()}>
                {res.SALVAR}
              </Button>
              <Button size="small" className="popUpButton" onClick={event => this.state.edit ? this.cancelEdit() : this.cancelDelete()}>
                {res.CANCELAR}
              </Button>
            </div>
            <div className={this.state.edit_name || this.state.create || this.state.create_circle ? "divContent floatDiv floatDivItens popUpF" : "sr-only"}>
              <div style={{ overflowY: 'auto' }}>
                {this.state.create || this.state.create_circle ?
                  <TextField
                    id="name"
                    label={res.NOME}
                    margin="none"
                    className="textI"
                    size="small"
                    fullWidth
                    onChange={this.handleChange('nameP')}
                    value={this.state.nameP}
                    onKeyDown={e => {
                      if (e.keyCode === 13) { this.saveCreate() }
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                  />
                  :
                  this.state.namesC
                    .map((item, index) => {
                      return (
                        <TextField
                          key={index}
                          id="namePoly"
                          label={res.NOME}
                          margin="none"
                          className="textI"
                          size="small"
                          fullWidth
                          onChange={this.handleChangeNameList(item.guid)}
                          value={item.name}
                          onKeyDown={e => {
                            if (e.keyCode === 13) { this.saveItem() }
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, 45)
                          }}
                        />
                      )
                    })

                }
              </div>
              <div className="divForm">
                {!this.state.create_circle &&
                  <Button size="small" className="popUpButton" onClick={event => this.state.create ? this.saveCreate() : this.saveItem()}>
                    {res.SALVAR}
                  </Button>
                }
                {this.state.create &&
                  <Button size="small" className="popUpButton" onClick={event => this.deleteLastCreate()}>
                    {res.DESFAZER}
                  </Button>
                }
                <Button size="small" className="popUpButton"
                  onClick={event =>
                    this.state.create ?
                      this.cancelCreate() :
                      this.state.create_circle ?
                        this.cancelCreateCirculo() :
                        this.cancelItem()}//fix
                >
                  {res.CANCELAR}
                </Button>
              </div>
            </div>
            {this.state.merge &&
              <div className="divContent floatW divIA">
                <div>
                  <FormControl className="notTooBig notTooClose">
                    <InputLabel htmlFor="age-simple">{res.AGRUPAR}</InputLabel>
                    <Select
                      value={this.state.type}
                      onChange={event => { this.setState({ type: event.target.value }); }}
                      inputProps={{
                        id: 'type',
                      }}
                    >
                      <MenuItem key="2" value="2">{res.LIGAR_AREA}</MenuItem>
                      {/* <MenuItem key="3" value="3">{res.ANEXAR_AREA}</MenuItem> */}
                      <MenuItem key="5" value="5">{res.ANEXAR_AREA}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {(this.state.type === '3' || this.state.type === '5') &&
                  <div className="">
                    <TextField
                      id="name"
                      label={res.TAMANHO}
                      margin="normal"
                      className="textI"
                      inputProps={{ style: { textAlign: 'right' } }}
                      onChange={this.handleChange('gap_size')}
                      value={this.state.gap_size}
                      onKeyDown={e => {
                        if (e.keyCode === 13) { this.junta(); }
                      }}
                      onInput={(e) => {
                        e.target.value = isNaN(e.target.value) ? this.state.gap_size.toString().slice(0, 3).trim() : e.target.value.toString().slice(0, 3).trim()
                      }}
                    />
                    <span style={{ position: 'relative', bottom: '-1.3rem' }}>  {res.METROS}</span>
                  </div>}
                <div style={{ padding: '1rem' }}></div>
                <Button size="small" className="popUpButton" disabled={!(this.state.sel_poly.length > 1)} onClick={event => { this.junta(); }}>
                  {res.SALVAR}
                </Button>
                <Button size="small" className="popUpButton" onClick={event => { this.setState({ merge: false }); }}>
                  {res.CANCELAR}
                </Button>
              </div>}
            {this.state.goto &&
              <div className="divContent floatW divS" style={{ width: '15rem' }}>
                <div>
                  <span style={{ marginRight: '2rem' }}>{res.IRPARA}</span>
                  <Button size="small" className="popUpButton blButton" disabled={this.state.polygons.length <= 0} onClick={event => this.goto("poly")}>
                    <SvgIcon viewBox="76 18 21 26" className="iconMapP" >
                      <path id="polygon" d="M 100 23.4 L 97 36.9 L 83 39.6 L 76 27 L 86 18 Z" />
                    </SvgIcon>
                  </Button>
                  <Button size="small" className="popUpButton blButton" disabled={this.state.home === Config.place} onClick={event => this.goto("home")}>
                    <MaterialIcon icon="home" className="iconMap" />
                  </Button>
                </div>
                <TextField
                  id="lat"
                  label="LAT"
                  margin="normal"
                  size='small'
                  onChange={this.handleChange('lat')}
                  value={this.state.lat}
                  onKeyDown={e => {
                    if (e.keyCode === 13) { this.goto() }
                  }}
                  onInput={(e) => {
                    e.target.value = isNaN(e.target.value) ? this.state.lat : e.target.value.toString().slice(0, 20).trim()
                  }}
                />
                <TextField
                  id="lng"
                  label="LNG"
                  margin="normal"
                  onChange={this.handleChange('lng')}
                  value={this.state.lng}
                  onKeyDown={e => {
                    if (e.keyCode === 13) { this.goto() }
                  }}
                  onInput={(e) => {
                    e.target.value = isNaN(e.target.value) ? this.state.lng : e.target.value.toString().slice(0, 20).trim()
                  }}
                />
                <div className="divForm">
                  <Button size="small" className="popUpButton" onClick={event => this.goto()}>
                    {res.IR}
                  </Button>
                  <Button size="small" className="popUpButton" onClick={event => this.setState({ goto: false })}>
                    {res.CANCELAR}
                  </Button>
                </div>
              </div>}

            {this.state.label &&
              <div className='tool-tip-H'
                style={{
                  right: '1rem',
                  position: 'absolute',
                  bottom: '0.3rem',
                  zIndex: 1099,
                  textAlign: 'right',
                  color: '#edb83b'
                }}>
                {this.state.label_text}
              </div>}
          </div>
        </div>
      </div >
    );
  };
}

const TextFields1 = EditDraw;

const Box = (props) => {
  const { link, res } = props;
  let { local } = useParams();
  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} local={local} res={res} />
    </div>);
}

export default Box;

