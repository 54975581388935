import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    id: this.props.id,
    name: '',
    description: '',
    email: '',
    type: '',
    ativo: 1,
  };

  componentDidMount() {
    if (this.state.id) {
      const apiUrl = Config.server + '/partner/' + this.state.id;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              this.setState({
                name: result[0].name,
                description: result[0].description,
                email: result[0].email,
                type: result[0].type,
                ativo: result[0].ativo
              });
            }
          },
          (error) => {
            if (error.status === 401) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
    this.updateList();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/partner/';

    const formData = new URLSearchParams();
    if (this.state.id) {
      apiUrl += this.state.id;
      formData.append('id', this.state.id);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    formData.append('name', this.state.name);
    formData.append('description', this.state.description);
    formData.append('email', this.state.email);
    formData.append('type', this.state.type);
    formData.append('ativo', this.state.ativo);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          let msg = this.props.res.ITEM_INCLUIR;
          if (this.state.id) {
            msg = this.props.res.ITEM_ALTERADO;
          }
          notification.open({
            message: this.props.res.SUCESSO,
            description: msg,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
          if (!this.state.id) {
            this.setState({
              name: '',
              description: '',
              email: '',
              type: ''
            });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  updateList = () => {
    const apiUrl = Config.server + '/partner';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            empresa_data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  render() {
    const { classes, link, res } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="containerTop">
          <div><h1>{'Parceiro'}</h1></div>
          <div className="centerTop"></div>
          <div>
            <Button variant="contained" onClick={evemt => { link('/app/cadastro/parceiro/list') }} className="popUpButton">
              {res.CANCELAR}
            </Button> &nbsp;
            <Button type="submit" variant="contained" color="primary" className="popUpButton">
              <SaveIcon className="iconMapS" />
              {res.SALVAR}
            </Button>
          </div>
        </div>
        <div className="form-group notTooBig">
          <TextField
            id="name"
            label={res.NOME}
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange('name')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="description"
            label={res.DESCRICAO}
            className={classes.textField}
            value={this.state.description}
            onChange={this.handleChange('description')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="email"
            label={res.EMAIL}
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange('email')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          {false && <TextField
            id="type"
            label={res.TIPO}
            className={classes.textField}
            value={this.state.type}
            onChange={this.handleChange('type')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />}
        </div>
      </form>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

