class Tools {
    constructor() {
        this.r = null;
        this.i = 0;
    }

    teste = () => {
        return 'Hello!';
    }

    newGuid = () => {
        this.i = 0;
        this.r = new Uint8Array(32);
        window.crypto.getRandomValues(this.r);
        return this.b();
    }

    b = (a) => {
        return a ? (((a ^ this.r[this.i++]) % 16) >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.b);
    };

    fancyTimeFormat = (seconds) => {
        let d = Number(seconds);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);
        return h + ":" + m.toString().padStart(2, "0") + ":" + s.toString().padStart(2, "0");
    }

    dateFormat = (date, zone) => {
        let ret = "";
        if (zone === 'en-US') {
            let hora = (date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
            let t = (date.getHours() > 12 ? ' PM' : ' AM');
            ret = (date.getMonth() + 1) + "." + date.getDate() + "." + date.getFullYear() + " " + ("00" + hora).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + t;
        } else {
            ret = ("00" + date.getDate()).slice(-2) + "." + ("00" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
        }
        return ret;
    }

    dateFormatWithSeconds = (date, zone) => {
        let ret = "";
        if (zone === 'en-US') {
            let hora = (date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
            let t = (date.getHours() > 12 ? ' PM' : ' AM');
            ret = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + ("00" + hora).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2) + t;
        } else {
            ret = ("00" + date.getDate()).slice(-2) + "/" + ("00" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2);
        }
        return ret;
    }

    formatHec = (hec) => {
        return ((hec) / 10000).toFixed(3);
    }

    formatDate = (date) => {
        return (date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear());
    }

    formatDateTime = (date) => {
        return (date.getDate() + '/' + ("00" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()) + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
    }

    cssShow = (col, show) => {
        let cols = document.getElementsByClassName(col);
        for (let i = 0; i < cols.length; i++) {
            cols[i].style.display = show ? 'block' : 'none';
        }
    }

    getDateToInputDate = (value) => {
        let date = this.adjustForTimezone(new Date(value));
        let ret = date.getFullYear() + '-';
        ret += (date.getMonth() + 1).toString().padStart(2, '0') + '-';
        ret += date.getDate().toString().padStart(2, '0');
        return ret;
    }

    adjustForTimezone = (date) => {
        const timeOffsetInMS = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date
    }
}

const tools = new Tools();

export default tools;
