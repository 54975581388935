import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import Skeleton from '@material-ui/lab/Skeleton';
import EqualizerIcon from '@material-ui/icons/Equalizer';

class DiaryApplication extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            jobs: props.jobs || [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobs !== this.props.jobs) {
            this.update();
        }
    }

    update = () => {
        this.setState({
            jobs: this.props.jobs || [],
            isLoading: false,
        }, this.renderChart);
    };

    renderChart = () => {
        const chartElement = this.chartRef.current;
        if (chartElement) {
            const ctx = chartElement.getContext('2d');

            const labels = this.state.jobs.map(job => job.title || 'Sem Título');
            const plannedData = this.state.jobs.map(job => (job.list_exe && job.list_exe.length > 0 && job.list_exe[0].area_polygon) || 0);
            const executedData = this.state.jobs.map(job => (job.list_exe && job.list_exe.length > 0 && job.list_exe[0].area_spray) || 0);

            const plannedDataset = {
                label: 'Área Planejada (ha)',
                data: plannedData,
                backgroundColor: '#FA9F34',
                borderColor: '#FA9F34',
                borderWidth: 1,
            };

            const executedDataset = {
                label: 'Área Executada (ha)',
                data: executedData,
                backgroundColor: '#FFE7D0',
                borderColor: '#FFE7D0',
                borderWidth: 1,
            };

            if (this.barChart) {
                this.barChart.destroy();
            }

            const chartWidth = Math.max(this.state.jobs.length * 150, 600);

            this.barChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels,
                    datasets: [plannedDataset, executedDataset],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Planejado / Executado',
                            color: 'white',
                            position: 'bottom',
                        },
                        legend: {
                            labels: {
                                color: 'white',
                            },
                        },
                    },
                    scales: {
                        y: {
                            ticks: {
                                color: 'lightgray',
                                beginAtZero: true,
                            },
                            title: {
                                display: true,
                                color: 'white',
                                text: 'Área (ha)',
                            },
                        },
                        x: {
                            ticks: {
                                color: 'lightgray',
                                autoSkip: false,
                                maxRotation: 0,
                                minRotation: 0,
                            }
                        }
                    },
                },
            });
        }
    };

    render() {
        const { res } = this.props;

        return (
            <div className="dashboard-card" style={{ height: '100%' }}>
                <div className="divContent"
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        borderRadius: '.8rem',
                        backgroundColor: '#343a40',
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                    }}
                >
                    <div className="cardheader">
                        <EqualizerIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                        <span className="title">{res.APLICACOES}</span>
                    </div>
                    <div className="cardmiddle" style={{ display: 'block', height: '100%', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                        {this.state.isLoading || this.state.jobs.length === 0 ? (
                            <Skeleton variant="rect" width="100%" height="100%" />
                        ) : (
                            <div style={{ minWidth: `${Math.max(this.state.jobs.length * 150, 600)}px`, height: '100%' }}>
                                <canvas ref={this.chartRef} style={{ width: '100%', height: '100%' }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

DiaryApplication.propTypes = {
    jobs: PropTypes.array.isRequired,
    res: PropTypes.object.isRequired,
};

export default DiaryApplication;