import React from 'react';
import MaterialIcon from '../../components/MaterialIcon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinkIcon from '@material-ui/icons/Link';
import { notification } from 'antd';

import Config from '../../constants/appConfig';

import { useParams } from "react-router";

class LoginPage extends React.Component {
  guid = this.props.id;
  state = {
    password1: '',
    password2: '',
    codeGuid: '',
  };

  componentDidMount = () => {
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
    document.getElementById("loading").classList.add('load');
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.password1 !== this.state.password2) {
      notification.open({
        message: this.props.res.CONFERE_SENHA,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
    } else {
      const apiUrl = Config.server + '/authenticateReset';
      const formData = new URLSearchParams();

      if (this.guid) {
        formData.append('guid', this.guid);
      } else {
        formData.append('guid', this.state.codeGuid);
      }

      formData.append('password', this.state.password1);

      let options = {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result && result.error) {
              notification.open({
                message: this.props.res[result.message],
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else {
              notification.open({
                message: this.props.res.SUCESSO,
                description: this.props.res.SENHA_ALTERADA,
                icon: <MaterialIcon icon="check_circle" className="text-success" />
              });
            }
            this.props.history.push('/app');
          },
          (error) => {
            if (error.status === 405) {
              notification.open({
                message: this.props.res.PERMISSAO,
                description: this.props.res.PERMISSAO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (error.status === 401 || error.status === 403) {
              this.props.link('/login');
            }
            else {
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG_INFORMACOES,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  }

  render() {
    const { res } = this.props;
    return (
      <div className="login-page">
        <div className="login-img "></div>
        <div className="login-div">
          <div className="login-container">
            <div className="login-logo" style={{ marginBottom: '2rem' }} />
            <h2>{res.REDEFINIR_SENHA}</h2>
            <p className="lead" style={{ fontSize: '1rem', letterSpacing: '2px' }}>
              {res.REDEFINIR_SENHA_USUARIO}
            </p>
            <form onSubmit={this.handleSubmit} className="form-v1">
              {!this.guid
                ?
                <div className="form-group">
                  <div className="input-group-v1">
                    <div className="input-group-icon">
                      <LinkIcon />
                    </div>
                    <TextField
                      required
                      id="login2-codeGuid"
                      label="Código"
                      name="codeGuid"
                      value={this.state.codeGuid}
                      autoComplete="off"
                      type='text'
                      fullWidth
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                :
                ''
              }
              <div className="form-group">
                <div className="input-group-v1">
                  <div className="input-group-icon">
                    <MaterialIcon icon="lock" />
                  </div>
                  <TextField
                    required
                    id="login2-password1"
                    label={res.NOVA_SENHA}
                    type="password"
                    name="password1"
                    value={this.state.password1}
                    onChange={this.handleInputChange}
                    fullWidth
                    autoComplete="new-password"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-group-v1">
                  <div className="input-group-icon">
                    <MaterialIcon icon="lock" />
                  </div>
                  <TextField
                    required
                    id="login2-password2"
                    label={res.REPETIR_SENHA}
                    type="password"
                    name="password2"
                    value={this.state.password2}
                    onChange={this.handleInputChange}
                    fullWidth
                    autoComplete="new-password"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 45)
                    }}
                  />
                </div>
              </div>
              <div className="form-group bitMoreSpace">
                <Button variant="contained" color="primary" type="submit" className="login-button">
                  {res.MUDAR_SENHA}
                </Button>
              </div>
            </form>

          </div>
        </div>
      </div>
    );
  }
}
//<p className="bitMoreSpace">Forgot your username or password? <a href="/pass">Reset password</a></p>
const Box = (props) => {
  const { link, res, history } = props;
  let { id } = useParams();
  return (
    <div>
      <LoginPage link={link} id={id} res={res} history={history} />
    </div>)
}

export default Box;