import React from 'react';
import Config from '../../constants/appConfig';

class Footer extends React.Component {
    render() {

        return (
            <div className="footer">
                <div className="footer-text"><span>{'v' + Config.version}  © 2024 TRAVICAR</span></div>
            </div>
        )
    }
}

export default Footer;