import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {

  state = {
    empresa_data: [],
    name: '',
    login: '',
    email: '',
    password: '',
    password_2: '',
    id_company: '',
    id: this.props.id,
    type: 1,
    perfil_data: [],
    checked: [2],
    reset: false,
    update_pass: false
  };

  componentDidMount() {

    if (this.state.id) {
      const apiUrl = Config.server + '/user/' + this.state.id;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              this.setState({
                name: result[0].name,
                login: result[0].login,
                email: result[0].email,
                id_company: result[0].id_company,
                type: result[0].type
              });
            }
          },
          (error) => {
            if (error.status === 401) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
    this.loadCompany();
    this.loadProfile();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  loadCompany = () => {
    const apiUrl = Config.server + '/company';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            empresa_data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  loadProfile() {
    const apiUrl = Config.server + '/profile';
    let obj = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };
    fetch(apiUrl, obj)
      .then((res) => {
        if (res.status !== 200)
          throw res;//new Error(res.status);
        else
          return res.json();
      })
      .then(
        (result) => {
          this.setState({ perfil_data: result });
          this.loadUserProfile();
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }


  loadUserProfile() {
    const apiUrl = Config.server + '/user_profile/' + this.state.id;
    let obj = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };
    fetch(apiUrl, obj)
      .then((res) => {
        if (res.status !== 200)
          throw res;//new Error(res.status);
        else
          return res.json();
      })
      .then(
        (result) => {
          let array = [];
          result.forEach(element => {
            array.push(element.id_profile);
          });
          this.setState({ checked: array });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.password !== this.state.password_2) {
      notification.open({
        message: this.props.res.SENHA_DIFERENTE,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return;
    }

    let method = 'POST';
    let apiUrl = Config.server + '/user/';
    if (this.state.id) {
      apiUrl += this.state.id;
      method = 'PUT';
    }

    const formData = new URLSearchParams();

    formData.append('id', this.state.id);
    formData.append('id_company', this.state.id_company);
    formData.append('name', this.state.name);
    formData.append('login', this.state.login);
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);
    formData.append('profiles', this.state.checked);
    if (this.state.reset) {
      formData.append('reset', 'reset');
    }

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          let msg = this.props.res.ITEM_INCLUIR;
          if (this.state.id) {
            msg = this.props.res.ITEM_ALTERADO;
          }
          notification.open({
            message: this.props.res.SUCESSO,
            description: msg,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
        },
        (error) => {
          if (error.status === 400) {
            notification.open({
              message: this.props.res.LOGIN_REPETIDO,
              description: this.props.res.LOGIN_REPETIDO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );

  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  render() {
    const { classes, link, res } = this.props;

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="containerTop">
          <div><h1>{res.USUARIO}</h1></div>
          <div className="centerTop"></div>
          <div>
            <Button variant="contained" onClick={evemt => { link('/app/cadastro/usuario/list') }} className="popUpButton">
              {res.CANCELAR}
            </Button> &nbsp;
            <Button type="submit" variant="contained" color="primary" className="popUpButton">
              <SaveIcon className="iconMapS" />
              {res.SALVAR}
            </Button>
          </div>
        </div>
        <div className="form-group notTooBig">
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="age-simple">{res.EMPRESA}</InputLabel>
            <Select
              value={this.state.id_company}
              onChange={this.handleChange('id_company')}
              inputProps={{
                id: 'id_company',
              }}
            >
              {this.state.empresa_data.map((n, key) => {
                return (
                  <MenuItem key={key} value={n.id}>{n.nick_name}</MenuItem>
                );
              })}
            </Select>
            <TextField
              required
              id="name"
              label={res.NOME}
              className={classes.textField}
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="login"
              label={res.LOGIN}
              className={classes.textField}
              value={this.state.login}
              onChange={this.handleChange('login')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="email"
              label={res.EMAIL}
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            {this.state.id ?
              <div style={{ width: '100%', textAlign: 'left', padding: '.4rem' }}>
                {res.REDEFINIR_SENHA}
                <Switch
                  checked={this.state.update_pass}
                  onClick={(e) => this.setState({ update_pass: !this.state.update_pass })}
                  color="primary"
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disableRipple
                />
                {(this.state.update_pass) ?
                  <span onClick={(e) => this.setState({ reset: !this.state.reset })} style={{ paddingLeft: '.8rem', cursor: 'pointer' }}>
                    <Checkbox name="checkedC" checked={this.state.reset} />{res.RESET}
                  </span>
                  : ''}
              </div>
              : ''}
            {(this.state.update_pass || !this.state.id) ?
              <span>
                <TextField
                  id="password"
                  label={res.SENHA}
                  className={classes.textField}
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  type="password"
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
                <TextField
                  id="password2"
                  label={res.REPETIR_SENHA}
                  className={classes.textField}
                  value={this.state.password_2}
                  onChange={this.handleChange('password_2')}
                  type="password"
                  margin="normal"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 45)
                  }}
                />
              </span>
              : ''}
          </FormControl>
          <List className={classes.root}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {res.PERFIL}
              </ListSubheader>
            }
          >
            {this.state.perfil_data.map((item, key) => {
              return (
                <ListItem key={key} role={undefined} dense button onClick={this.handleToggle(item.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={this.state.checked.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText key={key} id={item.id} primary={item.name} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </form>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

