import React, { Component } from 'react';
import Chart from 'chart.js/auto';

class Charts extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.chartInstance = null;
    }

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataLabels !== prevProps.dataLabels ||
            this.props.dataValues !== prevProps.dataValues ||
            this.props.backgroundColors !== prevProps.backgroundColors ||
            this.props.chartType !== prevProps.chartType) {
            this.buildChart();
        }
    }

    componentWillUnmount() {
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
    }

    buildChart = () => {
        if (this.props.chartType === 'radar') {
            this.buildRadarChart();
        } else if (this.props.chartType === 'doughnut') {
            this.buildDoughnutChart();
        } else if (this.props.chartType === 'areaTotal') {
            this.buildAreaTotalChart();
        } else {
            this.buildPieChart();
        }
    }

    buildAreaTotalChart = () => {
        const { dataLabels, dataValues, backgroundColors, dataUnits, title, subtitle } = this.props;

        const total = dataValues.slice(0, 2).reduce((acc, value) => acc + value, 0);
        const totalUnit = dataUnits[0];

        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        this.chartInstance = new Chart(this.chartRef.current.getContext('2d'), {
            type: 'doughnut',
            data: {
                labels: dataLabels,
                datasets: [{
                    data: dataValues,
                    backgroundColor: backgroundColors,
                    dataUnits: dataUnits,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '70%',
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            boxWidth: 20,
                            boxHeight: 20,
                            generateLabels: function (chart) {
                                const data = chart.data;
                                const dataUnits = chart.data.datasets[0].dataUnits;
                                if (data.labels.length && data.datasets.length) {
                                    return data.labels.map((label, i) => ({
                                        text: `${label}: ${data.datasets[0].data[i]} ${dataUnits[i] || ''}`,
                                        fillStyle: data.datasets[0].backgroundColor[i],
                                    }));
                                } else {
                                    return [];
                                }
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed !== undefined) {
                                    label += context.parsed + (dataUnits[context.dataIndex] || '');
                                }
                                return label;
                            }
                        }
                    }
                },
            },
            plugins: [{
                afterDraw: chart => {
                    let ctx = chart.ctx;
                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                    ctx.font = `${subtitle} Arial`;
                    ctx.fillStyle = '#000';
                    ctx.fillText('Total', centerX, centerY + 15);
                    ctx.font = `bold ${title} Arial`;
                    ctx.fillText(`${total} ${totalUnit}`, centerX, centerY - 10);
                    ctx.restore();
                }
            }]
        });
    }

    buildPieChart = () => {
        const { dataLabels, dataValues, backgroundColors, dataUnits } = this.props;

        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        this.chartInstance = new Chart(this.chartRef.current.getContext('2d'), {
            type: 'pie',
            data: {
                labels: dataLabels,
                datasets: [{
                    data: dataValues,
                    backgroundColor: backgroundColors,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed !== undefined) {
                                    label += context.parsed + (dataUnits[context.dataIndex] || '');
                                }
                                return label;
                            }
                        }
                    }
                }
            }
        });
    }

    buildDoughnutChart = () => {
        const { dataLabels, dataValues, backgroundColors, dataUnits, title, subtitle } = this.props;

        const total = dataValues.reduce((acc, value) => acc + value, 0);
        const totalUnit = dataUnits[0];

        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        this.chartInstance = new Chart(this.chartRef.current.getContext('2d'), {
            type: 'doughnut',
            data: {
                labels: dataLabels,
                datasets: [{
                    data: dataValues,
                    backgroundColor: backgroundColors,
                    dataUnits: dataUnits,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '70%',
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            boxWidth: 20,
                            boxHeight: 20,
                            generateLabels: function (chart) {
                                const data = chart.data;
                                const dataUnits = chart.data.datasets[0].dataUnits;
                                if (data.labels.length && data.datasets.length) {
                                    return data.labels.map((label, i) => ({
                                        text: `${label}: ${data.datasets[0].data[i]} ${dataUnits[i] || ''}`,
                                        fillStyle: data.datasets[0].backgroundColor[i],
                                    }));
                                } else {
                                    return [];
                                }
                            }
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed !== undefined) {
                                    label += context.parsed + (dataUnits[context.dataIndex] || '');
                                }
                                return label;
                            }
                        }
                    }
                }
            },
            plugins: [{
                afterDraw: chart => {
                    let ctx = chart.ctx;
                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                    ctx.font = `${subtitle} Arial`;
                    ctx.fillStyle = '#000';
                    ctx.fillText('Total', centerX, centerY + 15);
                    ctx.font = `bold ${title} Arial`;
                    ctx.fillText(`${total} ${totalUnit}`, centerX, centerY - 10);
                    ctx.restore();
                }
            }]
        });
    }

    buildRadarChart = () => {
        const { dataLabels, dataValues } = this.props;

        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        this.chartInstance = new Chart(this.chartRef.current.getContext('2d'), {
            type: 'radar',
            data: {
                labels: dataLabels,
                datasets: [{
                    label: 'Valor Médio',
                    data: dataValues,
                    backgroundColor: 'rgba(0, 123, 255, 0.2)',
                    borderColor: 'rgba(0, 123, 255, 1)',
                    borderWidth: 2,
                    fill: true
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    r: {
                        angleLines: {
                            display: true
                        },
                        suggestedMin: 0,
                        suggestedMax: 100
                    }
                },
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: function (tooltipItems) {
                                return tooltipItems[0].dataset.label;
                            },
                            label: function (context) {
                                let label = context.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                const value = context.raw;
                                label += value + '%';
                                return label;
                            }
                        }
                    },
                    legend: {
                        display: true,
                        labels: {
                            generateLabels: function (chart) {
                                let datasets = chart.data.datasets;
                                if (datasets.length) {
                                    return datasets.map((dataset, i) => ({
                                        text: i === 0 ? dataset.label : '',
                                        fillStyle: dataset.backgroundColor,
                                        strokeStyle: dataset.borderColor,
                                        lineWidth: dataset.borderWidth,
                                        hidden: !chart.isDatasetVisible(i)
                                    }));
                                }
                                return [];
                            }
                        }
                    }
                }
            }
        });
    }

    render() {
        return (
            <canvas ref={this.chartRef} />
        );
    }
}

export default Charts;