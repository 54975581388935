class Calls {
    constructor() {
    }

    toJSON = async (length, body, update) => {
        let loaded = 0;
    
        const reader = body.getReader(); // `ReadableStreamDefaultReader`
        const decoder = new TextDecoder();
        const chunks = [];
    
        async function read() {
          const { done, value } = await reader.read();
    
          // all chunks have been read?
          if (done) {
            return JSON.parse(chunks.join(''));
          }
    
          const chunk = decoder.decode(value, { stream: true });
          chunks.push(chunk);
          loaded += value.byteLength;
    
          update && update(loaded, length);
    
          return read(); // read the next chunk
        }
    
        return read();
      }
}

const calls = new Calls();

export default calls;
