import React from 'react';

export default function CustomizedSlider(props) {
  const { text, value, inverted } = props;

  const color = [
    "red",
    "red",
    "#e09f26",
    "#e09f26",
    "green"
  ]

  const getColor = (i) => {
    let c = JSON.parse(JSON.stringify(color));
    if (i) {
      return c.reverse();
    } else {
      return c;
    }
  }

  return (
    <div style={{ marginBottom: '.5rem' }}>
      <div className="titleTextRel" style={{ marginBottom: '.6rem' }}>
        {text}
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 2rem' }}>
        <div style={{ width: '100%', position: 'relative' }}>
          <div style={{ marginBottom: '.3rem', borderRadius: '25px', height: '.6rem', width: '100%', backgroundColor: 'white', overflow: 'hidden', border: 'solid 1px #c9c9c9' }}>
            <div style={{ zIndex: '1', height: '1rem', width: '100%', backgroundImage: 'linear-gradient(to right, ' + getColor(inverted) + ')' }}></div>
          </div>
          <div style={{ width: 'calc(100% - 18px)', position: 'relative' }}>
            <div style={{ borderRadius: '25px', marginTop: '-1.2rem', marginLeft: value + '%', zIndex: '2', width: '18px', height: '18px', backgroundColor: '#3f51b5', position: 'absolute' }}></div>
          </div>
        </div>
        <div style={{ marginLeft: '.5rem', marginTop: '-0.3rem' }}>
          {value + '%'}
        </div>
      </div>
    </div>
  );
}
