import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

class TransferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.selectedCards || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCards !== this.props.selectedCards) {
      this.setState({ checked: this.props.selectedCards });
    }
  }

  handleToggle = (value) => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked }, () => {
      this.props.onSelectionChange(newChecked);
    });
  };

  render() {
    const { availableCards } = this.props;

    return (
      <List>
        {availableCards.map((card) => {
          const labelId = `transfer-list-label-${card}`;

          return (
            <ListItem key={card} role={undefined} dense button onClick={() => this.handleToggle(card)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={this.state.checked.indexOf(card) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={card} />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default TransferList;