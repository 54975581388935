import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

import Config from '../../constants/appConfig';
import Layout from './layout';
import Login from './../../routes/login/login';
import Reset from './../../routes/login/reset';
import ForgotPassword from './../../routes/login/forgotPassword';

import './../../styles/layout.css';
import './../../styles/content.css';
import 'antd/dist/antd.css';

class AppLayout extends React.Component {
    state = {
        redirect: false,
        link: '',
        res: require('./../../assets/resources/language/pt/text.json')
    };
    lan = '';

    componentDidMount() {
        let location = window.location.pathname.split('/');//fix: fazer de uma maneira melhor
        if (location[1] !== 'monitor') {
            const apiUrl = Config.server + '/checkToken';
            let obj = {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            };
            fetch(apiUrl, obj)
                .then(res => {
                    if (res.status !== 200) {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .catch(err => {
                    this.setState({ redirect: true });
                });
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    switchLanguage = (cod) => {
        if (this.lan !== cod) {
            let lan = null;
            if (cod === 'en-US') {
                lan = require('./../../assets/resources/language/en/text.json')
            } else {
                lan = require('./../../assets/resources/language/pt/text.json')
            }
            this.setState({ res: lan });

            this.lan = cod;
            let apiUrl = Config.server + '/language';
            const formData = new URLSearchParams();
            formData.append('language', cod);
            let options = {
                method: 'PUT',
                body: formData,
                headers: {
                    'Authorization': localStorage.getItem('access-token-jwt')
                }
            }

            fetch(apiUrl, options)
                .then((res) => {
                    if (res.status !== 200) throw res;
                    else return res.json();
                })
                .then(
                    (result) => { },
                    (error) => {
                        if (error.status === 401 || error.status === 403) {
                            console.log(error);
                            this.setState({ link: '/login' });
                        }
                    }
                );
        }
    }

    render() {
        try {
            if (this.state.redirect) {
                this.setState({ redirect: false });
                return (<Redirect to={'/login'} />);
            }

            return (

                <Switch>
                    <Route exact path="/">
                        <Redirect to={'/app'} />
                    </Route>

                    <Route path="/app" component={(props) => <Layout {...props} link={this.state.link} res={this.state.res} lan={this.switchLanguage} />} />
                    <Route path="/login" component={(props) => <Login {...props} res={this.state.res} lan={this.switchLanguage} />} />
                    <Route path="/forgotPassword" component={(props) => <ForgotPassword {...props} res={this.state.res} lan={this.switchLanguage} />} />
                    <Route path="/reset/:id" component={(props) => <Reset {...props} res={this.state.res} lan={this.switchLanguage} />} />
                    <Route path="/reset/" component={(props) => <Reset {...props} res={this.state.res} lan={this.switchLanguage} />} />
                </Switch>
            )
        } catch (e) {
            this.setState({ link: '/login' });
        }
    }
}

export default AppLayout;
