import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import Button from '@material-ui/core/Button';
import saveAs from 'file-saver';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  
  getDate = (data) => {
    let date = data ? data : new Date();
    let ret = date.getFullYear() + '-';
    ret += (date.getMonth() + 1).toString().padStart(2, '0') + '-';
    ret += date.getDate().toString().padStart(2, '0');
    //ret += date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
    return ret;
  }

  state = {
    limit_orc_ha: 0,
    limit_apr_ha: 0,
    limit_play_ha: 0,
    ini: this.getDate(),
    fim: this.getDate()
  };

  componentDidMount() {
    this.loadLimit();
  };

  loadLimit() {
    const apiUrl = Config.server + '/company_config';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            let temp = { limit_orc_ha: 0, limit_apr_ha: 0, limit_play_ha: 0 };

            temp.limit_orc_ha = result[0].limit_orc_ha ? result[0].limit_orc_ha : 0;
            temp.limit_apr_ha = result[0].limit_apr_ha ? result[0].limit_apr_ha : 0;
            temp.limit_play_ha = result[0].limit_play_ha ? result[0].limit_play_ha : 0;
            this.setState({
              limit_orc_ha: temp.limit_orc_ha,
              limit_apr_ha: temp.limit_apr_ha,
              limit_play_ha: temp.limit_play_ha,
            });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: "PERMISSAO",
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: "ERRO",
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  downloadRel = () => {
    let apiUrl = Config.server + '/relextrato';
    let method = 'POST';

    let ini = new Date(this.state.ini);
    ini.setDate(ini.getDate() + 1);
    let fim = new Date(this.state.fim)
    fim.setDate(fim.getDate() + 1);
    const formData = new URLSearchParams();
    formData.append('ini', ini.getTime());
    formData.append('fim', fim.getTime());

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.blob();
      })
      .then(
        (result) => {
          let file_name = "EXT" + Date.now() + '.pdf';
          if (result.type === 'application/pdf' && result.size > 0) {
              saveAs(result, file_name);
          } else {
              notification.open({
                  message: this.props.res.ERRO,
                  description: this.props.res.ERRO_MSG,
                  icon: <MaterialIcon icon="error" className="text-danger" />
              });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }


  render() {
    const { res } = this.props;

    return (
      <div>
        <div className="containerTop">
          <div><h1>{res.EXTRATO}</h1></div>
          <div className="centerTop"></div>
          <div>
          </div>
        </div>
        <div className="form-group notTooBig">
          <div style={{ textAlign: 'left', paddingLeft: '1rem' }}>
            <TextField
              id="limit_orc_ha"
              label={res.LIMITE_DE_ORCAMENTO_RESTANTE}
              value={this.state.limit_orc_ha}
              onChange={this.handleChange('limit_orc_ha')}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="limit_apr_ha"
              label={res.LIMITE_DE_AVALIACAO_RESTANTE}
              value={this.state.limit_apr_ha}
              onChange={this.handleChange('limit_apr_ha')}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="limit_play_ha"
              label={res.LIMITE_DE_VISUALIZACAO_RESTANTE}
              value={this.state.limit_play_ha}
              onChange={this.handleChange('limit_play_ha')}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              id="date"
              type="date"
              label={res.DE}
              value={this.state.ini}
              onChange={this.handleChange('ini')}
              margin="normal"
            />
            <TextField
              id="date"
              type="date"
              label={res.ATE}
              style={{ marginLeft: '1rem' }}
              value={this.state.fim}
              onChange={this.handleChange('fim')}
              margin="normal"
            />
            <Button variant="contained" color="primary" style={{ width: '5rem', marginTop: '1.7rem', marginLeft: '1rem' }} onClick={this.downloadRel} >
              {" Relatório "}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

