import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import tools from '../../components/util/tools';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import MaskedInput from 'react-text-mask';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {

  cid = this.props.id;

  state = {
    name: '',
    nick_name: '',
    registrationNumber: '',
    fone: '',
    email: '',
    contatc_name: '',
    cep: '',
    uf: '',
    localidade: '',
    bairro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    obs: '',
    image: '',
    imagehaschanged: false,
    tipo_pessoa: 'J',
    cnpj: '',
    cpf: '',
    id: this.cid,
    limit_orc_ha: 0,
    limit_apr_ha: 0,
    limit_play_ha: 0,
    limit_als_ha: 0,
    limit_json_ha: 0,
    limit_kmz_ha: 0,
    limit_clima_api: 0,
    storage_size: 0,
    limit: 0,
    openTask: false,
    type: '',
    itensTask: []
  };


  componentDidMount() {
    if (this.state.id) {
      const apiUrl = Config.server + '/company/' + this.state.id;//TODO: arrumar!!!

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              if (!result[0].cnpj || result[0].cnpj.length > 16) {
                this.setState({ cnpj: result[0].cnpj, tipo_pessoa: 'J' });
              } else {
                this.setState({ cpf: result[0].cnpj, tipo_pessoa: 'F' });
              }
              this.setState({
                name: result[0].name,
                nick_name: result[0].nick_name,
                registrationNumber: result[0].registrationNumber,
                fone: result[0].fone,
                email: result[0].email,
                contatc_name: result[0].contatc_name,
                cep: result[0].cep || '',
                uf: result[0].uf || '',
                localidade: result[0].localidade || '',
                bairro: result[0].bairro || '',
                logradouro: result[0].logradouro || '',
                numero: result[0].numero || '',
                complemento: result[0].complemento || '',
                obs: result[0].obs || '',
                image: result[0].image
              });
              this.loadLimit();
            }
          },
          (error) => {
            if (error.status === 405) {
              notification.open({
                message: "PERMISSAO",
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (error.status === 401 || error.status === 403) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: "ERRO",
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  loadImage = file => {
    if (file.target.files.length > 0) {
      let filePath = file.target.files[0];

      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        this.setState({
          image: fileReader.result,
          imagehaschanged: true
        });
      };

      fileReader.readAsDataURL(filePath);
    }
  };

  loadLimit() {
    if (this.state.id) {
      const apiUrl = Config.server + '/company_config_id/' + this.state.id;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              let temp = {
                limit_orc_ha: result[0].limit_orc_ha ? result[0].limit_orc_ha : 0,
                limit_apr_ha: result[0].limit_apr_ha ? result[0].limit_apr_ha : 0,
                limit_play_ha: result[0].limit_play_ha ? result[0].limit_play_ha : 0,
                limit_json_ha: result[0].limit_json_ha ? result[0].limit_json_ha : 0,
                limit_kmz_ha: result[0].limit_kmz_ha ? result[0].limit_kmz_ha : 0,
                limit_als_ha: result[0].limit_als_ha ? result[0].limit_als_ha : 0,
                limit_clima_api: result[0].limit_clima_api ? result[0].limit_clima_api : 0,
                storage_size: result[0].storage_size ? result[0].storage_size / 1024 / 1024 : 0,
              };

              this.setState(temp);
            }
          },
          (error) => {
            if (error.status === 405) {
              notification.open({
                message: "PERMISSAO",
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            } else if (error.status === 401 || error.status === 403) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: "ERRO",
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCEP = event => {
    const limit = 8;
    const cep = event.target.value.slice(0, limit);

    this.setState({
      cep: cep,
    });

    if (cep.length < 8) {
      return;
    }

    const apiUrl = Config.server + '/cep';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'cep': cep
      }
    }

    fetch(apiUrl, options)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            uf: result.uf || '',
            localidade: result.localidade || '',
            bairro: result.bairro || '',
            logradouro: result.logradouro || ''
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      )

  };

  handleSubmit = (event) => {
    event.preventDefault();
    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/company/';//TODO: arrumar!!!

    const formData = new URLSearchParams();
    if (this.state.id) {
      apiUrl += this.state.id;
      formData.append('id', this.state.id);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    if (this.state.tipo_pessoa === 'J') {
      formData.append('cnpj', this.state.cnpj);
    } else {
      formData.append('cnpj', this.state.cpf);
    }
    formData.append('name', this.state.name);
    formData.append('nick_name', this.state.nick_name);
    formData.append('registrationNumber', this.state.registrationNumber);
    formData.append('fone', this.state.fone);
    formData.append('email', this.state.email);
    formData.append('contatc_name', this.state.contatc_name);
    formData.append('cep', this.state.cep);
    formData.append('uf', this.state.uf);
    formData.append('localidade', this.state.localidade);
    formData.append('bairro', this.state.bairro);
    formData.append('logradouro', this.state.logradouro);
    formData.append('numero', this.state.numero);
    formData.append('complemento', this.state.complemento);
    formData.append('obs', this.state.obs);

    if (this.state.imagehaschanged) {
      formData.append('image', this.state.image);
    }

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result != null && result.errno) {
            console.log("Error: ", result);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });

            return;
          }
          let msg = 'O item foi incluido';
          if (this.state.id) {
            msg = 'O item foi alterado';
          }
          notification.open({
            message: 'Successo',
            description: msg,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
          if (!this.state.id) {
            this.setState({
              cnpj: '',
              cpf: '',
              tipo_pessoa: 'J',
              name: '',
              nick_name: '',
              registrationNumber: '',
              fone: '',
              email: '',
              contatc_name: '',
              adress: '',
              cep: '',
            });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  addLimit = () => {
    let apiUrl = Config.server + '/company_limit';
    let method = 'POST';

    const formData = new URLSearchParams();

    formData.append('id', this.state.id);
    formData.append('type', this.state.type);
    let value = isNaN(this.state.limit) ? 0 : Number(this.state.limit);
    formData.append('value', value);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          console.log('addLimit', result);

          this.listLimit();
          let temp = {
            limit_orc_ha: result.limit_orc_ha ? result.limit_orc_ha : 0,
            limit_apr_ha: result.limit_apr_ha ? result.limit_apr_ha : 0,
            limit_als_ha: result.limit_als_ha ? result.limit_als_ha : 0,
            limit_play_ha: result.limit_play_ha ? result.limit_play_ha : 0,
            limit_json_ha: result.limit_json_ha ? result.limit_json_ha : 0,
            limit_kmz_ha: result.limit_kmz_ha ? result.limit_kmz_ha : 0,
            limit_clima_api: result.limit_clima_api ? result.limit_clima_api : 0,
            limit: '0',
          };
          this.setState(temp);
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  listLimit(openTask, type) {
    this.setState({
      openTask,
      type
    });

    const apiUrl = Config.server + '/company_limit_id/' + this.state.id + '/' + type;//TODO: arrumar!!!

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            itensTask: result,
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: "Permissão Negada",
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else {
            console.log(error);
            notification.open({
              message: "Erro Inesperado",
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  clickItem = (valor) => {
    this.setState({ limit: valor });
  };

  maskedCPFCNPJ = (props) => {
    const { inputRef, ...other } = props;
    if (this.state.tipo_pessoa === "F") {
      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
        />
      );
    } else {
      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
        />
      );
    }
  };

  setCrop = (e) => {

  };

  render() {
    const { classes, link, res } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <Dialog onClose={event => this.setState({ openTask: false })} aria-labelledby="simple-dialog-title" open={this.state.openTask}>
          <DialogTitle id="simple-dialog-title">Limites</DialogTitle>
          <div style={{ padding: '1rem', width: '25rem' }}>
            <div>
              <TextField
                id="limit"
                label="Limite adicional (ha)"
                value={this.state.limit}
                onChange={this.handleChange('limit')}
                type="number"
                className="mb-4 notTooClose"
                size="small"
                margin="dense"
                style={{ maxWidth: '10rem', width: '100%' }}
                onKeyDown={e => {
                  if (e.keyCode === 13) { this.addLimit() }
                }}
              />
              <Button type="submit" variant="contained" color="primary" className="popUpButton" style={{ marginTop: '1rem', marginLeft: '.5rem' }} onClick={this.addLimit}>
                Adicionar
              </Button>
            </div>
            <List>
              {this.state.itensTask.map((task, key) => (
                <ListItem button onClick={(event) => { this.clickItem(task.value) }}>
                  <div style={{ marginRight: 'auto', width: '6rem' }}>{task.value > 0 ? '+' + task.value : task.value}</div><div style={{ width: '8rem' }}>{tools.formatDateTime(new Date(task.created))}</div><div style={{ marginLeft: '1rem', width: '10rem' }}>{task.name}</div>
                </ListItem>
              ))}
            </List>
          </div>
        </Dialog>
        <div className="containerTop">
          <div><h1>Empresa</h1></div>
          <div className="centerTop"></div>
          <div>
            <Button variant="contained" onClick={evemt => { link('back') }} className="popUpButton">
              Cancelar
            </Button> &nbsp;
            <Button type="submit" variant="contained" color="primary" className="popUpButton">
              <SaveIcon className="iconMapS" />
              Salvar
            </Button>
          </div>
        </div>
        <div className="form-group notTooBig">
          <FormControl
            style={{ padding: ".4rem 0 .4rem 0" }}
            className={classes.textField}
            fullWidth
            component="fieldset">
            <div className='titleTextRel'>{res.PESSOA}</div>
            <RadioGroup row id="tipo_pessoa" aria-label="pessoa" name="pessoa1" value={this.state.tipo_pessoa} onChange={this.handleChange('tipo_pessoa')}>
              <FormControlLabel value="F" control={<Radio color="primary" />} label={res.FISICA} />
              <FormControlLabel value="J" control={<Radio color="primary" />} label={res.JURIDICA} />
            </RadioGroup>
          </FormControl>
          <FormControl
            fullWidth
            className={classes.textField}>
            <InputLabel htmlFor="formatted-text-mask-input">{res.CPFCNPJ}</InputLabel>
            <Input
              value={(this.state.tipo_pessoa === 'F' ? this.state.cpf : this.state.cnpj) || ' '}
              onChange={this.handleChange((this.state.tipo_pessoa === 'F' ? 'cpf' : 'cnpj'))}
              onFocus={(event) => { event.target.select() }}
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={this.maskedCPFCNPJ}
            />
          </FormControl>
          <TextField
            required
            id="name"
            label="Razão social"
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange('name')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="nick_name"
            label="Nome fantasia"
            className={classes.textField}
            value={this.state.nick_name}
            onChange={this.handleChange('nick_name')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <FormControl
            className={classes.textField}
            fullWidth>

            <img src={this.state.image} width={200} height={200} alt="Logo" />
            <label htmlFor="contained-button-file" style={{ display: 'inline', textAlign: 'left', marginTop: '1rem', marginBottom: '1rem' }}>
              <input
                accept=".jpg, .bmp, .png"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={this.loadImage}
              />
              <label htmlFor="contained-button-file">

                <Button component="span" variant="contained" color="primary" className="popUpButton" title={this.props.res.IMPORTAR_KMZ}>
                  <MaterialIcon icon="attach_file" className="iconMap" />
                  Carregar Imagem
                </Button>
              </label>
            </label>
          </FormControl>
          <div className={classes.textField} style={{ textAlign: 'left' }}>
            <TextField
              id="limit_orc_ha"
              label="Limite de orçamento restante (ha)"
              value={this.state.limit_orc_ha}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <div style={{ display: 'inline-block', marginTop: '1rem', marginLeft: '0.5rem' }}>
              <Button className={this.state.edit_speed ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={(event) => { this.listLimit(true, 'O'); }} disabled={this.state.id ? '' : 'disabled'}>
                <MaterialIcon icon="settings" className="iconMap" />
              </Button>
            </div>
            <TextField
              id="limit_apr_ha"
              label="Limite de avaliação restante (ha)"
              value={this.state.limit_apr_ha}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <div style={{ display: 'inline-block', marginTop: '1rem', marginLeft: '0.5rem' }}>
              <Button className={this.state.edit_speed ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={(event) => { this.listLimit(true, 'A'); }} disabled={this.state.id ? '' : 'disabled'}>
                <MaterialIcon icon="settings" className="iconMap" />
              </Button>
            </div>
            <TextField
              id="limit_als_ha"
              label="Limite de qualidade restante (ha)"
              value={this.state.limit_als_ha}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <div style={{ display: 'inline-block', marginTop: '1rem', marginLeft: '0.5rem' }}>
              <Button className={this.state.edit_speed ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={(event) => { this.listLimit(true, 'Q'); }} disabled={this.state.id ? '' : 'disabled'}>
                <MaterialIcon icon="settings" className="iconMap" />
              </Button>
            </div>
            <TextField
              id="limit_play_ha"
              label="Limite de visualização restante (ha)"
              value={this.state.limit_play_ha}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <div style={{ display: 'inline-block', marginTop: '1rem', marginLeft: '0.5rem' }}>
              <Button className={this.state.edit_speed ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={(event) => { this.listLimit(true, 'P'); }} disabled={this.state.id ? '' : 'disabled'}>
                <MaterialIcon icon="settings" className="iconMap" />
              </Button>
            </div>
            <TextField
              id="limit_json_ha"
              label="Limite de traducao restante (ha)"
              value={this.state.limit_json_ha}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <div style={{ display: 'inline-block', marginTop: '1rem', marginLeft: '0.5rem' }}>
              <Button className={this.state.edit_speed ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={(event) => { this.listLimit(true, 'J'); }} disabled={this.state.id ? '' : 'disabled'}>
                <MaterialIcon icon="settings" className="iconMap" />
              </Button>
            </div>
            <TextField
              id="limit_kmz_ha"
              label="Limite de KMZ restante (ha)"
              value={this.state.limit_kmz_ha}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <div style={{ display: 'inline-block', marginTop: '1rem', marginLeft: '0.5rem' }}>
              <Button className={this.state.edit_speed ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={(event) => { this.listLimit(true, 'K'); }} disabled={this.state.id ? '' : 'disabled'}>
                <MaterialIcon icon="settings" className="iconMap" />
              </Button>
            </div>
            <TextField
              id="limit_clima_api"
              label="Limite diario de chamada de API/Clima"
              value={this.state.limit_clima_api}
              type="number"
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
            <div style={{ display: 'inline-block', marginTop: '1rem', marginLeft: '0.5rem' }}>
              <Button className={this.state.edit_speed ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={(event) => { this.listLimit(true, 'C'); }} disabled={this.state.id ? '' : 'disabled'}>
                <MaterialIcon icon="settings" className="iconMap" />
              </Button>
            </div>
            <TextField
              id="storage_size"
              label="Total em arquivos"
              value={`${this.state.storage_size.toFixed(1)} MB`}
              className="mb-4 notTooClose"
              size="small"
              margin="dense"
              style={{ maxWidth: '32rem', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
          <TextField
            id="fone"
            label="Telefone"
            className={classes.textField}
            value={this.state.fone}
            onChange={this.handleChange('fone')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="email"
            label="Email"
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange('email')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="registrationNumber"
            label="Registro M.A.P.A."
            className={classes.textField}
            value={this.state.registrationNumber}
            onChange={this.handleChange('registrationNumber')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="contatc_name"
            label="Nome do contato"
            className={classes.textField}
            value={this.state.contatc_name}
            onChange={this.handleChange('contatc_name')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="cep"
            label="CEP"
            type='number'
            className={classes.textField}
            value={this.state.cep}
            onChange={this.handleCEP}
            margin="normal"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="localidade"
            label="Localidade"
            className={classes.textField}
            value={this.state.localidade}
            onChange={this.handleChange('localidade')}
            margin="normal"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="bairro"
            label="Bairro"
            className={classes.textField}
            value={this.state.bairro}
            onChange={this.handleChange('bairro')}
            margin="normal"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="logradouro"
            label="Logradouro"
            className={classes.textField}
            value={this.state.logradouro}
            onChange={this.handleChange('logradouro')}
            margin="normal"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="numero"
            label="Número"
            className={classes.textField}
            value={this.state.numero}
            onChange={this.handleChange('numero')}
            margin="normal"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="complemento"
            label="Complemento"
            className={classes.textField}
            value={this.state.complemento}
            onChange={this.handleChange('complemento')}
            margin="normal"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            multiline
            fullWidth
            rows={4}
            id="obs"
            label="Observações"
            className={classes.textField}
            value={this.state.obs}
            onChange={this.handleChange('obs')}
            margin="normal"
          />
        </div>
      </form>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

