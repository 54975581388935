import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';

import Tools from '../../components/util/tools';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    cpfError: false,
    id: this.props.id,
    name: '',
    cpf: '',
    email: '',
    canac: '',
    birthdate: null,
    mnte_validate: null,
    paga_validate: null,
    cma_validate: null,
    guid: null,
  };

  componentDidMount() {
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };


  handleSubmit = (event) => {
    event.preventDefault();

    let apiUrl = '';
    let method = 'POST';
    apiUrl = Config.server + '/alert';

    const formData = new URLSearchParams();
    formData.append('title', this.state.title);
    //formData.append('text', this.state.text);
    //formData.append('callback', this.state.callback);    
    formData.append('expires_days', this.state.expires_days);
    formData.append('type', this.state.type);
    formData.append('origin', this.state.origin);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          let msg = this.props.res.ITEM_INCLUIR;
          notification.open({
            message: this.props.res.SUCESSO,
            description: msg,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });

          this.setState({
            title: '',
            //text:'',
            //callback:'',    
            expires_days: 0,
            type: '',
            origin: '',
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  render() {
    const { link, res } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="containerTop">
          <div><h1>{"Alerta"}</h1></div>
          <div className="centerTop"></div>
          <div>
            <Button type="submit" variant="contained" color="primary" className="popUpButton">
              <SaveIcon className="iconMapS" />
              {res.SALVAR}
            </Button>
          </div>
        </div>
        <div className="form-group notTooBig">
          {/*title: '',
            //text:'',
            //callback:'',    
            expires_days: 0,
            type: '',
            origin: '',*/}
          <TextField
            id="name"
            label={res.TITULO}
            value={this.state.title}
            required={true}
            onChange={this.handleChange('title')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />

         
        </div>
      </form>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

