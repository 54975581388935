import React from 'react';

export default (props) => {
    const { style, onClick, open } = props;

    let s = {
        zIndex: style.zIndex,
        display: open ? 'block' : 'none'
    };

    return (
        <div className="back-drop" style={s} onClick={onClick}>
            <div className="back-drop" style={{ backgroundColor: style.color?style.color:'#fff', opacity: '40%' }} ></div>
            <div className="in-back-drop" >
                {props.children}
            </div>
        </div>
    )
}
