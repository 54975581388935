class Tools {
    teste = () => {
        return 'Hello!';
    }

    //ajusta a ordem de acordo com o tipo
    orderPath = (linhas, line, type, lado, options) => {
        let newLines = [];
        if (type === 'BACKTOBACK') {
            newLines = linhas;

        } else if (type === 'SKIPBACKTOBACK') {
            let odd = linhas.filter((item) => { return (item.key % 2 === 1 || item.key % 2 === -1) });
            let even = linhas.filter((item) => item.key % 2 === 0);
            if (line % 2 === 0) {
                newLines = odd.concat(even);
            } else {
                newLines = even.concat(odd);
            }

        } else if (type === 'QUICKRACETRACK') {
            let half = Math.ceil(linhas.length / 2);
            for (let i = 0; i < half; i++) {
                newLines.push(linhas[i]);
                if (linhas.length > (half + i)) {
                    newLines.push(linhas[half + i]);
                }
            }
        } else if (type === 'RACETRACK') {
            if (options) {
                if ((options.size_loop * 2) < (options.qtd_line * linhas.length)) {
                    let fMid = Math.ceil(options.size_loop / options.size_line);
                    let part = parseInt(linhas.length / (fMid * 2));

                    let ret = [];
                    let final = 0;
                    for (let i = 1; i <= part; i++) {
                        let fim = (i * (fMid * 2)) - 1;
                        let ini = fim + 1 - (fMid * 2);
                        final = fim;

                        let temp_lines = linhas.slice(ini, fim + 1);
                        ret = ret.concat(this.orderPath(temp_lines, line, (ret.length > 0 ? 'REVERSERACETRACK' : 'QUICKRACETRACK'), lado));
                    }
                    let rest = linhas.slice(final + 1, linhas.length);
                    newLines = ret.concat(this.orderPath(rest, line, 'REVERSERACETRACK', lado));
                } else {
                    newLines = this.orderPath(linhas, line, 'QUICKRACETRACK', lado);
                }
            } else {
                newLines = this.orderPath(linhas, line, 'QUICKRACETRACK', lado);
            }
        } else if (type === 'REVERSERACETRACK') {
            let half = Math.ceil(linhas.length / 2);
            let start = parseInt(linhas.length / 2);
            for (let i = 0; i < half; i++) {
                newLines.push(linhas[start + i]);
                if (i < start) {
                    newLines.push(linhas[i]);
                }
            }
        } else if (type === 'EXPAND') {
            let size = linhas.length;
            let half = Math.ceil(linhas.length / 2);
            for (let i = 0; i < half; i++) {
                if (line % 2 === 0) {
                    newLines.push(linhas[(size - half) + i]);
                }
                if (size - i > half) {
                    newLines.push(linhas[(size - half - 1) - i]);
                }
                if (line % 2 !== 0) {
                    newLines.push(linhas[(size - half) + i]);
                }
            }
        } else if (type === 'SQUEEZE') {
            let size = linhas.length;
            let half = Math.ceil(linhas.length / 2);
            for (let i = 0; i < half; i++) {
                newLines.push(linhas[i]);
                if (size - i > half) {
                    newLines.push(linhas[linhas.length - 1 - i]);
                }
            }
        }

        return newLines;
    }

}

const tools = new Tools();

export default tools;
