import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';


import { useParams } from "react-router";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    primary: {
        backgroundColor: '#3f51b5',
        color: 'white',
    },
    secondary: {
        backgroundColor: '#e0e0e0',
    },
});

class assessmentTests extends React.Component {
    state = {
        id: this.props.id,
    };

    componentDidMount() { };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    };

    render() {
        const { link, res, classes } = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="containerTop">
                    <div><h1>{res.AVALIACAO_TESTES}</h1></div>
                    <div className="centerTop"></div>
                    <div>
                        <Button variant="contained" onClick={() => { link('') }} className="popUpButton">
                            {res.CANCELAR}
                        </Button> &nbsp;
                        <Button type="submit" variant="contained" color="primary" className="popUpButton">
                            <SaveIcon className="iconMapS" />
                            {res.SALVAR}
                        </Button>
                    </div>
                </div>
                <div className="form-group notTooBig">
                    <h1> body</h1>
                </div>
            </form>
        );
    }
}

assessmentTests.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(assessmentTests);

const Box = (props) => {
    const { link, res } = props;
    let { id } = useParams();
    return (
        <div className="rowForm">
            <div className="divContent">
                <TextFields1 link={link} id={id} res={res} />
            </div>
        </div>)
}

export default Box;

