import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Config from '../../../constants/appConfig';
import MaterialIcon from '../../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    cpfError: false,
    id: this.props.id,
    name: '',
    cpf: '',
    crea: '',
    email: '',
  };

  componentDidMount() {
    if (this.state.id) {
      const apiUrl = Config.server + '/agronomist/' + this.state.id;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              this.setState({
                name: result[0].name,
                cpf: result[0].cpf,
                crea: result[0].crea,
                email: result[0].email
              });
            }
          },
          (error) => {
            if (error.status === 401) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  maskedCPF = (props) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAircraftBrandChange = event => {
    this.setState({
      id_aircraftbrand: event.target.value,
      id_aircraftmodel: ''
    });
    if (event.target.value) {
      this.loadAircraftModels(event.target.value);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.cpfError || isNaN(this.state.cpf.replace('.', '').replace('-', '')) || !this.state.cpf) {
      notification.open({
        message: 'Campo CPF com valor invalido',
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      this.setState({ cpfError: true });
      return;
    }

    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/agronomist/';

    const formData = new URLSearchParams();
    if (this.state.id) {
      apiUrl += this.state.id;
      formData.append('agronomist_id', this.state.id);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    formData.append('agronomist_cpf', this.state.cpf);
    formData.append('agronomist_email', this.state.email);
    formData.append('agronomist_name', this.state.name);
    formData.append('agronomist_crea', this.state.crea);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result && result.error) {
            notification.open({
              message: this.props.res[result.message],
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else {
            let msg = this.props.res.ITEM_INCLUIR;
            if (this.state.id) {
              msg = this.props.res.ITEM_ALTERADO;
            }
            notification.open({
              message: this.props.res.SUCESSO,
              description: msg,
              icon: <MaterialIcon icon="check_circle" className="text-success" />
            });

            if (!this.state.id) {
              this.setState({
                name: '',
                cpf: '',
                crea: '',
                email: ''
              });
            }
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  render() {
    const { link, res } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="containerTop">
          <div><h1>{res.AGRONOMO_}</h1></div>
          <div className="centerTop"></div>
          <div>
            <Button variant="contained" onClick={evemt => { link('/app/cadastro/agronomo/list') }} className="popUpButton">
              {res.CANCELAR}
            </Button> &nbsp;
            <Button type="submit" variant="contained" color="primary" className="popUpButton">
              <SaveIcon className="iconMapS" />
              {res.SALVAR}
            </Button>
          </div>
        </div>
        <div className="form-group notTooBig">
          <TextField
            id="name"
            label={res.NOME}
            required={true}
            value={this.state.name}
            onChange={this.handleChange('name')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <FormControl
            fullWidth>
            <InputLabel htmlFor="formatted-text-mask-input">{res.CPF + '*'}</InputLabel>
            <Input
              value={this.state.cpf || ' '}
              error={this.state.cpfError}
              onChange={(ev) => {
                this.setState({ cpfError: isNaN(this.state.cpf.replace('.', '').replace('-', '')) })
                this.handleChange('cpf')(ev);
              }}
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={this.maskedCPF}
            />
          </FormControl>
          <TextField
            id="email"
            label={res.EMAIL}
            required={true}
            value={this.state.email}
            onChange={this.handleChange('email')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="crea"
            label={res.CREA}
            required={true}
            value={this.state.crea}
            onChange={this.handleChange('crea')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
        </div>
      </form>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;

