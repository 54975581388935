import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

class DiarySummary extends React.Component {

  constructor(props) {
    super();
    this.props = props;
  };

  state = {
    pendingJobs: 0,
    doneJobs: 0,
    estimatedHectares: 0,
    percentDone: 0
  };

  componentDidMount() {
    this.update();
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.update();
    }
  };

  update = () => {
    let pending = 0;
    let done = 0;
    let hplanned = 0.0;

    this.props.jobs.forEach(job => {
      if (job.list_exe && job.list_exe.length > 0) {
        const { area_spray, area_polygon } = job.list_exe[0];

        if (area_polygon > 0) {
          if (area_spray >= area_polygon) {
            done++;
          } else if (area_spray < area_polygon) {
            pending++;
          }

          hplanned += area_polygon;
        }
      }
    });

    const totalJobs = pending + done;
    const percentDone = totalJobs > 0 ? (done / totalJobs) * 100 : 0;

    this.setState({
      pendingJobs: pending,
      doneJobs: done,
      estimatedHectares: hplanned,
      percentDone: percentDone
    });
  };

  render() {
    const { res } = this.props;

    return (
      <div className='dashboard-card'>
        <div className='divContent'
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            borderRadius: '.8rem',
            backgroundColor: '#343a40',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            position: 'absolute'
          }}
        >
          <div className='cardheader'>
            <SvgIcon viewBox="0 0 448 512" style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }}>
              <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z" />
            </SvgIcon>
            <span className='title'>
              {res.PLANEJADOS_HOJE}
            </span>
          </div>
          <div className='cardmiddle'>
            <span className='cardmiddle-legend'>
              <span style={{ display: 'inline-table', width: '50%', textAlign: 'right', paddingRight: '.5rem' }}>
                {res.PENDENTES}
              </span>
              <span style={{ display: 'inline-table', width: '50%', textAlign: 'left', paddingLeft: '.5rem' }}>
                {res.EXECUTADOS}
              </span>
            </span>
            <h2 className='cardmiddle-value'>{this.state.pendingJobs} / {this.state.doneJobs}</h2>
          </div>
          <div className='cardfooter'>
            <span className='cardfooter-content'>
              <span style={{ display: 'inline-table', paddingLeft: '1rem', width: '50%' }}>
                <span style={{ display: 'table-row', width: '100%', color: '#fa7d34' }}>
                  {res.HECTARES_PLANEJADOS}
                </span>
                <span style={{ display: 'table-row', width: '100%', fontSize: '1rem', fontWeight: 'bold' }}>
                  {this.state.estimatedHectares}ha
                </span>
              </span>
              <span style={{ display: 'inline-table', paddingRight: '1rem', width: '50%' }}>
                <span style={{ display: 'table-row', width: '100%', color: '#fa7d34' }}>
                  {res.INDICE_EXECUTADOS}
                </span>
                <span style={{ display: 'table-row', width: '100%', fontSize: '1rem', fontWeight: 'bold' }}>
                  <span style={{ display: 'inline-block' }}>
                    <span style={{ display: 'block', float: 'left', marginRight: '.2rem' }}>{this.state.percentDone.toFixed(0)}%</span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 20 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 40 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 60 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 80 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                    <span style={{ display: 'block', float: 'left', width: '.5rem', marginRight: '.2rem', marginTop: '.2rem', height: '1rem', backgroundColor: this.state.percentDone >= 100 ? '#fa7d34' : '#FFF', border: '.1rem solid #FFF' }}></span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export default DiarySummary;