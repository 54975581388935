import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';

import Config from '../../../constants/appConfig';
import MaterialIcon from '../../../components/MaterialIcon';
import { notification } from 'antd';

import { useParams } from "react-router";

import MaskedInput from 'react-text-mask';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    id: this.props.id,
    id_client: this.props.idClient,
    name: '',
    cep: '',
    uf: '',
    localidade: '',
    bairro: '',
    logradouro: '',
    numero: '',
    designativo: 'SSQZ',
    obs: ''
  };

  componentDidMount() {
    if (this.state.id) {
      const apiUrl = Config.server + '/property/' + this.state.id;

      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      }

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then(
          (result) => {
            if (result.length > 0) {
              this.setState({
                name: result[0].name,
                cep: result[0].cep || '',
                uf: result[0].uf || '',
                localidade: result[0].localidade || '',
                bairro: result[0].bairro || '',
                logradouro: result[0].logradouro || '',
                numero: result[0].numero || '',
                designativo: result[0].designativo || '',
                obs: result[0].obs || ''
              });
            }
          },
          (error) => {
            if (error.status === 401) {
              this.props.link('/login');
            }
            else {
              console.log(error);
              notification.open({
                message: this.props.res.ERRO,
                description: this.props.res.ERRO_MSG,
                icon: <MaterialIcon icon="error" className="text-danger" />
              });
            }
          }
        );
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  maskedCPFCNPJ = (props) => {
    const { inputRef, ...other } = props;
  
    if(this.state.tipo_pessoa === "F"){
      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
        />
      );
    } else {
      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
        />
      );
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAircraftBrandChange =  event => {
    this.setState({
      id_aircraftbrand: event.target.value,
      id_aircraftmodel: ''
    });
    if(event.target.value){
      this.loadAircraftModels(event.target.value);
    }
  };

  handleCEP = event => {
    const limit = 8;
    const cep = event.target.value.slice(0, limit);

    this.setState({
      cep: cep,
    });

    if(cep.length < 8){
      return;
    }

    const apiUrl = Config.server + '/cep';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'cep': cep
      }
    }

    fetch(apiUrl, options)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            uf: result.uf || '',
            localidade: result.localidade || '',
            bairro: result.bairro || '',
            logradouro: result.logradouro || ''
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      )

  };

  handleSubmit = (event) => {
    event.preventDefault();
    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/property';

    const formData = new URLSearchParams();
    if (this.state.id) {
      apiUrl += '/' + this.state.id;
      formData.append('id', this.state.id);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    formData.append('id_client', this.state.id_client);
    formData.append('name', this.state.name);
    formData.append('cep', this.state.cep);
    formData.append('uf', this.state.uf);
    formData.append('localidade', this.state.localidade);
    formData.append('bairro', this.state.bairro);
    formData.append('logradouro', this.state.logradouro);
    formData.append('numero', this.state.numero);
    formData.append('designativo', this.state.designativo);
    formData.append('obs', this.state.obs);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if(result && result.error){
            notification.open({
              message: this.props.res[result.message],
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }else{
            let msg = this.props.res.ITEM_INCLUIR;
            if (this.state.id) {
              msg = this.props.res.ITEM_ALTERADO;
            }
            notification.open({
              message: this.props.res.SUCESSO,
              description: msg,
              icon: <MaterialIcon icon="check_circle" className="text-success" />
            });
            
            if (!this.state.id) {
              this.setState({
                name: '',
                cep: '',
                logradouro: '',
                numero: '',
                bairro: '',
                localidade: '',
                uf: '',
                designativo: 'SSQZ',
                obs: ''
              });
            }
          }

          if(this.props.onSave){
            this.props.onSave();
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  onCloseEditProperty = () => {
    this.setState({showEditProperty: false});
  };

  deletePRegs = (id) => {
    const { selected } = this.state;
    const apiUrl = Config.server + '/property';
    const formData = new URLSearchParams();
    let descr = '';

    if (isNaN(id)) {
      formData.append('ids', selected);
      descr = this.props.res.ITENS_EXCLUIDOS;
    }
    else {
      formData.append('ids', id);
      descr = this.props.res.ITEM_EXCLUIDO;
    }

    const options = {
      method: 'DELETE',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ selected: [] });

          notification.open({
            message: this.props.res.SUCESSO,
            description: descr,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });

          this.updateList();
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  render() {
    const { classes, link, res, modal } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="containerTop">
          <div><h1>{res.PROPRIEDADE}</h1></div>
          <div className="centerTop"></div>
          <div>
            {!modal && <Button variant="contained" onClick={event => { link('/app/cadastro/cliente/list') }} className="popUpButton">
              {res.CANCELAR}
            </Button>} &nbsp;
            <Button type="submit" variant="contained" color="primary" className="popUpButton">
              <SaveIcon className="iconMapS" />
              {res.SALVAR}
            </Button>
          </div>
        </div>
        <div className="form-group notTooBig">
          <TextField
            id="name"
            className={classes.textField}
            label={res.NOME}
            value={this.state.name}
            onChange={this.handleChange('name')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <div style={{width: "100%", display: "grid", gridTemplateColumns: "auto auto", columnGap: "1rem" }}>
            <TextField
              id="cep"
              label={res.CEP}
              type='number'
              className={classes.textField}
              value={this.state.cep}
              onChange={this.handleCEP}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="localidade"
              label={res.LOCALIDADE}
              className={classes.textField}
              value={this.state.localidade}
              onChange={this.handleChange('localidade')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="bairro"
              label={res.BAIRRO}
              className={classes.textField}
              value={this.state.bairro}
              onChange={this.handleChange('bairro')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="logradouro"
              label={res.LOGRADOURO}
              className={classes.textField}
              value={this.state.logradouro}
              onChange={this.handleChange('logradouro')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="numero"
              label={res.NUMERO}
              className={classes.textField}
              value={this.state.numero}
              onChange={this.handleChange('numero')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
            <TextField
              id="complemento"
              label={res.COMPLEMENTO}
              className={classes.textField}
              value={this.state.complemento}
              onChange={this.handleChange('complemento')}
              margin="normal"
              fullWidth
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 45)
              }}
            />
          </div>
          <FormControl
            style={{padding: ".4rem 0 .4rem 0"}}
            className={classes.textField}
            fullWidth
            component="fieldset">
            <div className='titleTextRel'>{res.DESIGNATIVO}</div>
            <RadioGroup row id="designativo" aria-label="designativo" name="designativo" value={this.state.designativo} onChange={this.handleChange('designativo')}>
              <FormControlLabel value="SSQZ" control={<Radio color="primary" />} label="SSQZ" />
              <FormControlLabel value="ZZZZ" control={<Radio color="primary" />} label="ZZZZ" />
            </RadioGroup>
          </FormControl>
          <TextField
            multiline
            fullWidth
            rows={4}
            id="obs"
            label="Observações"
            className={classes.textField}
            value={this.state.obs}
            onChange={this.handleChange('obs')}
            margin="normal"
          />
        </div>
      </form>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res, modal, idProperty, idClient, onSave } = props;
  let { id } = useParams();
  let vid = (!modal) ? id : idProperty || undefined;
  return (
    <div className={(!modal) ? "rowForm" : ""}>
      <div className="divContent">
        <TextFields1 link={link} id={vid} res={res} idClient={idClient} modal={modal} onSave={onSave} />
      </div>
    </div>)
}

export default Box;

