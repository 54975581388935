import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { notification } from 'antd';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SvgIcon from '@material-ui/core/SvgIcon';

import * as turf from '@turf/turf';
//import SvgIcon from '@material-ui/core/SvgIcon';
import L from 'leaflet';
import 'leaflet-draw';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import tools from '../../components/util/tools';
//import Geo from '../../components/util/geo';
//import Img_mark from '../../components/image/img';
import Img_mark from './../../assets/images/aircraft.png';
import Marks from './../../components/image/img';

import { useParams } from "react-router";

import Backd from '../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Calls from '../../components/util/calls';
import Slider from '@material-ui/core/Slider';

import uMarker from './update_marker';
import img from './../point/img';

class marks {
  constructor() {
    this.id = '';
    this.guid = '';
    this.name = '';
    this.icon = '';
    this.alt = '';
    this.lat = '';
    this.lng = '';
    this.r = '';
    this.type = '';
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    tiro: 0,
    sTime: 1,

    markers: [],

    stack: false,
    stack_value: [1, 1, 1],
    detail: [],
    progress: false,
    progressValue: 0,
    ruler_a: false,
    speed: 0,
    time: 0,

    litros: 0,
    area: 0,

    ativo: false,

    play: false,
    end: false,
    file: null,
    follow: false,

    total: 0,
    showProgressBar: true,
    valueBar: 0,
    newTimeline: 0,
    showDev: false,

    title: '...',
    task: localStorage.getItem('check-task') === 'null' ? null : localStorage.getItem('check-task')
  };
  marker = {
    marker: null,
    angle: 45
  };
  time = 0;
  size = 0;
  patterns = 0;
  cfg = 0;
  yaw = 0;
  poly = [];
  spray_pointer = [];
  polyLin = null;
  polySin = null;
  myStylein = {
    color: '#32a852',
    fillOpacity: .9,
    opacity: 0,
    className: 'aplicacao'
  };
  polyLout = null;
  polySout = null;
  myStyleout = {
    color: '#ffd500',
    fillOpacity: .9,
    opacity: 0,
    className: 'aplicacao'
  };
  polyLmiss = null;
  polySmiss = null;
  myStylemiss = {
    color: '#ff4d00',
    fillOpacity: .9,
    opacity: 0,
    className: 'aplicacao'
  };
  polyS = null;
  lineL = null;
  line = null;
  iPlay = 1;
  exitIn = false;

  ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };

  featureGroupL = null;
  featureGroupP = null;
  featureGroupPoly = null;
  featureGroupPM = null;

  componentDidMount() {
    this.boot();
    uMarker(L);

  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  boot = () => {
    this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);

    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

    this.featureGroupL = L.featureGroup();
    this.featureGroupL.addTo(this.mymap);
    this.featureGroupP = L.featureGroup();
    this.featureGroupP.addTo(this.mymap);
    this.featureGroupPoly = L.featureGroup();
    this.featureGroupPoly.addTo(this.mymap);

    this.featureGroupPM = L.featureGroup();
    this.featureGroupPM.addTo(this.mymap);

    new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: false
    }).addTo(this.mymap);

    this.mymap.on('draw:drawvertex', e => {
      if (this.drawCreateToolbar.type === 'polyline') {
        let layerIds = Object.keys(e.layers._layers);
        if (layerIds.length > 1) {
          let v2 = e.layers._layers[layerIds[1]];
          this.ruler_p.second.lat = v2._latlng.lat;
          this.ruler_p.second.lng = v2._latlng.lng;
          requestAnimationFrame(() => this.completeRuler());
        } else if (layerIds.length === 1) {
          let v1 = e.layers._layers[layerIds[0]];
          this.ruler_p.first.lat = v1._latlng.lat;
          this.ruler_p.first.lng = v1._latlng.lng;
        }
      }
    });

    L.drawLocal.draw.handlers.polyline.tooltip.start = '';
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';

    this.mymap.on('moveend', (e) => {
      this.checkMarkers();
    });

    this.mymap.on('zoomend', () => {
      this.checkMarkers();
    });

    if (this.props.guid) {
      this.checkJob();
    }
    this.list_markers();
  };

  //upload de arquivos zjob
  loadFile = file => {
    for (let index = 0; index < file.target.files.length; index++) {
      const filePath = file.target.files[index];
      setTimeout(() => {
        let ext = fileName => fileName.split(".").pop();
        if (ext(filePath.name.toLowerCase()) === 'zjob') {
          this.uploadFile(filePath);
        }
      }, 50);
    };
    document.getElementById('contained-button-file').value = null;
    //
  };
  uploadFile = (file) => {
    let apiUrl = Config.server + '/replay';
    let method = 'POST';

    const formData = new FormData();

    formData.append('file', file, 'teste.file');

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result) {
            if (result.title) {
              this.setState({ title: result.title });
            }
            this.clear(() => {
              this.exe(result);
            });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };
  checkJob = async () => {
    this.setState({ process: true });
    let guid = this.props.guid;
    let apiUrl = Config.server + '/view/' + guid;
    let method = 'GET';

    let options = {
      method: method,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    this.setState({ progress: true, progressValue: 0, openTask: false });
    let result;
    try {
      const response = await fetch(apiUrl, options);
      result = await Calls.toJSON(response.headers.get('content-length'), response.body,
        (loaded, length) => {
          this.setState({ progressValue: (100 * loaded / length).toFixed() });
        });
    } catch (error) {
      this.setState({ progress: false });
      notification.open({
        message: this.props.res.ERRO,
        description: this.props.res.ERRO_MSG,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return;
    }
    this.setState({ progressValue: 0 });

    if (result) {
      if (result.title) {
        this.setState({ title: result.title });
      }
      this.clear(() => {
        this.exe(result);
      });
    }
  };

  list_markers = () => {
    const apiUrl = Config.server + '/point/0';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            let m = [];
            for (let i = 0; i < result.length; i++) {
              const element = result[i];

              let tempMark = new marks();
              tempMark.id = element.id;
              tempMark.guid = element.guid;
              tempMark.name = element.name;
              tempMark.lat = element.lat;
              tempMark.lng = element.lng;
              tempMark.alt = element.height;
              tempMark.r = element.radios;
              tempMark.desc = element.icon;
              tempMark.type = element.type;

              for (let j = 0; j < img.length; j++) {
                if (img[j].desc.includes(element.icon)) {
                  tempMark.img = img[j].img;
                  break;
                }
              }
              m.push(tempMark)
              this.drawMarker(tempMark);
            }
            this.setState({ markers: m });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  drawMarker = (marker) => {
    let dIcon = L.icon({
      iconUrl: marker.img,
      iconAnchor: marker.type === 'O' ? [25, 30] : [13, 33],
      popupAnchor: [0, -25]
    });
    let newLatLng = new L.LatLng(marker.lat, marker.lng);
    let myLayer = L.marker(newLatLng, { icon: dIcon });
    myLayer._leaflet_id = marker.guid;
    myLayer.bindPopup(marker.name);
    //myLayer.addTo(this.featureGroupPM);

    if (marker.r > 0) {
      let myStyle = {
        "color": 'red',
        "weight": 4,
        "opacity": 0.5,
        "radius": marker.r
      };
      myLayer = L.circle(newLatLng, myStyle);
      myLayer._leaflet_id = marker.guid + 'r';
      myLayer.addTo(this.featureGroupPM);
    }
  };

  checkMarkers = () => {
    let mapBounds = this.mymap.getBounds();
    for (let i = this.state.markers.length - 1; i >= 0; i--) {
      let m = this.mymap._layers[this.state.markers[i].guid];
      if (this.mymap.getZoom() > 11) {
        if (!m) {
          this.drawMarker(this.state.markers[i]);
        } else {
          let shouldBeVisible = mapBounds.contains(m.getLatLng());
          if (m._icon && !shouldBeVisible) {
            this.featureGroupPM.removeLayer(m);
            let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
            if (mr)
              this.featureGroupP.removeLayer(mr);
          }
        }
      } else if (m) {
        this.featureGroupPM.removeLayer(m);
        let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
        if (mr)
          this.featureGroupP.removeLayer(mr);
      }
    }
  };

  checkTask = () => {
    if (this.props.guid) {
      this.getJob();
    } else {
      this.getTask();
    }
  };
  getTask = () => {
    let task = this.state.task;
    if (task) {
      let item = task;
      if (item) {
        let apiUrl = Config.server + '/replay/' + item;//TODO: arrumar!!!

        const options = {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('access-token-jwt')
          }
        }

        fetch(apiUrl, options)
          .then((res) => {
            if (res.status !== 200) throw res;
            else return res.json();
          })
          .then(
            (result) => {
              if (result) {
                if (result.cantRun) {
                  this.setState({ task: null });
                } else {
                  if (result.title) {
                    this.setState({ title: result.title, task: null });
                  }
                  this.clear(() => {
                    this.exe(result);
                  });
                }
              }
            },
            (error) => {
              this.setState({ progress: false });
              if (error.status === 405) {
                notification.open({
                  message: this.props.res.PERMISSAO,
                  description: this.props.res.PERMISSAO_MSG,
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              } else if (error.status === 401 || error.status === 403) {
                this.props.link('/login');
              } else if (error.status === 400) {
                error.json().then(
                  (errCode) => {
                    notification.open({
                      message: this.props.res[errCode.code],
                      icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                  });
              } else {
                console.log(error);
                notification.open({
                  message: this.props.res.ERRO,
                  description: this.props.res.ERRO_MSG,
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              }
            }
          );
      }
    }
  };

  getJob = async () => {
    if (this.props.guid) {
      let guid = this.props.guid;
      if (guid) {
        let apiUrl = Config.server + '/job_replay/' + guid;//TODO: arrumar!!!

        const options = {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('access-token-jwt')
          }
        }

        this.setState({ progress: true, progressValue: 0, openTask: false });
        let result;
        try {
          const response = await fetch(apiUrl, options);
          result = await Calls.toJSON(response.headers.get('content-length'), response.body,
            (loaded, length) => {
              this.setState({ progressValue: (100 * loaded / length).toFixed() });
            });
        } catch (error) {
          this.setState({ progress: false });
          notification.open({
            message: this.props.res.ERRO,
            description: this.props.res.ERRO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
          return;
        }
        this.setState({ progressValue: 0 });

        if (result) {
          if (result.cantRun) {
            this.setState({ task: null });
          } else {
            if (result.title) {
              this.setState({ title: result.title, task: null });
            }
            this.clear(() => {
              this.exe(result);
            });
          }
        }

      }
    }
  };

  clear = (func) => {
    this.featureGroupL.clearLayers();
    this.featureGroupP.clearLayers();
    this.featureGroupPoly.clearLayers();
    if (this.marker.marker) {
      this.mymap.removeLayer(this.marker.marker);
    }

    this.iPlay = 1;
    this.spray_poly_count = 0;
    this.spray_tiro_count = -1;
    this.cfg = 0;
    this.isFuel = false;
    this.temp_area = 0;
    this.area = 0;
    this.litros = 0;

    this.setState({
      tiro: 0,
      sTime: 1,

      stack: false,
      stack_value: [1, 1, 1],
      detail: [],
      progress: false,

      ruler_a: false,
      speed: 0,
      time: 0,
      ativo: false,
      play: false,
      file: null,
      follow: false,
      end: false
    }, () => {
      func();
    });
  };

  exe = (result) => {
    this.setState({ file: result });
    this.size = result.cfg[0][2];
    for (let i = 0; i < result.shape.length; i++) {
      const element = result.shape[i];
      let poly = JSON.parse(element[0]);
      poly.push(poly[0]);
      this.poly.push(turf.polygon([this.geoInvertArray(poly)]));
      this.polyMap({ poly: poly, type: element[1] });
    }

    let myStyle = {
      color: '#d0d0d0',
      weight: 1,
      opacity: 1,
      smoothFactor: 1,
      className: 'caminho'
    };
    if (result.nav.length === 0) return;
    let p1 = new L.LatLng(result.nav[0][1], result.nav[0][2]);
    let p2 = new L.LatLng(result.nav[1][1], result.nav[1][2]);

    this.lineL = [[result.nav[0][2], result.nav[0][1]], [result.nav[1][2], result.nav[1][1]]];
    //this.line = new L.polyline(this.geoInvertArray(lineL), myStyle);
    this.line = new L.polyline([p1, p2], myStyle);
    this.line.addTo(this.featureGroupL);

    let dIcon = null;
    let c = JSON.parse(localStorage.getItem('c-estimate'));
    if (c && c.base && c.base.length > 0) {
      dIcon = L.icon({
        iconUrl: Marks[11].img,
        iconAnchor: [13, 33]
      });
      let mark = L.marker(p1, { icon: dIcon });
      mark.addTo(this.featureGroupPoly);
    }

    dIcon = L.icon({
      iconUrl: Img_mark,
      iconAnchor: [21, 14],
      iconSize: [43.2, 28.8]
    });
    this.marker.marker = L.marker(p1, { icon: dIcon });
    this.marker.marker.addTo(this.mymap);

    this.pathMap(result.nav, 1, this.size);
    if (result.shape.length > 0) {
      this.mymap.fitBounds(this.featureGroupPoly.getBounds());
    } else {
      this.mymap.fitBounds(this.featureGroupL.getBounds());
    }

    this.loadTimeLine(result.nav);
  };

  loadTimeLine = (nav) => {
    for (let i = 0; i < nav.length - 1; i++) {
      const navC = nav[i];
      const navN = nav[i + 1];
      if ((navC[4] === 0 && navN[4] === 1) || (navC[4] === 1 && i === 0)) {//inicio de aplicacao
        this.spray_pointer.push({
          id: this.spray_pointer.length,
          start: i + 1,
          end: i + 1,
          area: 0,
          liters: 0,
        });
      } else if (navC[4] === 1 && navN[4] === 0) {//fim de aplicacao
        this.spray_pointer[this.spray_pointer.length - 1].end = i;
      }
    }
    if (this.spray_pointer[this.spray_pointer.length - 1].start
      === this.spray_pointer[this.spray_pointer.length - 1].end) {//se terminou o trabalho aplicando
      this.spray_pointer[this.spray_pointer.length - 1].end = nav.length - 1;
    }
    this.setState({ total: nav.length });
  };

  isMovingBar = false;
  handleChangeBar = (event, value) => {
    this.isMovingBar = true;
    this.setState({ valueBar: value });
  };

  updateTime = (toTime) => {
    this.setState({ progress: true }, () => {
      this.play(false, () => {
        this.runUpdate(toTime, () => {
          this.isMovingBar = false;
          this.setState({ progress: false });
        })
      })
    });
  };
  runUpdate = (toTime, callback) => {
    let total = Number(this.state.total) - 3;
    let perc = this.state.valueBar / 1000;
    let newTimeline = Math.round(perc * total) + 1;
    if (toTime) {
      newTimeline = toTime;
    }

    if (newTimeline === this.iPlay || this.state.total < newTimeline || newTimeline < 0) {
      callback();
      return;
    }

    let goI = 0;
    let posPlay = { i1: -1, i2: -1 };
    let posTimeline = { i1: -1, i2: -1 };
    for (let i = 0; i < this.spray_pointer.length; i++) {
      const start = this.spray_pointer[i];
      const i2 = this.spray_pointer.length - 1 - i;
      const end = this.spray_pointer[i2];

      if (start.start < this.iPlay) {
        posPlay.i1 = i;
      }
      if (end.end > this.iPlay) {
        posPlay.i2 = i2;
      }

      if (start.start < newTimeline) {
        posTimeline.i1 = i;
      }
      if (end.end > newTimeline) {
        posTimeline.i2 = i2;
      }
    }
    //apaga poly e linha nescessario
    if (newTimeline > this.iPlay) {//move para frente
      if (posPlay.i1 === posPlay.i2 && posPlay.i1 >= 0) {
        this.area -= this.spray_pointer[this.spray_tiro_count].area;
        this.litros -= this.spray_pointer[this.spray_tiro_count].liters;
        this.spray_tiro_count--;
        loop2: for (let j = 0; j < 99; j++) {
          let layer = this.mymap._layers['spray_' + (posPlay.i1) + '_' + j];
          if (layer) {
            this.featureGroupP.removeLayer(layer);
          } else {
            break loop2;
          }
        }
        goI = this.spray_pointer[posPlay.i1].start - 2;
        this.lineL = this.lineL.slice(0, goI);
        this.line.setLatLngs(this.geoInvertArray(this.lineL));
      } else {
        goI = this.iPlay;
      }
    } else {//move para tras
      let diff = posPlay.i1 - posTimeline.i1;
      if ((diff === 0 && posTimeline.i2 === posTimeline.i1) || (posTimeline.i2 === posTimeline.i1 && posPlay.i2 === posPlay.i2)) {
        diff += 1;
      }
      for (let i = 0; i < diff; i++) {
        this.area -= this.spray_pointer[posPlay.i1 - i].area;
        this.litros -= this.spray_pointer[posPlay.i1 - i].liters;
        this.spray_tiro_count--;
        loop2: for (let j = 0; j < 99; j++) {
          let layer = this.mymap._layers['spray_' + (posPlay.i1 - i) + '_' + j];
          if (layer) {
            this.featureGroupP.removeLayer(layer);
          } else {
            break loop2;
          }
        }
      }
      if (posTimeline.i2 === posTimeline.i1 && this.spray_pointer[posTimeline.i1].start - 2 < newTimeline) {
        goI = this.spray_pointer[posTimeline.i1].start - 2;
      } else {
        goI = newTimeline;
      }
      this.lineL = this.lineL.slice(0, goI);
      this.line.setLatLngs(this.geoInvertArray(this.lineL));
    }
    //*/

    //monta poly
    const montaPolyIn = (isComplete, liters) => {
      if (this.polyLin) {
        if (this.spray_poly_count === 0) {
          this.spray_tiro_count++;
          this.spray_pointer[this.spray_tiro_count].area = 0;
          this.spray_pointer[this.spray_tiro_count].liters = 0;
        }
        let linePoly = this.createPoly(this.polyLin, this.size);
        let latlngs = this.geoInvertArray(linePoly);
        this.polySin = L.polygon(latlngs, this.myStylein);
        this.polySin._leaflet_id = 'spray_' + this.spray_tiro_count + '_' + this.spray_poly_count++;
        arrPoly.push(this.polySin);
        //this.polySin.addTo(this.featureGroupP);
        if (isComplete) {
          this.polyLin = null;
        }
        let area = turf.area(turf.polygon([linePoly])) / 10000;
        this.spray_pointer[this.spray_tiro_count].area += area;
        this.area += area;

        let l = liters / (this.spray_pointer[this.spray_tiro_count].end - this.spray_pointer[this.spray_tiro_count].start);
        let litros = this.spray_pointer[this.spray_tiro_count].area * l;
        this.spray_pointer[this.spray_tiro_count].liters = litros;
        this.litros += litros;
      }
    };
    const montaPolyOut = (isComplete, liters) => {
      if (this.polyLout) {
        if (this.spray_poly_count === 0) {
          this.spray_tiro_count++;
          this.spray_pointer[this.spray_tiro_count].area = 0;
          this.spray_pointer[this.spray_tiro_count].liters = 0;
        }
        let linePoly = this.createPoly(this.polyLout, this.size);
        let latlngs = this.geoInvertArray(linePoly);
        this.polySout = L.polygon(latlngs, this.myStyleout);
        this.polySout._leaflet_id = 'spray_' + this.spray_tiro_count + '_' + this.spray_poly_count++;
        arrPoly.push(this.polySout);
        //this.polySout.addTo(this.featureGroupP);
        if (isComplete) {
          this.polyLout = null;
        }
        let area = turf.area(turf.polygon([linePoly])) / 10000;
        this.spray_pointer[this.spray_tiro_count].area += area;
        this.area += area;

        let l = liters / (this.spray_pointer[this.spray_tiro_count].end - this.spray_pointer[this.spray_tiro_count].start);
        let litros = this.spray_pointer[this.spray_tiro_count].area * l;
        this.spray_pointer[this.spray_tiro_count].liters += litros;
        this.litros += litros;
      }
    };
    //*/

    ///cria poly nescessario
    let liters = 0;
    let endOpen = false;
    this.spray_poly_count = 0;
    this.polyLin = null;
    this.polyLout = null;
    let arrPoly = [];
    const runLoop = (i) => {
      if (i <= newTimeline) {
        const nav = this.state.file.nav[i];
        const nav2 = this.state.file.nav[i > 0 ? i - 1 : 0];
        this.lineL.push([nav[2], nav[1]]);

        //monta poly
        let isIn = false;
        loop2: for (let j = 0; j < this.poly.length; j++) {
          if (turf.booleanPointInPolygon(turf.point([nav2[2], nav2[1]]), this.poly[j])
            || turf.booleanPointInPolygon(turf.point([nav[2], nav[1]]), this.poly[j])) {
            isIn = true;
            break loop2;
          }
        }
        if (nav[4] === 1 && nav2) {//aplicando 
          endOpen = true;
          liters += nav[7];
          if (isIn) {//dentro do poly
            if (this.polyLin) {
              this.polyLin.push([nav[2], nav[1]]);
            } else {
              this.polyLin = [[nav2[2], nav2[1]], [nav[2], nav[1]]];
            }
          } else {//fora do poly
            if (this.polyLout) {
              this.polyLout.push([nav[2], nav[1]]);
            } else {
              this.polyLout = [[nav2[2], nav2[1]], [nav[2], nav[1]]];
            }
          }
        } else {// nao aplicando
          endOpen = false;
          if (this.polyLin) {
            montaPolyIn(true, liters);
          }
          if (this.polyLout) {
            montaPolyOut(true, liters);
          }
          liters = 0;
          this.spray_poly_count = 0;
        }
        if (i % 1500 === 0) {
          setTimeout(() => {
            runLoop(++i);
          }, 10);
        } else {
          runLoop(++i);
        }
      } else {
        if (endOpen) {
          if (this.polyLin) {
            montaPolyIn(false, liters);
          }
          if (this.polyLout) {
            montaPolyOut(false, liters);
          }
        }

        for (let j = 0; j < arrPoly.length; j++) {
          const poly = arrPoly[j];
          poly.addTo(this.featureGroupP);
        }
        //console.log(this.mymap);
        //*/

        //atualiza o timer
        this.iPlay = newTimeline;
        //atualiza a linha
        this.line.setLatLngs(this.geoInvertArray(this.lineL));
        //atualiza o icone da aeronave    
        let bearing = this.state.file.nav[newTimeline][5];
        let to = [this.state.file.nav[newTimeline][1], this.state.file.nav[newTimeline][2]];
        this.moveMarker(to, 0, bearing);
        //atualiza labels da interface
        if (this.spray_tiro_count < 0) {//******* verificar inconsistencia dos columuladores */
          this.area = 0;
          this.litros = 0;
        }
        this.temp_area = this.area;
        this.setState({
          newTimeline: newTimeline,
          speed: Math.round(this.state.file.nav[newTimeline][3] * 10) / 10,
          time: this.state.file.nav[newTimeline][6],
          area: this.area,
          litros: this.litros
        }, callback);
      }
    };
    runLoop(goI);
  };

  polyMap = (poly) => {
    const color_x = '#ff002b';
    const myStyle = {
      color: '#47839e',
      weight: 4,
      opacity: 0.5,
      fillColor: '#FF1493',
      fillOpacity: 0.2
    };

    if (poly.type === 'X') {
      myStyle.color = color_x;
      myStyle.fillColor = color_x;
    }

    let myLayer = L.polygon(poly.poly, myStyle);
    myLayer.addTo(this.featureGroupPoly);
  };

  spray_poly_count = 0;
  spray_tiro_count = -1;
  cfg = 0;
  isFuel = false;
  temp_area = 0;
  area = 0;
  litros = 0;
  isPlaying = false;
  pathMap = (line, i, size) => {
    if (!this.isPlaying) {
      return;
    }
    if (!this.state.file || line.length === 0) {
      this.play(false);
      //this.setState({ end: true });
      return;
    }
    let pTime = Date.now();
    this.iPlay = i;
    if (this.isMovingBar) {
      this.setState({ newTimeline: i });
    } else {
      let total = Number(this.state.total) - 3;
      let perc = 100 * i / total;
      this.setState({ newTimeline: i, valueBar: Math.round(perc * 10) });
    }

    let bearing = line[i][5];
    if (this.cfg !== (line[i][0])) {
      this.cfg = (line[i][0]);
    }

    this.lineL.push([line[i][2], line[i][1]]);
    this.line.setLatLngs(this.geoInvertArray(this.lineL));

    let isIn = false;
    for (let j = 0; j < this.poly.length; j++) {
      if (turf.booleanPointInPolygon(turf.point([line[i - 1][2], line[i - 1][1]]), this.poly[j])
        || turf.booleanPointInPolygon(turf.point([line[i][2], line[i][1]]), this.poly[j])) {
        isIn = true;
        break;
      }
    }
    if (line[i][3] < 5) {//se a velodidade for < m/s
      this.isFuel = true;
    } else {
      if (this.isFuel) {
        let dIcon = L.icon({
          iconUrl: Marks[0].img,
          iconAnchor: [13, 33]
        });
        let mark = L.marker([line[i][1], line[i][2]], { icon: dIcon });
        mark.addTo(this.featureGroupPoly);
        this.isFuel = false;
      }
      if (line[i][4] === 1) {//aplicando     
        if (this.spray_poly_count === 0) {
          this.spray_tiro_count++;
        }
        if (isIn) {//dentro do poly
          if (this.polySin) {//continua polySin
            this.polyLin.push([line[i][2], line[i][1]]);
            let linePoly = this.createPoly(this.polyLin, size);
            this.polySin.setLatLngs(this.geoInvertArray(linePoly));
            this.spray_pointer[this.spray_tiro_count].area = turf.area(turf.polygon([linePoly])) / 10000;
            this.spray_pointer[this.spray_tiro_count].liters = this.spray_pointer[this.spray_tiro_count].area * line[i][7];

            let past_area = this.area;
            this.area = this.temp_area + this.spray_pointer[this.spray_tiro_count].area;
            this.litros += (this.area - past_area) * line[i][7];

          } else {//inicia polySin
            //if (line[i - 1][4] === 1) {
            if (this.polySout) {
              this.temp_area = this.area;
            }
            this.setState({ ativo: true });
            this.polyLin = [[line[i - 1][2], line[i - 1][1]], [line[i][2], line[i][1]]];
            let latlngs = this.geoInvertArray(this.createPoly(this.polyLin, size));
            this.polySin = L.polygon(latlngs, this.myStylein);
            this.polySin._leaflet_id = 'spray_' + this.spray_tiro_count + '_' + this.spray_poly_count++;
            this.polySin.addTo(this.featureGroupP);
            //}
          }

          this.polySout = null;
          this.polyLout = null;
          this.exitIn = true;
          if (this.polySmiss) {
            this.polyLmiss = null;
            this.polySmiss = null;
          }
        } else {//fora do poly
          if (this.polySout) {//continua polySout
            this.polyLout.push([line[i][2], line[i][1]]);
            let linePoly = this.createPoly(this.polyLout, size);
            this.polySout.setLatLngs(this.geoInvertArray(linePoly));

            this.spray_pointer[this.spray_tiro_count].area = turf.area(turf.polygon([linePoly])) / 10000;
            this.spray_pointer[this.spray_tiro_count].liters = this.spray_pointer[this.spray_tiro_count].area * line[i][7];

            let past_area = this.area;
            this.area = this.temp_area + turf.area(turf.polygon([linePoly])) / 10000;
            this.litros += (this.area - past_area) * line[i][7];
          } else {//inicia polySout
            //if (line[i - 1][4] === 1) {
            if (this.polySin) {
              this.temp_area = this.area;
            }
            this.setState({ ativo: true });
            this.polyLout = [[line[i - 1][2], line[i - 1][1]], [line[i][2], line[i][1]]];
            let latlngs = this.geoInvertArray(this.createPoly(this.polyLout, size));
            this.polySout = L.polygon(latlngs, this.myStyleout);
            this.polySout._leaflet_id = 'spray_' + this.spray_tiro_count + '_' + this.spray_poly_count++;
            this.polySout.addTo(this.featureGroupP);
            //}
          }
          this.polySin = null;
          this.polyLin = null;
        }
      } else {
        if (line[i - 1][4] === 1) {//aplicando  
          this.temp_area = this.area;
        }
        this.spray_poly_count = 0;
        if (this.polySin) {
          this.setState({ tiro: this.state.tiro + 1, ativo: false });
          this.polySin = null;
          this.polyLin = null;
        } else if (this.polySout) {
          this.setState({ tiro: this.state.tiro + 1, ativo: false });
          this.polySout = null;
          this.polyLout = null;
        }
      }
    }
    if (!isNaN(line[i + 1][1]) && !isNaN(line[i + 1][2])) {
      let to = [line[i + 1][1], line[i + 1][2]];
      let time = (line[i + 1][6] - line[i][6]) > 2 ? 200 : (line[i + 1][6] - line[i][6]) * 1000;
      if (time / this.state.sTime > 0) {
        this.moveMarker(to, time / this.state.sTime, bearing);
        this.setState({ speed: Math.round(line[i][3] * 10) / 10, time: line[i][6], area: this.area, litros: this.litros });
      }
      pTime = (Date.now() - pTime);
      const nextPath = () => {
        if (line.length - 2 > i && this.isPlaying) {
          this.pathMap(line, ++i, size);
        } else if (line.length - 20 < i) {
          this.play(false);
          this.setState({ end: true });
        }
      };
      setTimeout(nextPath, (time - pTime) / this.state.sTime)
    }
  };

  moveMarker = (pos, time, angle) => {
    if (time > 0) {
      this.marker.marker.slideTo(pos, {
        duration: time,
        rotationAngle: angle,
        keepAtCenter: this.state.follow
      });
    } else {
      this.marker.marker.setLatLng(pos);
      this.marker.marker.setRotationAngle(angle);
    }
  };

  geoInvertArray = (poly) => {
    let array = [];
    for (let i = 0; i < poly.length; i++) {
      array.push([poly[i][1], poly[i][0]]);
    }
    return array;
  };

  createPoly = (points, widthLine) => {
    let width = widthLine / 2 / 1000;//metade da linha em km
    //let points = line.geometry.coordinates;
    let bear = [null, null];
    let poly = [[], []];
    for (let i = 0; i < points.length; i++) {
      const ele1 = i > 0 ? points[i - 1] : false;
      const ele2 = points[i];
      const ele3 = i < points.length - 1 ? points[i + 1] : false;

      if (ele1) {
        let b = turf.bearing(ele1, ele2);
        bear[0] = b < 0 ? b + 360 : b;
      }
      if (ele3) {
        let b = turf.bearing(ele2, ele3);
        bear[1] = b < 0 ? b + 360 : b;
      }

      if (ele1 && ele3) {
        let mBear = bear[1];
        if (bear[0] - bear[1] < 10 && bear[1] - bear[0] < 10) {
          mBear = (bear[0] - bear[1]) / 2 + bear[1];
        }
        poly[0].push(turf.destination(ele2, width, mBear + 90).geometry.coordinates);
        poly[1].push(turf.destination(ele2, width, mBear - 90).geometry.coordinates);
      } else if (ele1) {
        poly[0].push(turf.destination(ele2, width, bear[0] + 90).geometry.coordinates);
        poly[1].push(turf.destination(ele2, width, bear[0] - 90).geometry.coordinates);
      } else if (ele3) {
        poly[0].push(turf.destination(ele2, width, bear[1] + 90).geometry.coordinates);
        poly[1].push(turf.destination(ele2, width, bear[1] - 90).geometry.coordinates);
      }
    }
    poly[1].reverse();
    let apl = poly[0].concat(poly[1]);
    apl.push(apl[0]);

    return apl;
  };

  play = (go, callback) => {
    this.isPlaying = go;
    if (go && !this.state.play) {
      if (this.state.end) {
        this.setState({ end: false, tiro: 0 });
        this.featureGroupL.clearLayers();
        this.featureGroupP.clearLayers();
        this.featureGroupPoly.clearLayers();
        if (this.marker.marker) {
          this.mymap.removeLayer(this.marker.marker);
        }
        this.exe(this.state.file);
      } else {
        this.pathMap(this.state.file.nav, this.iPlay, this.size);
      }
    }
    this.setState({ play: go }, callback);
  };
  formatRegua = (valor) => {
    let text = '';
    if (valor < 1000) {
      text = Math.round(valor) + ' m';
    } else {
      text = (Math.round(valor / 10) / 100) + ' km';
    }

    return text;
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  zoomInL = () => {
    this.mymap.zoomIn();
  };

  zoomOutL = () => {
    this.mymap.zoomOut();
  };

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (!dF.className.includes('div-listFloatC')) {
      dF.classList.toggle("div-listFloatC");
    } else {
      dF.classList.remove("div-listFloatC");
    }
  };

  handleStackClick = (tipo) => {
    let stack_value = this.state.stack_value;
    if (tipo === 1) {
      if (stack_value[0] === 1) {
        stack_value[0] = 0;
        tools.cssShow('aplicacao', false);
      } else {
        stack_value[0] = 1;
        tools.cssShow('aplicacao', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 2) {
      if (stack_value[1] === 1) {
        stack_value[1] = 0;
        tools.cssShow('caminho', false);
      } else {
        stack_value[1] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 3) {
      if (stack_value[2] === 1) {
        stack_value[2] = 0;
        tools.cssShow('caminho', true);
      } else {
        stack_value[2] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    }
  };

  regua = (start) => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }

    this.featureGroupPoly.removeLayer(this.mymap._layers['regua']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM1']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM2']);
    if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
      this.setState({ ruler_a: false });
      this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
      this.drawCreateToolbar.disable();
    } else {
      this.setState({ ruler_a: true });
      this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
      this.drawCreateToolbar.enable();
      if (start) {
        this.drawCreateToolbar.addVertex(start);
      }
    }
  };

  completeRuler = () => {
    this.drawCreateToolbar.disable();

    let latlngs = [
      [this.ruler_p.first.lat, this.ruler_p.first.lng],
      [this.ruler_p.second.lat, this.ruler_p.second.lng]
    ];
    let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    let text = latlngF.distanceTo(latlngS);


    let polyline = L.polyline(latlngs);

    polyline._leaflet_id = 'regua';
    polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
    polyline.addTo(this.featureGroupPoly);

    let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    })
    m1.on('click', (e) => {
      this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    })
    m1._leaflet_id = 'reguaM1';
    m1.addTo(this.featureGroupPoly);

    let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    });
    m2.on('click', (e) => {
      this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    })
    m2._leaflet_id = 'reguaM2';
    m2.addTo(this.featureGroupPoly);
  };

  cancelRuler = () => {
    if (this.ruler_p.second.lat) {
      this.regua();
    }
  };

  render() {
    const { res } = this.props;

    return (
      <div className="gridEst">
        <div className="divContent">
          <div className="containerTitle">
            <div>
              <h2 title={this.state.title}>{this.state.title}</h2>
            </div>
            <div className="centerTop"></div>
            <div>
              <Button variant="contained" className="popUpButton" color="primary" style={{ marginRight: '.4rem' }} onClick={event => (this.props.link('/app/relatorio/' + this.props.guid))} title={'Voltar'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-file-earmark-arrow-down-fill" viewBox="-4 -4 24 24">
                  <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5ZM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5Z" />
                </svg>
              </Button>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Backd style={{ zIndex: 1002, color: '#fff' }} open={this.state.progress}>
            {this.state.progressValue > 0 ?
              <CircularProgress style={{ scale: '2' }} variant="determinate" value={this.state.progressValue} color="inherit" disableShrink /> :
              <CircularProgress color="inherit" disableShrink />
            }
          </Backd>
          <div className="rowMapC maxHT">
            <div id="divFloat" className="div-list divContentList list-p">
              <div>
                {this.state.showDev &&
                  <div style={{ display: 'flex' }}>
                    <TextField
                      id="newTimeline"
                      style={{ width: '5rem', margin: '.5rem' }}
                      value={this.state.newTimeline}
                      onChange={this.handleChange('newTimeline')}
                      margin="normal"
                      type="number"
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 10)
                      }}
                    />
                    <TextField
                      id="total"
                      style={{ width: '5rem', margin: '.5rem' }}
                      value={this.state.total}
                      onChange={this.handleChange('total')}
                      margin="normal"
                      type="number"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button className={"buttonS buttonMenu"}
                        disabled={this.state.file === null || this.state.play}
                        onClick={event => this.updateTime(Number(this.state.newTimeline))}>
                        GO
                      </Button>
                    </div>
                  </div>
                }
              </div>
              <div className="form-group">
                <div style={{ padding: '1rem' }}>
                  <span className="contentPercRel" style={{ margin: 'auto' }} onClick={() => this.setState({ showDev: !this.state.showDev })}>
                    spray:
                    <SvgIcon viewBox="-5 -11 24 35" className="iconArrow"
                      style={{ color: this.state.ativo ? '#32a852' : 'grey', width: '.9em', height: '.9em' }}>
                      <path id="polygon" d="M 0 0 c -4 4 -4 11 0 15 c 4 4 11 4 15 0 C 19 11 19 4 15 0 c -4 -4 -11 -4 -15 0 Z" />
                    </SvgIcon>
                    {this.state.ativo ? 'on' : 'off'}
                  </span>
                </div>
                <TextField
                  id="tiro"
                  label={res.TIRO}
                  value={this.state.tiro}
                  onChange={this.handleChange('tiro')}
                  margin="normal"
                  type="number"
                  fullWidth
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 10)
                  }}
                />
                <TextField
                  id="time"
                  label={res.TEMPO}
                  value={tools.fancyTimeFormat(this.state.time)}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
                <TextField
                  id="area"
                  label={res.AREA_APLICADA}
                  value={(Math.round(this.state.area * 10) / 10).toFixed(1)}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
                <TextField
                  id="litros"
                  label={res.COMSUMO_TOTAL}
                  value={Math.round(this.state.litros)}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
                <TextField
                  id="litrosMedio"
                  label={res.COMSUMO}
                  value={this.state.area > 0 ? (this.state.litros / this.state.area).toFixed(1) : 0}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
                <TextField
                  id="valocidade"
                  label={res.VELOCIDADE_TITLE}
                  value={Math.ceil(this.state.speed * 3.6)}
                  margin="normal"
                  type="number"
                  fullWidth
                />
              </div>
            </div>
            <div className="divContent divRelative map-p">
              <div className="containerTop">
                <div className="leftTop">
                  <Button className={this.state.ruler_a ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.regua()}>
                    <MaterialIcon icon="settings_ethernet" className="iconMap highlightSVG" />
                  </Button>
                </div>
                <div className="centerTop"></div>
                <div className="rightTop">
                  <label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                    <Button className="buttonS buttonMenu" onClick={event => this.setState({ stack: !this.state.stack })}>
                      <MaterialIcon icon="layers" className="iconMap" />
                    </Button>
                  </label>
                </div>
              </div>

              <div className="wrapperMap">
                <div id="mapid"></div>
                {this.state.showProgressBar &&
                  <div>
                    <div style={{ position: 'absolute', zIndex: '400', position: 'absolute', bottom: '.7rem', scale: '.8' }}>
                      <Button className={"buttonS buttonMenu"}
                        disabled={this.state.file === null}
                        onClick={event => { this.setState({ sTime: 1 }); this.play(this.state.play ? false : true); }}>
                        <MaterialIcon icon={this.state.end ? "replay" : this.state.play ? "pause" : "play_arrow"} className="iconMap" />
                      </Button>&nbsp;
                      <Button className={(this.state.play && this.state.sTime === 50) ? "buttonS buttonMenuHL" : "buttonS buttonMenu"}
                        disabled={this.state.file === null || !this.state.play}
                        onClick={event => this.setState({ sTime: this.state.sTime === 50 ? 1 : 50 })}>
                        <MaterialIcon icon="fast_forward" className="iconMap" />
                      </Button>&nbsp;
                    </div>
                    <div style={{ position: 'absolute', width: 'calc(100% - 5.7rem)', height: '1.75rem', bottom: '1rem', left: '5rem', zIndex: '400', backgroundColor: 'white', padding: '0 1rem', borderRadius: '.15rem', }}>
                      <Slider value={this.state.valueBar} min={0} max={1000} onChange={this.handleChangeBar} onChangeCommitted={ev => this.updateTime()} aria-labelledby="continuous-slider" />
                    </div>
                  </div>
                }
                <div className="zoomB">
                  <Button className="buttonS buttonMenu buttonMenuSmall" onClick={event => this.showMenu()}>
                    <MaterialIcon icon="menu" className="iconMapF" />
                  </Button>
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                    <MaterialIcon icon="add" className="iconMap" />
                  </Button>
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                    <MaterialIcon icon="remove" className="iconMap" />
                  </Button>
                </div>
                {this.state.stack &&
                  <div className="divContent floatW divS" onfocusout={event => this.setState({ stack: false })}>
                    <List
                      component="nav"
                      aria-labelledby="stack"
                    >
                      <ListItem key={2} button
                        className={(this.state.stack_value[1] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(2)}
                      >
                        <ListItemText id={2} primary="Caminho" />
                      </ListItem>

                      <ListItem key={1} button
                        className={(this.state.stack_value[0] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(1)}
                      >
                        <ListItemText id={1} primary="Aplicação" />
                      </ListItem>
                    </List>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { guid } = useParams();
  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} guid={guid} res={res} />
    </div>)
}
export default Box;

