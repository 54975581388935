import React from 'react';

import { notification } from 'antd';
import Config from '../../constants/appConfig';

import Checkbox from '@material-ui/core/Checkbox';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import MaterialIcon from '../MaterialIcon';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import SPW from "string-pixel-width";
//import { InsertLinkRounded } from '@material-ui/icons';

class AppList extends React.Component {
    state = {
        ini: [],
        fim: [],
        selectIni: [],
        selectFim: [],
        clients: [],
        id_client: 0,
        fazenda_client: [],
        filtro: '',
    };

    rowsPerPage = 50;
    lan = '';

    d = {
        zIndex: this.props.index + 1,
    };

    b = {
        textAlign: 'center',
        margin: 'auto',
        padding: '10px'
    };

    shift = false;

    lastClick = { guid: '', time: 0 };
    intervalClick = 300;

    componentDidMount() {
        this.loadClient();
        this.loadAreas(0);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    moveRight = (item, linked) => {
        let list = this.state.fim;
        if (item) {
            const element = this.state.ini.filter((obj) => item === obj.id);
            if (element.length > 0)
                list.push(element[0]);
            /*for (let i = 0; i < linked.length; i++) {
                let index_linked = list.indexOf(linked[i]);
                if (index_linked < 0) {
                    list.push(linked[i]);
                }
            }*/
        } else {
            for (let i = 0; i < this.state.selectIni.length; i++) {
                const element = this.state.ini.filter((obj) => this.state.selectIni[i] === obj.id);
                if (element.length)
                    list.push(element[0]);
            }
        }

        this.setState({ selectIni: [], fim: list });
    }
    moveLeft = (item, linked) => {
        let list = this.state.fim;
        if (item) {
            list = list.filter((obj) => item !== obj.id);
            /*for (let i = 0; i < linked.length; i++) {
                let index_linked = list.indexOf(linked[i]);
                if (index > -1 && index_linked > -1) {
                    list.splice(index_linked, 1);
                }
            } */
        } else {
            list = list.filter((item) => this.state.selectFim.indexOf(item.id) < 0);
        }

        this.setState({ selectFim: [], fim: list });
    }

    clickLeft = (item) => {
        let list = this.state.selectIni;
        if (this.shift) {
            let lastGuid = this.state.selectIni[this.state.selectIni.length - 1];

            let indexLast = lastGuid;
            let indexNow = item;

            if (indexNow > indexLast) {
                let c = indexNow;
                indexNow = indexLast;
                indexLast = c;
            }
            for (let i = indexNow; i <= indexLast; i++) {
                if (!this.state.selectIni.includes(i)) {
                    list.push(i);
                }
            }
        } else {
            if (this.lastClick.guid === item && this.lastClick.time > (Date.now() - this.intervalClick)) {
                let linked = null;/*this.state.fim.filter((propItem) => {
                    return propItem.id.includes(item) && propItem.id !== item;
                }).map(mapItem => mapItem.id);*/
                this.moveRight(item, linked);
            }
            this.lastClick.guid = item;
            this.lastClick.time = Date.now();

            let index = list.indexOf(item);
            if (index > -1) {
                list.splice(index, 1);
            } else {
                list.push(item);
            }

            /*let linked = this.state.fim.filter((propItem) => {
                return Array.isArray(propItem.id) && propItem.id.includes(item) && propItem.id !== item;
            }).map(mapItem => mapItem.id);
            for (let i = 0; i < linked.length; i++) {
                let index_linked = list.indexOf(linked[i]);
                if (index > -1 && index_linked > -1) {
                    list.splice(index_linked, 1);
                } else if (index < 0 && index_linked < 0) {
                    list.push(linked[i]);
                }
            }*/
        }
        this.shift = false;
        this.setState({ selectIni: list });
    }
    clickRight = (item) => {
        let list = this.state.selectFim;

        if (this.lastClick.guid === item && this.lastClick.time > (Date.now() - this.intervalClick)) {
            let linked = null;/*this.state.ini.filter((propItem) => {
                return propItem.id.includes(item) && propItem.id !== item;
            }).map(mapItem => mapItem.id);*/
            this.moveLeft(item, linked);
            return;
        }
        this.lastClick.guid = item;
        this.lastClick.time = Date.now();

        const index = list.indexOf(item);
        if (index > -1) {
            list.splice(index, 1);
        } else {
            list.push(item);
        }

        /*let linked = this.state.ini.filter((propItem) => {
            return propItem.id.includes(item) && propItem.id !== item;
        }).map(mapItem => mapItem.id);
        for (let i = 0; i < linked.length; i++) {
            let index_linked = list.indexOf(linked[i]);
            if (index > -1 && index_linked > -1) {
                list.splice(index_linked, 1);
            } else if (index < 0 && index_linked < 0) {
                list.push(linked[i]);
            }
        }*/

        this.setState({ selectFim: list });
    }

    handleSelectAllIni = (event, checked) => {
        if (checked) {
            let p = this.state.ini
                .map(n => n.id);
            this.setState({ selectIni: p });
        } else {
            this.setState({ selectIni: [] });
        }
    }
    handleSelectAllFim = (event, checked) => {
        if (checked) {
            let p = this.state.ini
                .map(n => n.id);
            this.setState({ selectFim: p });
        } else {
            this.setState({ selectFim: [] });
        }
    }

    limitChar = (string, limit) => {
        let ret = string;
        let size = SPW(string, { font: "Arial", size: 16 });
        if (size > limit) {
            ret = string.slice(0, (string.length * (limit - 16) / size)) + '...';
        }
        return ret;
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            page: 0
        });
    };

    limpaCampo = () => {
        document.getElementById('campoFiltro').value = '';
        this.clear();
    }

    clear = () => {
        this.setState({
            filtro: ''
        });
    };

    changePage = (to) => {
        if (to === 'P') {
            this.setState({ page: this.state.page - 1 });
        } else if (to === 'N') {
            this.setState({ page: this.state.page + 1 });
        }
    }

    keyDown = (e) => {
        if (e.keyCode === 16) {
            this.shift = true;
        }
    }

    keyUp = (e) => {
        this.shift = false;
    }

    loadAreas = (id, callback) => {
        const apiUrl = Config.server + '/areas/' + id;
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    if (id === 0) {
                        this.setState({ ini: result });
                    } else {
                        callback(result)
                    }
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    handleChangeClient = (name) => event => {
        this.setState({
            [name]: event.target.value,
        }, () => {
            if (event.target.value !== 0) {
                this.loadProperty(event.target.value, (res) => {
                    this.setState({ fazenda_client: res }, () => {
                        this.loadAreas(this.state.id_client, (result) => {
                            this.setState({ ini: result });
                        });
                    });
                });
            } else {
                this.loadAreas(0);
            }
        });
    };

    loadProperty = (id_client, callback) => {
        const apiUrl = Config.server + '/properties/' + id_client;
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    callback(result);
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    loadClient = () => {
        const apiUrl = Config.server + '/customer';
        let obj = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };
        fetch(apiUrl, obj)
            .then((res) => {
                if (res.status !== 200)
                    throw res;//new Error(res.status);
                else
                    return res.json();
            })
            .then(
                (result) => {
                    this.setState({ clients: result });
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    } else if (error.status === 400) {
                        error.json().then(
                            (errCode) => {
                              notification.open({
                                message: this.props.res[errCode.code],
                                icon: <MaterialIcon icon="error" className="text-danger" />
                              });
                            }); 
                    } else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    };

    handlePropertyListItemClick = (divId, itemId, guid) => {
        let elementDiv = document.getElementById(divId);
        let elementItem = document.getElementById(itemId);
        elementItem.classList.toggle("active");
        var panel = elementDiv.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
        //this.handleListItemClick(guid);
    }

    render() {
        const { listFilter, open, index, res, shift } = this.props;

        return (
            <div className="back-quest" style={{
                zIndex: index,
                display: open ? 'flex' : 'none'
            }} >
                <div className="divContentList div-select-f" style={this.d}>
                    <div className="divContentList list-p-filter" style={{ gridTemplateRows: '4rem 4rem auto ' }}>
                        <div style={{ padding: '.5rem', position: 'relative' }} className="gridFiltro">
                            <div style={{ width: '50%' }}>{res.ITENS}{shift ? '1' : '2'}</div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                                <Checkbox
                                    disableRipple
                                    className="checkMap"
                                    indeterminate={this.state.selectIni.length > 0 && this.state.selectIni.length < this.state.ini.length}
                                    checked={this.state.selectIni.length === this.state.ini.length}
                                    onChange={this.handleSelectAllIni}
                                />
                            </div>
                            <div style={{ width: '14rem' }}>
                                <SearchIcon style={{ marginRight: '.5rem', marginTop: '1.5rem' }} />
                                <TextField
                                    placeholder={res.FILTRO}
                                    margin="normal"
                                    onChange={this.handleChange('filtro')}
                                    id="campoFiltro"
                                    type="search"
                                />
                                {(this.state.filtro === '') ? <div></div> :
                                    <MaterialIcon icon="clear" style={{ position: 'absolute', top: '2.3rem', right: '1.2rem', cursor: 'pointer' }} onClick={() => this.limpaCampo()} />
                                }
                            </div>
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <Select
                                value={this.state.id_client}
                                style={{ width: '100%' }}
                                onChange={this.handleChangeClient('id_client')}
                                inputProps={{
                                    id: 'id_clientPup',
                                }}
                            >
                                <MenuItem key={0} value={0}>{this.state.tab === '1' ? this.tabItens[0].label : 'Selecione'}</MenuItem> :
                                {this.state.clients.map((item, key) => {
                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>;
                                })}
                            </Select>
                        </div>
                        <div className="div-list_a">
                            {this.state.id_client === 0 ?
                                <List
                                    onKeyDown={this.keyDown}
                                    onKeyUp={this.keyUp}
                                    component="nav"
                                    aria-labelledby="areas"
                                    style={{ position: 'absolute', width: '100%' }}
                                >
                                    {this.state.ini
                                        .filter((item) => {
                                            return (item.name.toUpperCase().includes(this.state.filtro.toUpperCase()) &&
                                                this.state.fim.indexOf(item) < 0);
                                        })
                                        .map((item, index) => {
                                            return (
                                                <ListItem key={index} button
                                                    className={(this.state.selectIni.indexOf(item.id) >= 0) ? 'SelItem' : ''}
                                                    onClick={event => this.clickLeft(item.id)}
                                                    title={item.name}
                                                >
                                                    <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                                    {
                                                        item.x && <div style={{ height: '1rem', width: '.5rem', backgroundColor: 'red' }}></div>
                                                    }
                                                </ListItem>
                                            )
                                        })}
                                </List>
                                :
                                <List
                                    onKeyDown={this.keyDown}
                                    onKeyUp={this.keyUp}
                                    component="nav"
                                    aria-labelledby="areas"
                                    style={{ position: 'absolute', width: '100%' }}
                                >
                                    {this.state.fazenda_client
                                        .filter(item => {
                                            if (item.name) {
                                                return item.name.toUpperCase().includes(this.state.filtro.toUpperCase());
                                            } else {
                                                return this.state.filtro ? null : item;
                                            }
                                        })
                                        .map((item, index) => {
                                            return (<div>
                                                <div style={{ display: 'flex' }} id={'itemdiv' + index}>
                                                    <ListItem key={index + 'c1'} button
                                                        id={'item' + index}
                                                        onClick={event => this.handlePropertyListItemClick('itemdiv' + index, 'item' + index, item.guid)}
                                                        title={item.name}
                                                    >
                                                        <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                                    </ListItem>
                                                </div>
                                                <List
                                                    onKeyDown={this.keyDown}
                                                    onKeyUp={this.keyUp}
                                                    component="nav"
                                                    className='panel'
                                                    aria-labelledby="areas"
                                                    style={{
                                                        position: 'relative', width: 'calc(100% - 2rem)', marginLeft: '2rem',
                                                        //borderBottom: (this.state.sel_poly.indexOf(item.guid) >= 0) ? 'solid -2px #f0f0f0' : '0',
                                                        borderLeft: 'solid 2px #f0f0f0', padding: '0 0'
                                                    }}
                                                >
                                                    {this.state.ini
                                                        .filter(itemA => {
                                                            let teste = item.id === itemA.id_property && this.state.fim.indexOf(itemA) < 0;
                                                            if (itemA.name) {
                                                                return teste && item.name.toUpperCase().includes(this.state.filtro.toUpperCase());
                                                            } else {
                                                                return !teste || this.state.filtro ? null : itemA;
                                                            }
                                                        })
                                                        .map((itemA, index) => {
                                                            return (<div style={{ display: 'flex' }} >
                                                                <ListItem key={index + 'c2'} button
                                                                    id={'item' + index}
                                                                    className={(this.state.selectIni.indexOf(itemA.id) >= 0) ? 'SelItem' : ''}
                                                                    title={itemA.name}
                                                                    onClick={event => this.clickLeft(itemA.id)}
                                                                >
                                                                    <ListItemText id={index} primary={this.limitChar(itemA.name, 150)} />
                                                                    {
                                                                        item.x && <div style={{ height: '1rem', width: '.5rem', backgroundColor: this.color_x }}></div>
                                                                    }
                                                                </ListItem>
                                                            </div>)
                                                        })}
                                                </List>
                                            </div>)
                                        })}
                                </List>
                            }
                        </div>
                    </div>
                    <div style={this.b}>
                        <Button className="buttonS buttonMenu" disabled={false} onClick={event => this.moveRight()} title={'Move para Direita'}>
                            <MaterialIcon icon="navigate_next" className="iconMap" />
                        </Button>
                        <Button className="buttonS buttonMenu" disabled={false} onClick={event => this.moveLeft()} title={'Move para Esquerda'}>
                            <MaterialIcon icon="navigate_before" className="iconMap" />
                        </Button>

                    </div>
                    <div className="divContentList list-p">
                        <div style={{ padding: '.5rem', display: 'flex' }}>
                            <div style={{ width: '50%' }}>
                                <Checkbox
                                    disableRipple
                                    className="checkMap"
                                    indeterminate={this.state.selectFim.length > 0 && this.state.selectFim.length < this.state.fim.length}
                                    checked={this.state.selectFim.length === this.state.fim.length}
                                    onChange={this.handleSelectAllFim}
                                />
                            </div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                                <Button type="submit" variant="contained" color={this.state.fim.length > 0 ? "primary" : "default"} className="popUpButton" onClick={e => {
                                    listFilter(this.state.fim);
                                }}>
                                    {this.state.fim.length > 0 ? res.IMPORTAR : res.CANCELAR}
                                </Button>
                            </div>
                        </div>
                        <div className="div-list_a">
                            <List
                                component="nav"
                                aria-labelledby="areas"
                                style={{ position: 'absolute', width: '100%' }}
                            >
                                {this.state.fim
                                    .map((item, index) => {
                                        return (
                                            <ListItem key={index} button
                                                className={(this.state.selectFim.indexOf(item.id) >= 0) ? 'SelItem' : ''}
                                                onClick={event => this.clickRight(item.id)}
                                                title={item.name}
                                            >
                                                <ListItemText id={index} primary={this.limitChar(item.name, 202)} />
                                                {
                                                    item.x && <div style={{ height: '1rem', width: '.5rem', backgroundColor: this.color_x }}></div>
                                                }
                                            </ListItem>
                                        )
                                    })}
                            </List>
                        </div>
                    </div>
                </div>
                <div className="div-select"></div>
            </div>
        )
    }
}

export default AppList;
