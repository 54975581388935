import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import SaveIcon from '@material-ui/icons/Save';

import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';

import { notification } from 'antd';

import { useParams } from "react-router";


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    empresa_data: [],
    id_company: null,
    id: this.props.id,
    name: '',
    serial: '',
    activationKey: '',
    model: '',
    type: 'station',
    public: '',
  };

  componentDidMount() {
    if (this.props.profile.includes(2)) {
      this.loadCompany();
    }
    if (this.state.id) {
      this.loadSource();
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  handleChange = item => event => {
    this.setState({
      [item]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/weather_source';

    let data = {};
    if (this.state.id) {
      apiUrl += `/${this.state.id}`;
      data.id = this.state.id;
      method = 'PUT';
    } else {
      method = 'POST';
    }

    data.id_company = this.state.id_company;
    data.name = this.state.name;
    data.model = this.state.model;
    data.type = this.state.type;
    data.serial = this.state.serial;
    data.activationKey = this.state.activationKey;

    let options = {
      method: method,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result && result.error) {
            notification.open({
              message: this.props.res[result.message],
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else {
            let msg = this.props.res.ITEM_INCLUIR;
            if (this.state.id) {
              msg = this.props.res.ITEM_ALTERADO;
            }
            notification.open({
              message: this.props.res.SUCESSO,
              description: msg,
              icon: <MaterialIcon icon="check_circle" className="text-success" />
            });

            if (!this.state.id) {
              this.setState({
                name: '',
                model: '',
                serial: '',
                activationKey: '',
              });
            }
            this.backPage();
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG_INFORMACOES,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  loadSource = () => {
    const apiUrl = Config.server + '/weather_source/' + this.state.id;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            this.setState({
              name: result[0].name,
              model: result[0].model === 'movel' ? 'movel' : 'fixa',
              serial: result[0].serial,
              activationKey: result[0].activationKey,
              id_company: result[0].id_company
            });
          }
        },
        (error) => {
          if (error.status === 401) {
            this.props.link('/login');
          }
          else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  loadCompany = () => {
    const apiUrl = Config.server + '/company';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            empresa_data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  backPage = () => {
    const { link } = this.props;
    link('/app/cadastro/estacao-meteorologica/list')
  };

  render() {
    const { res, profile } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="containerTop">
          <div><h1>{res.ESTACAO_METEOROLOGICA}</h1></div>
          <div className="centerTop"></div>
          <div>
            <Button variant="contained" onClick={this.backPage} className="popUpButton">
              {res.CANCELAR}
            </Button> &nbsp;
            <Button type="submit" variant="contained" color="primary" className="popUpButton">
              <SaveIcon className="iconMapS" />
              {res.SALVAR}
            </Button>
          </div>
        </div>
        <div className="form-group notTooBig">
          {profile.includes(2) &&
            <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="age-simple">{res.EMPRESA}</InputLabel>
              <Select
                value={this.state.id_company}
                onChange={this.handleChange('id_company')}
                inputProps={{
                  id: 'id_company',
                }}
              >
                {this.state.empresa_data.map((n, key) => {
                  return (
                    <MenuItem key={key} value={n.id}>{n.nick_name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          }
          <TextField
            id="name"
            label={res.NOME}
            value={this.state.name}
            onChange={this.handleChange('name')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          {profile.includes(2) ?
            <div style={{ display: 'flex', flexDirection: 'column', margin: '1rem 0' }}>
              <FormControl >
                <InputLabel id="modelStation">Modelo</InputLabel>
                <Select
                  className="selectStation"
                  labelId="modelStation"
                  id="model"
                  value={this.state.model}
                  onChange={this.handleChange('model')}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  <MenuItem value='' disabled>Selecione o modelo da estação</MenuItem>
                  <MenuItem value='fixa'>Estação Fixa</MenuItem>
                  <MenuItem value='movel'>Estação Móvel</MenuItem>
                </Select>
              </FormControl>
            </div> :
            <TextField
              id="name"
              label={'Modelo'}
              value={this.state.model === 'movel' ? 'Estação Móvel' : 'Estação Fixa'}
              margin="normal"
              fullWidth
            />
          }
          <TextField
            id="serial"
            label={res.SERIAL}
            value={this.state.serial}
            onChange={profile.includes(2) && this.handleChange('serial')}
            margin="normal"
            fullWidth
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 45)
            }}
          />
          <TextField
            id="activationKey"
            label={res.CHAVE_ATIVACAO}
            value={this.state.activationKey}
            onChange={this.handleChange('activationKey')}
            margin="normal"
            fullWidth
            disabled
          />
        </div>
      </form>
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res, profile } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 profile={profile} link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;