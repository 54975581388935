import React from 'react';//, { useState, useEffect }

export default function CustomizedGraph(props) {
  const { values, atual, type, grayZero } = props;

  const color = [
    grayZero && Number(values[0]) === 0 ? "#c7c7c7" : "#e74c3c",
    "#f1da0f",
    "#5db445",
    "#5db445",
    "#6dbfbf",
    "#a536f5",
  ];
  const colorText = "#4F4F4F";
  const colorTop = [
    grayZero && Number(values[0]) === 0 ? "#c7c7c7" : "#e74c3c",
    "#d1a700",
    "#5db445",
    "#5db445",
    "#6dbfbf",
    "#a536f5",
  ];

  const limitAtual = atual ? atual < -99 || atual > 99 ? Math.round(atual) : atual.toFixed(1) : 0;

  const pos = {
    minLimit: Number(values[0]),
    maxLimit: Number(values[4]),
    minTolerance: Number(values[1]),
    maxTolerance: Number(values[3]),
    value: values[2] ? Number(values[2]) : ((Number(values[3]) - Number(values[1])) / 2 + Number(values[1])),
  };
  let size, align, floatText;
  if (type === 'P') {
    //pos.minLimit = 3;
    const length = {
      tolerance: (pos.maxTolerance - pos.value) > (pos.value - pos.minTolerance) ?
        (pos.maxTolerance - pos.value) * 2 :
        (pos.value - pos.minTolerance) * 2,
      limit: (pos.maxLimit - pos.value) > (pos.value - pos.minLimit) ?
        (pos.maxLimit - pos.value) * 2 :
        (pos.value - pos.minLimit) * 2,
    };
    size = {
      minTolerance: (70 / length.limit) * (pos.value - pos.minTolerance),
      maxTolerance: (70 / length.limit) * (pos.maxTolerance - pos.value),
      minLimit: (70 / length.limit) * (pos.value - pos.minLimit),
      maxLimit: (70 / length.limit) * (pos.maxLimit - pos.value),
    };
    align = {
      minTolerance: 50 - size.minTolerance,
      maxTolerance: 50 - size.maxTolerance,
      minLimit: 50 - size.minLimit,
      maxLimit: 50 - size.maxLimit,
    };

    floatText = {
      color: atual >= pos.minTolerance && atual <= pos.maxTolerance ? colorTop[2] :
        atual >= pos.minLimit && atual < pos.minTolerance ? colorTop[1] :
          atual > pos.minTolerance && atual <= pos.maxLimit ? colorTop[4] :
            atual < pos.minLimit ? colorTop[0] :
              atual > pos.maxLimit ? colorTop[5] : 'black',
      align: atual < (pos.minLimit) ? -5 :
        atual > (pos.maxLimit * 1.1) ? 76 :
          (70 / length.limit) * ((length.limit / 2) + (atual >= pos.value ?
            (atual - pos.value) :
            (pos.value - atual) * -1))
    };

  } else {
    const length = {
      tolerance: (pos.maxTolerance - pos.value) > (pos.value - pos.minTolerance) ?
        (pos.maxTolerance - pos.value) * 2 :
        (pos.value - pos.minTolerance) * 2,
      limit: (pos.maxLimit - pos.value) > (pos.value - pos.minLimit) ?
        (pos.maxLimit - pos.value) * 2 :
        (pos.value - pos.minLimit) * 2,
    };
    size = {
      minTolerance: (70 / length.limit) * (pos.value - pos.minTolerance),
      maxTolerance: (70 / length.limit) * (pos.maxTolerance - pos.value),
      minLimit: (70 / length.limit) * (pos.value - pos.minLimit),
      maxLimit: (70 / length.limit) * (pos.maxLimit - pos.value),
    };
    align = {
      minTolerance: 50 - size.minTolerance,
      maxTolerance: 50 - size.maxTolerance,
      minLimit: 50 - size.minLimit,
      maxLimit: 50 - size.maxLimit,
    };

    floatText = {
      color: atual >= pos.minTolerance && atual <= pos.maxTolerance ? colorTop[2] :
        atual >= pos.minLimit && atual < pos.minTolerance ? colorTop[1] :
          atual > pos.minTolerance && atual <= pos.maxLimit ? colorTop[4] :
            atual < pos.minLimit ? colorTop[0] :
              atual > pos.maxLimit ? colorTop[5] : 'black',
      align: atual < (pos.minLimit) ? -5 :
        atual > (pos.maxLimit * 1.1) ? 76 :
          (70 / length.limit) * ((length.limit / 2) + (atual >= pos.value ?
            (atual - pos.value) :
            (pos.value - atual) * -1))
    };
  }

  return (
    <div style={{ marginTop: '.5rem', display: 'flex', justifyContent: 'center', height: '6.5rem', position: 'relative' }}>

      <div style={{ width: '45%', height: '1rem', backgroundColor: color[0], position: 'absolute', top: '2rem', left: '6%', borderRadius: '0.3rem' }}></div>
      <div style={{ width: '45%', height: '1rem', backgroundColor: color[5], position: 'absolute', top: '2rem', right: '6%', borderRadius: '0.3rem' }}></div>

      {pos.minLimit < pos.minTolerance ?
        <div style={{ width: `${size.minLimit}%`, height: '1rem', backgroundColor: color[1], position: 'absolute', top: '2rem', left: `${align.minLimit}%` }}></div>
        : null}
      {pos.maxLimit > pos.maxTolerance ?
        <div style={{ width: `${size.maxLimit}%`, height: '1rem', backgroundColor: color[4], position: 'absolute', top: '2rem', right: `${align.maxLimit}%` }}></div>
        : null}
      {pos.minTolerance < pos.value ?
        <div style={{ width: `${size.minTolerance}%`, height: '1rem', backgroundColor: color[2], position: 'absolute', top: '2rem', left: `${align.minTolerance}%` }}></div>
        : null}
      {pos.maxLimit > pos.value ?
        <div style={{ width: `${size.maxTolerance}%`, height: '1rem', backgroundColor: color[3], position: 'absolute', top: '2rem', right: `${align.maxTolerance}%` }}></div>
        : null}
      <div style={{ width: `2%`, height: '1rem', backgroundColor: color[2], position: 'absolute', top: '2rem' }}></div>

      {pos.minLimit < pos.minTolerance ? <div>
        <div style={{ width: '2px', height: '1.5rem', backgroundColor: colorText, position: 'absolute', top: '1.95rem', left: `${align.minLimit}%` }}></div>
        <div style={{ width: '2rem', position: 'absolute', top: '3.4rem', left: `calc(${align.minLimit}% - 1rem)`, textAlign: 'center' }}>{Math.round(pos.minLimit * 1000) / 1000}</div>
      </div> : null}
      {pos.minTolerance < pos.value ? <div>
        <div style={{ width: '2px', height: '2.5rem', backgroundColor: colorText, position: 'absolute', top: '1.95rem', left: `${align.minTolerance}%` }}></div>
        <div style={{ width: '2rem', position: 'absolute', top: '4.4rem', left: `calc(${align.minTolerance}% - 1rem)`, textAlign: 'center' }}>{Math.round(pos.minTolerance * 1000) / 1000}</div>
      </div> : null}

      {values[2] || pos.minTolerance === pos.maxTolerance ? <div style={{ position: 'absolute', top: '.4rem', textAlign: 'center' }}>{Math.round(pos.value * 1000) / 1000}</div> : null}
      {values[2] || pos.minTolerance === pos.maxTolerance ? <div style={{ width: '2px', height: '1.5rem', backgroundColor: colorText, position: 'absolute', top: '1.6rem' }}></div> : null}

      {pos.maxTolerance > pos.value ? <div>
        <div style={{ width: '2px', height: '1.5rem', backgroundColor: colorText, position: 'absolute', top: '1.95rem', right: `${align.maxTolerance}%` }}></div>
        <div style={{ width: '2rem', position: 'absolute', top: '3.4rem', right: `calc(${align.maxTolerance}% - 1rem)`, textAlign: 'center' }}>{Math.round(pos.maxTolerance * 1000) / 1000}</div>
      </div> : null}
      {pos.maxLimit > pos.maxTolerance ? <div>
        <div style={{ width: '2px', height: '2.5rem', backgroundColor: colorText, position: 'absolute', top: '1.95rem', right: `${align.maxLimit}%` }}></div>
        <div style={{ width: '2rem', position: 'absolute', top: '4.4rem', right: `calc(${align.maxLimit}% - 1rem)`, textAlign: 'center' }}>{Math.round(pos.maxLimit * 1000) / 1000}</div>
      </div> : null}

      {atual ?
        <div style={{
          width: '2.1rem', height: '2.1rem', display: 'flex', justifyContent: 'center', alignItems: 'center',
          border: '2px solid gray', backgroundColor: 'white', borderRadius: '1.3rem',
          position: 'absolute', top: '1.4rem', left: `${floatText.align + 10}%`, fontWeight: 'bold', color: floatText.color
        }}>{limitAtual}</div>
        : null}
    </div>
  );
}
