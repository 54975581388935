import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 3rem)',
    marginLeft: '1.5rem',
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    marginTop: -5,
    marginLeft: -12,
  },
  track: {
    height: 0,
  },
  rail: {
    opacity: 1,
    height: 8,
    borderRadius: 4,
    backgroundImage: "linear-gradient(.25turn, #1bc718, #ffd500, #ff0000)"
  },
})(Slider);

export default function CustomizedSlider(props) {
  const classes = useStyles();
  const { res, value, handleChange } = props;
  const [count, setCount] = useState(20);

  const marks = [
    {
      value: 1,
      label: res.FACIL,
    },
    {
      value: 4,
      label: res.MEDIO,
    },
    {
      value: 7,
      label: res.DIFICIL,
    }
  ];

  useEffect(() => {
    setCount(value && value > 0 ? (value - 1) * 10 : 0);
  }, [value]);

  const handleChangeS = (event, newValue) => {
    if (handleChange) {
      handleChange(event, newValue);
    }
  };

  return (
    <div className={classes.root}>
      <div className="titleTextRel" style={{ marginLeft: '-2rem', marginBottom: '1rem' }}>
        {res.DIFICULDADE + ' (' + count + '%)'}
      </div>
      <PrettoSlider
        aria-label="pretto slider"
        value={value}
        onChange={handleChangeS}
        step={1}
        min={1}
        max={7}
        marks={marks}
      />
    </div>
  );
}
